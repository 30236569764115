import { useEffect, useState } from 'react'

import { useAddItem, useCart } from '@framework/cart'
import type { Product } from '@commerce/types/product'
import { trackAddToCart } from '@lib/datalayer'
import { StoryblokContent } from '@lib/storyblok'

import {
  getProductVariant,
  selectDefaultOptionFromProduct,
  SelectedOptions,
} from '@components/product/helpers'
import { useUI } from '@components/ui'

import { CartItemBody } from '../../framework/commerce/types/cart'
import { ExtendedProduct } from '../../types/storyblok'

export default function useAddToCart(
  story: StoryblokContent,
  product: Product
) {
  const [loading, setLoading] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({})
  const { data: cartContents } = useCart()
  const { openSidebar } = useUI()
  const addItem = useAddItem()

  const variant = getProductVariant(product, selectedOptions)

  useEffect(() => {
    selectDefaultOptionFromProduct(product, setSelectedOptions)
  }, [product])

  const getOptionSelectionsForBC = () =>
    Object.entries(selectedOptions).map(
      ([productDisplayName, optionChoiceLabel]) => {
        const option = product.options.find(
          (option) => option.displayName.toLowerCase() === productDisplayName
        )
        if (!option) {
          return console.error(
            'Could not find option',
            productDisplayName,
            option
          )
        }

        const choice = option.values.find(
          (value) => value.label.toLowerCase() === optionChoiceLabel
        )
        if (!choice) {
          return console.error(
            'Could not find choice',
            option,
            optionChoiceLabel
          )
        }

        return {
          option_id: option.id,
          option_value: choice.entityId,
        }
      }
    )

  const addToCart = async () => {
    setLoading(true)
    try {
      const optionSelections = getOptionSelectionsForBC()

      const cartItemBody = {
        productId: String(product.id),
        variantId: String(variant ? variant.id : product.variants[0].id),
      } as CartItemBody
      if (!!optionSelections.length) {
        // @ts-ignore
        cartItemBody['optionSelections'] = optionSelections || []
      }
      await addItem(cartItemBody)
      trackAddToCart(
        { content: story, bcProduct: product } as ExtendedProduct,
        cartContents
      )
      openSidebar()
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return {
    addToCart,
    loading,
    variant,
  }
}

import React, { ButtonHTMLAttributes, forwardRef, useMemo, useRef } from 'react'


export interface DropdownButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  icon?: React.ReactNode
  children?: React.ReactNode
  highlighted?: boolean
  showNavigationIcon?: boolean
}

const DropdownButton: React.FC<DropdownButtonProps> = ({ highlighted, className, icon, showNavigationIcon, children, ...rest }: DropdownButtonProps) => {
  return (<button
    className={`${highlighted ? 'bg-beige' : 'bg-offwhite'} text-brown font-medium relative flex items-center w-full p-2 cursor-pointer rounded text-sm hover:bg-brown hover:text-offwhite focus:outline-none focus:ring-1 focus:ring-inset focus:ring-brown focus:text-brown focus:bg-beige transition-all ${className}`}
    type="button"
    {...rest}
  >
    {icon}
    <span className="mx-2 whitespace-nowrap">{children}</span>
    {showNavigationIcon && <svg className='w-5 h-5 ml-auto' viewBox='0 0 24 24'>
      <path fill='currentColor' d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z'></path>
    </svg>}
  </button>)
}

export default DropdownButton

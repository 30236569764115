'use client'

import { useEffect, useState } from 'react'
import router from 'next/router'
import Script from 'next/script'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import * as Sentry from '@sentry/nextjs'
import Cookies from 'js-cookie'
import mixpanel from 'mixpanel-browser'
import TagManager from 'react-gtm-module'

import { trackPageView } from '@lib/datalayer'
import useReportImpersonation from '@lib/hooks/useReportImpersonation'
import useUser from '@lib/hooks/useUser'

interface Props {
  children: React.ReactNode
}

declare const window: Window &
  typeof globalThis & {
    klaviyo: any
    edgetag: any
    Northbeam: any
    mazeUniversalSnippetApiKey: any
  }

const growthbook = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_API_KEY,
  enableDevMode: process.env.VERCEL_ENV !== 'production',
  navigate: (url: string) => router.replace(url),
  navigateDelay: 0,
  trackingCallback: (
    experiment: { key: any },
    result: { variationId: any }
  ) => {
    mixpanel.track('$experiment_started', {
      'Experiment name': experiment.key,
      'Variant name': result.variationId,
      $source: 'growthbook',
    })
  },
})

const headers = new Headers()
const anonymousId =
  Cookies.get('anonymousId') ?? headers.get('anonymousId') ?? undefined

export default function IdentifyWrapper({ children }: Props) {
  const { user } = useUser()
  const [klaviyoIdentified, setKlaviyoIdentified] = useState(false)
  const [mixpanelIdentified, setMixpanelIdentified] = useState(false)
  const [edgetagIdentified, setEdgetagIdentified] = useState(false)
  const [sentryIdentified, setSentryIdentified] = useState(false)
  const [tracked, setTracked] = useState(false)
  useReportImpersonation()

  useEffect(() => {
    growthbook.loadFeatures({ autoRefresh: true })
  }, [])

  useEffect(() => {
    try {
      if (sentryIdentified || !Sentry || !user) {
        return
      }

      Sentry.setUser({ email: user.email, id: user.sub })
      setSentryIdentified(true)
    } catch (e) {}
  }, [user, sentryIdentified])

  useEffect(() => {
    try {
      if (mixpanelIdentified || !mixpanel || !user) {
        return
      }

      mixpanel.identify(user.sub)

      if (anonymousId) {
        mixpanel.alias(anonymousId, user.sub)
      }

      mixpanel.people.set({
        $email: user.email,
        $first_name: user.given_name,
        $last_name: user.family_name,
        $name: user.name,
      })

      setMixpanelIdentified(true)
    } catch (e) {}
  }, [user, mixpanelIdentified])

  useEffect(() => {
    try {
      if (klaviyoIdentified || !window.klaviyo || !user) {
        return
      }

      const identity = {
        $email: user.email,
        $first_name: user.given_name,
        $last_name: user.family_name,
      }

      window.klaviyo.identify(identity)
      setKlaviyoIdentified(true)
    } catch (e) {}
  }, [user, klaviyoIdentified])

  useEffect(() => {
    try {
      if (edgetagIdentified || !window.edgetag || !user) {
        return
      }

      window.edgetag('data', {
        email: user.email,
        firstName: user.given_name,
        lastName: user.last_name,
      })

      setEdgetagIdentified(true)
    } catch (e) {}
  }, [user, edgetagIdentified])

  useEffect(() => {
    async function fetchCart() {
      try {
        const response = await fetch('/api/cart')
        if (response.ok) {
          const { data } = await response.json()
          return data
        } else {
          console.error('Failed to fetch cart:', response.status)
        }
      } catch (error) {
        console.error('Failed to fetch cart:', error)
      }
      return null
    }

    async function initialize() {
      const cartData = await fetchCart()
      document.body.classList?.remove('loading')

      window.edgetag =
        window.edgetag ||
        function () {
          window.edgetag.stubs = window.edgetag.stubs || []
          window.edgetag.stubs.push(arguments)
        }

      if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
        window.edgetag('init', {
          edgeURL: 'https://zfccd.mylifeforce.com',
          disableConsentCheck: true,
        })

        TagManager.initialize({
          gtmId: 'GTM-NZF34LG',
        })
      }

      mixpanel.init('011425ba7a5b502351ce5e4ba02deeb5', {
        debug: process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production',
        loaded: function (mx) {
          growthbook.setAttributes({
            ...growthbook.getAttributes(),
            id: mx.get_distinct_id(),
            deviceId: anonymousId,
          })
        },
      })

      // routeChangeComplete is not fired on initial page load so we should manually
      // issue exactly one initial tracking call
      if (!tracked) {
        trackPageView(cartData)
        setTracked(true)
      }

      function updateGrowthBookURL() {
        growthbook.setURL(window.location.href)
      }

      const handleRouteChange = () => trackPageView(cartData)

      router.events.on('routeChangeComplete', handleRouteChange)
      router.events.on('routeChangeComplete', updateGrowthBookURL)

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
        router.events.off('routeChangeComplete', updateGrowthBookURL)
      }
    }
    initialize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    var r: { A?: any; B?: any; C?: any; D?: any; E?: any } = {}
    var n
    var a: any
    ;((n = r = r || {}).A = 'identify'),
      (n.B = 'trackPageView'),
      (n.C = 'fireEmailCaptureEvent'),
      (n.D = 'fireCustomGoal'),
      (n.E = 'firePurchaseEvent')

    function t(n: any, ...args: any[]) {
      for (var e: any[] = [], r = 1; r < args.length; r++) e[r - 1] = args[r]
      a.push({ fnName: n, args: e })
    }
    var e: any =
      (((e = { _q: a } as any)[r.A] = function (n: any, e: any) {
        return t(r.A, n, e)
      }),
      (e[r.B] = function () {
        return t(r.B)
      }),
      (e[r.C] = function (n: any, e: any) {
        return t(r.C, n, e)
      }),
      (e[r.D] = function (n: any, e: any) {
        return t(r.D, n, e)
      }),
      (e[r.E] = function (n: any) {
        return t(r.E, n)
      }),
      (window.Northbeam = e))
  }, [])

  useEffect(() => {
    let timeStamp = null

    try {
      timeStamp = window.sessionStorage.getItem('maze-us')
    } catch (err) {}

    if (!timeStamp) {
      timeStamp = new Date().getTime()

      try {
        window.sessionStorage.setItem('maze-us', `${timeStamp}`)
      } catch (err) {}
    }

    const scriptElement = document.createElement('script')
    scriptElement.src = `https://snippet.maze.co/maze-universal-loader.js?t=${timeStamp}&apiKey=a287f7ac-0776-4bad-bd3d-58552e4f8a1c`
    scriptElement.async = true
    document.getElementsByTagName('head')[0].appendChild(scriptElement)
    window.mazeUniversalSnippetApiKey = 'a287f7ac-0776-4bad-bd3d-58552e4f8a1c'
  }, [])

  const isProduction = process.env.VERCEL_ENV === 'production'

  return (
    <>
      {isProduction && (
        <>
          <Script
            id="klaviyo-snippet"
            src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_API_KEY}`}
            strategy="lazyOnload"
          />
          <Script
            id="edgetag-script"
            src="https://zfccd.mylifeforce.com/load"
            strategy="lazyOnload"
          />
          <Script
            id="northbeam-script"
            src="https://j.northbeam.io/ota-sp/03d8781e-19dc-4b0b-9c09-22c5894572fd.js"
            strategy="lazyOnload"
          />
        </>
      )}

      <GrowthBookProvider growthbook={growthbook}>
        {children}
      </GrowthBookProvider>
    </>
  )
}

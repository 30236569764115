import Image from 'next/image'
import { renderRichText } from '@storyblok/react'

import { StoryblokContent } from '@lib/storyblok'

import { getImageAspectRatio } from './helper'

export default function TestimonialCard({ blok }: StoryblokContent) {
  const { description, image } = blok

  return (
    <div className="testimonial-card relative">
      <figure
        className="relative"
        style={{ aspectRatio: `${getImageAspectRatio(image)}` }}
      >
        <Image
          src={image.filename}
          fill={true}
          alt={image.alt}
          className="object-cover object-center rounded-[4px] select-none"
        />
      </figure>
      <div
        className={`richtext absolute bottom-6 left-6 text-off-white`}
        dangerouslySetInnerHTML={{
          __html: renderRichText(description),
        }}
      />
    </div>
  )
}

import React from 'react'
import { renderRichText, storyblokEditable } from '@storyblok/react/rsc'

import { StoryblokContent } from '@lib/storyblok'

import styles from './TextBanner.module.css'

interface Props {
  blok: StoryblokContent
}

export default function TextBanner({ blok }: Props) {
  const { copy } = blok

  return (
    <div
      className={`${styles['text-banner']} section`}
      {...storyblokEditable(blok)}
    >
      <div
        className={`richtext grid gap-6 text-center max-w-[800px] mx-auto text-dark-brown`}
        dangerouslySetInnerHTML={{ __html: renderRichText(copy) }}
      />
    </div>
  )
}

import classNames from 'classnames'

type Image = {
  filename?: string
  alt?: string
}

type ImagePairModuleProps = {
  blok: {
    component?: string
    image_left?: Image
    image_right?: Image
    image_left_sizing?: 'small' | 'regular'
    image_right_sizing?: 'small' | 'regular'
  }
}

export default function ImagePairModule(props: ImagePairModuleProps) {
  const { blok } = props

  return (
    <div className="flex flex-wrap sm:grid sm:grid-cols-2 gap-4 mt-20">
      <ImageBlock
        sizing={blok.image_left_sizing}
        image={blok.image_left}
        index={0}
      />
      <ImageBlock
        sizing={blok.image_right_sizing}
        image={blok.image_right}
        index={1}
      />
    </div>
  )
}

type ImageBlockProps = {
  image?: Image
  sizing?: 'small' | 'regular'
  index: number
}

function ImageBlock({ image, index, sizing }: ImageBlockProps) {
  if (!image) return null

  return (
    <div
      className={classNames(
        'relative sm:w-full',
        sizing === 'small'
          ? 'order-1 mb-[-12.5%] z-[2] sm:order-[0] w-1/2 sm:px-[12.5%] self-start'
          : ' order-2 sm:order-[0] w-3/4 px-0 self-end',
        sizing === 'small' && index === 0 && '',
        sizing === 'small' && index === 1 && 'ml-auto',
        sizing === 'regular' && index === 1 && 'ml-auto'
      )}
    >
      <img
        src={image.filename}
        alt={image.alt}
        className={classNames(
          'w-full h-auto rounded-[4px]',
          sizing === 'small' ? 'sm:mb-[50%]' : ''
        )}
      />
    </div>
  )
}

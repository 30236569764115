import React, { FC, SVGProps } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { renderRichText } from '@storyblok/react'
import {
  getStoryblokApi,
  ISbStoriesParams,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react/rsc'
import StoryblokStory from '@storyblok/react/story'

import type { Page } from '@commerce/types/page'
import getSlug from '@lib/get-slug'
import { StoryblokContent } from '@lib/storyblok'

import Logo from '@/components-v7/Logo'
import SubscriptionForm from '@/components-v7/SubscriptionForm'

import styles from './Footer.module.css'

const socialLinks = [
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/golifeforce',
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          clipRule="evenodd"
          d="M12,0c6.63,0,12,5.41,12,12.07,0,6.03-4.39,11.02-10.12,11.93v-8.44h2.8l.53-3.49h-3.33v-2.26c0-.84.36-1.65,1.44-1.84.16-.03.33-.04.51-.04h1.51v-2.97s-.09-.01-.24-.04c-.45-.07-1.47-.2-2.45-.2h0c-.34,0-.67.03-.98.08-.41.07-.79.18-1.14.34-1.51.67-2.41,2.13-2.41,4.28v2.66h-3.05v3.49h3.05v8.44C4.39,23.09,0,18.1,0,12.07,0,5.41,5.37,0,12,0Z"
          fillRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/golifeforce/',
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          d="M12,2.16c3.2,0,3.58.01,4.85.07,3.25.15,4.77,1.69,4.92,4.92.06,1.26.07,1.64.07,4.85s-.01,3.58-.07,4.85c-.15,3.23-1.66,4.77-4.92,4.92-1.27.06-1.64.07-4.85.07s-3.58-.01-4.85-.07c-3.26-.15-4.77-1.7-4.92-4.92-.06-1.27-.07-1.64-.07-4.85s.01-3.58.07-4.85c.15-3.23,1.66-4.77,4.92-4.92,1.27-.06,1.64-.07,4.85-.07ZM12,0c-3.26,0-3.67.01-4.95.07C2.69.27.27,2.69.07,7.05c-.06,1.28-.07,1.69-.07,4.95s.01,3.67.07,4.95c.2,4.36,2.62,6.78,6.98,6.98,1.28.06,1.69.07,4.95.07s3.67-.01,4.95-.07c4.35-.2,6.78-2.62,6.98-6.98.06-1.28.07-1.69.07-4.95s-.01-3.67-.07-4.95c-.2-4.35-2.62-6.78-6.98-6.98-1.28-.06-1.69-.07-4.95-.07ZM12,5.84c-3.4,0-6.16,2.76-6.16,6.16s2.76,6.16,6.16,6.16,6.16-2.76,6.16-6.16-2.76-6.16-6.16-6.16ZM12,16c-2.21,0-4-1.79-4-4s1.79-4,4-4,4,1.79,4,4-1.79,4-4,4ZM18.41,4.16c-.8,0-1.44.64-1.44,1.44s.65,1.44,1.44,1.44,1.44-.64,1.44-1.44-.64-1.44-1.44-1.44Z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
]

interface Props {
  blok: StoryblokContent
}

export default function Footer({ blok }: Props) {
  if (!blok) return null
  return (
    <div {...storyblokEditable(blok)} className={styles.footerWrapper}>
      {blok &&
        blok.preFooterBlocks.map((nestedBlok: StoryblokContent) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      <footer className={`${styles.footer}`}>
        <div className="px-4 md:px-6">
          <div className="rounded-[4px] bg-dark-brown text-off-white py-6 px-4 md:py-8 md:px-6">
            <div className="flex flex-col">
              <div className="md:grid md:gap-x-6 grid-cols-footerTabletV7 xl:grid-cols-footerDesktopV7">
                {/* Row Top */}
                <div className="md:col-span-1">
                  <Link className="block" href="/">
                    <Logo className="fill-current w-full h-auto" />
                  </Link>
                </div>

                <div className="my-10 md:my-0 md:col-start-3 md:col-span-1">
                  <div className="mt-4 md:mt-0">
                    <SubscriptionForm className="relative w-full" />
                  </div>
                </div>

                {/* Row Middle */}

                <div className={`md:col-span-1 ${styles.support} `}>
                  <p className="opacity-60 eyebrow text-white mb-8 md:mb-8">
                    Support
                  </p>
                  <div className="flex flex-col lg:flex-row gap-8 md:gap-10">
                    <div
                      className="richtext"
                      dangerouslySetInnerHTML={{
                        __html: renderRichText(blok.description),
                      }}
                    />

                    <div className="">
                      <ul className="richtext">
                        {blok.supportLinks.map(
                          (nestedBlok: StoryblokContent) => (
                            <li key={nestedBlok._uid}>
                              <StoryblokComponent blok={nestedBlok} />
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  className={`md:col-start-3 md:col-span-1 ${styles.navigation}`}
                >
                  {blok.navigation.map((nestedBlok: StoryblokContent) => (
                    <StoryblokComponent
                      blok={nestedBlok}
                      key={nestedBlok._uid}
                    />
                  ))}

                  {/* Social Links Navigation */}
                  <nav
                    className={`${styles['social-menu']} md:col-start-2 self-end`}
                  >
                    {socialLinks.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="text-white"
                        target="_blank"
                      >
                        <span className="sr-only">{item.name}</span>
                        <item.icon
                          className="w-6 aspect-square"
                          aria-hidden="true"
                        />
                      </a>
                    ))}
                  </nav>
                </div>

                <hr className="col-span-3 bt-off-white-70" />

                <div className="richtext text-sm col-span-3 md:grid md:grid-cols-12 relative mt-6 md:mt-6 mb-8">
                  <div className="m-0 md:col-span-10 flex flex-col gap-x-6 gap-y-4 items-start md:flex-row md:flex-wrap">
                    {blok.legalLinks.map((nestedBlok: StoryblokContent) => (
                      <StoryblokComponent
                        blok={nestedBlok}
                        key={nestedBlok._uid}
                      />
                    ))}
                  </div>

                  <div className="md:col-span-2 text-right leading-none absolute md:static top-0 right-0 m-0">
                    <span className="text-sm font-light whitespace-nowrap">
                      &copy; Lifeforce {new Date().getFullYear()}
                    </span>
                  </div>
                </div>

                <div className="md:order-1 md:col-start-3 md:col-span-1 text-center md:text-right my-10 md:my-0">
                  <a
                    className="block w-[102px] h-[88px] mx-auto md:mr-0"
                    href="http://legitscript.com"
                    rel="noreferrer"
                    target="_blank"
                    title="Verify LegitScript Approval"
                  >
                    <Image
                      src="https://static.legitscript.com/seals/12155851.png"
                      alt="LegitScript approved"
                      width={102}
                      height={88}
                      className="block"
                    />
                  </a>
                </div>

                <div
                  className="md:col-span-1 text-xs text-off-white-70 richtext md:max-w-[420px]"
                  dangerouslySetInnerHTML={{
                    __html: renderRichText(blok.legalDescription),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

import { format } from 'date-fns'
import { Subscription } from 'recurly'
import { ProductStory } from 'types/storyblok'

import type { LineItem } from '@commerce/types/cart'
import { Product } from '@commerce/types/product'

export const BC_DX_PRODUCT_ID = '170'
export const BC_DX_VARIANT_ID = '158'

export const BC_LF_MEMBERSHIP_PRODUCT_ID = '165'
export const BC_LF_MEMBERSHIP_VARIANT_ID = '131'
export const BC_LF_MEMBERSHIP_OPTION_ID = 158
export const BC_LF_MEMBERSHIP_OPTION_VALUE = 148

export const LF_CLASSIC_MEMBERSHIP_MONTHLY_PRICE = 149

export const BC_LF_ANN_SLUG = 'lifeforce-membership-annual'
export const BC_LF_ANN_PRODUCT_ID = '175'
export const BC_LF_ANN_VARIANT_ID = '163'

// Butterfly Labs Test Kits
export const BL_TEST_KITS_SKUS = [
  'FB67DB01', // male at home
  'FB67DB02', // male lab
  'FB67DB03', // female at home
  'FB67DB04', // female lab
  'FB67VIR01',
]
export const DX_SKUS = {
  FB67VIR01: true,
  FB67VIR02: true,
  FB67VIR03: true,
  FB67VIR04: true,
  FB67VIR05: true,
  FB67VIR06: true,
  FB67VIR07: true,
  FB67VIR08: true,
  FB67VIR09: true,
  FB67VIR10: true,
  FB67VIR11: true,
  FB67VIR12: true,
  FB67VIR13: true,
  FB67VIR14: true,
  FB67VIR15: true,
  FB67VIR16: true,
}
export const LF_DIAGNOSTIC_SKU = 'FB67VIR01'
export const LF_DIAGNOSTIC_SLUG = 'lifeforce-diagnostic'
export const LF_MEMBERSHIP_SKU = 'LFPPP0001'
export const LF_MEMBERSHIP_SLUG = 'membership'
export const LF_CORE_MEMBERSHIP_SKU = 'LFPPCOREANN'
export const LF_CORE_MEMBERSHIP_SLUG = 'annual-lifeforce-core-membership'
export const BL_TEST_MALE_KIT_CODES = ['BFLY-12YCYQ', 'BFLY-21H6YI']
export const BL_TEST_FEMALE_KIT_CODES = ['BFLY-ZH78U1', 'BFLY-9GNX0G']
export const CONTROLLED_PRODUCTS_NAMES = [
  'Testosterone Gel',
  'Testosterone Injection',
  'Kyzatrex',
]
export const CONTROLLED_PRODUCTS_SKUS = ['TC06HL00', 'TIJ06HL00', 'KX100034']
export const PRESCRIPTION_PRODUCT_NAMES = [
  'Testosterone Gel',
  'Testosterone Injection',
  'Anastrozole',
  'Clomiphene',
  'Sermorelin',
  'Metformin',
  'Acarbose',
  'Micronized Progesterone',
  'Desiccated Thyroid',
  'Tadalafil',
  'Estradiol Cream',
  'PT-141',
  'Semaglutide',
  'Estradiol Patch',
  'Rosuvastatin',
  'Tirzepatide',
  'Kyzatrex',
]
export const PHARMA_SKUS = [
  'PT008001',
  'AC08AL01',
  'TC06HL00',
  'MET-00010',
  'SERM0000915MG',
  'COLMI0000650MG',
  'LFDANST000055MG',
  'TC06HL00',
  'TIJ06HL00',
  'MP700457',
  'DT029393',
  'TAD400100',
  'EC002006',
  'TAD400100',
  'SEMA00091P1',
  'EP002006',
  'ST002023',
  'TIR087542',
  'KX100034',
]

export const NX_SKUS = [
  'PEAK-RISE-00023',
  'PEAK-REST-00024',
  'PEAK-NMN-00027',
  'OMEGA-00029',
  'DHEA-00030',
  'MAGN-00031',
  'METHY-00032',
  'PEAK-DEF-00030',
  'VIT-K-00050',
  'PEAK-HEALTH-00035',
  `COQ-00023`,
]

export const MEMBERSHIP_SKUS = ['LFPPP0001', 'LFPPCOREANN']

interface SkuIconCopyMapping {
  [sku: string]: {
    icon: string
    copy: string
  }
}

export const NX_SKUS_ICON_COPY_MAPPING: SkuIconCopyMapping = {
  'peak-nmn': {
    icon: 'dna',
    copy: 'HEALTHSPAN + LONGEVITY',
  },
  'peak-healthspan': {
    icon: 'clock',
    copy: 'AGING + METABOLISM',
  },
  'peak-rest': {
    icon: 'moon',
    copy: 'SLEEP + RECOVERY',
  },
  'peak-defense': {
    icon: 'lungs',
    copy: 'IMMUNE SYSTEM',
  },
  'peak-rise': {
    icon: 'thunder',
    copy: 'ENERGY + FOCUS',
  },
  dhea: {
    icon: 'medical-jar',
    copy: 'HORMONE HEALTH',
  },
  methylation: {
    icon: 'arrows-loop',
    copy: 'DETOXIFICATION',
  },
  'vitamin-d-k': {
    icon: 'bone',
    copy: 'BONE + IMMUNE + MUSCLE',
  },
  omega: {
    icon: 'heart',
    copy: 'CARDIAC + IMMUNE',
  },
  magnesium: {
    icon: 'brain',
    copy: 'BRAIN + MUSCLE',
  },
  coq10: {
    icon: 'heart',
    copy: 'CARDIAC + MUSCLE',
  },
}

export const OVERRIDE_CART_LINE_DESCRIPTION = {
  // LFPPPANN1: '2 months free',
}

export const SINGLE_QUANTITY_PRODUCT_IDS = [
  '170', // dx
  '175', // annual membership
]

export const CATEGORIES: { [key: string]: string } = {
  '20': 'Publications',
  '23': 'Shop All',
  '24': 'Testing',
  '25': 'Injections',
  '26': 'Creams',
  '27': 'Tabs',
  '28': 'Capsules',
  '29': 'Foams',
  '30': 'Regimens',
  '31': 'Optimization',
  '32': 'Nutraceuticals',
  '33': 'membership',
  '34': 'Digital',
}

interface EnrichedProduct extends ProductStory {
  bcProduct: Product
  userSubscribed?: Subscription[]
  dosingInstructions?: string
  subtotal?: number|null
}

export type { EnrichedProduct }

export const LIMITLESS_DX_PROMOTION_COUPON =
  'limitless_diagnostic_membership_5812'
export const LIVE_BETTER_LONGER_DX_PROMOTION_COUPON =
  'get_started_diagnostic_membership_5812'
export const NESSWELL_DX_PROMOTION_COUPON =
  'nesswell_diagnostic_membership_2853'
export const HYPERICE_DX_PROMOTION_COUPON = 'hyperice_diagnostic_2854'
export const A360_FREE_DX_PROMOTION_COUPON = 'a360_free_dx_9350'
export const UPW_DX_GWP_PROMOTION_COUPON = 'upw_gwp_march_2023_membership_5318'

export const FREE_PRODUCT_COUPONS = [
  {
    couponCode: 'BFREST22', // coupon code that triggers the free product
    requiredProductSlugs: ['lifeforce-diagnostic'], // required products if any to trigger the free product
    requiredProductRejectMessage:
      'You must purchase a Lifeforce Diagnostic to use this coupon.', // message to display if required products are not in the cart
    // products that will be added to cart for free
    products: [
      {
        sku: 'PEAK-REST-00024',
        slug: 'peak-rest',
        quantity: 1,
        shippingRequired: true,
        description: null,
      },
    ],
    addToCartMessage: 'BFREST22 Comes with a free bottle of Peak Rest!', // message to display when the free product is added to cart
  },
  {
    couponCode: 'BFRISE22', // coupon code that triggers the free product
    requiredProductSlugs: ['lifeforce-diagnostic'], // required products if any to trigger the free product
    requiredProductRejectMessage:
      'You must purchase a Lifeforce Diagnostic to use this coupon.', // message to display if required products are not in the cart
    // products that will be added to cart for free
    products: [
      {
        sku: 'PEAK-RISE-00023',
        slug: 'peak-rise',
        quantity: 1,
        shippingRequired: true,
        description: null,
      },
    ],
    addToCartMessage: 'BFRISE22 Comes with a free bottle of Peak Rise!', // message to display when the free product is added to cart
  },
  {
    couponCode: LIVE_BETTER_LONGER_DX_PROMOTION_COUPON,
    requiredProductSlugs: ['lifeforce-diagnostic'],
    requiredProductRejectMessage:
      'You must purchase a Lifeforce Diagnostic to get a discounted membership.',
    products: [
      {
        sku: LF_MEMBERSHIP_SKU,
        slug: LF_MEMBERSHIP_SLUG,
        quantity: 1,
        shippingRequired: false,
        description: () => {
          let startsAt = new Date()
          startsAt = new Date(startsAt.setMonth(startsAt.getMonth() + 1))
          return `Next billing date: ${format(
            startsAt,
            'M/d/yyyy'
          )} for ${LF_CLASSIC_MEMBERSHIP_MONTHLY_PRICE}`
        },
      },
    ],
    addToCartMessage: null,
  },
  {
    couponCode: HYPERICE_DX_PROMOTION_COUPON,
    requiredProductSlugs: ['lifeforce-diagnostic'],
    requiredProductRejectMessage:
      'You must purchase a Lifeforce Diagnostic to get a discounted membership.',
    products: [
      {
        sku: LF_MEMBERSHIP_SKU,
        slug: LF_MEMBERSHIP_SLUG,
        quantity: 1,
        shippingRequired: false,
        description: () => {
          let startsAt = new Date()
          startsAt = new Date(startsAt.setMonth(startsAt.getMonth() + 1))
          return `Next billing date: ${format(startsAt, 'M/d/yyyy')} for $99`
        },
      },
    ],
    addToCartMessage: null,
  },
  {
    couponCode: NESSWELL_DX_PROMOTION_COUPON,
    requiredProductSlugs: [
      'lifeforce-membership-annual',
      'lifeforce-diagnostic',
    ],
    requiredProductRejectMessage:
      'Must purchase both Lifeforce Diagnostic and Membership',
    products: [],
    addToCartMessage: null,
  },
  {
    couponCode: A360_FREE_DX_PROMOTION_COUPON,
    requiredProductSlugs: [
      'lifeforce-membership-annual',
      'lifeforce-diagnostic',
    ],
    requiredProductRejectTitle:
      'Must purchase both Lifeforce Diagnostic and Membership',
    requiredProductRejectMessage: '',
    products: [],
    addToCartMessage: null,
  },
  {
    couponCode: UPW_DX_GWP_PROMOTION_COUPON,
    requiredProductSlugs: ['lifeforce-diagnostic'],
    requiredProductRejectMessage:
      'You must purchase a Lifeforce Diagnostic to get this offer.',
    products: [
      {
        sku: LF_MEMBERSHIP_SKU,
        slug: LF_MEMBERSHIP_SLUG,
        quantity: 1,
        shippingRequired: false,
        description: () => {
          let startsAt = new Date()
          startsAt = new Date(startsAt.setMonth(startsAt.getMonth() + 1))
          return `Next billing date: ${format(
            startsAt,
            'M/d/yyyy'
          )} for ${LF_CLASSIC_MEMBERSHIP_MONTHLY_PRICE}`
        },
      },
      {
        sku: 'PEAK-RISE-00023',
        slug: 'peak-rise',
        quantity: 1,
        shippingRequired: true,
        description: null,
      },
      {
        sku: 'PEAK-REST-00024',
        slug: 'peak-rest',
        quantity: 1,
        shippingRequired: true,
        description: null,
      },
      {
        sku: 'PEAK-DEF-00030',
        slug: 'peak-defense',
        quantity: 1,
        shippingRequired: true,
        description: null,
      },
    ],
    addToCartMessage: null,
  },
]

// Memberships are only sold as part of a dx + membership promotion now.  Due to
// implementation details of these promotions the membership is never actually in
// either the cart or the recurly invoice as a line item.
//
// One negative side effect of this is that we lose all analytics tracking for
// the membership as it's all lineitem based.
//
// For now we will resort to mocking a line item for the membership and adding it
// manually during tracking when the invoice contains a membership granting promotion.
export const membershipLineItem: LineItem = {
  variantId: BC_LF_MEMBERSHIP_VARIANT_ID,
  productId: BC_LF_MEMBERSHIP_PRODUCT_ID,
  name: 'Lifeforce Membership',
  quantity: 1,
  variant: {
    id: BC_LF_MEMBERSHIP_VARIANT_ID,
    sku: LF_MEMBERSHIP_SKU,
    name: 'Lifeforce Membership',
    image: {
      url: 'https://cdn11.bigcommerce.com/s-byr25matix/products/165/images/444/Lifeforce_Membership_1316x1122__45667.1661442435.220.290.jpg?c=1',
    },
    price: LF_CLASSIC_MEMBERSHIP_MONTHLY_PRICE,
    listPrice: LF_CLASSIC_MEMBERSHIP_MONTHLY_PRICE,
    requiresShipping: true,
  },
  path: LF_MEMBERSHIP_SLUG || LF_CORE_MEMBERSHIP_SLUG,
  discounts: [],
  options: [{ name: 'Frequency', nameId: 158, value: 'Monthly', valueId: 148 }],
  childOptions: [],
  id: 'some-arbitrary-id',
  brand: 'Lifeforce',
  price: LF_CLASSIC_MEMBERSHIP_MONTHLY_PRICE,
  category: 'membership',
  categories: ['membership'],
}

export const MEMBERSHIP_LINE_ITEM = {
  sku: 'LFPPP0001',
  variants: [
    {
      id: 131,
      options: [],
    },
  ],
  id: '165',
  options: [
    {
      id: 158,
      displayName: 'test',
      values: [
        {
          label: 'test',
          // id: 158,
          entityId: 148,
        },
      ],
    },
  ],
}

export const DEFAULT_SETUP_SUBTOTAL = 549
export const DEFAULT_SETUP_TOTAL = 349

'use client'
import React from 'react'
import Image from 'next/image'
import { renderRichText, storyblokEditable } from '@storyblok/react/rsc'

import { StoryblokContent } from '@lib/storyblok'

import { getImageAspectRatio } from '../helper'

import styles from './StickyColumns.module.css'

interface Props {
  blok: StoryblokContent
}

export default function StickyColumns({ blok }: Props) {
  const {
    desktopImagePlacement,
    intro,
    mainImage,
    secondaryImage,
    secondaryImageDescription,
    bg_color,
    display_outro,
    outro,
  } = blok

  const secondaryImageDimensions = secondaryImage.filename
    .split('/')[5]
    .split('x')
  const secondaryImageAspectRatio =
    secondaryImageDimensions[0] / secondaryImageDimensions[1]
  return (
    <div
      className={`${styles['sticky-column']} ${
        bg_color === 'light-beige' ? 'bg-light-beige' : 'bg-off-white'
      } section grid gap-x-4 lg:gap-x-6 lg:gap-y-20 grid-cols-4 lg:grid-cols-12 px-4 lg:px-6 `}
      {...storyblokEditable(blok)}
    >
      <div className="grid grid-cols-[subgrid] col-span-full lg:col-span-7 gap-y-14 lg:gap-y-0">
        {intro.content.length > 1 && (
          <div className="col-span-full lg:col-span-5 lg:col-start-2">
            <div
              className={`richtext ${styles['sticky-richtext']}`}
              dangerouslySetInnerHTML={{ __html: renderRichText(intro) }}
            />
          </div>
        )}

        <figure
          className={`relative col-span-3 lg:col-span-full lg:mt-20 ${
            desktopImagePlacement == 'reverse' ? ' col-start-2' : ''
          }`}
          style={{ aspectRatio: `${getImageAspectRatio(mainImage)}` }}
        >
          <Image
            src={mainImage.filename}
            fill={true}
            alt={mainImage.alt}
            className="object-cover object-center rounded-[4px]"
          />
        </figure>
      </div>

      <div
        className={`grid lg:block grid-cols-[subgrid] col-span-full lg:col-span-5${
          desktopImagePlacement == 'reverse'
            ? ' lg:col-start-1 lg:row-start-1'
            : ''
        }`}
      >
        <div className="grid lg:block grid-cols-[subgrid] col-span-full -mt-[56px] lg:mt-0 lg:w-4/5 lg:mx-auto lg:sticky lg:top-[calc(var(--header-top--offset)_+_80px)] transition-[top] duration-500">
          <figure
            className={`relative col-span-2${
              desktopImagePlacement == 'reverse' ? '' : ' col-start-3'
            }`}
            style={{
              aspectRatio: `${secondaryImageAspectRatio > 1.2 ? 1.25 : 1}`,
            }}
          >
            <Image
              src={secondaryImage.filename}
              fill={true}
              alt={secondaryImage.alt}
              className="object-cover object-center rounded-[4px]"
            />
          </figure>

          {secondaryImageDescription && (
            <div
              className={`richtext ${styles['sticky-richtext']} col-span-full mt-5 lg:mt-8`}
              dangerouslySetInnerHTML={{
                __html: renderRichText(secondaryImageDescription),
              }}
            />
          )}
        </div>
      </div>

      {display_outro && outro && (
        <div className={`${styles['sticky-outro']}`}>
          <div
            className={`richtext ${styles['sticky-richtext']} lg:text-center`}
            dangerouslySetInnerHTML={{ __html: renderRichText(outro) }}
          />
        </div>
      )}
    </div>
  )
}

const ArrowRight = ({ ...props }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="40"
        height="40"
        rx="20"
        fill="#FDD17C"
        className="group-hover:fill-dark-brown transition-colors duration-200 ease-in-out"
      />
      <path
        d="M14 20.75C13.5858 20.75 13.25 20.4142 13.25 20C13.25 19.5858 13.5858 19.25 14 19.25V20.75ZM26.5303 19.4697C26.8232 19.7626 26.8232 20.2374 26.5303 20.5303L21.7574 25.3033C21.4645 25.5962 20.9896 25.5962 20.6967 25.3033C20.4038 25.0104 20.4038 24.5355 20.6967 24.2426L24.9393 20L20.6967 15.7574C20.4038 15.4645 20.4038 14.9896 20.6967 14.6967C20.9896 14.4038 21.4645 14.4038 21.7574 14.6967L26.5303 19.4697ZM14 19.25H26V20.75H14V19.25Z"
        fill="#26180F"
        className="group-hover:fill-yellow transition-colors duration-200 ease-in-out"
      />
    </svg>
  )
}

export default ArrowRight

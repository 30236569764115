'use client'

import React, { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { renderRichText } from '@storyblok/react'
import { AnimatePresence, motion, useInView } from 'framer-motion'

import { StoryblokContent } from '@lib/storyblok'

import { Button } from '@components/design'
import useAddToCartSlug from '@components-v7/UseAddToCartSlug'

import { slugify } from '../PurchaseModule/PurchaseNav'

function linkResolver(link: {
  linktype: string
  story: { url: string }
  url: string
}) {
  switch (link.linktype) {
    case 'story': {
      var href: string = link.story ? `/${link.story.url}` : ''
      break
    }
    case 'url': {
      var href: string = link.url
      break
    }
    default: {
      var href: string = `https://www.google.com/`
    }
  }
  return href
}

interface Props {
  blok: StoryblokContent
  setActiveSection?: (section: string) => void
}

interface Biomarker {
  _uid: string
  heading: string
  description: string
  common_symptoms: string
  biomarkers_tested: string
  bottom_text: string
}

export default function BiomarkersDrawers(props: Props) {
  const { blok, setActiveSection } = props
  const {
    text,
    cta_link,
    cta_text,
    biomarkers,
    Title,
    add_to_cart_product_slug,
  } = blok

  const [openDrawers, setOpenDrawers] = useState<{ [key: string]: boolean }>({})
  const pathname = usePathname() ?? ''

  const { isAddToCartCtaDisabled, addProductToCart } = useAddToCartSlug({
    add_to_cart_product_slug,
    story: blok,
  })

  const toggleDrawer = (id: string) => {
    setOpenDrawers((prev) => ({ ...prev, [id]: !prev[id] }))
  }

  const ref = React.useRef<HTMLDivElement>(null)
  const inView = useInView(ref)

  useEffect(() => {
    if (inView) {
      setActiveSection?.(Title || '')
    }
  }, [inView])

  return (
    <div
      ref={ref}
      id={slugify(Title || '')}
      className={`bg-off-white section section--bg-light-beige px-4 ${
        pathname.includes('/product/')
          ? 'lg:px-0 !pb-0 lg:rounded-b-none'
          : 'lg:px-6'
      }`}
    >
      <div
        className={`rounded bg-light-beige px-4 lg:px-6 pt-8 pb-7 lg:pt-8 lg:pb-10 ${
          pathname.includes('/product/') ? 'lg:rounded-b-none' : ''
        }`}
      >
        <div className="flex flex-col gap-8 lg:flex-row lg:justify-between">
          {text && (
            <div className="max-w-[564px]">
              <div
                className="richtext"
                dangerouslySetInnerHTML={{ __html: renderRichText(text) }}
              />
            </div>
          )}
          {add_to_cart_product_slug && cta_text && (
            <div className="block">
              <Button
                className="max-w-max"
                color="secondary"
                onClick={() => addProductToCart()}
                disabled={isAddToCartCtaDisabled}
              >
                {cta_text}
              </Button>
            </div>
          )}
          {!add_to_cart_product_slug && cta_text && (
            <div className="block">
              <Link
                className="button button--secondary max-w-max"
                href={linkResolver(cta_link)}
                title={cta_text}
              >
                {cta_text}
              </Link>
            </div>
          )}
        </div>
        {biomarkers && (
          <div className="border-t border-dark-brown-40 mt-10 lg:mt-14">
            {biomarkers.map((biomarker: Biomarker, index: number) => {
              const {
                _uid,
                heading,
                description,
                common_symptoms,
                biomarkers_tested,
                bottom_text,
              } = biomarker
              return (
                <div key={_uid} className={`border-b border-dark-brown-40`}>
                  <button
                    aria-label={heading}
                    onClick={() => toggleDrawer(index.toString())}
                    className="w-full flex flex-row items-center justify-center gap-4 md:gap-6 py-4 text-dark-brown"
                  >
                    <span
                      className={`h3 ${
                        pathname.includes('/product/')
                          ? 'lg:text-[3.5rem]'
                          : 'lg:text-[5rem]'
                      }`}
                    >
                      {heading}
                    </span>
                    <svg
                      width="40"
                      height="41"
                      viewBox="0 0 40 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-auto h-[32px] lg:h-[41px]"
                    >
                      <circle
                        cx="20"
                        cy="20.5002"
                        r="19.5"
                        stroke="#26180F"
                        strokeOpacity="0.4"
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1="11"
                        y1="20.5"
                        x2="29"
                        y2="20.5"
                        stroke="#26180F"
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1="20"
                        y1="11.5"
                        x2="20"
                        y2="29.5"
                        stroke="#26180F"
                        vectorEffect="non-scaling-stroke"
                        className={`transition-opacity duration-200 ease-in-out ${
                          openDrawers[index as keyof typeof openDrawers]
                            ? 'opacity-0'
                            : 'opacity-100'
                        }`}
                      />
                    </svg>
                  </button>
                  <div className="relative overflow-hidden">
                    <AnimatePresence initial={true}>
                      {openDrawers[index as keyof typeof openDrawers] && (
                        <motion.div
                          className="overflow-hidden"
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { height: 'auto' },
                            collapsed: { height: 0 },
                          }}
                          transition={{
                            duration: 0.3,
                          }}
                        >
                          <div
                            className={`flex flex-col gap-8 lg:gap-10 mb-10 lg:mb-20`}
                          >
                            <p className="text-center text-[14px] lg:text-[18px] text-dark-brown-65 mt-2 max-w-[566px] mx-auto">
                              {description}
                            </p>
                            <div className="flex flex-col lg:flex-row gap-4 lg:gap-6">
                              <div className="bg-off-white p-6 rounded w-full lg:w-1/2">
                                <span className="block eyebrow w-full text-center mb-6 pb-6 border-b border-dark-brown-40 text-dark-brown">
                                  Common Symptoms
                                </span>
                                <div className="flex flex-row flex-wrap gap-2 items-center justify-center">
                                  {common_symptoms
                                    .split(',')
                                    .map((tag, index) => (
                                      <span
                                        className="text-[14px] bg-light-orange px-3 py-1 rounded-[40px] text-dark-brown leading-[18px] cursor-default"
                                        key={index}
                                      >
                                        {tag.trim()}
                                      </span>
                                    ))}
                                </div>
                              </div>
                              <div className="bg-off-white p-6 rounded w-full lg:w-1/2">
                                <span className="block eyebrow w-full text-center mb-6 pb-6 border-b border-dark-brown-40 text-dark-brown">
                                  Biomarkers Tested
                                </span>
                                <div className="flex flex-row flex-wrap gap-2 items-center justify-center">
                                  {biomarkers_tested
                                    .split(',')
                                    .map((tag, index) => (
                                      <span
                                        className="text-[14px] bg-light-sage px-3 py-1 rounded-[40px] text-dark-brown leading-[18px] cursor-default"
                                        key={index}
                                      >
                                        {tag.trim()}
                                      </span>
                                    ))}
                                </div>
                              </div>
                            </div>
                            {bottom_text && (
                              <p className="text-center text-[14px] lg:text-[18px] mt-2 text-dark-brown-65 max-w-[566px] mx-auto">
                                {bottom_text}
                              </p>
                            )}
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

import React from 'react'
import Link from 'next/link'
import {
  renderRichText,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react/rsc'

import { StoryblokContent } from '@lib/storyblok'

import { Button } from '@components/design'
import useAddToCartSlug from '@components-v7/UseAddToCartSlug'

import styles from './MultiColumn.module.css'

function linkResolver(link: {
  linktype: string
  story: { url: string }
  url: string
}) {
  switch (link.linktype) {
    case 'story': {
      var href: string = `/${link.story?.url || '/'}`
      break
    }
    case 'url': {
      var href: string = link.url
      break
    }
    default: {
      var href: string = `https://www.google.com/`
    }
  }
  return href
}

interface Props {
  blok: StoryblokContent
}

export default function MultiColumn({ blok }: Props) {
  const { intro, link_label, link, add_to_cart_product_slug } = blok

  const { isAddToCartCtaDisabled, addProductToCart } = useAddToCartSlug({
    add_to_cart_product_slug,
    story: blok,
  })

  return (
    <div
      className="multi-column section bg-off-white px-4 lg:px-6"
      {...storyblokEditable(blok)}
    >
      <div
        className={`text-center richtext ${styles['custom-rte']} max-w-[343px] lg:max-w-[550px] mx-auto`}
        dangerouslySetInnerHTML={{ __html: renderRichText(intro) }}
      ></div>

      <div className="multi-column__cols grid lg:grid-flow-col auto-cols-fr my-10 lg:my-14 bg-light-beige lg:py-6 px-4 rounded">
        {blok.columns.map((nestedBlok: StoryblokContent) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>

      {add_to_cart_product_slug && link_label && (
        <div className="text-center">
          <Button
            className="max-w-max mx-auto"
            onClick={() => addProductToCart()}
            disabled={isAddToCartCtaDisabled}
          >
            {link_label}
          </Button>
        </div>
      )}

      {!add_to_cart_product_slug && link_label && (
        <div className="text-center">
          <Link
            className="button max-w-max mx-auto"
            href={linkResolver(link)}
            title={link_label}
          >
            {link_label}
          </Link>
        </div>
      )}
    </div>
  )
}

'use client'

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import useSWR from 'swr'

import useUser from '@lib/hooks/useUser'

import { AccountResult } from '../../pages/api/account'

const fetcher = (url: string) =>
  fetch(url).then((res) => {
    if (res.status <= 201) return res.json()

    return null
  })

type AccountContextType = {
  fetchAccountData: () => Promise<void>
  getUserData: () => any
  getAddressData: () => any
  getDxAddressData: () => any
  isUserLoading: boolean
  refetchUserInfo: () => Promise<any>
  user: any
  accountData: any
  showRegisterTest: boolean
  setShowRegisterTest: (val: boolean) => void
}

const AccountContextDefaultValues: AccountContextType = {
  fetchAccountData: () => Promise.resolve(),
  getUserData: () => {},
  getAddressData: () => {},
  getDxAddressData: () => {},
  isUserLoading: false,
  refetchUserInfo: () => Promise.resolve(),
  user: null,
  accountData: null,
  showRegisterTest: false,
  setShowRegisterTest: (val: boolean) => {},
}

const AccountContext = createContext<AccountContextType>(
  AccountContextDefaultValues
)

export function useAccount() {
  return useContext(AccountContext)
}

type Props = {
  children: ReactNode
}

export function AccountProvider({ children }: Props) {
  const { user, refetch, isLoading: isUserLoading } = useUser(false)
  const { data, mutate } = useSWR<AccountResult>('/api/account', fetcher)

  const infoData = {
    email: user?.email || '',
    emailVerified: user?.email_verified || false,
    firstName: user?.given_name || data?.firstName || '',
    lastName: user?.family_name || data?.lastName || '',
    userObj: user || null,
    dataObj: data || null,
  }

  const addressInfo = {
    streetAddress: data?.address?.street1 || '',
    streetAddress2: data?.address?.street2 || '',
    zip: data?.address?.postalCode || '',
    city: data?.address?.city || '',
    state: data?.address?.region || '',
    phone: user?.address?.phone || data?.address?.phone || '',
  }

  const dxAddressInfo = {
    streetAddress: data?.dxAddress?.street1 || addressInfo.streetAddress,
    streetAddress2: data?.dxAddress?.street2 || addressInfo.streetAddress2,
    zip: data?.dxAddress?.postalCode || addressInfo.zip,
    city: data?.dxAddress?.city || addressInfo.city,
    state: data?.dxAddress?.region || addressInfo.state,
    phone: data?.dxAddress?.phone || addressInfo.phone,
  }

  const [userData, setUserData] = useState(infoData)
  const [addressData, setAddressData] = useState(addressInfo)
  const [dxAddressData, setDxAddressData] = useState(dxAddressInfo)
  const [showRegisterTest, setShowRegisterTest] = useState(false)

  useEffect(() => {
    if (!userData.email) {
      fetchAccountData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  const fetchAccountData = async () => {
    const mutatedData = await mutate()
    const userInfo = await refetch()

    if (
      userInfo &&
      !('error' in userInfo) &&
      mutatedData &&
      !('error' in mutatedData)
    ) {
      setUserData({
        email: userInfo?.email || userData.email,
        emailVerified: userInfo?.email_verified || userData.emailVerified,
        firstName:
          userInfo?.given_name || mutatedData?.firstName || userData.firstName,
        lastName:
          userInfo?.family_name || mutatedData?.lastName || userData.lastName,
        userObj: userInfo || userData.userObj,
        dataObj: mutatedData || userData.dataObj,
      })

      setAddressData({
        phone:
          userInfo?.address?.phone ||
          mutatedData?.address?.phone ||
          addressData.phone,
        streetAddress:
          mutatedData?.address?.street1 || addressData.streetAddress,
        streetAddress2:
          mutatedData?.address?.street2 ?? addressData.streetAddress2,
        zip: mutatedData?.address?.postalCode || addressData.zip,
        city: mutatedData?.address?.city || addressData.city,
        state: mutatedData?.address?.region || addressData.state,
      })

      setDxAddressData({
        phone:
          userInfo?.address?.phone ||
          mutatedData?.dxAddress?.phone ||
          addressData.phone,
        streetAddress:
          mutatedData?.dxAddress?.street1 || dxAddressData.streetAddress,
        streetAddress2:
          mutatedData?.dxAddress?.street2 || dxAddressData.streetAddress2,
        zip: mutatedData?.dxAddress?.postalCode || dxAddressData.zip,
        city: mutatedData?.dxAddress?.city || dxAddressData.city,
        state: mutatedData?.dxAddress?.region || dxAddressData.state,
      })
    }
  }

  const getUserData = () => {
    return userData
  }

  const getAddressData = () => {
    return addressData
  }

  const getDxAddressData = () => {
    return dxAddressData
  }

  const refetchData = () => {
    return Promise.all([refetch, fetchAccountData])
  }

  const valueObj = {
    fetchAccountData,
    getUserData,
    getAddressData,
    getDxAddressData,
    isUserLoading,
    user,
    accountData: data,
    refetchUserInfo: refetchData,
    setShowRegisterTest,
    showRegisterTest,
  }

  return (
    <>
      <AccountContext.Provider value={valueObj}>
        {children}
      </AccountContext.Provider>
    </>
  )
}

import { isAfter, parseJSON, subDays } from 'date-fns'

export const hasPurchasedItem = async (
  itemSKUs: string[],
  previousDays?: number
): Promise<boolean> => {
  try {
    const data = await fetch('/api/orders')
    const ordersData = await data.json()
    const orders = previousDays
      ? ordersData.orders.filter((order: any) =>
          isAfter(
            parseJSON(order.created_at),
            subDays(new Date(), previousDays)
          )
        )
      : ordersData.orders
    const skus = itemSKUs.map((sku: string) => sku.toLowerCase())

    return orders.some((order: any) =>
      order.line_items.some((item: any) =>
        skus.includes(item.variant.sku.toLowerCase())
      )
    )
  } catch (e) {
    return false
  }
}

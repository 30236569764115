import { useEffect, useState } from 'react'

export const elementsOverlap = (el1: any, el2: any): boolean => {
  const domRect1 = el1.getBoundingClientRect()
  const domRect2 = el2.getBoundingClientRect()

  return !(
    domRect1.top > domRect2.bottom ||
    domRect1.right < domRect2.left ||
    domRect1.bottom < domRect2.top ||
    domRect1.left > domRect2.right
  )
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}

export const downloadFile = async (
  fileUrl: string,
  fileName: string,
  blank: boolean = false
) => {
  const resp = await fetch(fileUrl)
  if (!resp.ok) {
    const body = (await resp.json()) as any
    throw new Error(body.message)
  }
  const file = await resp.blob()
  const link = document.createElement('a')
  link.href = URL.createObjectURL(file)
  if (blank) {
    link.target = '_blank'
    link.rel = 'noopener noreferrer'
  } else {
    link.setAttribute('download', fileName)
  }
  document.body.appendChild(link)
  link.click()
  link.remove()
}

const SESSION_STORAGE_PROMOTION_COUPON_KEY = 'lf_last_promotion_coupon'

export const saveLastPromotionToSessionStorage = (coupon: string | null) => {
  if (!coupon) sessionStorage.removeItem(SESSION_STORAGE_PROMOTION_COUPON_KEY)
  else sessionStorage.setItem(SESSION_STORAGE_PROMOTION_COUPON_KEY, coupon)
}

export const getLastPromotionFromSessionStorage = (): string | null => {
  return sessionStorage.getItem(SESSION_STORAGE_PROMOTION_COUPON_KEY)
}

export const truncateString = (str: string, length: number): string => {
  if (!str) return str

  if (str.length <= length) return str

  return str.substring(0, length)
}

export const formatPhoneNumber = (number: string) => {
  let cleaned = ('' + number).replace(/\D/g, '')
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`
  }

  return number
}

'use client'

import React from 'react'
import { Toaster } from 'react-hot-toast'

export default function ToasterComponent() {
  return <Toaster
    position="bottom-center"
    containerStyle={{ top: 60 }}
    toastOptions={{ duration: 5000 }}
  />
}

'use client'

import React, { useCallback, useEffect, useState } from 'react'

const PageHasScrolled = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [scrollDirection, setScrollDirection] = useState('')

  const handlePageScroll = useCallback(
    (e) => {
      const window = e.currentTarget
      if (scrollPosition > window.scrollY) {
        setScrollDirection('up')
      } else if (scrollPosition < window.scrollY) {
        setScrollDirection('down')
      }
      setScrollPosition(window.scrollY)

      requestAnimationFrame(() => {
        document.documentElement.toggleAttribute(
          'scrolled',
          window.scrollY > 65
        )
        document.documentElement.setAttribute(
          'data-scroll-direction',
          scrollDirection
        )
      })
    },
    [scrollDirection, scrollPosition]
  )

  useEffect(() => {
    setScrollPosition(window.scrollY)
    // handleScroll()
    window.addEventListener('scroll', handlePageScroll)
    return () => {
      window.removeEventListener('scroll', handlePageScroll)
    }
  }, [handlePageScroll])

  return <></>
}

export default PageHasScrolled

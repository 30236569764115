import Image from 'next/image'
import classNames from 'clsx'

import { ReviewerContent } from '@lib/blog'

interface Props {
  className?: string
  reviewer: ReviewerContent
}

const AUTHOR_IMAGE_SIZE = 80

const BlogPostReviewerCard = ({ className, reviewer }: Props) => {
  return (
    <div className={classNames('flex items-start mt-6 lg:mt-8', className)}>
      <div className="flex-initial mr-4">
        {reviewer?.photo?.filename ? (
          <Image
            className="object-cover overflow-hidden rounded"
            alt={`photo of ${reviewer.name}`}
            src={reviewer.photo.filename}
            height={AUTHOR_IMAGE_SIZE}
            width={AUTHOR_IMAGE_SIZE}
          />
        ) : (
          <div
            className="bg-dark-brown-40 rounded"
            style={{
              height: `${AUTHOR_IMAGE_SIZE}px`,
              width: `${AUTHOR_IMAGE_SIZE}px`,
            }}
          >
            <span></span>
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <span className="eyebrow text-dark-brown-65 block mb-2 lg:mb-3">
          Medically Reviewed By
        </span>
        <span className="body font-medium">{reviewer?.name}</span>
        {reviewer?.title && (
          <span className="body text-dark-brown-65">{reviewer.title}</span>
        )}
      </div>
    </div>
  )
}

export default BlogPostReviewerCard

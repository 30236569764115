import React from 'react'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'

import { StoryblokContent } from '@/lib/storyblok'

const Collection = ({ blok }: { blok: StoryblokContent }) => (
  <div {...storyblokEditable(blok)}>
    {blok.body.map((nestedBlok: StoryblokContent) => (
      <StoryblokComponent blok={nestedBlok.content} key={nestedBlok.uuid} />
    ))}
  </div>
)

export default Collection

import { Dispatch, SetStateAction } from 'react'

import fetcher from '@framework/fetcher'
import type { Product } from '@commerce/types/product'

export type SelectedOptions = Record<string, string | null>
export function getProductVariant(product: Product, opts: SelectedOptions) {
  const variant = product.variants.find((variant) => {
    return Object.entries(opts).every(([key, value]) =>
      variant.options.find((option) => {
        if (
          option.__typename === 'MultipleChoiceOption' &&
          option.displayName.toLowerCase() === key.toLowerCase()
        ) {
          return option.values.find((v) => v.label.toLowerCase() === value)
        }
      })
    )
  })
  return variant
}

export function selectDefaultOptionFromProduct(
  product: Product,
  updater: Dispatch<SetStateAction<SelectedOptions>>
) {
  // Selects the default option
  product.options?.forEach((v) => {
    updater((choices) => {
      if (v.__typename === 'TextFieldOption') {
        return choices
      }

      return {
        ...choices,
        [v.displayName.toLowerCase()]:
          v.displayName.includes('Frequency') &&
          v.values.find((value) => value.label === '25 Day')
            ? '25 day'
            : v.values.find((value) => value.label === 'Monthly')
            ? 'monthly'
            : v.values[0].label.toLowerCase(),
      }
    })
  })
}

export const focusAreas = [
  {
    slug: 'energy-vitality',
    name: 'Energy and Vitality',
    link: '/landers/energy-and-vitality',
  },
  {
    slug: 'focus-mood',
    name: 'Focus and Mood',
    link: '/landers/focus-and-mood',
  },
  {
    slug: 'rest-recovery',
    name: 'Rest and Recovery',
    link: '/landers/rest-and-recovery',
  },
  {
    slug: 'longevity',
    name: 'Health and Longevity',
    link: '/landers/health-and-longevity',
  },
  {
    slug: 'lifeforce-diagnostic',
    name: 'Diagnostics',
    link: '/product/diagnostic',
  },
  {
    slug: 'male-hormone-optimization',
    name: 'Hormone Optimization for Men',
    link: '/landers/male-hormone-optimization',
  },
  {
    slug: 'female-hormone-optimization',
    name: 'Hormone Optimization for Women',
    link: '/landers/female-hormone-optimization',
  },
]

export const PRODUCT_SLUG_TO_BC_SLUG_MAP: any = {
  membership: 'lifeforce-membership',
  diagnostic: 'lifeforce-membership',
}

export async function getProductBySlug(slug: string, enriched: boolean = false) {
  const productSlug = PRODUCT_SLUG_TO_BC_SLUG_MAP[slug] ?? slug

  const product = await fetcher({
    url: `/api/product/${productSlug}?enriched=${enriched ? '1' : '0'}`,
  })

  return product
}

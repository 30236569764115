"use client"
import React, { useState } from 'react'
import clsx from 'clsx'
import ClickOutside from 'lib/click-outside/click-outside'

import { Dropdown } from '@components/design/Dropdown'

export interface SelectDropdownProps {
  children?: React.ReactNode
  title: string
  icon?: React.ReactNode
  className?: string
  buttonClassName?: string
  dropdownClassName?: string
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  children,
  title,
  icon,
  buttonClassName,
  className,
  dropdownClassName,
}: SelectDropdownProps) => {
  const [showDropdown, setShowDropdown] = useState(false)

  return (
    <ClickOutside active={true} onClick={() => setShowDropdown(false)}>
      <div className={clsx('relative', className)}>
        <button
          onClick={() => setShowDropdown(!showDropdown)}
          className={clsx(
            'bg-offwhite text-brown relative flex items-center border border-brown/40 p-2 cursor-pointer rounded-full font-medium text-sm hover:bg-brown hover:text-offwhite focus:outline-none focus:ring-1 focus:ring-brown focus:rounded focus:text-brown focus:bg-beige focus:border-transparent transition-all',
            buttonClassName
          )}
        >
          {icon}
          <span className="xs:mx-2 mx-1 whitespace-nowrap">{title}</span>
          <svg
            className="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
          </svg>
        </button>
        {showDropdown && (
          <div onClick={() => setShowDropdown(false)}>
            <Dropdown className={dropdownClassName}>{children}</Dropdown>
          </div>
        )}
      </div>
    </ClickOutside>
  )
}

export default SelectDropdown

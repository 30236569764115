import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import cn from 'clsx'
import { truncate } from 'lodash'

import useRemoveItem from '@framework/cart/use-remove-item'
import useUpdateItem from '@framework/cart/use-update-item'
import usePrice from '@framework/product/use-price'
import type { LineItem } from '@commerce/types/cart'
import { trackAddToCartFromCart, trackRemoveFromCart } from '@lib/datalayer'
import { NX_SKUS, PHARMA_SKUS } from '@lib/productCatalog'
import { ItemOption } from '@lib/productCatalog/productHelpers'
import { saveLastPromotionToSessionStorage } from '@lib/uiUtils'

import { useUI } from '@components/ui/context'
import Quantity from '@components/ui/Quantity'

import s from './CartItem.module.css'

const placeholderImg = '/product-img-placeholder.svg'

const SideCartItem = ({
  item,
  variant = 'default',
  currencyCode,
  refreshCart,
  ...rest
}: {
  variant?: 'default' | 'display'
  item: LineItem
  currencyCode: string,
  refreshCart: () => void
}) => {
  const { closeSidebarIfPresent } = useUI()
  const [removing, setRemoving] = useState(false)
  const [quantity, setQuantity] = useState<number>(item.quantity)
  const removeItem = useRemoveItem()
  const updateItem = useUpdateItem({ item })

  const { price } = usePrice({
    amount: item.variant.price * item.quantity,
    baseAmount: item.variant.listPrice * item.quantity,
    currencyCode,
  })

  const handleChange = async (value: any) => {
    setQuantity(Number(value))
    await updateItem({ quantity: Number(value) })
    refreshCart()
  }

  const increaseQuantity = async (n = 1) => {
    const val = Number(quantity) + n
    const res = await updateItem({ quantity: val })

    if (item.quantity != val) {
      n == 1 ? trackAddToCartFromCart(item) : trackRemoveFromCart(item)
    }

    if (res) {
      setQuantity(val)
    }

    refreshCart()
  }

  const handleRemove = async () => {
    setRemoving(true)
    try {
      await removeItem(item)
      trackRemoveFromCart(item)
      saveLastPromotionToSessionStorage(null)
      refreshCart()
    } catch (error) {
      setRemoving(false)
    }
  }

  // TODO: Add a type for this
  const options = (item as any).options

  useEffect(() => {
    // Reset the quantity state if the item quantity changes
    if (item.quantity !== Number(quantity)) {
      setQuantity(item.quantity)
    }
    // TODO: currently not including quantity in deps is intended, but we should
    // do this differently as it could break easily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.quantity])

  const isRx = useMemo(() => {
    return PHARMA_SKUS.includes(item.variant.sku.toUpperCase())
  }, [item])

  // The url we get in lineitems with the useCart hook sometimes changes between
  // rerenders, this code ensures that the img remains consistent across rerenders
  const imgData = useMemo(() => {
    return {
      url: item.variant.image?.url || placeholderImg,
      alt: item.variant.image?.altText || 'Product Image',
    }
  }, [])

  return (
    <tr className="border-b border-brown/10 last:border-0">
      <td className="w-16 h-16 pl-0 pr-2 py-4 sm:w-20 sm:h-20 sm:pr-3 align-top">
        <Link
          href={`/product/${item.path}`}
          onClick={() => closeSidebarIfPresent()}
          className="block w-16 h-16 sm:w-20 sm:h-20 rounded overflow-hidden hover:opacity-80 focus:outline-none"
        >
          <Image
            className="w-full h-full object-cover"
            width={64}
            height={64}
            src={imgData.url}
            alt={imgData.alt}
          />
        </Link>
      </td>
      <th className="px-0 py-4 text-left font-normal align-top" scope="row">
        <span>
          <span className="block mb-1">
            <Link
              href={`/product/${item.path}`}
              onClick={() => closeSidebarIfPresent()}
              className="block mb-0.25 text-base font-medium text-gray-900 hover:text-secch"
            >
              {item.name}
            </Link>
          </span>
          <ul className="text-sm text-warmbrown">
            {options && options.length > 0 && (
              <>
                {options.map((option: ItemOption, i: number) => {
                  if (option.value !== 'One-time') {
                    if (isRx) {
                      // Rx
                      return (
                        <li key={i}>
                          Only available to Lifeforce members. Subscription
                          renews every {option.value}.
                        </li>
                      )
                    }

                    // Nx
                    return (
                      <li key={i}>
                        Members{' '}
                        <span className="font-medium italic">
                          save an additional 20%
                        </span>{' '}
                        off the subscribe & save price. Subscription renews{' '}
                        {option.value}.
                      </li>
                    )
                  } else {
                    return (
                      <span key={i}>
                        Members{' '}
                        <span className="font-medium italic">save 20%</span>.{' '}
                        One-time purchase.
                      </span>
                    )
                  }
                })}
              </>
            )}
          </ul>
        </span>
      </th>
      <td className="pl-2 pr-0 sm:pr-2 pb-4 pt-[13px] align-top">
        {options?.some((opt: ItemOption) => opt.value === 'One-time') && (
          <Quantity
            value={quantity}
            handleRemove={handleRemove}
            handleChange={handleChange}
            increase={() => increaseQuantity(1)}
            decrease={() => increaseQuantity(-1)}
            max={6}
          />
        )}
        {!options?.some((opt: ItemOption) => opt.value === 'One-time') && (
          <button
            onClick={handleRemove}
            type="button"
            className="w-full bg-offwhite text-brown relative flex items-center border border-brown/0 px-2 py-1 cursor-pointer rounded-full font-medium text-sm hover:bg-brown hover:text-offwhite focus:outline-none focus:ring-1 focus:ring-brown focus:rounded focus:text-brown focus:bg-beige focus:border-transparent transition-all"
          >
            <svg
              className="mx-auto h-5 w-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path>
            </svg>
          </button>
        )}
      </td>
      <td className="pl-2 pr-0 py-4 align-top">
        <span className="block text-brown font-medium text-sm text-right leading-6">
          {price}
        </span>
      </td>
    </tr>
  )
}

export default SideCartItem

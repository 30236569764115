'use client'

import Image from 'next/image'
import { storyblokEditable } from '@storyblok/react/rsc'
import clsx from 'clsx'

import { StoryblokContent } from '@lib/storyblok'

interface Props {
  blok: StoryblokContent
}

export default function PressBar({ blok }: Props) {
  const { press_bar_items } = blok

  return (
    <div
      className="neue-haas-unica text-brown bg-offwhite overflow-hidden"
      {...storyblokEditable(blok)}
    >
      <div className="px-4 py-10 lg:px-6 lg:py-20 max-w-full w-[1200px] mx-auto flex flex-row justify-evenly">
        {press_bar_items.map(
          (
            press_item: {
              logo: { filename: string; alt: string }
            },
            index: number
          ) => {
            const mobileClasses = index === 4 ? 'hidden md:flex' : ''
            return (
              <div
                key={press_item.logo.filename}
                className={clsx('px-2 flex items-center', mobileClasses)}
              >
                <Image
                  src={press_item.logo.filename}
                  fill={false}
                  alt={press_item.logo.alt}
                  className={'object-center max-h-14 w-full object-contain'}
                  width={230}
                  height={80}
                />
              </div>
            )
          }
        )}
      </div>
    </div>
  )
}

import Image from 'next/image'
import { renderRichText, storyblokEditable } from '@storyblok/react/rsc'

import { StoryblokContent } from '@lib/storyblok'

import './SingleImageWithText.css'

interface Props {
  blok: StoryblokContent
}

export default function TextWithImage({ blok }: Props) {
  const { heading, image, text, focus_areas, layout } = blok

  return (
    <div
      className="bg-off-white px-4 lg:px-6 section"
      {...storyblokEditable(blok)}
    >
      {heading && (
        <div className="section lg:grid lg:grid-cols-12">
          <h3 className="h3 text-dark-brown lg:col-span-7">{heading}</h3>
        </div>
      )}
      <div className="flex flex-col lg:grid lg:grid-cols-12 lg:items-center">
        <div
          className={`lg:col-span-5 ${
            layout === 'left' ? 'lg:col-start-2' : 'lg:col-start-7'
          }`}
        >
          {image && (
            <div className="relative w-full pb-[100%] overflow-hidden rounded">
              <Image
                src={image.filename}
                fill={true}
                alt={image.alt}
                className="object-cover object-center"
              />
            </div>
          )}
        </div>

        <div
          className={`lg:col-span-4 lg:row-start-1 ${
            layout === 'left' ? 'lg:col-start-8' : 'lg:col-start-2'
          }`}
        >
          {text && (
            <div
              className={`richtext mt-4 single-image-richtext`}
              dangerouslySetInnerHTML={{ __html: renderRichText(text) }}
            />
          )}
          {focus_areas && (
            <div className="flex flex-row flex-wrap gap-2 mt-6">
              {focus_areas.split(',').map((pill: string, index: number) => (
                <span
                  className="text-[14px] bg-light-sage whitespace-nowrap px-3 py-1 rounded-[40px] text-dark-brown leading-[18px]"
                  key={index}
                >
                  {pill.trim()}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

import { ReactNode } from 'react'
import cn from 'clsx'

const BaseModalFooter = (props: {
  children: ReactNode
  className?: string
}) => {
  return (
    <div className={cn('-mt-4 flex items-center flex-row w-full gap-2 pt-6 pb-6 -mb-4 sticky bottom-0 bg-offwhite', props.className)}>
      {props.children}
    </div>
  )
}

export default BaseModalFooter

import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { storyblokEditable } from '@storyblok/react/rsc'
import { format, parse } from 'date-fns'
import { render } from 'storyblok-rich-text-react-renderer'

import BlogPostAuthorCard from '@components-v7/Blog/BlogPostAuthorCard'
import BlogPostReviewerCard from '@components-v7/Blog/BlogPostReviewerCard'

import MembershipFAQ from '../components/membership/membership-faq'
import { RichTextResolver } from '../lib/storyblok/StoryblokProvider'
import { StoryblokContent } from '@/lib/storyblok'

// formatting publish date
function formattedDate(publishedDate: string) {
  const parsedDate = parse(publishedDate, 'yyyy-MM-dd HH:mm', new Date())
  const formattedDate = format(parsedDate, 'MM.dd.yy')
  return formattedDate
}

const BlogPost = ({ blok }: { blok: StoryblokContent }) => {
  let matches = blok.image?.match(/\/([0-9]+)x([0-9]+)\//) ?? []
  const imageWidth = isNaN(Number(matches[1])) ? 1920 : Number(matches[1])
  const imageHeight = isNaN(Number(matches[2])) ? 1080 : Number(matches[2])

  return (
    <div {...storyblokEditable(blok)} className="relative">
      <div className="mb-10 lg:mb-20">
        <Link
          className="block font-medium body text-dark-brown"
          href="/journal"
          title="Back to Journal"
        >
          <span>Back to Journal</span>
        </Link>
      </div>
      <div className="mb-10 lg:mb-20">
        {blok.image && (
          <Image
            className="rounded overflow-hidden w-full max-w-[566px] mx-auto mb-6 lg:mb-20"
            src={`https:${blok.image}`}
            alt={blok.title}
            width={imageWidth}
            height={imageHeight}
            priority
          />
        )}
        {blok.published_at && (
          <span className="eyebrow block text-center text-dark-brown my-6 lg:mt-20">
            {formattedDate(blok.published_at)}
          </span>
        )}
        <h1 className="h2 text-center max-w-[920px] mx-auto w-full text-dark-brown">
          {blok.title}
        </h1>
        {blok?.category && (
          <div className="flex items-center justify-center mt-8 lg:mt-6">
            <Link
              href={`/journal/category/${blok.category}`}
              className="block text-[12px] bg-light-sage px-3 py-1 rounded-[40px] leading-[18px] uppercase font-medium tracking-wide"
            >
              {blok.category == 'mental-health'
                ? 'Mental Health'
                : blok.category}
            </Link>
          </div>
        )}
      </div>

      <div className="border-t border-b border-dark-brown-40 flex flex-col lg:flex-row lg:items-center lg:justify-center pb-6 lg:pb-8 mb-10 lg:mb-20 lg:gap-20 max-w-[1156px] mx-auto">
        <BlogPostAuthorCard author={blok.author.content} />
        {blok.medical_reviewers?.length > 0 &&
          blok.medical_reviewers.map((reviewer: any, index: number) => (
            <BlogPostReviewerCard
              key={`reviewer-${index}`}
              reviewer={reviewer.content}
            />
          ))}
      </div>

      <article className="richtext article-richtext max-w-[684px] mx-auto">
        {render(blok.content, {
          blokResolvers: {
            'membership-faq': (blok: any) => <MembershipFAQ blok={blok} />,
          },
          defaultBlokResolver: (component: any, blok: any) => {
            const html = RichTextResolver(component, blok)
            return <div dangerouslySetInnerHTML={{ __html: html }} />
          },
        })}
      </article>
    </div>
  )
}

export default BlogPost

'use client'

import { createContext } from 'react'

export type storyBlokLinksType = {
  [key: string]: {
    real_path: string
  }
}

type LinksProviderPropTypes = {
  value: storyBlokLinksType
  children: React.ReactNode
}

export const LinksContext = createContext<storyBlokLinksType>(
  {} as storyBlokLinksType
)

export default function LinksProvider({
  value,
  children,
}: LinksProviderPropTypes) {
  return <LinksContext.Provider value={value}>{children}</LinksContext.Provider>
}

'use client'

import { Fragment, ReactNode, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import cn from 'clsx'

interface ModalProps {
  open: Boolean
  title: ReactNode
  subtitle: ReactNode
  onClosed: () => void
  children: ReactNode
  className?: string
  titleClassName?: string
}

const Modal: React.FC<ModalProps> = ({
  open,
  title,
  subtitle,
  children,
  onClosed,
  className,
  titleClassName,
}) => {
  const closeModal = () => {
    onClosed()
  }

  const focusedElementRef = useRef(null)

  return (
    <Transition.Root show={Boolean(open)} as={Fragment}>
      <Dialog
        as="div"
        className="fixed top-0 right-0 bottom-0 left-0 bg-charcoal bg-opacity-80 cursor-pointer flex overflow-auto"
        style={{ backdropFilter: 'blur(5px)', zIndex: 9999 }}
        onClose={() => closeModal()}
      >
        <div className="relative block w-full m-auto p-4 max-w-2xl">
          <div className="fixed inset-0" />

          <Dialog.Panel>
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={cn(
                  'cursor-default relative block bg-offwhite border border-brown/10 rounded mb-8 last:mb-0',
                  className
                )}
              >
                {/*
                Create hidden button to focus on modal open, otherwise the
                dialog automatically gives focus to the first focusable element
                */}
                <button
                  type="button"
                  ref={focusedElementRef}
                  className="absolute -z-50"
                />
                <div className="sticky top-0 py-3.5 bg-offwhite z-10 rounded flex items-center gap-2 px-4">
                  <h2
                    className={`flex-grow text-warmbrown text-2xl bradford ${titleClassName}`}
                  >
                    {title}
                  </h2>
                  <button
                    type="button"
                    onClick={closeModal}
                    className="-mr-2 bg-offwhite text-brown relative flex items-center border border-brown/0 p-2 text-center cursor-pointer rounded-full font-medium text-sm hover:bg-brown hover:text-offwhite focus:outline-none focus:ring-1 focus:ring-brown focus:rounded focus:text-brown focus:bg-beige focus:border-transparent transition-all"
                  >
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path>
                    </svg>
                  </button>
                </div>
                <div className="p-4">
                  <div className="mb-4">
                    <div className="text-base text-warmbrown">{subtitle}</div>
                  </div>
                  {children}
                </div>
              </div>
            </Transition.Child>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal

import { useEffect, useState } from 'react'

import { useAddItem, useCart } from '@framework/cart'
import { CartItemBody } from '@commerce/types/cart'
import type {
  Product,
  ProductOption,
  ProductVariant,
} from '@commerce/types/product'
import { trackAddToCart } from '@lib/datalayer'
import { hasPurchasedItem } from '@lib/helpers/orders'
import { BL_TEST_KITS_SKUS, EnrichedProduct } from '@lib/productCatalog'

import { Button } from '@components/design'
import {
  getProductVariant,
  selectDefaultOptionFromProduct,
  SelectedOptions,
} from '@components/product/helpers'
import { useUI } from '@components/ui'

export default function AddToCartButton({
  product_object,
  cta_text,
}: {
  product_object: EnrichedProduct
  cta_text?: string
}) {
  const addItem = useAddItem()
  const { openSidebar } = useUI()
  const [loading, setLoading] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({})
  const product: Product = product_object.bcProduct
  const { data: cartContents } = useCart()
  const [wasPurchasedRecently, setWasPurchasedRecently] = useState(false)
  const [productInCart, setProductInCart] = useState(false)
  const [variant, setVariant] = useState<ProductVariant | undefined>()

  const cartCheck = () => {
    const cartItem = cartContents?.lineItems.find((item) => {
      return (
        item.variantId == variant?.id &&
        item.options?.some((option) => option.value === 'Monthly')
      )
    })

    setProductInCart(!!cartItem)
    hasPurchasedItem(BL_TEST_KITS_SKUS, 60).then((purchased) => {
      setWasPurchasedRecently(purchased)
    })
  }

  useEffect(() => {
    const productVariant = getProductVariant(product, selectedOptions)
    if (productVariant) {
      setVariant(productVariant)
    }

    selectDefaultOptionFromProduct(product, setSelectedOptions)
  }, [product])

  useEffect(() => {
    cartCheck()
  })

  const getOptionSelectionsForBC = () =>
    Object.entries(selectedOptions).map(
      ([productDisplayName, optionChoiceLabel]) => {
        const option: ProductOption | undefined = product.options.find(
          (option) => option.displayName.toLowerCase() === productDisplayName
        )
        if (!option) {
          return console.error(
            'Could not find option',
            productDisplayName,
            option
          )
        }
        const choice = option.values.find(
          (value) => value.label.toLowerCase() === optionChoiceLabel
        )
        if (!choice) {
          return console.error(
            'Could not find choice',
            option,
            optionChoiceLabel
          )
        }
        return {
          option_id: option.id,
          option_value: choice.entityId,
        }
      }
    )

  const addToCart = async () => {
    if (loading) return
    setLoading(true)
    try {
      const optionSelections = getOptionSelectionsForBC()

      const cartItemBody = {
        productId: String(product.id),
        variantId: String(variant ? variant.id : product.variants[0].id),
      } as CartItemBody
      if (!!optionSelections.length) {
        // @ts-ignore
        cartItemBody['optionSelections'] = optionSelections || []
      }
      await addItem(cartItemBody)
      trackAddToCart(product_object, cartContents)
      openSidebar()
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <Button
      onClick={addToCart}
      loading={loading}
      disabled={wasPurchasedRecently || productInCart}
    >
      {!!productInCart ? 'Already in cart' : cta_text || 'Add To Cart'}
    </Button>
  )
}

import { storyblokEditable } from '@storyblok/react'

import { StoryblokContent } from '@lib/storyblok'

import styles from './VimeoVideo.module.css'

interface VimeoVideoProps {
  blok: StoryblokContent | Omit<StoryblokContent, '_uid' | 'component'>
}

export default function VimeoVideo({ blok }: VimeoVideoProps) {
  const { title, subtitle, vimeo_url, disclaimer } = blok

  return (
    <div
      className="px-6 py-12 bradford text-warmbrown md:py-14"
      {...storyblokEditable(blok)}
    >
      <h1 className="mb-4 text-center text-h3-m md:text-h1-m">{title}</h1>

      <p className="max-w-2xl mx-auto mb-10 text-base md:text-h5-m text-center">
        {subtitle}
      </p>

      <div className={`${styles['container']}`}>
        <iframe
          src={vimeo_url}
          allow="fullscreen"
          allowFullScreen
          className="w-full h-full"
        />

        {disclaimer && (
          <p className="mx-auto mt-2 text-left text-body-sm">{disclaimer}</p>
        )}
      </div>
    </div>
  )
}

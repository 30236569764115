import React, { useEffect, useMemo, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { renderRichText, storyblokEditable } from '@storyblok/react/rsc'
import useSWR from 'swr'

import fetcher from '@framework/fetcher'
import useCart from '@commerce/cart/use-cart'
import { LineItem } from '@commerce/types/cart'
import type { Product } from '@commerce/types/product'
import { useMembershipData } from '@lib/context/MembershipContext'
import useAddToCart from '@lib/hooks/useAddToCart'
import {
  EnrichedProduct,
  LF_DIAGNOSTIC_SKU,
  LF_MEMBERSHIP_SKU,
} from '@lib/productCatalog'
import { StoryblokContent } from '@lib/storyblok'

import { Button } from '@components/design'
import { getProductBySlug } from '@components/product/helpers'

import { SubscriptionsResponse } from '@/types/subscription'

interface Props {
  blok: StoryblokContent
}

export default function ImagePurchase({ blok }: Props) {
  const {
    media,
    mobile_media,
    price,
    compare_at_price,
    title,
    tag,
    layout,
    top_content,
    middle_content,
    bottom_content,
    cta_text,
    cta_link,
    story,
    add_to_cart_product_slug,
  } = blok

  const [product, setProduct] = useState<Product | undefined>()
  const { data: cart, isLoading: isCartLoading } = useCart()
  const { data: subscriptionData, error: subscriptionError } =
    useSWR<SubscriptionsResponse>('/api/account/all-subscriptions', fetcher)
  const isSubscriptionLoading = !subscriptionError && !subscriptionData
  const { canBuyMembershipWithoutDx, membershipData, isLoadingMembershipData } =
    useMembershipData()

  const {
    addToCart,
    loading: addToCartLoading,
    variant: productVariant,
    // @ts-ignore
  } = useAddToCart(story, product ?? { variants: [] })

  useEffect(() => {
    if (!add_to_cart_product_slug) return

    getProductBySlug(add_to_cart_product_slug).then(
      (product: Product | undefined) => {
        setProduct(product)
      }
    )
  }, [add_to_cart_product_slug])

  const isSelectedProductInCart = cart?.lineItems?.some((item: LineItem) => {
    return item.productId === product?.id
  })

  const hasProductSubscription = !!subscriptionData?.products?.find(
    (p: EnrichedProduct) => {
      return (
        p.bcProduct.id == product?.id &&
        p.userSubscribed?.some(
          (s) =>
            s.state === 'active' ||
            s.state === 'queued_for_activation' ||
            s.state === 'future'
        )
      )
    }
  )

  const isCtaDisabled = useMemo(() => {
    if (isCartLoading) return true
    if (isSubscriptionLoading) return true
    if (isLoadingMembershipData) return true
    if (product && isSelectedProductInCart) return true
    if (product && hasProductSubscription) return true

    if (
      product?.sku?.toLowerCase() === LF_MEMBERSHIP_SKU.toLowerCase() &&
      membershipData?.isMember
    ) {
      return true
    }

    if (
      product?.sku?.toLowerCase() === LF_DIAGNOSTIC_SKU.toLowerCase() &&
      (membershipData?.diagnostic_codes?.length ?? 0) > 0
    ) {
      return true
    }

    return false
  }, [
    isCartLoading,
    product,
    isSelectedProductInCart,
    isSubscriptionLoading,
    hasProductSubscription,
    isLoadingMembershipData,
    membershipData,
  ])

  const onCtaClick = () => {
    if (addToCartLoading) return

    if (product) {
      if (
        product?.sku?.toLowerCase() === LF_MEMBERSHIP_SKU.toLowerCase() &&
        canBuyMembershipWithoutDx
      ) {
        return (window.location.href = '/account/profile')
      }

      addToCart()
    } else {
      window.location.href = `/${cta_link.cached_url}`
    }
  }

  return (
    <div
      className={`relative lg:py-9 lg:px-6 overflow-hidden`}
      {...storyblokEditable(blok)}
    >
      <div
        className={`relative lg:absolute lg:inset-0 ${
          layout === 'short' ? 'hidden lg:block' : ''
        }`}
      >
        {mobile_media && mobile_media.filename && (
          <div className="relative pb-[100%] block lg:hidden">
            <Image
              src={mobile_media.filename}
              fill={true}
              alt={mobile_media.alt}
              className={`object-cover object-center`}
            />
          </div>
        )}
        {media && media.filename && (
          <div
            className={`relative pb-[100%] lg:pb-[55%] ${
              mobile_media && mobile_media.filename ? 'hidden lg:block' : ''
            }`}
          >
            <Image
              src={media.filename}
              fill={true}
              alt={media.alt}
              className={`object-cover object-center`}
            />
          </div>
        )}
      </div>
      <div
        className={`relative p-4 pb-10 lg:pb-4 lg:my-auto lg:ml-auto flex flex-col gap-4 lg:border lg:border-dark-brown rounded-[4px] h-fit bg-off-white w-full lg:max-w-[448px]`}
      >
        {top_content && (
          <div
            className="richtext purchase-module-richtext lg:my-10 flex flex-col items-center"
            dangerouslySetInnerHTML={{ __html: renderRichText(top_content) }}
          />
        )}
        {middle_content && (
          <div
            className={`richtext purchase-module-richtext ${
              layout === 'short' ? 'hidden' : 'block'
            }`}
            dangerouslySetInnerHTML={{ __html: renderRichText(middle_content) }}
          />
        )}
        <div className="border border-dark-brown rounded p-2">
          <div className="flex flex-row justify-between items-center mb-4">
            <div className="flex flex-row items-center gap-1">
              <span className="h5 text-[19px] text-dark-brown">{title}</span>
              {tag && (
                <span className="block text-[12px] bg-light-teal px-3 py-1 rounded-[40px] leading-[18px] text-dark-brown tracking-wide">
                  {tag}
                </span>
              )}
            </div>
            <div className="text-dark-brown flex flex-row items-center gap-1">
              {compare_at_price && (
                <span className="opacity-40">
                  <s>{compare_at_price}</s>
                </span>
              )}
              {price && <span>{price}</span>}
            </div>
          </div>
          {bottom_content && (
            <div
              className="richtext purchase-module-richtext"
              dangerouslySetInnerHTML={{
                __html: renderRichText(bottom_content),
              }}
            />
          )}
        </div>
        <div>
          {product ? (
            <Button
              className="w-full"
              loading={addToCartLoading}
              title={cta_text}
              disabled={isCtaDisabled}
              onClick={onCtaClick}
            >
              {cta_text}
            </Button>
          ) : (
            <CtaLink
              cta_link={cta_link}
              cta_text={cta_text}
              isCtaDisabled={isCtaDisabled}
            />
          )}
        </div>
      </div>
    </div>
  )
}

type CtaLinkTypes = {
  cta_link: {
    linktype: 'url' | 'story'
    story: {
      url: string
    }
    url: string
  }
  cta_text: string
  isCtaDisabled: boolean
}

function CtaLink({ cta_link, cta_text, isCtaDisabled }: CtaLinkTypes) {
  const { linktype } = cta_link
  var href = ''

  if (linktype === 'story' && cta_link.story) {
    href = `/${cta_link.story.url}`

    if (href.endsWith('/')) {
      href = href.slice(0, -1)
    }
  } else {
    href = cta_link.url ? cta_link.url : '/'
  }

  return (
    <Link
      className={`button button--full ${
        isCtaDisabled ? 'pointer-events-none' : ''
      }`}
      href={href}
    >
      {cta_text}
    </Link>
  )
}

import Image from 'next/image'
import Link from 'next/link'
import { renderRichText, storyblokEditable } from '@storyblok/react/rsc'
import clsx from 'clsx'

import { StoryblokContent } from '@lib/storyblok'

import { Button } from '@components/design'

interface Props {
  blok: StoryblokContent
}

export default function DiscountedBundle({ blok }: Props) {
  const {
    media,
    mobile_media,
    price,
    compare_at_price,
    title,
    tag,
    layout,
    top_content,
    middle_content,
    bottom_content,
    ctas,
  } = blok

  const hasBottomContent = !!bottom_content?.content?.[0]?.content

  const isBottomVisible =
    tag || title || compare_at_price || price || hasBottomContent
  return (
    <div
      className={`relative lg:py-9 lg:px-6 overflow-hidden`}
      {...storyblokEditable(blok)}
    >
      <div
        className={`relative lg:absolute lg:inset-0 ${
          layout === 'short' ? 'hidden lg:block' : ''
        }`}
      >
        {mobile_media && mobile_media.filename && (
          <div className="relative pb-[100%] block lg:hidden">
            <Image
              src={mobile_media.filename}
              fill={true}
              alt={mobile_media.alt}
              className={`object-cover object-center`}
            />
          </div>
        )}
        {media && media.filename && (
          <div
            className={`relative pb-[100%] lg:pb-[55%] ${
              mobile_media && mobile_media.filename ? 'hidden lg:block' : ''
            }`}
          >
            <Image
              src={media.filename}
              fill={true}
              alt={media.alt}
              className={`object-cover object-center`}
            />
          </div>
        )}
      </div>
      <div
        className={`relative p-4 pb-10 lg:pb-4 lg:my-auto lg:ml-auto flex flex-col gap-4 lg:border lg:border-dark-brown rounded-[4px] bg-off-white w-full lg:max-w-[448px]`}
      >
        {top_content && (
          <div
            className="richtext purchase-module-richtext lg:my-10 flex flex-col items-center"
            dangerouslySetInnerHTML={{ __html: renderRichText(top_content) }}
          />
        )}
        {middle_content && (
          <div
            className={`richtext purchase-module-richtext ${
              layout === 'short' ? 'hidden' : 'block'
            }`}
            dangerouslySetInnerHTML={{ __html: renderRichText(middle_content) }}
          />
        )}
        {isBottomVisible && (
          <div className="border border-dark-brown rounded p-2">
            <div className="flex flex-row justify-between items-center mb-4">
              <div className="flex flex-row items-center gap-1">
                <span className="h5 text-[19px] text-dark-brown">{title}</span>
                {tag && (
                  <span className="block text-[12px] bg-light-teal px-3 py-1 rounded-[40px] leading-[18px] text-dark-brown tracking-wide">
                    {tag}
                  </span>
                )}
              </div>
              <div className="text-dark-brown flex flex-row items-center gap-1">
                {compare_at_price && (
                  <span className="opacity-40">
                    <s>{compare_at_price}</s>
                  </span>
                )}
                {price && <span>{price}</span>}
              </div>
            </div>
            {bottom_content && (
              <div
                className="richtext purchase-module-richtext"
                dangerouslySetInnerHTML={{
                  __html: renderRichText(bottom_content),
                }}
              />
            )}
          </div>
        )}
        {ctas?.map((cta: CtaWithBadgeTypes) => (
          <CtaLink key={cta.text} {...cta} />
        ))}
      </div>
    </div>
  )
}

type CtaWithBadgeTypes = {
  badge_color: string
  badge_text: string
  is_highlighted: boolean
  text: string
  url: {
    linktype: 'url' | 'story'
    story: {
      url: string
    }
    url: string
  }
}

function CtaLink({
  url,
  text,
  is_highlighted,
  badge_color,
  badge_text,
}: CtaWithBadgeTypes) {
  const { linktype } = url
  var href = ''

  if (linktype === 'story' && url.story) {
    href = `/${url.story.url}`

    if (href.endsWith('/')) {
      href = href.slice(0, -1)
    }
  } else {
    href = url.url ? url.url : '/'
  }

  return (
    <div className="relative">
      <Link className="w-full" href={href}>
        <Button
          color={is_highlighted ? 'primary' : 'secondary'}
          className="w-full"
          title={text}
        >
          {text}
        </Button>
        {badge_text && <Badge color={badge_color} text={badge_text} />}
      </Link>
    </div>
  )
}

function Badge({ color = 'yellow', text }: { color: string; text: string }) {
  const colorClass = `bg-${color || 'yellow'}`
  const textClass = `text-${
    (color || 'yellow') === 'brown' ? 'offwhite' : 'brown'
  }`
  return (
    <div className="absolute -top-1.5 right-8">
      <div className="relative">
        <div className="absolute top-px -left-1.5">
          <div className="w-0 h-0 border-l-[6px] border-l-transparent border-b-[5px] border-b-brown/40 border-r-[6px] border-r-transparent"></div>
        </div>
        <div className="absolute top-px -right-1.5">
          <div className="w-0 h-0 border-l-[6px] border-l-transparent border-b-[5px] border-b-brown/40 border-r-[6px] border-r-transparent"></div>
        </div>
        <span
          className={clsx(
            'relative text-xs px-2 py-1 rounded-b-xl font-medium',
            colorClass,
            textClass
          )}
        >
          {text}
        </span>
      </div>
    </div>
  )
}

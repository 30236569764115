'use client'

import React from 'react'
import Image from 'next/image'
import { renderRichText, storyblokEditable } from '@storyblok/react/rsc'

import { StoryblokContent } from '@lib/storyblok'

import styles from './HeroVideo.module.css'

type MediaPropType = {
  media: {
    filename: string
    alt: string
  }
}

const Media = ({ media }: MediaPropType) => {
  if (media && media.filename) {
    if (media.filename.includes('.mp4')) {
      return (
        <video
          autoPlay
          loop
          muted
          playsInline
          src={media.filename}
          className="absolute inset-0 object-cover object-center w-full h-full"
        />
      )
    } else {
      return (
        <Image
          src={media.filename}
          fill={true}
          alt={media.alt}
          className="block object-cover object-center"
        />
      )
    }
  }

  return <h1>Missing media</h1>
}

interface Props {
  blok: StoryblokContent
}

export default function HeroMedia({ blok }: Props) {
  const { description, image, layout, darkMode } = blok

  const __html = darkMode
    ? `
    :root:not([scrolled]) :has(.hero:first-child) .header:not(:has(.mobile-menu[open])) {
      --color-background: 0 0 0, 0;
      --color-foreground: var(--off-white);
    }
  `
    : `
    :root:not([scrolled]) :has(.hero:first-child) .header:not(:has(.mobile-menu[open])) {
      --color-background: 0 0 0, 0;
    }
  `
  return <p>This Component has been deprecated.</p>
  return (
    <div
      className={`${
        styles['hero-media']
      } hero hero-media relative  bg-black grid-cols-1 grid-rows-1 ${
        layout === 'short' ? styles['short'] : styles['full']
      }`}
      {...storyblokEditable(blok)}
    >
      <style dangerouslySetInnerHTML={{ __html }}></style>
      <div
        className={`${styles.media} relative self-stretch justify-self-stretch col-start-1 row-start-1`}
      >
        {image && image.filename && <Media media={image} />}
      </div>
      <div
        className={`${
          styles.text
        } richtext relative grid gap-[2rem] max-w-[375px] lg:max-w-[563px] col-start-1 row-start-1 mt-auto mr-auto ${
          darkMode ? 'text-off-white' : ''
        }`}
        dangerouslySetInnerHTML={{ __html: renderRichText(description) }}
      />
    </div>
  )
}

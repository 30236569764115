'use client'

import React from 'react'
import { useRouter  } from 'next/navigation';

import ConfirmStartPlan from '@components/common/Modals/ConfirmStartPlan'
import { useUI } from '@components/ui'

export default function ModalView() {
  const {displayModal, modalView, closeModal, modalPayload} = useUI()
  const router = useRouter()

  const onConfirm = async () => {
    closeModal()
    router.push('/account/profile')
  }

  return (
   <>
     {displayModal && modalView === 'CONFIRM_START_PLAN_MODAL' &&
       <ConfirmStartPlan onClosed={() => closeModal()} onConfirm={onConfirm} products={modalPayload} offerPromoCode={null} />
     }
   </>
  )
}

import React, { FC, useCallback } from 'react'

import { DropdownButton } from '../../design/Dropdown'
import { SelectDropdown } from '../../design/SelectDropdown'

export interface QuantityProps {
  value: number
  increase: () => any
  decrease: () => any
  handleRemove: React.MouseEventHandler<HTMLButtonElement>
  handleChange: React.ChangeEventHandler<HTMLInputElement>
  max?: number
}

const Quantity: FC<QuantityProps> = ({
  value,
  handleChange,
  handleRemove,
  max = 6,
}) => {
  let quantities: number[] = []
  for (let i = 1; i <= max; i++) quantities.push(i)

  const handleQuantityChange = useCallback(
    (quantity: any) => {
      if (quantity === 'delete') {
        return handleRemove(quantity)
      }

      return handleChange(quantity)
    },
    [handleChange, handleRemove]
  )

  return (
    <SelectDropdown
      buttonClassName="px-2 py-1 ml-auto"
      dropdownClassName="left-0 min-w-max"
      className="max-w-max ml-auto"
      title={value.toString()}
    >
      {quantities.map((q) => {
        return (
          <DropdownButton
            className="px-2 py-1"
            highlighted={q === value}
            key={q}
            onClick={() => handleQuantityChange(q)}
          >
            {q}
          </DropdownButton>
        )
      })}
      <span className="block h-px w-full bg-brown/10 mt-2"></span>
      <DropdownButton
        className="px-2 py-1"
        onClick={() => handleQuantityChange('delete')}
      >
        Delete
      </DropdownButton>
    </SelectDropdown>
  )
}

export default Quantity

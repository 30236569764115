'use client'

import React from 'react'
import Image from 'next/image'
import { renderRichText, storyblokEditable } from '@storyblok/react/rsc'
import { motion } from 'framer-motion'

import { StoryblokContent } from '@lib/storyblok'

import styles from './StackedImagesWithText.module.css'

interface Props {
  blok: StoryblokContent
}

export default function StackedImagesWithText({ blok }: Props) {
  const { text, small_image, large_image, layout } = blok

  const container = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.6,
        staggerChildren: 0.075,
      },
    },
  }

  const item = {
    hidden: { opacity: 0, y: '20px' },
    show: { opacity: 1, y: '0' },
  }

  return (
    <div
      className={`${styles['stacked-images']} section ${styles[layout]}`}
      {...storyblokEditable(blok)}
    >
      <div className="w-full lg:w-1/2 self-center">
        {text && (
          <div className="lg:max-w-[564px] mx-auto">
            <div
              className="richtext"
              dangerouslySetInnerHTML={{ __html: renderRichText(text) }}
            />
          </div>
        )}
      </div>
      <motion.div
        variants={container}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.5 }}
        className="grid grid-cols-4 w-full lg:w-1/2 lg:grid-cols-6"
      >
        <motion.div
          variants={item}
          key={0}
          className={`col-span-3 ${
            layout == 'text-left'
              ? 'mt-[-6rem] col-start-2 lg:col-start-3'
              : 'lg:col-start-2'
          }`}
        >
          <Image
            src={large_image.filename}
            fill={false}
            width={600}
            height={600}
            alt={large_image.alt}
            className={`object-contain overflow-hidden rounded`}
          />
        </motion.div>

        <motion.div
          variants={item}
          key={1}
          className={`col-span-2 ${
            layout == 'text-left'
              ? 'row-start-1 lg:col-start-2'
              : 'col-start-3 mt-[-6rem] lg:col-start-4'
          }`}
        >
          <Image
            src={small_image.filename}
            fill={false}
            width={600}
            height={600}
            alt={small_image.alt}
            className={`object-contain overflow-hidden rounded`}
          />
        </motion.div>
      </motion.div>
    </div>
  )
}

/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from 'react'

import useAddItem, { UseAddItem } from '@commerce/cart/use-add-item'
import type { AddItemHook, CartItemBody } from '@commerce/types/cart'
import { CommerceError } from '@commerce/utils/errors'
import type { MutationHook } from '@commerce/utils/types'
import {
  BC_DX_PRODUCT_ID,
  BC_LF_MEMBERSHIP_PRODUCT_ID,
} from '@lib/productCatalog'

import useToast from '@components/ui/Toast'

import useCart from './use-cart'

export default useAddItem as UseAddItem<typeof handler>

export const handler: MutationHook<AddItemHook> = {
  fetchOptions: {
    url: '/api/cart',
    method: 'POST',
  },
  async fetcher({ input: item, options, fetch }) {
    if (
      item.quantity &&
      (!Number.isInteger(item.quantity) || item.quantity! < 1)
    ) {
      throw new CommerceError({
        message: 'The item quantity has to be a valid integer greater than 0',
      })
    }

    const data = await fetch({
      ...options,
      body: { item },
    })

    return data
  },
  useHook:
    ({ fetch: cartFetch }) =>
    () => {
      const { mutate, data: cartD } = useCart()
      const toast = useToast()

      return useCallback(
        async function addItem(input: CartItemBody) {
          if (
            cartD &&
            cartD.lineItems.find(
              (li) =>
                li.productId == input.productId &&
                (input.productId == BC_DX_PRODUCT_ID ||
                  input.productId == BC_LF_MEMBERSHIP_PRODUCT_ID)
            )
          ) {
            toast.error(
              'Error',
              `Multiple ${
                input.productId == BC_LF_MEMBERSHIP_PRODUCT_ID
                  ? 'Memberships'
                  : 'Diagnostics'
              } cannot be in the cart at the same time`
            )
          } else {
            const data = await cartFetch({ input })
            await mutate(data, false)
            return data
          }
        },
        [cartFetch, mutate, cartD]
      )
    },
}

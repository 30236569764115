import React from 'react'

export type MembershipFeaturesProps = {
  monthlySubtotal: string
  monthlyTotal: string
  excludePrice?: boolean
}

export default function MembershipFeatures({
  monthlySubtotal,
  monthlyTotal,
  excludePrice,
}: MembershipFeaturesProps) {
  return (
    <>
      {excludePrice && <span className='block text-sm text-warmbrown'>Includes:</span>
      }
      {!excludePrice && <span className='block text-sm text-warmbrown'>
        {monthlySubtotal > monthlyTotal && (
          <span className={'line-through'}>{monthlySubtotal}</span>
        )}{' '}
        {monthlyTotal} / month after (cancel anytime) includes:
      </span>}
      <ul className="hidden sm:block text-sm text-warmbrown list-disc pl-6 max-w-[380px]">
        <li>
          At-home blood draw every 3 months for 50+ biomarkers
        </li>
        <li>1:1 clinical consultation every 3 months</li>
        <li>Unlimited health coaching</li>
        <li>20% off supplements</li>
        <li>Access to members-only pharmaceuticals</li>
        <li>Personalized program and biomarker tracking dashboard</li>
      </ul>
    </>
  )
}

'use client'

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import { BillingInfoType } from 'pages/api/account/billing-info'
import { MembershipDetailsData } from 'pages/api/membership/details'
import InputMask from 'react-input-mask'
import useSWR, { useSWRConfig } from 'swr'

import fetcher from '@framework/fetcher'
import { formatPrice } from '@commerce/product/use-price'
import type { ProductOption } from '@commerce/types/product'
import { CheckoutCoupon, PurchaseInvoice } from '@lib/checkout/checkoutReducer'
import {
  trackModalCompletePurchase,
  trackPlanMetrics,
  trackReportDetailsConfirmPlanCheckoutClick,
  trackReportDetailsConfirmPlanCouponClick,
} from '@lib/datalayer'
import { normalizeResponse } from '@lib/helpers/normalized'
import {
  CONTROLLED_PRODUCTS_SKUS,
  EnrichedProduct,
  LF_CORE_MEMBERSHIP_SLUG,
  LF_MEMBERSHIP_SLUG,
} from '@lib/productCatalog'
import { isMembership, isNx, isRx } from '@lib/productCatalog/productHelpers'

import { SSN_REGEX } from '@components/checkout/constants'
import { BaseModal, Button } from '@components/design'
import ErrorContainer from '@components/error-container'
import { getMonthlyOption as getPlanOption } from '@components/product/ProductCard/AddToCartToggle'
import { useUI } from '@components/ui'
import useToast from '@components/ui/Toast'

export interface PurchasePlanProps {
  onClosed: () => void
  onConfirm: () => Promise<void>
  products: EnrichedProduct[]
  offerPromoCode?: string | null
}

type ErrorType = {
  message: string
  source: string
}

const ConfirmStartPlan: FC<PurchasePlanProps> = (props) => {
  const { onClosed, onConfirm, products, offerPromoCode } = props
  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState<ErrorType | null>()
  const toast = useToast()
  const [ssn, setSsn] = useState<string>('')
  const [hasSSN, setHasSSN] = useState<boolean>()
  const [showCoupons, setShowCoupons] = useState(false)
  const [coupons, setCoupons] = useState<any[]>([])
  const [pendingCoupon, setPendingCoupon] = useState<string>()
  const [isCouponCodeInvalid, setCouponCodeInValid] = useState<boolean>()
  const [isCouponCodeRedeemed, setCouponCodeRedeemed] = useState<boolean>()
  const [isCouponCodeRedeemable, setCouponCodeRedeemable] =
    useState<boolean>(true)
  const [apiError, setApiError] = useState<string | null>()
  const router = useRouter()
  const pathname = usePathname() ?? ''
  const isCore = useMemo(
    () => products.some((p) => p.slug === LF_CORE_MEMBERSHIP_SLUG),
    [products]
  )
  const [coreError, setCoreError] = useState(false)
  const { setModalView } = useUI()
  const hasRx = products.some((p) => isRx(p))

  const { data: fetchedMembershipDetails } = useSWR<MembershipDetailsData>(
    '/api/membership/details'
  )
  const { mutate } = useSWRConfig()
  const membershipDetails: MembershipDetailsData = normalizeResponse(
    fetchedMembershipDetails
  )
  const canPurchaseRx =
    membershipDetails?.isMember || products.some((p) => isMembership(p))

  const { data: billingInfo } = useSWR<BillingInfoType, any>(
    '/api/account/billing-info',
    async (url: string) => {
      const response = await fetch(url)
      const body = await response.json()
      if (response.status === 200) {
        return body
      } else {
        return null
      }
    }
  )

  const billingInfoMissing = useMemo(() => {
    return (
      !billingInfo || (!billingInfo.address && !billingInfo.isLifesculptMember)
    )
  }, [billingInfo])

  useEffect(() => {
    if (membershipDetails) {
      setHasSSN(membershipDetails.ssn_exists)
    }
  }, [membershipDetails])

  const isBuyingControlled = useMemo(() => {
    return CONTROLLED_PRODUCTS_SKUS.map((sku) => sku.toLowerCase()).some(
      (sku) =>
        products.some(
          (product) =>
            product.bcProduct.sku?.toLowerCase() === sku.toLowerCase()
        )
    )
  }, [products])

  const isMembershipProduct = (product: EnrichedProduct) => {
    return product.slug === LF_MEMBERSHIP_SLUG
  }

  const handleConfirm = async () => {
    setSubmitting(true)
    setError(null)
    setApiError(null)
    setCoreError(false)
    let shouldRedirect = true
    if (hasRx && !canPurchaseRx) {
      setSubmitting(false)
      return setError({
        message: 'Lifeforce Membership is required to purchase Rx products',
        source: 'membership',
      })
    }
    if (membershipDetails?.isMember && isCore) {
      setCoreError(true)
      return
    }

    if (isBuyingControlled && !hasSSN) {
      if (!ssn || !ssn.match(SSN_REGEX)) {
        setSubmitting(false)
        return setError({
          message: 'Please enter your Social Security Number',
          source: 'ssn',
        })
      }
      try {
        await fetcher({
          url: '/api/account/update',
          method: 'POST',
          body: JSON.stringify({ ssn }),
        })
      } catch (err: any) {
        toast.error(err.message)
        setSubmitting(false)
        return
      }
    }

    const productsForPlan = mapProductsForApi()
    const coupon = coupons.find((c) => c.dismissible)?.code

    try {
      const { invoice } = await fetcher({
        url: `/api/account/start-plan`,
        method: 'POST',
        body: JSON.stringify({
          products: productsForPlan,
          coupon: coupon,
        }),
      })
      const invoiceData = normalizeResponse(invoice)
      await onConfirm()
      toast.success(
        'Plan Purchased',
        'You have successfully purchased your plan.'
      )

      mutate('/api/account/all-subscriptions')

      const { isValidCoupon } = coupon
        ? await fetcher({
            url: `/api/checkout/validate-coupon?coupon=${coupon}`,
            method: 'GET',
          })
        : { isValidCoupon: false }

      if (pathname === '/account/profile' || pathname === '/account/plan') {
        trackModalCompletePurchase(
          products.map((p) => p.bcProduct),
          coupon && isValidCoupon ? [coupon] : [],
          invoiceData,
          invoiceData.account.code
        )
      }
      if (pathname === '/account/profile') {
        trackPlanMetrics(
          products.map((p) => p.bcProduct),
          invoiceData
        )
      }
      trackReportDetailsConfirmPlanCheckoutClick(productsForPlan)
      setModalView('')
      setSubmitting(false)
      onClosed()
    } catch (err: any) {
      shouldRedirect = false
      if (
        err.errors &&
        typeof err.errors !== 'string' &&
        err.errors.some((e: any) => e.code === 'membership_exists')
      ) {
        setCoreError(true)
      } else {
        const error =
          typeof err.errors === 'string'
            ? err.errors
            : 'Checkout failed. Please contact customer experience for assistance.'

        setApiError(error)
      }
    } finally {
      if (isCore && shouldRedirect) {
        if (isRetest) {
          router.push('/register-test?mode=retest')
        } else {
          router.push('/register-test')
        }
      }
    }
  }

  const placeholderImg: string = '/lifeforce/products.jpg'
  const productImageURL = useCallback((p: EnrichedProduct): string => {
    if (p.bcProduct?.images[0]?.url) {
      return p.bcProduct.images[0].url
    }
    if (p?.content?.main_image?.filename) {
      return p.content.main_image.filename
    }
    return placeholderImg
  }, [])

  function getPlanLabel(option: ProductOption) {
    const planOption = getPlanOption(option)

    return planOption ? `${planOption.label}` : ''
  }

  const mapProductsForApi = () => {
    return products.map((product) => ({
      slug: product.bcProduct.slug,
      option: getPlanOption(product.bcProduct.options[0]),
      sku: product.bcProduct.sku ?? '',
    }))
  }

  useEffect(() => {
    fetcher({
      url: '/api/checkoutless/account-level-coupons',
      method: 'POST',
      body: {
        products: mapProductsForApi(),
      },
    }).then((accountLevelCoupons: any) => {
      let existingCoupons: any[] = []
      for (let existingCoupon of coupons) {
        if (
          !accountLevelCoupons.some((c: any) => c.code === existingCoupon.code)
        ) {
          existingCoupons.push(existingCoupon)
        }
      }

      setCoupons(() => [...existingCoupons, ...accountLevelCoupons])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  const [previewInvoice, setPreviewInvoice] = useState<PurchaseInvoice>()

  const productsWithPrices = useMemo(() => {
    if (!products) return []
    if (!previewInvoice) return products

    let productsWithPrices: EnrichedProduct[] = []

    products.forEach((product) => {
      const previewInvoiceLineItem = previewInvoice.lineItems.find(
        (item) =>
          item.itemCode?.toLowerCase() === product.bcProduct.sku?.toLowerCase()
      )
      if (previewInvoiceLineItem?.subtotal ?? 0 > 0) {
        product.bcProduct.price = {
          value: previewInvoiceLineItem!.subtotal!,
          currencyCode: previewInvoiceLineItem!.currency!,
        }
      }
    })

    return products
  }, [previewInvoice, products])

  useEffect(() => {
    if (!products) return
    if (billingInfoMissing) return

    fetcher({
      url: `/api/checkoutless/pricing`,
      method: 'POST',
      body: {
        products: mapProductsForApi(),
        coupon: coupons.find((c) => c.dismissible)?.code,
      },
    })
      .then((invoicePreview: any) => {
        setPreviewInvoice(invoicePreview.invoice)
      })
      .catch((err: any) => {
        if (err.errors && err.errors.length > 0) {
          toast.error(err.errors[0].message)
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, coupons])

  const fetchCoupon = async (code: string) => {
    const coupon = await fetcher({
      url: `/api/checkout/validate-coupon?coupon=${code}`,
    })

    return coupon
  }

  useEffect(() => {
    if (!offerPromoCode) {
      return
    }

    const applyPromoCoupon = async (promoCode: string) => {
      const coupon = await fetchCoupon(promoCode)

      if (coupon.isValidCoupon) {
        setCoupons((prev) => {
          return [...prev, { ...coupon, code: promoCode, dismissible: true }]
        })
      }
    }

    applyPromoCoupon(offerPromoCode)
  }, [offerPromoCode])

  const onCouponApply = useCallback(
    async (e: any) => {
      e.preventDefault()
      setCouponCodeInValid(false)
      setCouponCodeRedeemed(false)
      setCouponCodeRedeemable(true)
      if (pendingCoupon) {
        setPendingCoupon(pendingCoupon.trim())

        if (coupons.some((coupon) => coupon.code === pendingCoupon)) {
          setPendingCoupon('')
          return
        }

        try {
          const coupon = await fetchCoupon(pendingCoupon)

          if (coupon.hasBeenRedeemed) {
            setCouponCodeRedeemed(true)
          } else if (coupon.isValidCoupon) {
            if (
              coupon.redeemableForSkus?.length > 0 &&
              !coupon.redeemableForSkus.some((code: string) =>
                products.some(
                  (product) =>
                    product.bcProduct.sku?.toLowerCase() === code.toLowerCase()
                )
              )
            ) {
              setCouponCodeRedeemable(false)
              return
            }
            setCoupons([
              ...coupons,
              {
                ...coupon,
                code: pendingCoupon,
                dismissible: true,
              },
            ])

            trackReportDetailsConfirmPlanCouponClick(pendingCoupon)

            setPendingCoupon('')
          } else {
            setCouponCodeInValid(true)
          }
        } catch (err) {
          console.error(err)
        }
      }
    },
    [coupons, pendingCoupon, setCoupons, setPendingCoupon, products]
  )

  const onCouponRemove = (coupon: CheckoutCoupon) => {
    setCoupons(coupons.filter((c) => c.code !== coupon.code))
  }

  const isRetest = useMemo(() => {
    if (!membershipDetails) return false
    if (!membershipDetails?.diagnostics) return false
    return membershipDetails?.diagnostics.some(
      (diagnostic: any) => diagnostic.states.error === false
    )
  }, [membershipDetails])

  return (
    <BaseModal.Modal
      open={true}
      title="Confirm Additions"
      subtitle={''}
      onClosed={onClosed}
    >
      <div className="relative block -my-4 cursor-default">
        <div className="pb-4 my-4 border-b border-brown/10">
          <div className="text-base text-warmbrown">
            <p>
              Please confirm you would like to add the following to your plan.
              We will use your shipping, billing, and payment information on
              file. If you need to update your shipping, billing, or payment
              information, go to your{' '}
              <Link
                href="/account/details"
                className="font-medium underline transition-all hover:text-darkteal"
              >
                account details
              </Link>
              .
            </p>
          </div>
        </div>
        <table className="w-full mb-4 -mt-4 border-collapse table-auto">
          <caption className="sr-only">Order Bundle</caption>
          <thead>
            <tr>
              <th scope="col" className="h-0 p-0">
                <span className="sr-only">Product Image</span>
              </th>
              <th scope="col" className="h-0 p-0">
                <span className="sr-only">Description</span>
              </th>
              <th scope="col" className="h-0 p-0">
                <span className="sr-only">Price</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {productsWithPrices.map((product) => (
              <tr key={product.id} className="border-b border-gray-300">
                <td className="w-16 h-16 py-4 pl-0 pr-2 sm:w-20 sm:h-20 sm:pr-3">
                  <a
                    href="#"
                    className={`block w-16 h-16 overflow-hidden bg-gray-100 border border-gray-300 sm:w-20 sm:h-20 rounded-0 hover:opacity-100 hover:border-secch focus:outline-none focus:ring-1 focus:ring-secch focus:border-secch ${
                      isCore ? 'pointer-events-none' : ''
                    }`}
                  >
                    {/* TODO: disabled with the assumption that this will be removed/updated in the work @glibt is currently doing */}
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      className="object-cover w-full h-full"
                      src={productImageURL(product)}
                      alt=""
                    />
                  </a>
                </td>
                <th className="px-0 py-4 font-normal text-left" scope="row">
                  <span>
                    <span className="block mb-1">
                      <a
                        href="#"
                        className="text-base font-medium text-brown hover:text-darkteal"
                      >
                        {product.name}
                      </a>
                    </span>

                    <ul className="text-sm text-warmbrown">
                      {isRx(product) && (
                        <li>
                          Only available to Lifeforce members. Subscription
                          renews every{' '}
                          {getPlanLabel(product.bcProduct.options[0])}.
                        </li>
                      )}
                      {isNx(product) && (
                        <li>
                          Members{' '}
                          <span className="italic font-medium">
                            save an additional 20%
                          </span>{' '}
                          off the subscribe & save price. Subscription renews{' '}
                          {getPlanLabel(product.bcProduct.options[0])}.
                        </li>
                      )}
                      {isMembershipProduct(product) && (
                        <li>
                          Subscription renews{' '}
                          {getPlanLabel(product.bcProduct.options[0])}.
                        </li>
                      )}
                    </ul>
                  </span>
                </th>
                <td className="px-0 py-4 align-top">
                  <span className="block text-sm font-medium text-right text-brown sm:text-sm">
                    {formatPrice({
                      amount: product.bcProduct.price.value || 0,
                      currencyCode: 'USD',
                      locale: 'en-US',
                    })}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={isCore ? '' : 'mb-4'}>
          {isBuyingControlled && !hasSSN && (
            <div>
              <label className="block mb-1 text-sm text-warmbrown">
                Social Security Number
                <span className="font-medium text-uxred"> *</span>
              </label>
              <InputMask
                mask={'999-99-9999'}
                placeholder={'000-00-0000'}
                value={ssn}
                onChange={(e) => setSsn(e.target.value)}
                className={`${
                  error?.source === 'ssn' ? 'border-uxred' : 'border-brown/40'
                } block w-full bg-transparent py-2 px-3 leading-5 text-brown placeholder:text-warmbrown/80 border border-brown/40 rounded sm:text-sm transition-all focus:bg-offwhite focus:ring-1 focus:ring-brown focus:border-brown`}
              />
              <div className="my-2 text-xs text-warmbrown">
                <span>
                  Your social security number is required when purchasing a
                  controlled substance.
                </span>
              </div>
              {error?.source === 'ssn' && (
                <ErrorContainer className="mt-1">
                  {error.message}
                </ErrorContainer>
              )}
            </div>
          )}
          {!isCore && (
            <>
              <div className="mt-4">
                <button
                  type="button"
                  onClick={() => setShowCoupons(!showCoupons)}
                  className="block text-sm font-medium underline transition-all cursor-pointer text-brown hover:text-darkteal focus:outline-none"
                >
                  Have a coupon code?
                </button>
              </div>
              {showCoupons && (
                <form className="flex items-center justify-between w-full gap-2 mt-4">
                  <input
                    type="text"
                    value={pendingCoupon}
                    onChange={(e) => {
                      setPendingCoupon(e.target.value)
                      setCouponCodeInValid(false)
                      setCouponCodeRedeemed(false)
                      setCouponCodeRedeemable(true)
                    }}
                    className="block w-full px-3 py-2 leading-5 transition-all bg-transparent border rounded text-brown placeholder:text-warmbrown/80 border-brown/40 sm:text-sm focus:bg-offwhite focus:ring-1 focus:ring-brown focus:border-brown"
                    placeholder="Coupon"
                  />
                  <Button
                    type="submit"
                    color="secondary"
                    onClick={onCouponApply}
                    disabled={!pendingCoupon || pendingCoupon.trim() === ''}
                  >
                    Apply
                  </Button>
                </form>
              )}
            </>
          )}
          {isCouponCodeRedeemed && !isCore && (
            <div className="my-2">
              <div className="flex items-center px-3 py-2 text-sm bg-red-100 border border-red-100 text-uxred rounded-0">
                <span>Coupon code has already been redeemed</span>
              </div>
            </div>
          )}
          {isCouponCodeInvalid && !isCore && (
            <div className="my-2">
              <div className="flex items-center px-3 py-2 text-sm bg-red-100 border border-red-100 text-uxred rounded-0">
                <span>Coupon code expired or invalid</span>
              </div>
            </div>
          )}
          {!isCouponCodeRedeemable && !isCore && (
            <div className="my-2">
              <div className="flex items-center px-3 py-2 text-sm bg-red-100 border border-red-100 text-uxred rounded-0">
                <span>
                  Coupon code is not redeemable for any selected products
                </span>
              </div>
            </div>
          )}
          {error?.message && error?.source !== 'ssn' && (
            <div className="my-2">
              <div className="flex items-center px-3 py-2 text-sm bg-red-100 border border-red-100 text-uxred rounded-0">
                <span>{error?.message}</span>
              </div>
            </div>
          )}

          {billingInfoMissing && (
            <div className="my-2">
              <div className="flex items-center px-3 py-2 text-sm bg-red-100 border border-red-100 text-uxred rounded-0">
                <span>
                  Please update your billing info{' '}
                  {!isCore ? (
                    <Link href="/account/details" className="underline">
                      here
                    </Link>
                  ) : (
                    <Link
                      href="/account/details"
                      target="_blank"
                      className="underline"
                    >
                      here
                    </Link>
                  )}{' '}
                  to continue with the purchase.
                </span>
              </div>
            </div>
          )}
          {coreError && (
            <div className="my-2">
              <ErrorContainer>
                <span>
                  This membership is not available for your account at this
                  time. If you are interested in converting to a{' '}
                  {membershipDetails.membershipType === 'core'
                    ? 'classic '
                    : 'core '}
                  membership, please reach out to our concierge team at{' '}
                  <Link
                    className="font-medium"
                    href="mailto:support@mylifeforce.com"
                  >
                    support@mylifeforce.com
                  </Link>
                  .
                </span>
              </ErrorContainer>
            </div>
          )}
          {apiError && (
            <div className="my-2">
              <ErrorContainer>
                <span>{apiError}</span>
              </ErrorContainer>
            </div>
          )}
          {!isCore && showCoupons && (
            <div className="flex flex-wrap items-center w-full gap-2 mt-4 overflow-hidden">
              {coupons.map((coupon: CheckoutCoupon) => (
                <span
                  key={coupon.description}
                  className="relative inline-flex items-center px-3 py-1 text-xs font-medium tracking-wider text-center uppercase border rounded-full bg-sage/40 text-warmbrown border-sage/0"
                >
                  {coupon.description}
                  {coupon.dismissible && (
                    <button
                      type="button"
                      onClick={() => onCouponRemove(coupon)}
                      className="relative flex items-center p-1 ml-1 -my-1 -mr-3 text-sm font-medium text-center transition-all bg-transparent rounded-full cursor-pointer text-brown hover:bg-brown hover:text-offwhite focus:outline-none focus:ring-1 focus:ring-brown focus:rounded focus:text-brown focus:bg-beige focus:border-transparent"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  )}
                </span>
              ))}
            </div>
          )}
        </div>

        <table className="w-full mt-4 border-t border-collapse table-auto border-brown/10">
          <caption className="sr-only">Totals</caption>
          <thead>
            <tr>
              <th scope="col" className="h-0 p-0">
                <span className="sr-only">Description</span>
              </th>
              <th scope="col" className="h-0 p-0">
                <span className="sr-only">Amount</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-0">
              <td className="pt-4 pb-0 pl-0 pr-0">
                <span className="block text-sm leading-6 text-left text-warmbrown">
                  Subtotal
                </span>
              </td>
              <td className="pt-4 pb-0 pl-2 pr-0">
                <span className="block text-sm font-medium leading-6 text-right text-brown">
                  {formatPrice({
                    amount: previewInvoice?.subtotal || 0,
                    currencyCode: 'USD',
                    locale: 'en-US',
                  })}
                </span>
              </td>
            </tr>
            <tr className="border-0">
              <td className="pt-0 pb-0 pl-0 pr-0">
                <span className="block text-sm leading-6 text-left text-warmbrown">
                  Additional Discounts
                </span>
              </td>
              <td className="pt-0 pb-0 pl-2 pr-0">
                {previewInvoice && previewInvoice.discount > 0 && (
                  <span className="block text-sm leading-6 text-right text-brown">
                    -{' '}
                    {formatPrice({
                      amount: previewInvoice.discount,
                      currencyCode: 'USD',
                      locale: 'en-US',
                    })}
                  </span>
                )}
              </td>
            </tr>
            <tr className="border-0">
              <td className="pt-0 pb-0 pl-0 pr-0">
                <span className="block text-sm leading-6 text-left text-warmbrown">
                  Taxes
                </span>
              </td>
              <td className="pt-0 pb-0 pl-2 pr-0">
                <span className="block text-sm leading-6 text-right text-brown">
                  {formatPrice({
                    amount: previewInvoice?.tax || 0,
                    currencyCode: 'USD',
                    locale: 'en-US',
                  })}
                </span>
              </td>
            </tr>
            <tr className="border-0">
              <td className="pt-0 pb-4 pl-0 pr-0">
                <span className="block text-sm leading-6 text-left text-warmbrown">
                  Shipping
                </span>
              </td>
              <td className="pt-0 pb-4 pl-2 pr-0">
                <span className="block text-sm font-medium leading-6 text-right text-brown">
                  FREE
                </span>
              </td>
            </tr>
            <tr className="border-t border-brown/10">
              <td className="pt-4 pb-0 pl-0 pr-0">
                <span className="block text-lg font-medium leading-6 text-left text-brown">
                  Total
                </span>
              </td>
              <td className="pt-4 pb-0 pl-2 pr-0">
                <span className="block text-lg font-medium leading-6 text-right text-brown">
                  {' '}
                  {formatPrice({
                    amount: previewInvoice?.total || 0,
                    currencyCode: 'USD',
                    locale: 'en-US',
                  })}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        {isCore && (
          <div className="mt-4">
            <div className="text-xs text-warmbrown/80">
              <span>
                <span className="font-medium">
                  Annual Membership Refund Policy
                </span>
                : 1. Pre-blood draw user is entitled to a full refund. 2. After
                one blood draw user is entitled to a 40% refund. 3. After the
                second blood draw, no refund is issued. 4.Refunds requested
                after 90 days will be issued by check.
              </span>
            </div>
          </div>
        )}

        <div className="sticky bottom-0 flex flex-row items-center w-full gap-2 pt-6 pb-6 -mb-4 bg-offwhite">
          <Button
            type="button"
            color="secondary"
            onClick={onClosed}
            className="w-full"
          >
            Cancel
          </Button>
          <Button
            type="button"
            disabled={
              billingInfoMissing ||
              isSubmitting ||
              !previewInvoice ||
              !membershipDetails ||
              coreError
            }
            className="w-full"
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </div>
      </div>
    </BaseModal.Modal>
  )
}

export default ConfirmStartPlan

'use client'

import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { storyblokEditable } from '@storyblok/react/rsc'
import classNames from 'classnames'
import { AnimatePresence, motion, useInView, useScroll } from 'framer-motion'

import { StoryblokContent } from '@lib/storyblok'

import AnimatingBlock from './AnimatingBlock'

import styles from './AnimationWIthText.module.css'

interface Props {
  blok: StoryblokContent
}

export default function AnimationWithText({ blok }: Props) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const textRef = React.useRef<HTMLDivElement>(null)
  const textIsInView = useInView(textRef, { amount: 0.3 })
  const { scrollYProgress } = useScroll({ target: containerRef })
  const [scrollState, setScrollState] = useState(1)

  const { link } = blok

  useEffect(() => {
    const thresholds = [0.0, 0.2, 0.6, 0.8]
    const unsubscribe = scrollYProgress.onChange((value) => {
      const newState =
        thresholds.findIndex((threshold) => value < threshold) + 1 || 4
      if (newState !== scrollState) {
        setScrollState(newState)
      }
    })

    return () => unsubscribe()
  }, [scrollYProgress, scrollState])

  return (
    <AnimatePresence>
      <div
        {...storyblokEditable(blok)}
        className="animation-with-text !mb-0 section bg-off-white"
      >
        <div
          className="bg-off-white px-6 h-[350vh] max-w-[1500px] mx-auto pb-0 mb-[var(--header-top--offset)] translate-y-[var(--header-top--offset)] transition-all duration-500"
          ref={containerRef}
        >
          <h2 className="text-center top-8 max-w-[500px] lg:max-w-[800px] h-screen mx-auto col-span-6 h3 bg-off-white  sm:px-6 sticky text-dark-brown">
            Taking control of your health means answering three critical
            questions:
          </h2>

          <div className="h-screen mt-[calc(-100vh+5rem)] z-10 pointer-events-none w-full sticky top-0 grid gap-6 grid-cols-12  overflow-hidden">
            <motion.div
              layout
              className={classNames(
                'relative col-span-12 sm:col-span-6 z-10  flex justify-center mt-[-20vmax] sm:mt-0  items-center',
                {
                  'sm:col-start-7 sm:h-screen': textIsInView,
                  'sm:col-start-4': !textIsInView,
                }
              )}
            >
              <div className="relative w-full max-w-[40vmax] max-h-[40vmax] sm:max-w-[30vmax] sm:max-h-[30vmax]  aspect-[1/1]">
                <motion.div
                  initial={{ opacity: 1 }}
                  animate={{
                    opacity: scrollState === 4 && textIsInView ? 1 : 0,
                  }}
                  className="w-full group  h-full absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
                >
                  <div className="w-full relative h-full">
                    <div className="absolute top-1/2 left-1/2 w-full h-full transform translate-y-[-50%] translate-x-[-50%] sm:translate-y-[-35%] sm:translate-x-[-56%]">
                      <Image
                        className=""
                        alt="screen"
                        fill={true}
                        src="/assets/placeholder/homepage-bg.svg"
                      />
                    </div>
                  </div>
                </motion.div>

                <motion.div
                  initial={{ opacity: 1 }}
                  animate={{
                    opacity: scrollState === 4 && textIsInView ? 1 : 0,
                  }}
                  transition={{
                    duration: scrollState === 4 && textIsInView ? 5 : 0,
                  }}
                  className="w-full group z-10  h-full absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 "
                >
                  <div className="w-full relative h-full">
                    <div className="absolute top-1/2 left-1/2 w-full h-full transform translate-y-[-50%] translate-x-[-50%]  lg:translate-y-[-35%] lg:translate-x-[-56%]">
                      <Image
                        className=""
                        alt="screen"
                        fill={true}
                        src="/assets/placeholder/homepage-line.svg"
                      />
                    </div>
                  </div>
                </motion.div>

                <motion.div
                  className={classNames(
                    'w-full transition-transform transition-margin duration-[1200ms]  aspect-[1/1] h-full absolute top-1/2 left-1/2  transform',
                    styles.active,
                    {
                      'scale-[0.19] -translate-y-[67%] -translate-x-[40%]  sm:-translate-y-[52%] sm:-translate-x-[45.8%]':
                        scrollState === 4,
                      'scale-[1]  -translate-y-1/2 -translate-x-1/2':
                        scrollState !== 4,
                    }
                  )}
                >
                  <AnimatingBlock
                    showBgLayers={
                      (scrollState === 1 && !textIsInView) ||
                      ((scrollState === 5 || scrollState === 4) && textIsInView)
                    }
                    scrollState={scrollState}
                  />
                </motion.div>
              </div>
            </motion.div>
          </div>

          <div
            className="text-center sm:text-left grid pointer-none gap-6 grid-cols-12 h-[30vh] sm:h-[50vh] sticky top-[70vh] sm:top-[40vh]"
            ref={textRef}
          >
            <div className="col-span-12 sm:col-span-7 sm:col-start-2 text-dark-brown relative">
              <AnimatePresence presenceAffectsLayout>
                <motion.div
                  key={4}
                  layout
                  className="absolute top-0 h4 w-full left-0"
                  initial={{ opacity: 1 }}
                  animate={{ opacity: scrollState === 5 ? 0 : 1 }}
                >
                  <motion.h4
                    className="sm:col-start-2 sm:col-span-5 col-span-12"
                    initial={{ opacity: 1 }}
                    animate={{ opacity: scrollState === 3 ? 1 : 0 }}
                    transition={{
                      duration: scrollState < 4 ? 0.5 : 0.3,
                    }}
                  >
                    Can I maintain my quality of life?
                  </motion.h4>
                  <motion.h4
                    className="sm:col-start-2 sm:col-span-5 col-span-12"
                    initial={{ opacity: 1 }}
                    transition={{
                      delay: scrollState < 4 ? 0.15 : 0,
                      duration: scrollState < 4 ? 0.5 : 0.3,
                    }}
                    animate={{ opacity: scrollState === 3 ? 1 : 0 }}
                  >
                    Have I minimized my risk of dying early?
                  </motion.h4>
                  <motion.h4
                    className="sm:col-start-2 sm:col-span-5 col-span-12"
                    initial={{ opacity: 1 }}
                    transition={{
                      delay: scrollState < 4 ? 0.3 : 0,
                      duration: scrollState < 4 ? 0.5 : 0.3,
                    }}
                    animate={{ opacity: scrollState === 3 ? 1 : 0 }}
                  >
                    Am I in control of how fast I{`’`}m aging?
                  </motion.h4>
                  <motion.h4
                    className="mt-6 sm:col-start-2 col-span-12 sm:col-span-3 mx-auto sm:mx-[initial] max-w-[500px]"
                    initial={{ opacity: 1 }}
                    transition={{
                      delay: scrollState < 4 ? 0.45 : 0,
                      duration: scrollState < 4 ? 0.5 : 0.3,
                    }}
                    animate={{ opacity: scrollState === 3 ? 1 : 0 }}
                  >
                    Ready to get answers?
                  </motion.h4>
                </motion.div>

                <motion.div
                  className="absolute top-0 richtext w-full"
                  key={5}
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: scrollState === 4 ? 1 : 0 }}
                >
                  <motion.div
                    layout
                    initial={{ opacity: 0, width: 0 }}
                    animate={{
                      opacity: scrollState === 4 ? 1 : 0,
                      width: scrollState === 4 ? '100%' : 0,
                    }}
                    transition={{
                      duration: 0.5,
                    }}
                    className="absolute w-full -top-4 sm:border-t sm:border-dark-brown-65"
                  />
                  <h4 className="mb-5 text-dark-brown mx-auto sm:mx-[initial] max-w-[460px]">
                    As your longevity partner, Lifeforce empowers you to move
                    from average to optimal.
                  </h4>
                  {link && (
                    <Link href={link} className="button button--primary">
                      Explore Health Goals
                    </Link>
                  )}
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </AnimatePresence>
  )
}

import {
  CONTROLLED_PRODUCTS_SKUS,
  EnrichedProduct,
  MEMBERSHIP_SKUS,
  NX_SKUS,
  PHARMA_SKUS,
} from '.'

export function customFieldValue(
  p: EnrichedProduct,
  field: string,
  defaultValue: string | undefined = void 0
): string | undefined {
  let value = defaultValue
  p.bcProduct.customFields?.edges.forEach((edge) => {
    if (edge?.node?.name === field) {
      value = edge.node.value
    }
  })
  return value
}

export function currentSubscriptionState(
  p: EnrichedProduct
): string | null | undefined {
  let state: string | null | undefined = p.userSubscribed?.[0]?.state
  return state
}

export function isRx(p: EnrichedProduct): boolean {
  return PHARMA_SKUS.includes(p.bcProduct.sku ?? '')
}

export function isNx(p: EnrichedProduct): boolean {
  return NX_SKUS.some(
    (nxSku) => p.bcProduct.sku?.toLowerCase() === nxSku.toLowerCase()
  )
}

export function isControlled(p: EnrichedProduct): boolean {
  return CONTROLLED_PRODUCTS_SKUS.includes(p.bcProduct.sku ?? '')
}

export function isSkipped(p: EnrichedProduct): boolean {
  const skipped = p.userSubscribed?.[0].customFields?.find(
    (i) => i.name === 'skipped'
  )
  if (!skipped) {
    return false
  }
  return skipped.value === '1'
}

export function isMembership(p: EnrichedProduct): boolean {
  return MEMBERSHIP_SKUS.includes(p.bcProduct.sku ?? '')
}

export type ItemOption = {
  name: string
  nameId: number
  value: string
  valueId: number
}

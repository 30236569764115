'use client'

import React, { useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'
import {
  renderRichText,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react/rsc'
import { useInView } from 'framer-motion'

import { StoryblokContent } from '@lib/storyblok'

import './static-images-text.css'

interface Props {
  blok: StoryblokContent
  setActiveSection?: (section: string) => void
}

export default function StaticImagesText(props: Props) {
  const { blok, setActiveSection } = props
  const pathname = usePathname() ?? ''

  // Suggested TODO, rework this to either use a map of refs
  // OR break out into separate components
  const refWhatWeMeasure = React.useRef<HTMLDivElement>(null)
  const whatWeMeasureInView = useInView(refWhatWeMeasure, { amount: 0 })

  useEffect(() => {
    if (whatWeMeasureInView) {
      setActiveSection?.('How It Works')
    }
  }, [whatWeMeasureInView])

  const refMeetYourTeam = React.useRef<HTMLDivElement>(null)
  const meetYourTeamInView = useInView(refMeetYourTeam, { amount: 0 })

  useEffect(() => {
    if (meetYourTeamInView) {
      setActiveSection?.('Your Team')
    }
  }, [meetYourTeamInView])

  return (
    <div
      {...storyblokEditable(blok)}
      className={`static-images-text section section--bg-light-beige px-4 ${
        pathname.includes('/product/membership')
          ? 'lg:px-0 lg:rounded-t-none'
          : 'lg:px-6'
      }`}
    >
      <div
        ref={refWhatWeMeasure}
        className={`bg-light-beige section py-7 lg:py-10 px-4 lg:px-6 rounded-[4px] `}
      >
        <h3 className="h4 heading bradford text-center text-dark-brown max-w-[448px] mx-auto">
          {blok.heading}
        </h3>

        <div className="section lg:grid lg:grid-cols-8 lg:gap-6 lg:items-center">
          <div
            className={`flex justify-center relative lg:grid-cols-[subgrid] lg:col-span-4 ${
              true ? 'lg:col-start-5' : ''
            }`}
          >
            <svg
              width="332"
              height="353"
              viewBox="0 0 332 353"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M83.8477 229.558C83.8737 229.605 129.391 302.445 193.952 304.438C231.12 301.203 258.522 289.432 301.706 215.063C329.778 160.209 340.157 115.556 322.508 72.8499C307.193 36.2807 274.306 12.2778 215.841 10.9414C172.605 12.9209 134.977 24.1379 107.116 43.2741C54.0708 79.7135 36.6221 144.904 83.8477 229.558Z"
                fill="url(#paint0_radial_8071_6351)"
              />
              <path
                d="M154.723 74.7773C116.786 72.8372 74.2274 97.0461 54.7896 144.313C34.5711 206.303 42.1089 248.471 79.0099 308.572C103.185 343.412 138.235 356.159 168.42 347.808C208.664 336.678 236.765 300.146 251.238 258.181C263.311 224.079 265.278 190.154 257.099 153.799C243.608 101.896 206.306 77.1979 154.723 74.7773Z"
                fill="url(#paint1_radial_8071_6351)"
              />
              <path
                d="M154.719 74.7773C116.782 72.8372 74.2235 97.0461 54.7856 144.313C34.5672 206.303 42.105 248.471 79.006 308.572C103.181 343.412 138.231 356.159 168.416 347.808C208.66 336.678 236.761 300.146 251.234 258.181C263.307 224.079 265.274 190.154 257.095 153.799C243.605 101.896 206.302 77.1979 154.719 74.7773Z"
                fill="url(#paint2_radial_8071_6351)"
              />
              <path
                d="M0.429127 140.742C-0.626678 159.721 1.76565 180.339 11.5461 200.458C25.5865 227.635 57.8606 237.733 99.8028 251.651C138.668 263.817 169.555 276.557 199.687 278.871C249.353 279.237 276.982 230.493 259.274 159.189C251.685 132.78 220.085 18.5587 129.472 6.81228C29.6756 2.51668 0.624762 110.793 0.429127 140.742Z"
                fill="url(#paint3_radial_8071_6351)"
              />
              <path
                d="M198.323 278.988C259.099 279.958 274.485 217.409 259.493 155.842C246.541 112.258 225.613 92.2229 188.309 79.7814C103.31 52.8573 27.5906 127.759 46.1208 231.27C53.2922 237.53 166.037 277.39 198.323 278.988Z"
                fill="url(#paint4_radial_8071_6351)"
              />
              <path
                d="M157.168 295.723C191.522 312.565 217.772 302.303 234.588 293.473C242.299 280.558 249.188 264.433 255.295 245.947C261.658 232.735 265.359 211.92 264.837 194.374C264.901 176.788 261.273 156.443 251.246 133.198C233.578 84.8866 200.641 32.1536 165.851 18.3147C85.6553 34.7642 48.1995 93.4046 57.6731 156.857C66.5686 224.458 117.683 278.564 157.168 295.723Z"
                fill="#D9B266"
                fillOpacity="0.2"
              />
              <defs>
                <radialGradient
                  id="paint0_radial_8071_6351"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(186.297 256.077) rotate(-64.9528) scale(256.719 223.43)"
                >
                  <stop stopColor="#EC6743" />
                  <stop offset="0.329" stopColor="#EC6743" />
                  <stop offset="0.777" stopColor="#9C4B3A" />
                  <stop offset="1" stopColor="#5A3221" />
                </radialGradient>
                <radialGradient
                  id="paint1_radial_8071_6351"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(-78.8772 173.943) rotate(127.406) scale(203.296 193.19)"
                >
                  <stop stopColor="#1B4041" />
                  <stop offset="0.63" stopColor="#385F60" />
                  <stop offset="1" stopColor="#B1D6D7" />
                </radialGradient>
                <radialGradient
                  id="paint2_radial_8071_6351"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(193.444 110.554) rotate(107.491) scale(243.918 210.291)"
                >
                  <stop stopColor="#385F60" />
                  <stop
                    offset="0.335905"
                    stopColor="#385F60"
                    stopOpacity="0.929"
                  />
                  <stop
                    offset="0.710968"
                    stopColor="#385F60"
                    stopOpacity="0.9"
                  />
                  <stop offset="1" stopColor="#B1D6D7" />
                </radialGradient>
                <radialGradient
                  id="paint3_radial_8071_6351"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(187.852 232.304) rotate(-118.451) scale(244.43 170.323)"
                >
                  <stop stopColor="#FDD17C" />
                  <stop offset="0.087" stopColor="#FDD17C" />
                  <stop offset="0.789" stopColor="#FDD17C" />
                  <stop offset="1" stopColor="#E39909" />
                </radialGradient>
                <radialGradient
                  id="paint4_radial_8071_6351"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(141.893 188.294) rotate(-23.8748) scale(136.515 119.37)"
                >
                  <stop stopColor="#FDD17C" />
                  <stop offset="0.529571" stopColor="#E8AC39" />
                  <stop offset="1" stopColor="#EC6743" />
                </radialGradient>
              </defs>
            </svg>

            <div className="lifescore absolute bottom-0 lg:bottom-2/3 lg:left-0 max-lg:right-0 max-lg:border-l border-b border-brown/40 w-1/3 lg:w-1/2 lg max-lg:h-1/2 flex max-lg:justify-center items-end lg:ml-[-20%] xl:ml-0">
              <h2 className="bradford text-dark-brown text-[3.5rem]">
                {blok.lifescore}
              </h2>
            </div>
          </div>

          <div
            className={`richtext max-lg:mt-8 lg:col-span-3 lg:-mr-6 lg:col-start-1 lg:row-start-1`}
            dangerouslySetInnerHTML={{ __html: renderRichText(blok.copy) }}
          />
        </div>

        {blok.topContent &&
          blok.topContent.map((b: StoryblokContent) => (
            <StoryblokComponent blok={b} key={b._uid} />
          ))}
      </div>

      <div
        id="your-team"
        className={`section lg:grid lg:grid-cols-8 lg:gap-6 lg:items-center`}
      >
        <div
          ref={refMeetYourTeam}
          className="richtext lg:grid-cols-[subgrid] lg:col-span-4"
          dangerouslySetInnerHTML={{
            __html: renderRichText(blok.bottomHeading),
          }}
        />

        <div className="lg:col-span-full">
          {blok.bottomContent &&
            blok.bottomContent.map((b: StoryblokContent) => (
              <StoryblokComponent blok={b} key={b._uid} />
            ))}
        </div>
      </div>
    </div>
  )
}

import useSWR, { KeyedMutator } from 'swr'

import { Cart } from '@framework/types/cart'

const fetchCart = async () => {
  const response = await fetch('/api/cart');
  if (!response.ok) {
    throw new Error('Failed to fetch cart');
  }
  return response.json();
};

export default function useSWRCart() {
  let response = useSWR<{
    data: Cart | null | undefined
  }>('/api/cart', fetchCart)

  const refreshCart = () => {
    setTimeout(async () => {
      await response.mutate()
    }, 1000)
  }

  return {
    ...response,
    data: response.data?.data,
    refreshCart
  }
}

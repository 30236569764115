'use client'

import { useState } from 'react'
import { CheckIcon } from '@heroicons/react/outline'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'

import { trackNewsletterSubscription } from '@lib/datalayer'

interface SubscriptionFormProps {
  className?: string
}

export default function SubscriptionForm({ className }: SubscriptionFormProps) {
  const [isSubmitting, setSubmitting] = useState(false)
  const [hasSubscribed, setHasSubscribed] = useState(false)

  return (
    <Formik
      initialValues={{ email: '' }}
      validateOnBlur
      validateOnChange
      onSubmit={(values, { resetForm, setErrors }) => {
        if (!values.email) {
          setErrors({ email: 'Please enter your email' })

          return
        }
        setSubmitting(true)
        axios.post('/api/subscribe', { email: values.email }).finally(() => {
          setHasSubscribed(true)
          trackNewsletterSubscription(values.email)
          setSubmitting(false)
          resetForm()
        })
      }}
    >
      <Form className={className}>
        <div className="h-[55px] w-full">
          <Field
            type="email"
            name="email"
            placeholder={hasSubscribed ? 'You’re In!' : 'Email'}
            className="w-full h-full pl-6 pr-[7.5rem] rounded-[40px] text-body lg:text-lg  text-white placeholder:text-white focus:border-white bg-off-white-40"
            validate={(value: string) => {
              if (value) {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                  return 'Please enter a valid email address.'
                }
              }
            }}
          />
          {/* {hasSubscribed && (
            <CheckIcon
              className="absolute w-5 h-5 top-4 right-2 z-10"
              color="green"
              aria-hidden="true"
            />
          )} */}
        </div>
        <ErrorMessage
          name="email"
          component="div"
          className="text-sm font-light text-red-600 mt-4"
        />
        <button
          className="absolute right-[8px] top-[8px] bottom-[8px] button lg:text-[17px]"
          type="submit"
          // loading={isSubmitting}
          // disabled={isSubmitting}
          // position="absolute"
        >
          Sign Up
        </button>
      </Form>
    </Formik>
  )
}

import React from 'react'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'

import { StoryblokContent } from '@/lib/storyblok'

const StaticPage = ({ blok }: { blok: StoryblokContent }) => {
  return (
    <main
      {...storyblokEditable(blok)}
      className={blok.minimalHeader ? 'minimal-header' : ''}
    >
      {(blok.Body ?? blok.body).map((nestedBlok: StoryblokContent) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </main>
  )
}

export default StaticPage

'use client'
import React from 'react'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import { renderRichText, storyblokEditable } from '@storyblok/react/rsc'

import { StoryblokContent } from '@lib/storyblok'

import { getImageAspectRatio } from '../helper'

interface Props {
  blok: StoryblokContent
}

export default function ImageGroupWithText({ blok }: Props) {
  const {
    bottomSmallImage,
    bottomSmallImageHasBorder,
    copy,
    isBottomSmallImage2Cols,
    isBottomSmallImageRight,
    isImageGroupRight,
    isTopSmallImage2Cols,
    isTopSmallImageRight,
    largeImage,
    largeImageHasBorder,
    largeImagePosition,
    topSmallImage,
    topSmallImageHasBorder,
  } = blok

  const pathname = usePathname() ?? ''

  let lgImagePosition = ''

  if (largeImagePosition === 'center') {
    lgImagePosition = 'ml-[12.5%] -mr-[25%]'
  } else if (largeImagePosition === 'right') {
    lgImagePosition = 'col-start-2'
  }
  // lgImagePosition = 'col-start-1 col-span-4'

  let topSmallImageClasses = ''

  if (isTopSmallImage2Cols) {
    topSmallImageClasses =
      'col-span-2 row-start-1 translate-y-1/2 mt-[calc(100%/var(--aspect-ratio)*-.5)]'

    if (isTopSmallImageRight) {
      topSmallImageClasses =
        'col-span-2 col-start-3 row-start-1 translate-y-1/2 mt-[calc(100%/var(--aspect-ratio)*-.5)]'
    }
  } else {
    if (isTopSmallImageRight) {
      topSmallImageClasses =
        'col-start-4 row-start-1 translate-y-1/2 mt-[calc(100%/var(--aspect-ratio)*-.5)]'
    } else {
      topSmallImageClasses =
        'row-start-1 translate-y-1/2 mt-[calc(100%/var(--aspect-ratio)*-.5)]'
    }
  }

  let bottomSmallImageClasses = ''

  if (isBottomSmallImage2Cols) {
    bottomSmallImageClasses = 'col-span-2'

    if (isBottomSmallImageRight) {
      bottomSmallImageClasses = 'col-span-2 col-start-3'
    }
  } else {
    if (isBottomSmallImageRight) {
      bottomSmallImageClasses = 'col-start-4'
    }
  }

  const topSmallImageStyle = topSmallImage.filename
    ? ({
        aspectRatio: `${getImageAspectRatio(topSmallImage)}`,
        '--aspect-ratio': `${getImageAspectRatio(topSmallImage)}`,
      } as React.CSSProperties)
    : {}

  const bottomSmallImageStyle = bottomSmallImage.filename
    ? ({
        aspectRatio: `${getImageAspectRatio(bottomSmallImage)}`,
        '--aspect-ratio': `${getImageAspectRatio(bottomSmallImage)}`,
      } as React.CSSProperties)
    : {}

  return (
    <div
      className={`section lg:grid lg:grid-cols-8 lg:gap-6 lg:items-center`}
      {...storyblokEditable}
    >
      <div
        className={`grid gap-x-6 grid-cols-4 lg:grid-cols-[subgrid] lg:col-span-4 ${
          isImageGroupRight ? 'lg:col-start-5' : ''
        }`}
      >
        {largeImage.filename && (
          <figure
            className={`row-start-2 col-span-3 relative self-center ${lgImagePosition}`}
            style={{ aspectRatio: `${getImageAspectRatio(largeImage)}` }}
          >
            {
              <Image
                src={largeImage.filename}
                fill={true}
                alt={largeImage.alt}
                className={`object-cover object-center rounded-[4px] ${
                  largeImageHasBorder ? 'border border-dark-brown-40' : ''
                }`}
              />
            }
          </figure>
        )}

        {topSmallImage.filename && (
          <figure
            className={`relative ${topSmallImageClasses}`}
            style={topSmallImageStyle}
          >
            {
              <Image
                src={topSmallImage.filename}
                fill={true}
                alt={topSmallImage.alt}
                className={`object-cover object-center rounded-[4px] ${
                  topSmallImageHasBorder ? 'border border-dark-brown-40' : ''
                }`}
              />
            }
          </figure>
        )}

        {bottomSmallImage.filename && (
          <figure
            className={`relative row-start-3 -translate-y-1/2 mb-[calc(100%/var(--aspect-ratio)*-.5)] ${bottomSmallImageClasses}`}
            style={bottomSmallImageStyle}
          >
            {
              <Image
                src={bottomSmallImage.filename}
                fill={true}
                alt={bottomSmallImage.alt}
                className={`object-cover object-center rounded-[4px] ${
                  bottomSmallImageHasBorder ? 'border border-dark-brown-40' : ''
                }`}
              />
            }
          </figure>
        )}
      </div>

      <div
        className={`richtext max-lg:mt-8 lg:grid-cols-[subgrid] lg:col-span-3 ${
          isImageGroupRight
            ? 'lg:col-start-1 lg:row-start-1'
            : 'lg:ml-[12.5%] lg:mr-[-12.5%]'
        }`}
        dangerouslySetInnerHTML={{ __html: renderRichText(copy) }}
      />
    </div>
  )
}

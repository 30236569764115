'use client'

import { FC, useEffect, useState } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { format, parseJSON } from 'date-fns'
import useSWR, { SWRResponse } from 'swr'

import { useIsFeatureOn } from '@lib/featureWhiteList'

import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
} from '@components/design/Dropdown'

import { LabOrderLeanResponse } from '../../../pages/api/account/reports'

interface Props {
  userAuthed: boolean
  onClose: () => void
}

interface ChildDropdownProps {
  navigateToUrl: (url: string) => void
}

export const ReportsDropdownOptions: FC<ChildDropdownProps> = ({
  navigateToUrl,
}) => {
  const { data: dxReports }: SWRResponse<LabOrderLeanResponse, Error> = useSWR(
    `/api/account/reports?lean=1`
  )
  const labTests = dxReports?.labTests ?? []

  if (labTests.length === 0) {
    return (
      <DropdownButton
        onClick={() => navigateToUrl('/account/reports')}
        icon={LAB_TEST_ICON}
      >
        No results yet
      </DropdownButton>
    )
  }

  return (
    <>
      {labTests.map((report) => (
        <DropdownButton
          onClick={() => navigateToUrl(`/account/reports/${report.id}`)}
          icon={LAB_TEST_ICON}
          key={report.id}
        >
          {format(parseJSON(report.createdAt), 'MMM d, yyyy')}
        </DropdownButton>
      ))}
    </>
  )
}

const ORDER_ICON = (
  <svg
    className="w-5 h-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M12 2C11.8 2 11.6 2.1 11.4 2.2L3.5 6.6C3.2 6.8 3 7.1 3 7.5V16.5C3 16.9 3.2 17.2 3.5 17.4L11.4 21.8C11.6 21.9 11.8 22 12 22S12.4 21.9 12.6 21.8L13.5 21.3C13.2 20.7 13.1 20 13 19.3V12.6L19 9.2V13C19.7 13 20.4 13.1 21 13.3V7.5C21 7.1 20.8 6.8 20.5 6.6L12.6 2.2C12.4 2.1 12.2 2 12 2M12 4.2L18 7.5L16 8.6L10.1 5.2L12 4.2M8.1 6.3L14 9.8L12 10.9L6 7.5L8.1 6.3M5 9.2L11 12.6V19.3L5 15.9V9.2M21.3 15.8L17.7 19.4L16.1 17.8L15 19L17.8 22L22.6 17.2L21.3 15.8Z"
    />
  </svg>
)

const SUBSCRIPTION_ICON = (
  <svg
    className="w-5 h-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M4 3C2.89 3 2 3.89 2 5V9C2 10.11 2.89 11 4 11H8C9.11 11 10 10.11 10 9V5C10 3.89 9.11 3 8 3M8.2 4.5L9.26 5.55L5.27 9.5L2.74 6.95L3.81 5.9L5.28 7.39M4 13C2.89 13 2 13.89 2 15V19C2 20.11 2.89 21 4 21H8C9.11 21 10 20.11 10 19V15C10 13.89 9.11 13 8 13M4 15H8V19H4M12 5H22V7H12M12 19V17H22V19M12 11H22V13H12Z"
    ></path>{' '}
  </svg>
)

export const OrdersOptions: FC<ChildDropdownProps> = ({ navigateToUrl }) => {
  return (
    <>
      <DropdownButton
        icon={ORDER_ICON}
        onClick={() => navigateToUrl('/account/orders')}
      >
        Order History
      </DropdownButton>

      <DropdownButton
        icon={SUBSCRIPTION_ICON}
        onClick={() => navigateToUrl('/account/subscriptions')}
      >
        Subscriptions
      </DropdownButton>
    </>
  )
}

const UserDropdown: FC<Props> = ({ userAuthed, onClose }) => {
  const [dropdown, setDropdown] = useState<
    'main_authed' | 'main_unauthed' | 'reports' | 'orders'
  >('main_unauthed')

  const router = useRouter()
  const pathname = usePathname()

  const isDeviceIntegrationEnabled = useIsFeatureOn('device-integration')

  const isCurrentRoute = (route: string) => pathname === route

  const navigateToUrl = (url: string) => {
    if (isCurrentRoute(url)) {
      return onClose()
    }
    router.push(url)
  }

  useEffect(() => {
    if (userAuthed) {
      setDropdown('main_authed')
    } else {
      setDropdown('main_unauthed')
    }
  }, [userAuthed])

  return (
    <div>
      {dropdown === 'main_unauthed' && (
        <Dropdown>
          <DropdownButton
            onClick={() => (window.location.href = '/api/auth/signup')}
            icon={SIGN_UP_ICON}
          >
            Sign Up
          </DropdownButton>
          <DropdownButton
            onClick={() => (window.location.href = '/api/auth/login')}
            icon={LOG_IN_ICON}
          >
            Log In
          </DropdownButton>
          <DropdownDivider />
          <DropdownButton
            onClick={() =>
              (window.location.href =
                'https://mylifeforce.zendesk.com/hc/en-us')
            }
            icon={HELP_CENTER_ICON}
          >
            Help Center
          </DropdownButton>
        </Dropdown>
      )}

      {dropdown === 'main_authed' && (
        <Dropdown>
          <DropdownButton
            onClick={() => navigateToUrl('/account/profile')}
            icon={DASHBOARD_ICON}
            highlighted={isCurrentRoute('/account/profile')}
          >
            Dashboard
          </DropdownButton>

          <DropdownButton
            onClick={() => navigateToUrl('/account/plan')}
            icon={LIFEFORCE_PLAN_ICON}
            highlighted={isCurrentRoute('/account/plan')}
          >
            Lifeforce Plan
            <span className="absolute w-2 h-2 rounded-full top-1 left-6 bg-yellow border border-brown/40"></span>
          </DropdownButton>

          <DropdownButton
            onClick={() => setDropdown('reports')}
            icon={RESULTS_ICON}
            showNavigationIcon={true}
            highlighted={isCurrentRoute('/account/reports')}
          >
            Results
          </DropdownButton>

          {isDeviceIntegrationEnabled && (
            <DropdownButton
              onClick={() => navigateToUrl('/account/devices')}
              icon={CONNECTED_DEVICE_ICON}
              highlighted={isCurrentRoute('/account/devices')}
            >
              Connected Devices
            </DropdownButton>
          )}

          <DropdownButton
            onClick={() => navigateToUrl('/account/appointments')}
            icon={APPOINTMENTS_ICONS}
            highlighted={isCurrentRoute('/account/appointments')}
          >
            Appointments
          </DropdownButton>

          <DropdownButton
            onClick={() => navigateToUrl('/account/documents')}
            icon={DOCUMENTS_ICON}
            highlighted={isCurrentRoute('/account/documents')}
          >
            Documents
          </DropdownButton>

          <DropdownButton
            onClick={() => setDropdown('orders')}
            icon={ORDER_ICON}
            showNavigationIcon={true}
          >
            Orders & Subscriptions
          </DropdownButton>

          <DropdownButton
            onClick={() => navigateToUrl('/account/details')}
            icon={ACCOUNT_DETAILS_ICON}
            highlighted={isCurrentRoute('/account/details')}
          >
            Account Details
          </DropdownButton>

          <DropdownButton
            onClick={() =>
              (window.location.href =
                'https://mylifeforce.zendesk.com/hc/en-us')
            }
            icon={HELP_CENTER_ICON}
          >
            Help Center
          </DropdownButton>

          <DropdownDivider />

          <DropdownButton
            onClick={() => (window.location.href = '/api/auth/logout')}
            icon={LOGOUT_ICON}
          >
            Log Out
          </DropdownButton>
        </Dropdown>
      )}

      {dropdown === 'reports' && (
        <Dropdown>
          <DropdownButton
            onClick={() => setDropdown('main_authed')}
            icon={BACK_ICON}
          >
            Back
          </DropdownButton>

          <DropdownDivider />

          <ReportsDropdownOptions navigateToUrl={navigateToUrl} />
        </Dropdown>
      )}

      {dropdown === 'orders' && (
        <Dropdown>
          <DropdownButton
            onClick={() => setDropdown('main_authed')}
            icon={BACK_ICON}
          >
            Back
          </DropdownButton>

          <DropdownDivider />

          <OrdersOptions navigateToUrl={navigateToUrl} />
        </Dropdown>
      )}
    </div>
  )
}

const SIGN_UP_ICON = (
  <svg
    className="w-5 h-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4M15,5.9C16.16,5.9 17.1,6.84 17.1,8C17.1,9.16 16.16,10.1 15,10.1A2.1,2.1 0 0,1 12.9,8A2.1,2.1 0 0,1 15,5.9M4,7V10H1V12H4V15H6V12H9V10H6V7H4M15,13C12.33,13 7,14.33 7,17V20H23V17C23,14.33 17.67,13 15,13M15,14.9C17.97,14.9 21.1,16.36 21.1,17V18.1H8.9V17C8.9,16.36 12,14.9 15,14.9Z"
    />
  </svg>
)

const LOG_IN_ICON = (
  <svg
    className="w-5 h-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M10,17V14H3V10H10V7L15,12L10,17M10,2H19A2,2 0 0,1 21,4V20A2,2 0 0,1 19,22H10A2,2 0 0,1 8,20V18H10V20H19V4H10V6H8V4A2,2 0 0,1 10,2Z"
    />
  </svg>
)

const HELP_CENTER_ICON = (
  <svg
    className="h-5 w-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M20 16V4H8V16H20M22 16C22 17.1 21.1 18 20 18H8C6.9 18 6 17.1 6 16V4C6 2.9 6.9 2 8 2H20C21.1 2 22 2.9 22 4V16M16 20V22H4C2.9 22 2 21.1 2 20V7H4V20H16M14.2 5C13.3 5 12.6 5.2 12.1 5.6C11.6 6 11.3 6.6 11.3 7.4H13.2C13.2 7.1 13.3 6.9 13.5 6.7C13.7 6.6 13.9 6.5 14.2 6.5C14.5 6.5 14.8 6.6 15 6.8C15.2 7 15.3 7.2 15.3 7.6C15.3 7.9 15.2 8.2 15.1 8.4C15 8.6 14.7 8.8 14.5 9C14 9.3 13.6 9.6 13.5 9.9C13.1 10.1 13 10.5 13 11H15C15 10.7 15 10.4 15.1 10.3C15.2 10.1 15.4 9.9 15.6 9.8C16 9.6 16.4 9.3 16.7 8.9C17 8.4 17.2 8 17.2 7.5C17.2 6.7 16.9 6.1 16.4 5.7C15.9 5.2 15.1 5 14.2 5M13 12V14H15V12H13Z"
    ></path>
  </svg>
)

const DASHBOARD_ICON = (
  <svg
    className="w-5 h-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M19,5V7H15V5H19M9,5V11H5V5H9M19,13V19H15V13H19M9,17V19H5V17H9M21,3H13V9H21V3M11,3H3V13H11V3M21,11H13V21H21V11M11,15H3V21H11V15Z"
    ></path>
  </svg>
)

const LIFEFORCE_PLAN_ICON = (
  <svg
    className="h-5 w-5"
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.5,9.63C14.21,10.32 13.03,11.2 12,12.26C10.97,11.19 9.79,10.31 8.5,9.63C8.74,6.86 9.92,4.14 12.06,2C14.18,4.12 15.31,6.84 15.5,9.63M12,15.45C14.15,12.17 17.82,10 22,10C22,20 12.68,21.88 12,22C11.32,21.89 2,20 2,10C6.18,10 9.85,12.17 12,15.45M12.05,5.19C11.39,6.23 10.93,7.38 10.68,8.58L12,9.55L13.35,8.57C13.12,7.37 12.68,6.22 12.05,5.19M12,19.97C12,19.97 18,19 19.74,12.25C14,14 12,19.1 12,19.1C12,19.1 9,13 4.26,12.26C6,19 12,19.97 12,19.97Z" />
  </svg>
)

const RESULTS_ICON = (
  <svg
    className="w-5 h-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M22,21H2V3H4V19H6V17H10V19H12V16H16V19H18V17H22V21M18,14H22V16H18V14M12,6H16V9H12V6M16,15H12V10H16V15M6,10H10V12H6V10M10,16H6V13H10V16Z"
    />
  </svg>
)

const CONNECTED_DEVICE_ICON = (
  <svg
    className="h-5 w-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M3 6H21V4H3C1.9 4 1 4.9 1 6V18C1 19.1 1.9 20 3 20H7V18H3V6M13 12H9V13.78C8.39 14.33 8 15.11 8 16C8 16.89 8.39 17.67 9 18.22V20H13V18.22C13.61 17.67 14 16.88 14 16S13.61 14.33 13 13.78V12M11 17.5C10.17 17.5 9.5 16.83 9.5 16S10.17 14.5 11 14.5 12.5 15.17 12.5 16 11.83 17.5 11 17.5M22 8H16C15.5 8 15 8.5 15 9V19C15 19.5 15.5 20 16 20H22C22.5 20 23 19.5 23 19V9C23 8.5 22.5 8 22 8M21 18H17V10H21V18Z"
    />
  </svg>
)

const APPOINTMENTS_ICONS = (
  <svg
    className="w-5 h-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M9,10H7V12H9V10M13,10H11V12H13V10M17,10H15V12H17V10M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,19H5V8H19V19Z"
    ></path>
  </svg>
)

const DOCUMENTS_ICON = (
  <svg
    className="w-5 h-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M16 0H8C6.9 0 6 .9 6 2V18C6 19.1 6.9 20 8 20H20C21.1 20 22 19.1 22 18V6L16 0M20 18H8V2H15V7H20V18M4 4V22H20V24H4C2.9 24 2 23.1 2 22V4H4M10 10V12H18V10H10M10 14V16H15V14H10Z"
    ></path>
  </svg>
)

const ORDERS_SUBSCRIPTIONS_ICON = (
  <svg
    className="w-5 h-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M9 20C9 21.1 8.1 22 7 22S5 21.1 5 20 5.9 18 7 18 9 18.9 9 20M17 18C15.9 18 15 18.9 15 20S15.9 22 17 22 19 21.1 19 20 18.1 18 17 18M7.2 14.8V14.7L8.1 13H15.5C16.2 13 16.9 12.6 17.2 12L21.1 5L19.4 4L15.5 11H8.5L4.3 2H1V4H3L6.6 11.6L5.2 14C5.1 14.3 5 14.6 5 15C5 16.1 5.9 17 7 17H19V15H7.4C7.3 15 7.2 14.9 7.2 14.8M18 2.8L16.6 1.4L11.8 6.2L9.2 3.6L7.8 5L11.8 9L18 2.8Z"
    ></path>
  </svg>
)

const ACCOUNT_DETAILS_ICON = (
  <svg
    className="w-5 h-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M10 4A4 4 0 0 0 6 8A4 4 0 0 0 10 12A4 4 0 0 0 14 8A4 4 0 0 0 10 4M10 6A2 2 0 0 1 12 8A2 2 0 0 1 10 10A2 2 0 0 1 8 8A2 2 0 0 1 10 6M17 12C16.84 12 16.76 12.08 16.76 12.24L16.5 13.5C16.28 13.68 15.96 13.84 15.72 14L14.44 13.5C14.36 13.5 14.2 13.5 14.12 13.6L13.16 15.36C13.08 15.44 13.08 15.6 13.24 15.68L14.28 16.5V17.5L13.24 18.32C13.16 18.4 13.08 18.56 13.16 18.64L14.12 20.4C14.2 20.5 14.36 20.5 14.44 20.5L15.72 20C15.96 20.16 16.28 20.32 16.5 20.5L16.76 21.76C16.76 21.92 16.84 22 17 22H19C19.08 22 19.24 21.92 19.24 21.76L19.4 20.5C19.72 20.32 20.04 20.16 20.28 20L21.5 20.5C21.64 20.5 21.8 20.5 21.8 20.4L22.84 18.64C22.92 18.56 22.84 18.4 22.76 18.32L21.72 17.5V16.5L22.76 15.68C22.84 15.6 22.92 15.44 22.84 15.36L21.8 13.6C21.8 13.5 21.64 13.5 21.5 13.5L20.28 14C20.04 13.84 19.72 13.68 19.4 13.5L19.24 12.24C19.24 12.08 19.08 12 19 12H17M10 13C7.33 13 2 14.33 2 17V20H11.67C11.39 19.41 11.19 18.77 11.09 18.1H3.9V17C3.9 16.36 7.03 14.9 10 14.9C10.43 14.9 10.87 14.94 11.3 15C11.5 14.36 11.77 13.76 12.12 13.21C11.34 13.08 10.6 13 10 13M18.04 15.5C18.84 15.5 19.5 16.16 19.5 17.04C19.5 17.84 18.84 18.5 18.04 18.5C17.16 18.5 16.5 17.84 16.5 17.04C16.5 16.16 17.16 15.5 18.04 15.5Z"
    ></path>
  </svg>
)

const LOGOUT_ICON = (
  <svg
    className="w-5 h-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M16,17V14H9V10H16V7L21,12L16,17M14,2A2,2 0 0,1 16,4V6H14V4H5V20H14V18H16V20A2,2 0 0,1 14,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2H14Z"
    ></path>
  </svg>
)

const LAB_TEST_ICON = (
  <svg
    className="h-5 w-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z"
    ></path>
  </svg>
)

const BACK_ICON = (
  <svg
    className="w-5 h-5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
    />
  </svg>
)

export default UserDropdown

'use client'
import React, { FC, useEffect, useRef } from 'react'
import cn from 'clsx'

import s from './Sidebar.module.css'

interface SidebarProps {
  children: any
  onClose: () => void
}

const Sidebar: FC<SidebarProps> = ({ children, onClose }) => {
  const sidebarRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const onKeyDownSidebar = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Escape') {
      onClose()
    }
  }

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.focus()
    }

    const sidebarElement = sidebarRef.current
    const contentElement = contentRef.current

    const handleScroll = (event: Event) => {
      if (contentElement && contentElement.contains(event.target as Node)) {
        event.stopPropagation()
      } else {
        event.preventDefault()
      }
    }

    const disableScroll = () => {
      document.body.style.overflow = 'hidden'
    }

    const enableScroll = () => {
      document.body.style.overflow = ''
    }

    if (sidebarElement && contentElement) {
      sidebarElement.addEventListener('wheel', handleScroll, { passive: false })
      sidebarElement.addEventListener('touchmove', handleScroll, {
        passive: false,
      })
      disableScroll()
    }

    return () => {
      if (sidebarElement && contentElement) {
        sidebarElement.removeEventListener('wheel', handleScroll)
        sidebarElement.removeEventListener('touchmove', handleScroll)
        enableScroll()
      }
    }
  }, [])

  return (
    <div
      className={cn(s.root)}
      ref={sidebarRef}
      onKeyDown={onKeyDownSidebar}
      tabIndex={1}
    >
      <div className="fixed top-0 right-0 bottom-0 left-0 bg-charcoal bg-opacity-80 cursor-pointer z-40" style={{'display': 'block', 'backdropFilter': 'blur(5px)'}}>
        <div className={s.backdrop} onClick={onClose} />
        <section className="bg-offwhite cursor-default fixed top-0 right-0 bottom-0 left-0 sm:left-auto sm:w-[640px] sm:shadow-xl sm:border-l sm:border-brown/10 overflow-auto">
          <div ref={contentRef}>{children}</div>
        </section>
      </div>
    </div>
  )
}

export default Sidebar

'use client'

import React, { useState } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'

import { StoryblokContent } from '@lib/storyblok'

interface Props {
  blok: StoryblokContent
}

export default function Chart({ blok }: Props) {
  const [isActive, setIsActive] = useState(false)

  const toggleActive = () => {
    setIsActive(!isActive)
  }

  return (
    <div
      className="chart section !mt-0 bg-off-white px-0 lg:px-6"
      {...storyblokEditable(blok)}
    >
      <div className="text-center mb-10 lg:mb-14 text-dark-brown px-4 lg:px-6 max-w-[550px] mx-auto">
        <h3 className="h3">
          Every Lifeforce member meets regularly with expert clinicians to not
          just measure but to actually drive results.
        </h3>
      </div>
      <div className="relative">
        <div
          className={` block px-4 lg:w-full overflow-x-auto max-w-[1156px] mx-auto ${
            isActive ? 'max-h-full' : 'max-h-[610px]'
          } lg:max-h-full overflow-y-hidden mb-[88px] lg:mb-0`}
        >
          {/* Row 1 */}
          <div className="bg-light-beige flex px-6 pt-6 flex-row flex-nowrap md:grid md:grid-cols-4 items-center min-w-min rounded-t">
            <div className="min-w-[200px] min-h-[94px] w-full h-full border-b border-dark-brown-40"></div>
            <div className="col-start-2 border-l border-dark-brown-40 h-[94px] flex items-center justify-center min-w-[153px] border-b ">
              <svg
                width="115"
                height="16"
                viewBox="0 0 115 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-max mx-auto"
              >
                <path
                  d="M0 15.2225V0.70861C0 0.421861 0.233811 0.1875 0.519886 0.1875H2.47015C2.75622 0.1875 2.99003 0.421861 2.99003 0.70861V12.5535C2.99003 12.843 3.22384 13.0746 3.50992 13.0746H11.2257C11.5145 13.0746 11.7456 13.309 11.7456 13.5957V15.2142C11.7456 15.5037 11.5118 15.7353 11.2257 15.7353H0.519886C0.23106 15.7353 0 15.501 0 15.2142V15.2225Z"
                  fill="#26180F"
                />
                <path
                  d="M13.0859 15.2225V0.70861C13.0859 0.421861 13.3197 0.1875 13.6058 0.1875H15.5561C15.8449 0.1875 16.076 0.421861 16.076 0.70861V15.2225C16.076 15.5092 15.8422 15.7436 15.5561 15.7436H13.6058C13.3197 15.7436 13.0859 15.5092 13.0859 15.2225Z"
                  fill="#26180F"
                />
                <path
                  d="M18.8262 15.2225V0.70861C18.8262 0.421861 19.06 0.1875 19.3461 0.1875H29.6117C29.8978 0.1875 30.1316 0.421861 30.1316 0.70861V2.32708C30.1316 2.61383 29.8978 2.84819 29.6117 2.84819H22.3388C22.0528 2.84819 21.819 3.08255 21.819 3.3693V6.32226C21.819 6.609 22.0528 6.84337 22.3388 6.84337H28.5005C28.7893 6.84337 29.0203 7.07773 29.0203 7.36448V8.98295C29.0203 9.27245 28.7865 9.50406 28.5005 9.50406H22.3388C22.0528 9.50406 21.819 9.73842 21.819 10.0252V15.2032C21.819 15.4927 21.5851 15.7243 21.2991 15.7243H19.3488C19.06 15.7243 18.8289 15.4899 18.8289 15.2032V15.2142L18.8262 15.2225Z"
                  fill="#26180F"
                />
                <path
                  d="M31.5977 15.2225V0.70861C31.5977 0.421861 31.8315 0.1875 32.1175 0.1875H42.8261C43.1122 0.1875 43.346 0.421861 43.346 0.70861V2.32708C43.346 2.61383 43.1122 2.84819 42.8261 2.84819H35.1103C34.8243 2.84819 34.5904 3.08255 34.5904 3.3693V5.90041C34.5904 6.18991 34.8243 6.42152 35.1103 6.42152H41.492C41.7781 6.42152 42.0119 6.65588 42.0119 6.94263V8.5611C42.0119 8.8506 41.7781 9.08221 41.492 9.08221H35.1103C34.8243 9.08221 34.5904 9.31657 34.5904 9.60332V12.5342C34.5904 12.8237 34.8243 13.0553 35.1103 13.0553H43.0462C43.335 13.0553 43.566 13.2897 43.566 13.5764V15.1949C43.566 15.4844 43.3322 15.716 43.0462 15.716H32.1175C31.8287 15.716 31.5977 15.4817 31.5977 15.1949V15.2087V15.2225Z"
                  fill="#26180F"
                />
                <path
                  d="M45.3672 15.2225V0.70861C45.3672 0.421861 45.601 0.1875 45.8871 0.1875H56.1528C56.4416 0.1875 56.6726 0.421861 56.6726 0.70861V2.32708C56.6726 2.61383 56.4388 2.84819 56.1528 2.84819H48.8799C48.5938 2.84819 48.36 3.08255 48.36 3.3693V6.32226C48.36 6.609 48.5938 6.84337 48.8799 6.84337H55.0415C55.3303 6.84337 55.5614 7.07773 55.5614 7.36448V8.98295C55.5614 9.27245 55.3275 9.50406 55.0415 9.50406H48.8799C48.5938 9.50406 48.36 9.73842 48.36 10.0252V15.2032C48.36 15.4927 48.1262 15.7243 47.8401 15.7243H45.8898C45.6037 15.7243 45.3699 15.4899 45.3699 15.2032V15.2142L45.3672 15.2225Z"
                  fill="#26180F"
                />
                <path
                  d="M73.8926 15.2225V0.708609C73.8926 0.421861 74.1264 0.1875 74.4125 0.1875H81.0967C83.7567 0.1875 85.8637 1.96589 85.8637 4.41152C85.8637 5.86456 85.3961 6.94538 84.4471 7.67879C84.1308 7.92143 84.1748 8.41221 84.5434 8.57213C85.3026 8.90299 85.6464 9.58126 85.6464 10.5408V15.2197C85.6464 15.5065 85.4126 15.7408 85.1265 15.7408H83.1763C82.8874 15.7408 82.6564 15.5065 82.6564 15.2197V11.8173C82.6564 10.731 81.7761 9.84871 80.6924 9.84871H77.4135C77.1274 9.84871 76.8936 10.0831 76.8936 10.3698V15.2142C76.8936 15.5037 76.6598 15.7353 76.3737 15.7353H74.4235C74.1346 15.7353 73.9036 15.501 73.9036 15.2142L73.8926 15.2225ZM76.8854 6.70826C76.8854 6.99501 77.1192 7.22937 77.4053 7.22937H80.2082C81.9825 7.22937 82.8682 6.42979 82.8682 4.98502C82.8682 3.543 81.9825 2.85095 80.2082 2.85095H77.4053C77.1192 2.85095 76.8854 3.08531 76.8854 3.37206V6.70275V6.70826Z"
                  fill="#26180F"
                />
                <path
                  d="M87.3507 6.9371C87.6505 3.06324 90.8716 -0.00275534 94.7529 1.85815e-06C98.5571 0.00275905 100.716 2.07893 101.179 4.72583C101.234 5.04567 100.98 5.33242 100.659 5.33242H98.6644C98.4333 5.33242 98.227 5.17526 98.1665 4.95468C97.7374 3.49612 96.5848 2.66621 94.8436 2.66621H94.6208C92.0709 2.66621 90.2967 4.66793 90.2967 7.99862C90.2967 11.3293 92.0709 13.331 94.6208 13.331H94.8436C96.5848 13.331 97.7429 12.5342 98.1665 11.0426C98.2325 10.8192 98.4333 10.6648 98.6644 10.6648H100.659C100.978 10.6648 101.231 10.9516 101.179 11.2659C100.725 14.0093 98.5598 16 94.7308 16C89.6145 16 86.9271 12.2916 87.3452 6.93159H87.3479L87.3507 6.9371Z"
                  fill="#26180F"
                />
                <path
                  d="M103.031 15.2225V0.70861C103.031 0.421861 103.265 0.1875 103.551 0.1875H114.26C114.546 0.1875 114.78 0.421861 114.78 0.70861V2.32708C114.78 2.61383 114.546 2.84819 114.26 2.84819H106.544C106.258 2.84819 106.024 3.08255 106.024 3.3693V5.90041C106.024 6.18991 106.258 6.42152 106.544 6.42152H112.931C113.217 6.42152 113.451 6.65588 113.451 6.94263V8.5611C113.451 8.8506 113.217 9.08221 112.931 9.08221H106.544C106.258 9.08221 106.024 9.31657 106.024 9.60332V12.5342C106.024 12.8237 106.258 13.0553 106.544 13.0553H114.48C114.766 13.0553 115 13.2897 115 13.5764V15.1949C115 15.4844 114.766 15.716 114.48 15.716H103.551C103.262 15.716 103.031 15.4817 103.031 15.1949V15.2087V15.2225Z"
                  fill="#26180F"
                />
                <path
                  d="M57.2363 7.93245C57.2363 2.97501 60.3144 0 64.71 0C69.1057 0 72.1837 2.97501 72.1837 7.93245C72.1837 12.8899 69.1057 15.8649 64.71 15.8649C60.3144 15.8649 57.2363 12.8899 57.2363 7.93245ZM64.6 13.2207H64.8201C67.568 13.2207 69.2157 11.2383 69.2157 7.93245C69.2157 4.62657 67.568 2.64415 64.8201 2.64415H64.6C61.852 2.64415 60.2044 4.62657 60.2044 7.93245C60.2044 11.2383 61.852 13.2207 64.6 13.2207Z"
                  fill="#26180F"
                />
              </svg>
            </div>
            <div className="border-l border-b border-dark-brown-40 h-[94px] flex items-center justify-center min-w-[153px]">
              <span className="block text-center">Primary Care</span>
            </div>
            <div className="border-l border-b border-dark-brown-40 h-[94px] flex items-center justify-center min-w-[153px]">
              <span className="block text-center">Diagnostic Companies</span>
            </div>
          </div>

          {/* Row 2 */}
          <div className="bg-light-beige px-6 flex flex-row flex-nowrap md:grid md:grid-cols-4 items-center min-w-min">
            <div className="min-w-[200px] flex w-full items-center justify-center h-[94px] md:p-6 border-b border-dark-brown-40">
              <span className="px-2">50+ biomarkers tested</span>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="41"
                height="42"
                viewBox="0 0 41 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20.5" cy="21" r="20.5" fill="#828A7A" />
                <path
                  d="M29.0918 14.3643L16.3639 27.0922L11 21.7283"
                  stroke="#F4F4EA"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <span>Varies</span>
            </div>
          </div>

          {/* Row 3 */}
          <div className="bg-light-beige px-6 flex flex-row flex-nowrap md:grid md:grid-cols-4 items-center min-w-min">
            <div className="flex items-center justify-center h-[94px] min-w-[200px] text-center md:p-6 border-b border-dark-brown-40">
              <span className="px-2">At-home blood test</span>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="41"
                height="42"
                viewBox="0 0 41 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20.5" cy="21" r="20.5" fill="#828A7A" />
                <path
                  d="M29.0918 14.3643L16.3639 27.0922L11 21.7283"
                  stroke="#F4F4EA"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
          </div>

          {/* Row 4 */}
          <div className="bg-light-beige px-6 flex flex-row flex-nowrap md:grid md:grid-cols-4 items-center min-w-min">
            <div className="flex items-center justify-center h-[94px] min-w-[200px] text-center md:p-6 border-b border-dark-brown-40">
              <span className="px-2">Continuous retesting</span>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="41"
                height="42"
                viewBox="0 0 41 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20.5" cy="21" r="20.5" fill="#828A7A" />
                <path
                  d="M29.0918 14.3643L16.3639 27.0922L11 21.7283"
                  stroke="#F4F4EA"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <span>Varies</span>
            </div>
          </div>

          {/* Row 5 */}
          <div className="bg-light-beige px-6 flex flex-row flex-nowrap md:grid md:grid-cols-4 items-center min-w-min">
            <div className="flex items-center justify-center h-[94px] min-w-[200px] text-center md:p-6 border-b border-dark-brown-40">
              <span className="px-2">Wearables data integration</span>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="41"
                height="42"
                viewBox="0 0 41 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20.5" cy="21" r="20.5" fill="#828A7A" />
                <path
                  d="M29.0918 14.3643L16.3639 27.0922L11 21.7283"
                  stroke="#F4F4EA"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
          </div>

          {/* Row 6 */}
          <div className="bg-light-beige px-6 flex flex-row flex-nowrap md:grid md:grid-cols-4 items-center min-w-min">
            <div className="flex items-center justify-center h-[94px] min-w-[200px] text-center md:p-6 border-b border-dark-brown-40">
              <span className="px-2">
                Consults with board certified clinicians
              </span>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="41"
                height="42"
                viewBox="0 0 41 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20.5" cy="21" r="20.5" fill="#828A7A" />
                <path
                  d="M29.0918 14.3643L16.3639 27.0922L11 21.7283"
                  stroke="#F4F4EA"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="41"
                height="42"
                viewBox="0 0 41 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20.5" cy="21" r="20.5" fill="#828A7A" />
                <path
                  d="M29.0918 14.3643L16.3639 27.0922L11 21.7283"
                  stroke="#F4F4EA"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
          </div>

          {/* Row 7 */}
          <div className="bg-light-beige px-6 flex flex-row flex-nowrap md:grid md:grid-cols-4 items-center min-w-min">
            <div className="flex items-center justify-center h-[94px] min-w-[200px] text-center border-b border-dark-brown-40">
              <span className="px-2">Clinician-built health plan</span>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px] md:p-6">
              <svg
                width="41"
                height="42"
                viewBox="0 0 41 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20.5" cy="21" r="20.5" fill="#828A7A" />
                <path
                  d="M29.0918 14.3643L16.3639 27.0922L11 21.7283"
                  stroke="#F4F4EA"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
          </div>

          {/* Row 8 */}
          <div className="bg-light-beige px-6 flex flex-row flex-nowrap md:grid md:grid-cols-4 items-center min-w-min">
            <div className="flex items-center justify-center h-[94px] min-w-[200px] text-center md:p-6 border-b border-dark-brown-40">
              <span className="px-2">Digital goal-tracking dashboard</span>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="41"
                height="42"
                viewBox="0 0 41 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20.5" cy="21" r="20.5" fill="#828A7A" />
                <path
                  d="M29.0918 14.3643L16.3639 27.0922L11 21.7283"
                  stroke="#F4F4EA"
                />
              </svg>
            </div>

            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="41"
                height="42"
                viewBox="0 0 41 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20.5" cy="21" r="20.5" fill="#828A7A" />
                <path
                  d="M29.0918 14.3643L16.3639 27.0922L11 21.7283"
                  stroke="#F4F4EA"
                />
              </svg>
            </div>
          </div>

          {/* Row 9 */}
          <div className="bg-light-beige px-6 flex flex-row flex-nowrap md:grid md:grid-cols-4 items-center min-w-min">
            <div className="flex items-center justify-center h-[94px] min-w-[200px] text-center md:p-6 border-b border-dark-brown-40">
              <span className="px-2">
                1:1 Nutrition, exercise, sleep, stress coaching
              </span>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="41"
                height="42"
                viewBox="0 0 41 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20.5" cy="21" r="20.5" fill="#828A7A" />
                <path
                  d="M29.0918 14.3643L16.3639 27.0922L11 21.7283"
                  stroke="#F4F4EA"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
          </div>

          {/* Row 10 */}
          <div className="bg-light-beige px-6 flex flex-row flex-nowrap md:grid md:grid-cols-4 items-center min-w-min">
            <div className="flex items-center justify-center h-[94px] min-w-[200px] text-center md:p-6 border-b border-dark-brown-40">
              <span className="px-2">FDA approved pharmaceuticals</span>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="41"
                height="42"
                viewBox="0 0 41 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20.5" cy="21" r="20.5" fill="#828A7A" />
                <path
                  d="M29.0918 14.3643L16.3639 27.0922L11 21.7283"
                  stroke="#F4F4EA"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="41"
                height="42"
                viewBox="0 0 41 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20.5" cy="21" r="20.5" fill="#828A7A" />
                <path
                  d="M29.0918 14.3643L16.3639 27.0922L11 21.7283"
                  stroke="#F4F4EA"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-b border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
          </div>

          {/* Row 11 */}
          <div className="bg-light-beige px-6 pb-6 rounded-b flex flex-row flex-nowrap md:grid md:grid-cols-4 items-center min-w-min">
            <div className="flex items-center justify-center h-[94px] min-w-[200px] text-center md:p-6">
              <span className="px-2">Clinical-grade supplements</span>
            </div>
            <div className="flex items-center justify-center border-l border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="41"
                height="42"
                viewBox="0 0 41 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20.5" cy="21" r="20.5" fill="#828A7A" />
                <path
                  d="M29.0918 14.3643L16.3639 27.0922L11 21.7283"
                  stroke="#F4F4EA"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
            <div className="flex items-center justify-center border-l border-dark-brown-40 h-[94px] min-w-[153px]">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F4F4EA" />
                <line
                  x1="26.3633"
                  y1="13.6363"
                  x2="13.6354"
                  y2="26.3642"
                  stroke="#26180F"
                />
                <line
                  x1="26.3632"
                  y1="26.3638"
                  x2="13.6353"
                  y2="13.6359"
                  stroke="#26180F"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          className={`flex items-end lg:hidden absolute left-0 right-0 bottom-[-42px] h-[146px] transition-colors duration-300 ease-in-out ${
            isActive
              ? 'bg-transparent'
              : 'bg-gradient-to-t from-off-white to-transparent from-40%'
          }`}
        >
          <button onClick={toggleActive} className="font-medium px-4">
            <span className={`${isActive ? 'hidden' : 'block'}`}>
              <span className="underline">See All Comparisons</span> +
            </span>
            <span className={`${isActive ? 'block' : 'hidden'}`}>
              <span className="underline">See Less</span> &mdash;
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

'use client'

import Modal from './BaseModal'
import Footer from './BaseModalFooter'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Modal,
  Footer,
}

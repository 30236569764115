import React, { useRef } from 'react'
import { useParams } from 'next/navigation'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import { kebabCase } from 'lodash'

import { StoryblokContent } from '@lib/storyblok'

import ProductCard from '@components-v7/ProductCard'

import './product-grid.css'

type Tag = {
  uuid: string
  name: string
}

type Product = {
  subtitle?: string
  image?: string
  name?: string
  slug?: string
  uuid?: string
  content?: {
    name?: string
    category?: string
    // TODO address types
    main_image?: any
    short_description?: any
    tags?: Tag[] | string[]
  }
}

export default function ProductGrid({ blok }: StoryblokContent) {
  const params = useParams()
  const { tag } = params || {}
  let grid = useRef(blok.grid)

  if (tag) {
    grid.current = blok.grid.filter((item: any) => {
      const tags = item.content.tags?.map(({ name }: { name: string }) =>
        kebabCase(name)
      )

      return tags?.includes(tag)
    })
  }

  return (
    <div
      className={`product-grid grid gap-x-6 relative before:absolute before:inset-x-0 before:top-0 before:h-[1px] before:bg-dark-brown/40 lg:grid-cols-9 lg:col-span-full mt-14 lg:mt-20 first:mt-0`}
      {...storyblokEditable}
    >
      <h3 className="h3 text-dark-brown my-3 lg:mt-6 lg:col-span-full">
        {blok.title}
      </h3>
      {blok.copy && (
        <p className="text-base lg:text-lg lg:col-span-4 lg:mt-6">
          {blok.copy}
        </p>
      )}

      <div className="collection-products mt-10 lg:mt-14 grid lg:grid-flow-dense gap-y-8 lg:gap-y-12 grid-cols-1 lg:grid-cols-[subgrid] lg:col-span-9">
        {grid.current.map((item: any) => {
          const { content } = item

          switch (content.component) {
            case 'product-v7':
              return (
                <ProductCard
                  key={item.uuid}
                  isInProductGrid={true}
                  modifiers={`col-span-3`}
                  product={item}
                />
              )

            default:
              return <StoryblokComponent blok={item.content} key={item.uuid} />
          }
        })}
      </div>
    </div>
  )
}

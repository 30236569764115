import React, { FC, ReactNode } from 'react'
import cn from 'clsx'

import { ChevronLeft,Cross } from '@components/icons'

import CartIcon from '../CartIcon'

import s from './SidebarLayout.module.css'

type ComponentProps = { title?: string, className?: string; children?: ReactNode } & (
  | { handleClose: () => any; handleBack?: never }
  | { handleBack: () => any; handleClose?: never }
)

const SidebarLayout: FC<ComponentProps> = ({
                                                title,
                                                children,
                                                className,
                                                handleClose,
                                                handleBack,
                                              }) => {
  return (
    <div>
      <div className='sticky top-0 z-30 h-16 bg-offwhite text-brown border-b border-brown/10'>
        <div
          className='relative w-full mx-auto px-4 lg:max-w-[1440px] h-16 flex gap-2 items-center text-sm font-medium'>
          <label className='block whitespace-nowrap font-medium text-sm tracking-wider uppercase text-brown'>{title}</label>
          <button type='button'
                  onClick={handleClose}
                  className='ml-auto -mr-2 bg-offwhite text-brown relative flex items-center border border-brown/0 p-2 text-center cursor-pointer rounded-full font-medium text-sm hover:bg-brown hover:text-offwhite focus:outline-none focus:ring-1 focus:ring-brown focus:rounded focus:text-brown focus:bg-beige focus:border-transparent transition-all'>
            <span className='mx-2 whitespace-nowrap'>Close</span>
          </button>
        </div>
      </div>

      <div className='mt-2 p-4'>
        {children}
      </div>
    </div>
  )
}

const SidebarLayoutOld: FC<ComponentProps> = ({
                                                title,
                                                children,
                                                className,
                                                handleClose,
                                                handleBack
                                              }) => {
  return (
    <div
      className={cn(
        'relative h-full flex flex-col font-aktiv-grotesk-sans',
        className
      )}
    >
      <header className='sticky top-0 z-30 flex items-center justify-between h-16 gap-2 bg-white'>
        {handleClose && (
          <button
            onClick={handleClose}
            aria-label='Close'
            className="relative flex items-center w-auto p-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-full cursor-pointer hover:bg-secch hover:border-secch hover:text-white focus:outline-none focus:ring-1 focus:ring-secch focus:border-secch"
          >
            <Cross className="w-5 h-5" />
            <span className="mx-2">Close</span>
          </button>
        )}
        {handleBack && (
          <button
            onClick={handleBack}
            aria-label="Go back"
            className="flex items-center transition duration-150 ease-in-out hover:text-accent-5 focus:outline-none"
          >
            <ChevronLeft className="w-6 h-6 hover:text-accent-3" />
            <span className="ml-2 text-xs text-accent-7">Back</span>
          </button>
        )}
        <span className={s.nav}>
          <nav className={cn(s.root, className)}>
            <ul className={s.list}>
              <CartIcon />
            </ul>
          </nav>
        </span>
      </header>
      <div className="box-border flex flex-col flex-1">{children}</div>
    </div>
  )
}

export default SidebarLayout

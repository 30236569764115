import NextLink from 'next/link'
import { usePathname } from 'next/navigation'

import { StoryblokContent } from '@lib/storyblok'

export default function Link({ blok }: { blok: StoryblokContent }) {
  const { call_to_action_link, call_to_action_text } = blok
  const { linktype } = call_to_action_link

  const pathname = usePathname() ?? ''

  // Return nothing if there is no link text
  if (!call_to_action_text) {
    return null
  }

  if (linktype === 'story') {
    if (call_to_action_link.story) {
      let href = `/${call_to_action_link.story.url}`

      if (href.endsWith('/')) {
        href = href.slice(0, -1)
      }

      return (
        <>
          <NextLink
            className={
              pathname && pathname.includes(href) ? 'current' : undefined
            }
            href={`/${call_to_action_link.story.url}`}
          >
            {call_to_action_text}
          </NextLink>
          {call_to_action_link.story.slug.includes('pharmaceuticals') && (
            <>
              &nbsp;
              <span className="tag tag--gold self-end">Members Only</span>
            </>
          )}
        </>
      )
    } else {
      return <span>{call_to_action_text}</span>
    }
  } else if (linktype === 'url') {
    return (
      <NextLink
        className={
          pathname && pathname.includes(call_to_action_link.url)
            ? 'current'
            : undefined
        }
        href={call_to_action_link.url}
        target={call_to_action_link.target}
      >
        {call_to_action_text}

        {call_to_action_text == 'Pharmaceuticals' && (
          <>
            &nbsp;
            <span className="tag tag--gold self-end">Members Only</span>
          </>
        )}
      </NextLink>
    )
  } else {
    return <span>{call_to_action_text} (unknown link type)</span>
  }
}

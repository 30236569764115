'use client'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import { renderRichText, storyblokEditable } from '@storyblok/react/rsc'
import { useInView } from 'framer-motion'
import { ISbRichtext } from 'storyblok-js-client'

import { StoryblokContent } from '@lib/storyblok'

import ArrowLeft from '@/components-v7/ArrowLeft'
import ArrowRight from '@/components-v7/ArrowRight'

import './Testimonials.css'

interface Props {
  blok: StoryblokContent
  setActiveSection?: (section: string) => void
}

export default function Testimonials(props: Props) {
  const { blok, setActiveSection } = props
  const { body, testimonials } = blok

  const SLIDES_PER_VIEW = 3 // Slides per view on desktop, defaults to 1 on mobile

  const sliderRef = useRef<HTMLDivElement>(null)
  const lastSlideRef = useRef<HTMLDivElement>(null)
  const [index, setIndex] = useState(0)
  const isLastSlideInView = useInView(lastSlideRef, { amount: 0.75 })
  const pathname = usePathname() ?? ''

  const ref = React.useRef<HTMLDivElement>(null)
  const mainView = useInView(ref)

  useEffect(() => {
    if (mainView) {
      setActiveSection?.('Reviews')
    }
  }, [mainView, setActiveSection])

  const goToNextSlide = useCallback(() => {
    const slider = sliderRef.current

    if (!slider) return

    const sliderLength = slider.children.length
    const oldIndex = index

    if (sliderLength === oldIndex + 1 || isLastSlideInView) {
      slider.scrollTo({ behavior: 'smooth', left: 0 })
      setIndex(0)
    } else {
      const newIndex =
        window.innerWidth > 1024 ? oldIndex + SLIDES_PER_VIEW : oldIndex + 1
      const currSlide =
        newIndex >= sliderLength
          ? slider.children[sliderLength - 1]
          : (slider.children[newIndex] as HTMLDivElement)

      slider.scrollTo({
        behavior: 'smooth',
        left: (currSlide as HTMLDivElement).offsetLeft,
      })
      setIndex(newIndex >= sliderLength ? sliderLength - 1 : newIndex)
    }
  }, [index, isLastSlideInView])

  const goToPreviousSlide = useCallback(() => {
    const slider = sliderRef.current

    if (!slider) return

    const oldIndex = index

    if (oldIndex === 0) {
      const newIndex = slider.children.length - 1
      const currSlide = slider.children[newIndex] as HTMLDivElement

      setIndex(newIndex)
      slider.scrollTo({ behavior: 'smooth', left: currSlide.offsetLeft })
    } else {
      const newIndex =
        window.innerWidth > 1024 ? oldIndex - SLIDES_PER_VIEW : oldIndex - 1
      const currSlide =
        newIndex <= 0
          ? slider.children[0]
          : (slider.children[newIndex] as HTMLDivElement)

      slider.scrollTo({
        behavior: 'smooth',
        left: (currSlide as HTMLDivElement).offsetLeft,
      })
      setIndex(newIndex <= 0 ? 0 : newIndex)
    }
  }, [index])

  return (
    <div
      ref={ref}
      id="reviews"
      className={`testimonials py-5 bg-off-white overflow-hidden mb-5 ${
        pathname.includes('/product/membership')
          ? 'relative before:block before:absolute before:inset-x-0 before:-top-[--header-height] before:h-[--header-height] pointer-events-none'
          : ''
      }`}
      {...storyblokEditable(blok)}
    >
      <div
        className={`pb-6 lg:pb-8 border-dark-brown/40 border-b mx-4 lg:mx-6 lg:flex lg:justify-between lg:items-end`}
      >
        <div
          className={`richtext text-dark-brown`}
          dangerouslySetInnerHTML={{ __html: renderRichText(body) }}
        />
        <div className="flex gap-2 mt-8 lg:mt-0">
          <ArrowLeft
            className="testimonials__arrow cursor-pointer group"
            onClick={goToPreviousSlide}
          />
          <ArrowRight
            className="testimonials__arrow cursor-pointer group"
            onClick={goToNextSlide}
          />
        </div>
      </div>

      <div className="mt-6 lg:mt-8 w-screen lg:w-auto">
        <div
          className={`flex gap-6 overflow-x-scroll no-scrollbar px-4 lg:px-6`}
          ref={sliderRef}
        >
          {testimonials.map(
            (
              {
                image,
                description,
              }: {
                image: { filename: string; alt: string }
                description: ISbRichtext
              },
              idx: number
            ) => {
              const imageDimensions = image.filename.split('/')[5].split('x')
              const imageAspectRatio =
                parseInt(imageDimensions[0], 10) /
                parseInt(imageDimensions[1], 10)

              return (
                <div
                  key={idx}
                  ref={
                    idx === blok.testimonials.length - 1 ? lastSlideRef : null
                  }
                  className={`testimonials__card w-3/4 lg:w-[calc((100vw_-_96px)_/_3)] shrink-0`}
                >
                  <figure
                    className="relative"
                    style={{ aspectRatio: `${imageAspectRatio}` }}
                  >
                    <Image
                      src={image.filename}
                      fill={true}
                      alt={image.alt}
                      className="object-cover object-center rounded-[4px] select-none"
                    />
                  </figure>
                  <div
                    className={`richtext mt-6`}
                    dangerouslySetInnerHTML={{
                      __html: renderRichText(description),
                    }}
                  />
                </div>
              )
            }
          )}
        </div>
      </div>
    </div>
  )
}

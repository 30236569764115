import { FC, SyntheticEvent, useEffect, useMemo } from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import cn from 'clsx'

import useCart from '@framework/cart/use-cart'
import usePrice from '@framework/product/use-price'
import useSWRCart from '@commerce/cart/use-swr-cart'
import { trackViewCart } from '@lib/datalayer'
import { DEFAULT_SETUP_TOTAL, LF_MEMBERSHIP_SKU } from '@lib/productCatalog'

import MembershipSideCartItem from '@components/cart/MembershipSideCartItem'
import HSALearnMore from '@components/common/HSALearnMore'
import SidebarLayout from '@components/common/SidebarLayout'
import Button from '@components/design/Button'
import { Bag, Check, Cross } from '@components/icons'
import { useUI } from '@components/ui/context'

import { testimonialsDataList } from '../../checkout/constants'
import TestimonialsCardsView from '../../checkout/TestimonialsCardsView'
import SideCartItem from '../SideCartItem'

import s from './CartSidebarView.module.css'

const CartSidebarView: FC = () => {
  const { closeSidebar, setSidebarView } = useUI()
  const { data, isLoading, refreshCart } = useSWRCart()
  const pathname = usePathname()

  const membershipLineItem = useMemo(() => {
    return data?.lineItems?.find((l) => l.variant?.sku === LF_MEMBERSHIP_SKU)
  }, [data?.lineItems])

  const cartLineItems = useMemo(() => {
    return data?.lineItems?.filter((l) => l.variant?.sku !== LF_MEMBERSHIP_SKU)
  }, [data?.lineItems])

  const adjustedSubTotal = useMemo(() => {
    if (!membershipLineItem) return data?.subtotalPrice

    const membershipLineItemPrice = membershipLineItem.variant.price
    return data?.subtotalPrice! - membershipLineItemPrice + DEFAULT_SETUP_TOTAL
  }, [membershipLineItem, data?.subtotalPrice])

  const adjustedTotal = useMemo(() => {
    if (!membershipLineItem) return data?.subtotalPrice

    const membershipLineItemPrice = membershipLineItem.variant.price
    return data?.totalPrice! - membershipLineItemPrice + DEFAULT_SETUP_TOTAL
  }, [membershipLineItem, data?.subtotalPrice])

  const { price: subTotal } = usePrice(
    data && {
      amount: Number(adjustedSubTotal),
      currencyCode: data.currency.code,
    }
  )
  const { price: total } = usePrice(
    data && {
      amount: Number(adjustedTotal),
      currencyCode: data.currency.code,
    }
  )

  useEffect(() => {
    if (data) trackViewCart(data, total)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCheckoutClick = (event: SyntheticEvent) => {
    event.preventDefault()
    if (pathname?.includes('/checkout')) {
      return closeSidebar()
    }

    window.location.href = '/checkout'
  }

  let isEmpty = !data || data.lineItems?.length === 0

  const handleClose = () => closeSidebar()

  const error = null
  const success = null

  const exploreButtonHref = '/collections/supplements'

  return (
    <SidebarLayout
      title={`Shopping Cart (${data?.lineItems?.length || 0})`}
      className={cn({
        [s.empty]: error || success || isLoading || isEmpty,
      })}
      handleClose={handleClose}
    >
      {isLoading || !!isEmpty ? (
        <>
          <label className="my-8 block whitespace-nowrap font-medium text-sm tracking-wider uppercase text-warmbrown/80 text-center">
            Your Shopping Cart Is Empty...
          </label>
          <div className="flex items-center flex-col sm:flex-row w-full gap-2 pt-6 pb-6 -mb-4 sticky bottom-0 bg-offwhite">
            <Link
              onClick={() => {
                closeSidebar()
              }}
              className="w-full"
              href={exploreButtonHref}
            >
              <Button type="button" className="w-full">
                Explore Products
              </Button>
            </Link>
          </div>
        </>
      ) : error ? (
        <div className="flex flex-col items-center justify-center flex-1 px-4">
          <span className="flex items-center justify-center w-16 h-16 border border-white rounded-full">
            <Cross width={24} height={24} />
          </span>
          <h2 className="pt-6 text-xl font-light text-center">
            We couldn’t process the purchase. Please check your card information
            and try again.
          </h2>
        </div>
      ) : success ? (
        <div className="flex flex-col items-center justify-center flex-1 px-4">
          <span className="flex items-center justify-center w-16 h-16 border border-white rounded-full">
            <Check />
          </span>
          <h2 className="pt-6 text-xl font-light text-center">
            Thank you for your order.
          </h2>
        </div>
      ) : (
        <>
          <div>
            <table className="table-auto w-full border-collapse -mt-4 -mb-4">
              <caption className="sr-only">Shopping Cart</caption>
              <thead>
                <tr>
                  <th scope="col" className="p-0 h-0">
                    <span className="sr-only">Product Image</span>
                  </th>
                  <th scope="col" className="p-0 h-0">
                    <span className="sr-only">Description</span>
                  </th>
                  <th scope="col" className="p-0 h-0">
                    <span className="sr-only">Quantity</span>
                  </th>
                  <th scope="col" className="p-0 h-0">
                    <span className="sr-only">Price</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {membershipLineItem && (
                  <MembershipSideCartItem
                    item={membershipLineItem}
                    currencyCode={data!.currency.code}
                    refreshCart={refreshCart}
                  />
                )}
                {cartLineItems?.map((item: any) => {
                  return (
                    <SideCartItem
                      key={item.id}
                      item={item}
                      currencyCode={data!.currency.code}
                      refreshCart={refreshCart}
                    />
                  )
                })}
              </tbody>
            </table>
            <table className="table-auto w-full border-collapse mt-4 border-t border-brown/10">
              <caption className="sr-only">Totals</caption>
              <thead>
                <tr>
                  <th scope="col" className="p-0 h-0">
                    <span className="sr-only">Description</span>
                  </th>
                  <th scope="col" className="p-0 h-0">
                    <span className="sr-only">Amount</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr className="border-0">
                  <td className="pl-0 pr-0 pt-4 pb-0">
                    <span className="block text-warmbrown text-sm text-left leading-6">
                      Subtotal
                    </span>
                  </td>
                  <td className="pl-2 pr-0 pt-4 pb-0">
                    <span className="block text-brown font-medium text-sm text-right leading-6">
                      {subTotal}
                    </span>
                  </td>
                </tr>
                <tr className="border-0">
                  <td className="pl-0 pr-0 pt-0 pb-0">
                    <span className="block text-warmbrown text-sm text-left leading-6">
                      Additional Discounts
                    </span>
                  </td>
                  <td className="pl-2 pr-0 pt-0 pb-0">
                    <span className="block text-brown text-sm text-right leading-6">
                      Applied at checkout
                    </span>
                  </td>
                </tr>
                <tr className="border-0">
                  <td className="pl-0 pr-0 pt-0 pb-0">
                    <span className="block text-warmbrown text-sm text-left leading-6">
                      Taxes
                    </span>
                  </td>
                  <td className="pl-2 pr-0 pt-0 pb-0">
                    <span className="block text-brown text-sm text-right leading-6">
                      Calculated at checkout
                    </span>
                  </td>
                </tr>
                <tr className="border-0">
                  <td className="pl-0 pr-0 pt-0 pb-4">
                    <span className="block text-warmbrown text-sm text-left leading-6">
                      Shipping
                    </span>
                  </td>
                  <td className="pl-2 pr-0 pt-0 pb-4">
                    <span className="block text-brown font-medium text-sm text-right leading-6">
                      FREE
                    </span>
                  </td>
                </tr>
                <tr className="border-t border-brown/10">
                  <td className="pl-0 pr-0 pt-4 pb-0">
                    <span className="block text-brown font-medium text-sm text-left leading-6">
                      Estimated Total
                    </span>
                  </td>
                  <td className="pl-2 pr-0 pt-4 pb-0">
                    <span className="block text-brown font-medium text-sm text-right leading-6">
                      {total}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <HSALearnMore source="sidecart" />

            <div className="flex items-center flex-col sm:flex-row w-full gap-2 pt-6 pb-6 -mb-4 sticky bottom-0 bg-offwhite">
              <Link
                href="/checkout"
                onClick={onCheckoutClick}
                className="group w-full justify-center bg-yellow text-brown relative flex items-center border border-brown/0 p-2 text-center cursor-pointer rounded-full font-medium text-sm hover:bg-brown hover:text-offwhite focus:outline-none focus:ring-1 focus:ring-brown focus:rounded focus:text-brown focus:bg-beige focus:border-transparent transition-all"
              >
                <span className="mx-2 whitespace-nowrap group-focus:invisible">
                  Proceed to Checkout
                </span>
                <span
                  role="status"
                  className="hidden absolute group-focus:block"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-brown/20 animate-spin fill-brown"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    ></path>
                  </svg>
                  <span className="sr-only">Loading...</span>
                </span>
              </Link>
            </div>
          </div>
        </>
      )}
      <div className="mt-2 pt-1 bg-beige/40 px-[-200px]">
        <TestimonialsCardsView testimonialsDataList={testimonialsDataList} />
      </div>
    </SidebarLayout>
  )
}

export default CartSidebarView

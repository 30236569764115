import { FC, useState } from 'react'
import {
  ISbRichtext,
  renderRichText,
  storyblokEditable,
} from '@storyblok/react'
import cn from 'clsx'

import { StoryblokContent } from '@lib/storyblok'

import s from './membershipFAQ.module.css'

interface QnA {
  _uid: string
  question: string
  answer: ISbRichtext
}

interface MembershipFAQProps {
  blok: StoryblokContent
}

const MembershipFAQ = ({ blok }: MembershipFAQProps) => {
  const { FAQs, heading, sub_heading, as_a_blog_content = false } = blok

  return (
    <div
      {...storyblokEditable(blok)}
      style={{ all: 'unset' }}
      className="bradford"
    >
      <div className="relative my-10 bradford px-4 mx-auto sm:px-8 lg:max-w-7xl">
        <div className="mb-8 text-center lg:mb-12">
          {heading && (
            <h2 className="text-2xl !mt-10 text-warmbrown sm:text-3xl">
              {heading}
            </h2>
          )}
          {sub_heading && (
            <p
              style={{ all: 'unset' }}
              className="mt-4 text-base text-warmbrown"
            >
              {sub_heading}
            </p>
          )}
        </div>
        <div
          className={`relative flex flex-col justify-start my-4 ${
            as_a_blog_content ? 'lg:-mx-[300px] lg:pl-32' : ''
          }`}
        >
          {FAQs.length > 0 &&
            FAQs.map((QnA: QnA, index: number) => {
              const isActive = index === 0
              return (
                <AccordionSection key={QnA._uid} QnA={QnA} active={isActive} />
              )
            })}
        </div>
      </div>
    </div>
  )
}

interface AccordionSectionProps {
  QnA: QnA
  active: boolean
}

const AccordionSection = ({ QnA, active }: AccordionSectionProps) => {
  const { question, answer } = QnA
  const htmlDescription = renderRichText(answer)
  const [isActive, setActive] = useState(active)

  const handleAccordionClick = () => {
    setActive(!isActive)
  }

  return (
    <>
      <span
        onClick={handleAccordionClick}
        className="flex items-center justify-between w-full gap-2 pb-4 mb-4 text-base text-warmbrown border-b border-gray-300 cursor-pointer group sm:text-xl hover:text-darkteal last:mb-0"
      >
        {question && (
          <h3 style={{ all: 'unset' }} className="text-left bradford">
            {question}
          </h3>
        )}
        <AccordionCollapseState activeSection={isActive} />
      </span>
      {isActive && (
        <div className="pb-8 mt-4 mb-4 border-b border-gray-300 last:mb-0 text-warmbrown bradford richtext article-richtext">
          <p
            className={cn(s.answer)}
            dangerouslySetInnerHTML={{ __html: htmlDescription }}
          />
        </div>
      )}
    </>
  )
}

interface AccordionCollapseStateProps {
  activeSection: boolean
}

const AccordionCollapseState: FC<AccordionCollapseStateProps> = ({
  activeSection,
}) => {
  const arrowUp = 'M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z'
  const arrowDown =
    'M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z'

  return (
    <svg
      className="w-6 h-6 text-warmbrown transition-all duration-150 transform cursor-pointer ease group-hover:text-darkteal"
      viewBox="0 0 24 24"
    >
      <path fill="currentColor" d={activeSection ? arrowUp : arrowDown}></path>
    </svg>
  )
}

export default MembershipFAQ

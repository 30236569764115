'use client'

import React from 'react'
import Image from 'next/image'
import { renderRichText, storyblokEditable } from '@storyblok/react/rsc'

import { StoryblokContent } from '@lib/storyblok'

import { getImageAspectRatio } from '@/components-v7/helper'

import styles from './FullBleedMedia.module.css'

type MediaPropType = {
  media: {
    filename: string
    alt: string
  }
  mediaHeight: string
  fallback: {
    filename: string
    alt: string
  }
}

const Media = ({ media, fallback }: MediaPropType) => {
  if (media && media.filename) {
    if (media.filename.includes('.mp4')) {
      return (
        <video
          autoPlay
          loop
          muted
          playsInline
          src={media.filename}
          className="w-full h-full object-cover object-center"
        >
          {fallback && fallback.filename && (
            <Image
              src={fallback.filename}
              fill={true}
              alt={fallback.alt}
              className="block object-cover object-center"
              title="Video failed to load"
            />
          )}
        </video>
      )
    } else {
      return (
        <Image
          src={media.filename}
          fill={true}
          alt={media.alt}
          className="block object-cover object-center"
        />
      )
    }
  }

  return <h1>Missing media</h1>
}

interface FullBleedMediaPropTypes {
  blok: StoryblokContent
}

export default function FullMedia({ blok }: FullBleedMediaPropTypes) {
  const {
    darkMode,
    description,
    media,
    mobileMedia,
    mediaHeight,
    mobileTextBelowImage,
    textPlacement,
    videoFallback,
    mobileFallback,
  } = blok

  const mediaStyles = {
    '--aspect-ratio': getImageAspectRatio(media),
  } as React.CSSProperties

  return (
    <div
      className={`
        ${styles['full-bleed-media']}
        ${darkMode ? styles['dark-mode'] : ''}
        ${styles[mediaHeight]}
        section
        section--full-bleed
        full-bleed-media
        relative
        ${darkMode ? 'bg-off-white' : 'bg-black'}
        grid
        gap-4 lg:gap-0
        grid-cols-1
        grid-rows-1
      `}
      {...storyblokEditable(blok)}
    >
      <figure
        className={`${
          styles.media
        } relative self-stretch justify-self-stretch col-start-1 row-start-1 ${
          media && media.filename.includes('.mp4') ? `${styles.overlay}` : ''
        }`}
        style={mediaStyles}
      >
        {media && media.filename && (
          <div
            className={`${
              mobileMedia && mobileMedia.filename ? 'hidden md:block' : 'block'
            }`}
          >
            <Media
              media={media}
              mediaHeight={mediaHeight}
              fallback={videoFallback}
            />
          </div>
        )}
        {mobileMedia && mobileMedia.filename && (
          <div className="block md:hidden">
            <Media
              media={mobileMedia}
              mediaHeight={mediaHeight}
              fallback={{
                filename: mobileFallback?.filename ?? '',
                alt: mobileFallback?.alt ?? '',
              }}
            />
          </div>
        )}
      </figure>
      <div
        className={`richtext
          ${styles.text}
          ${styles[textPlacement]}
          ${darkMode ? styles.darkMode : ''}
          ${mobileTextBelowImage ? `${styles.textbelow}` : ''}
        `}
        dangerouslySetInnerHTML={{ __html: renderRichText(description) }}
      />
    </div>
  )
}

import { useMemo, useState } from 'react'
import useSWR from 'swr'
import { SubscriptionsResponse } from 'types/subscription'

import { useAddItem, useCart, useRemoveItem } from '@framework/cart'
import { Cart, CartItemBody } from '@commerce/types/cart'
import type { OptionValue, ProductOption } from '@commerce/types/product'
import { healthie } from '@environment/index'
import { trackAddToCart, trackRemoveFromCart } from '@lib/datalayer'
import { FormAnswerGroup } from '@lib/healthie/types'
import {
  EnrichedProduct,
  LF_CORE_MEMBERSHIP_SKU,
  LF_MEMBERSHIP_SKU,
  PRESCRIPTION_PRODUCT_NAMES,
} from '@lib/productCatalog'
import { customFieldValue } from '@lib/productCatalog/productHelpers'

import { Button } from '@components/design'
import { ButtonProps } from '@components/design/Button'
import { useUI } from '@components/ui'

import { AccountResult } from '../../../pages/api/account'
import useToast from '../../ui/Toast'

interface AddToCartToggleProps {
  enrichedProduct: EnrichedProduct
  contextualChartingNotes?: FormAnswerGroup
  allowedRx?: boolean
  buttonColor?: ButtonProps['color']
}

export function getMonthlyOption(option: ProductOption): OptionValue | null {
  if (!option) {
    return null
  }

  let optionValue = option.values.find((value) => value.label == '25 Day')

  if (!optionValue) {
    optionValue = option.values.find((value) => value.label == 'Monthly')
  }

  if (!optionValue) {
    optionValue = option.values.find((value) => value.label == 'Annual')
  }

  if (!optionValue) {
    return null
  }

  return {
    option_id: parseInt(option.id),
    option_value: optionValue.entityId,
    label: optionValue.label,
  }
}

function getOneTimeOption(option: ProductOption) {
  if (!option) {
    return null
  }

  const optionValue = option.values.find((optionValue) => {
    return optionValue.label == 'One-time'
  })

  if (!optionValue) {
    return null
  }

  return {
    option_id: parseInt(option.id),
    option_value: optionValue.entityId,
  }
}

// The add to cart toggle should have 4 states
// * Add to cart - Able to add the product to cart. It's either not pharma, or it is pharma and the customer is part of the lifeforce program and has been prescribed by a doc
// * Remove from cart - The product is already in their cart
// * Already subscribed - No need to action
// * Can't add to cart - There may be a few reasons for this we'd like to provide details on here. 1. Hasn't talked to a doc 2. Hasn't added the Lifeforce Program

const fetcher = (url: string) => fetch(url).then((res) => res.json())

export function canPurchaseRx(
  enrichedProduct: EnrichedProduct,
  subscriptions: SubscriptionsResponse | undefined,
  cart: Cart | null | undefined,
  form?: FormAnswerGroup
): boolean {
  const product = enrichedProduct.bcProduct

  const allowableRx = form?.form_answers
    .find((answer) => {
      return (
        answer.custom_module_id ==
        healthie.RX_CLINICAL_ALLOWED_PHARMACEUTICALS_NOTE_MODULE_ID
      )
    })
    ?.answer?.split('\n')

  const performanceLineItem =
    cart &&
    cart.lineItems.find((li) => {
      return [LF_MEMBERSHIP_SKU, LF_CORE_MEMBERSHIP_SKU].includes(
        li.variant.sku.toUpperCase()
      )
    })

  const performanceSubscription = subscriptions?.products?.find((p) => {
    return [LF_MEMBERSHIP_SKU, LF_CORE_MEMBERSHIP_SKU].includes(
      p.bcProduct.sku?.toUpperCase() || ''
    )
  })

  if (
    PRESCRIPTION_PRODUCT_NAMES.includes(enrichedProduct.name) &&
    !allowableRx
  ) {
    return false
  }

  return (
    !PRESCRIPTION_PRODUCT_NAMES.includes(enrichedProduct.name) ||
    ((allowableRx || []).some((i) =>
      i.toLocaleLowerCase().includes(product.name.toLocaleLowerCase())
    ) &&
      !!form?.locked_at &&
      (!!performanceLineItem || !!performanceSubscription))
  )
}

const AddToCartToggle = (props: AddToCartToggleProps) => {
  const { enrichedProduct, contextualChartingNotes } = props
  const product = enrichedProduct.bcProduct
  const addItem = useAddItem()
  const removeItem = useRemoveItem()
  const { toggleSidebar } = useUI()
  const toast = useToast()

  const variantId =
    product?.variants?.[0]?.id || enrichedProduct.content.variant_id
  const productId = product?.id || enrichedProduct.content.product_id
  const monthlyOption = getMonthlyOption(product?.options?.[0]) || {
    option_id: enrichedProduct.content.option_id,
    option_value: enrichedProduct.content.option_value,
  }
  const oneTimeOption = getOneTimeOption(product?.options?.[0])

  const { data: cartContents, isLoading: isCartLoading } = useCart()
  const { data: subscriptionData, error: subscriptionError } =
    useSWR<SubscriptionsResponse>('/api/account/all-subscriptions', fetcher)
  const { data: accountData, error: accountError } = useSWR<AccountResult>(
    '/api/account',
    fetcher
  )

  const isAllowedInState = useMemo(() => {
    const allowedStates = customFieldValue(enrichedProduct, 'allowedStates', '')
      ?.split(',')
      .filter(Boolean)

    return allowedStates && allowedStates.length > 0
      ? allowedStates.includes(accountData?.address?.region as string)
      : true
  }, [enrichedProduct, accountData])

  const [loading, setLoading] = useState(false)
  const isSubscriptionLoading = !subscriptionError && !subscriptionData

  const productInCart = cartContents?.lineItems.find((item) => {
    return item.variantId == variantId
  })

  const canPurchase =
    canPurchaseRx(
      enrichedProduct,
      subscriptionData,
      cartContents,
      contextualChartingNotes
    ) || props.allowedRx

  const sku = enrichedProduct.bcProduct?.sku || enrichedProduct.content?.sku
  if (!sku) throw `No sku found for product ${enrichedProduct.name}`

  const productSubscription = subscriptionData?.products?.find((p) => {
    return p.bcProduct?.sku?.toLowerCase() == sku.toLocaleLowerCase()
  })

  const addToCart = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (!isAllowedInState) {
      toast.info(
        'Cannot Add to Plan',
        'This product is not available in your state'
      )
      return
    }
    setLoading(true)
    const cartItemBody = {
      productId: String(productId),
      variantId: String(variantId),
      optionSelections: allowOneTimeOption
        ? [oneTimeOption]
        : monthlyOption
        ? [monthlyOption]
        : [],
    } as CartItemBody
    try {
      await addItem(cartItemBody)
      trackAddToCart(enrichedProduct, cartContents)
      toggleSidebar()
    } catch {
      toast.error('Try Again', 'Add to cart failed. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const removeFromCart = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    setLoading(true)
    if (productInCart == null) {
      return
    }

    await removeItem(productInCart)
    trackRemoveFromCart(productInCart)
    setLoading(false)
  }

  const isUnavailable = product?.availabilityV2?.status == 'Unavailable'

  const allowOneTimeOption =
    !!productSubscription && !props.allowedRx && oneTimeOption

  return monthlyOption.option_id && monthlyOption.option_value ? (
    <div className="flex flex-row">
      {!productInCart && (
        <Button
          color={props.buttonColor}
          aria-label="Add"
          className="w-full"
          onClick={(event) => {
            if (!isCartLoading && !isSubscriptionLoading) {
              addToCart(event)
            }
          }}
          disabled={
            isUnavailable ||
            !canPurchase ||
            isCartLoading ||
            (!!productSubscription && !allowOneTimeOption)
          }
          loading={loading || isCartLoading || isSubscriptionLoading}
        >
          {productSubscription && !allowOneTimeOption
            ? 'Already subscribed'
            : 'Add To Bag'}
        </Button>
      )}
      {productInCart && (
        <Button
          color={props.buttonColor}
          aria-label="Add"
          className="w-full"
          disabled={!canPurchase}
          loading={loading || isCartLoading}
          onClick={removeFromCart}
        >
          Remove From Bag
        </Button>
      )}
    </div>
  ) : null
}

export default AddToCartToggle

import { useMemo,useRef, useState } from 'react'
import Image from 'next/image'
import classNames from 'classnames'
import { motion, useInView } from 'framer-motion'

import styles from './AnimationWIthText.module.css'

interface Props {
  scrollState: number
  showBgLayers: boolean
}

export default function AnimatingBlock(props: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const inView = useInView(ref)

  const { scrollState, showBgLayers } = props

  return (
    <>
      <motion.div
        ref={ref}
        className={classNames('transition-all duration-[1200ms]')}
      >
        <motion.div>
          <Image
            data-focus-id='0'
            src="/assets/lifescore.png"
            fill={true}
            alt="Lifeforce Score"
            className={classNames(
              styles['custom-scale-animation'],
             'opacity-100',
              'object-contain z-4 w-full h-full absolute transition-all duration-[500ms]'
            )}
          />

          {/* <Image
            src="/assets/lifescore-qual.png"
            fill={true}
            alt="Lifeforce Score"
            className={classNames(
              styles['custom-scale-animation'],
              showBgLayers ? 'opacity-0' : scrollState === 1 ? 'opacity-100 z-10' : 'opacity-10 z-4',
              'object-contain w-full h-full absolute transition-all duration-[500ms]'
            )}
          />

          <Image
            src="/assets/lifescore-risk.png"
            fill={true}
            alt="Lifeforce Score"
            className={classNames(
              styles['custom-scale-animation'],
              showBgLayers ? 'opacity-0' : scrollState === 2 ? 'opacity-100 z-10' : 'opacity-10 z-4',
              'object-contain w-full h-full absolute transition-all duration-[500ms]'
            )}
          />

        <Image
            src="/assets/lifescore-bage.png"
            fill={true}
            alt="Lifeforce Score"
            className={classNames(
              styles['custom-scale-animation'],
              showBgLayers ? 'opacity-0' : scrollState === 3 ? 'opacity-100 z-10' : 'opacity-10 z-4',
              'object-contain w-full h-full absolute transition-all duration-[500ms]'
            )}
          /> */}

          <div
            className={classNames(
              'bg-pulsing',
              styles['bg-assets'],
              'w-full z-4 object-contain h-full absolute transition-all duration-[500ms]',
              styles['custom-pulse-animation']
            )}
          >
            <Image
              src="/assets/lifescore-risk.png"
              fill={true}
              alt="Lifeforce Score"
              className={classNames(
                styles['custom-rot-animation3'],
                'el contrast-[1.89] object-contain w-full h-full opacity-40 absolute transition-all duration-[500ms]'
              )}
              style={{
                filter: 'drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))',
              }}
            />
            <Image
              src="/assets/lifescore-bage.png"
              fill={true}
              alt="Lifeforce Score"
              className={classNames(
                styles['custom-rot-animation2'],
                'el object-contain contrast-[1.89] w-full h-full opacity-40 absolute transition-all duration-[500ms]'
              )}
              style={{
                filter: 'drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))',
              }}
            />
            <Image
              src="/assets/lifescore-qual.png"
              fill={true}
              alt="Lifeforce Score"
              className={classNames(
                styles['custom-rot-animation1'],
                'el object-contain contrast-[1.89] w-full h-full opacity-40 absolute transition-all duration-[500ms]'
              )}
              style={{
                filter: 'drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))',
              }}
            />

            <Image
              src="/assets/lifescore-top.png"
              fill={true}
              alt="Lifeforce Score"
              className={classNames(
                styles['custom-scale-animation'],
                'el bg-item object-contain contrast-[1.89] w-full h-full',
                'opacity-80',
                'absolute transition-al'
              )}
              style={{
                filter: 'drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))',
              }}
            />
            <Image
              src="/assets/lifescore-mid.png"
              fill={true}
              alt="Lifeforce Score"
              className={classNames(
                styles['custom-scale-animation'],
                'el bg-item object-contain contrast-[1.89] w-full h-full',
               'opacity-80',
                'absolute transition-all duration-[500ms]'
              )}
              style={{
                filter: 'drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))',
              }}
            />
          </div>
        </motion.div>
      </motion.div>
    </>
  )
}
import React from 'react'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'

import { StoryblokContent } from '@/lib/storyblok'

const Home = ({ blok }: { blok: StoryblokContent }) => (
  <main {...storyblokEditable(blok)}>
    {blok.body.map((nestedBlok: StoryblokContent) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}
  </main>
)

export default Home

import { FC } from 'react'
import Image from 'next/image'
import SVGIcons from '@assets/svg/SVGIcons'

interface TestimonialCardProps {
  avatar: string
  name: string
  position: string
  statement: any
}

interface TestimonialsCardsViewProps {
  testimonialsDataList: TestimonialCardProps[]
}

const TestimonialsCardsView: FC<TestimonialsCardsViewProps> = ({
  testimonialsDataList,
}) => {
  const hasTestimonials = testimonialsDataList.length > 0

  return (
    <>
      {hasTestimonials &&
      <div className='mt-12'>
        <label
          className='mb-8 block whitespace-nowrap font-medium text-sm tracking-wider uppercase text-warmbrown text-center'>What
          Members Are Saying</label>
        {
          testimonialsDataList.map((testimonial, index) => {
            const { avatar, name, position, statement } = testimonial
            return <div key={index}>
                <TestimonialCard
                  avatar={avatar}
                  name={name}
                  position={position}
                  statement={statement}
                />
              {(index !== testimonialsDataList.length - 1) && <hr className='border-brown/10 my-8' />}
              </div>
          })}
      </div>}
    </>
  )
}

const TestimonialCard = ({
                           avatar,
                           name,
                           position,
                           statement
                         }: TestimonialCardProps) => {
  return (
    <div className="flex w-full gap-4">
      <div className="w-20 h-28 sm:w-24 sm:h-32 overflow-hidden flex-shrink-0 rounded">
        <Image
          className="object-cover w-full h-full"
          src={avatar}
          alt=""
          width={100}
          height={100}
        />
      </div>
      <div className="flex-grow">
        <p className="text-lg bradford text-brown">
          "{statement}"
        </p>
        <label className="mt-4 block text-xs font-medium tracking-wider uppercase text-warmbrown">
          {name} | {position}
        </label>
      </div>
    </div>
  )
}

export default TestimonialsCardsView

import React, { FC, useEffect, useMemo, useState } from 'react'
import Link from 'next/link'
import SVGIcons from '@assets/svg/SVGIcons'

import eventBus from '@lib/eventBus'
import { getUserId } from '@lib/helpers/user'
import useUser from '@lib/hooks/useUser'

import BaseModal from '@components/design/BaseModal'

import Button from '@/components/design/Button/Button'

interface HSALearnMoreProps {
  showGetQualified?: boolean
  linkToOrderNumber?: string
  source?: string
  mode?: string
}

const HSALearnMore: FC<HSALearnMoreProps> = ({
  showGetQualified,
  source,
  linkToOrderNumber,
  mode = 'light',
}: HSALearnMoreProps) => {
  const [showModal, setShowModal] = useState(false)
  const { user } = useUser()
  const userId = getUserId(user)
  const qualificationSurveyUrl = useMemo(() => {
    return `https://app.truemed.com/qualify/tm_qual_zksf0rat0h?source=${
      encodeURIComponent(source ?? '') ?? 'hsa_learnmore_modal'
    }&user_id=${encodeURIComponent(userId) ?? ''}`
  }, [userId, source])

  let learnMoreColor = 'text-seccm hover:text-secch'
  let color = 'text-warmbrown'
  if (mode === 'dark') {
    color = 'text-white'
    learnMoreColor = 'text-seccm-light hover:text-seccm'
  }

  const onShowModal = () => {
    setShowModal(true)
  }

  useEffect(() => {
    eventBus.on('SHOW_HSA_LEARN_MORE_MODAL', onShowModal)

    return () => {
      eventBus.remove('SHOW_HSA_LEARN_MORE_MODAL', onShowModal)
    }
  }, [])

  const ctaButton = useMemo(() => {
    if (linkToOrderNumber) {
      return (
        <Link className="w-1/2" href={`/account/orders/${linkToOrderNumber}`}>
          <Button type="button" className="w-full">
            Order Details
          </Button>
        </Link>
      )
    }

    if (showGetQualified) {
      return (
        <Link className="w-1/2" href={qualificationSurveyUrl} target={'_blank'}>
          <Button type="button" className="w-full">
            Get Qualified
          </Button>
        </Link>
      )
    }

    return (
      <Button
        type="button"
        onClick={() => setShowModal(false)}
        className="w-1/2"
      >
        Continue Browsing
      </Button>
    )
  }, [showGetQualified, qualificationSurveyUrl, linkToOrderNumber])

  return (
    <>
      <div className={`mt-4 text-xs font-medium text-brown flex items-center`}>
        <span>HSA/FSA-eligible with</span>
        <SVGIcons name={'truemed'} />
        <span>.</span>
        {!showGetQualified && (
          <button
            onClick={() => setShowModal(true)}
            className={`ml-1 underline hover:text-darkteal`}
          >
            Learn More
          </button>
        )}
        {showGetQualified && (
          <button
            onClick={() => setShowModal(true)}
            className="ml-1 underline hover:text-darkteal"
          >
            Get Qualified
          </button>
        )}
      </div>

      <BaseModal.Modal
        open={showModal}
        title={MODAL_TITLE}
        titleClassName="flex-grow flex items-center justify-start"
        subtitle={''}
        onClosed={() => setShowModal(false)}
      >
        <>
          <div className="mb-4">
            <p className="text-base text-warmbrown mb-2">
              The IRS allows you to{' '}
              <span className="italic font-medium">use your HSA/FSA funds</span>{' '}
              after you have made purchases from Lifeforce with a Letter of
              Medical Necessity, which Truemed helps you get.
            </p>
            <p className="text-base text-warmbrown mb-2">
              You can find itemized invoices for your orders in your Account
              Dashboard under Orders & Subscriptions {'>'}{' '}
              <Link
                href="/account/orders"
                target="_blank"
                className="underline font-medium hover:text-secch"
              >
                Order History
              </Link>
              .
            </p>
            <hr className="my-4 border-brown/10" />
            <h3 className="block text-lg font-medium text-brown mb-2">
              Pharmaceutical Purchases
            </h3>

            <p className="mb-2">
              You should only need your itemized invoice from your{' '}
              <Link
                href="/account/orders"
                target="_blank"
                className="underline font-medium hover:text-secch"
              >
                Order History
              </Link>{' '}
              to submit a claim for Rx purchases. Simply download your invoice
              and submit it for reimbursement through your FSA or HSA plan
              administrator.
            </p>

            <hr className="my-4 border-brown/10" />
            <h3 className="block text-lg font-medium text-brown mb-2">
              Lifeforce Diagnostic, Membership, or Supplement Purchases
            </h3>
            <p className="mb-2">
              To receive reimbursement for the Lifeforce diagnostic, membership,
              or supplement purchases, most FSA and HSA plans will require an
              itemized invoice and a Letter of Medical Necessity. Lifeforce has
              partnered with Truemed to make the process of receiving a letter
              quick and easy.
            </p>
            <p className="text-base text-warmbrown mb-2">
              To request a letter follow the steps below. Please note that you
              must have a qualified reason to receive the letter.
            </p>
            <ol className="pl-8 text-base text-warmbrown mb-2 list-decimal space-y-2">
              {userId && (
                <li>
                  Complete this{' '}
                  <Link
                    href={qualificationSurveyUrl}
                    target={'_blank'}
                    className="underline font-medium hover:text-secch"
                  >
                    short qualification survey
                  </Link>
                  . This will take about two minutes and will enable Truemed to
                  assess your eligibility for a letter.
                </li>
              )}

              {!userId && (
                <li>
                  Complete a short qualification survey. This will take about
                  two minutes and will enable Truemed to assess your eligibility
                  for a letter.
                </li>
              )}

              <li>
                If your purchase is eligible based on your responses to the
                survey questions, a Truemed provider will draft a Letter of
                Medical Necessity for you and you will receive it by email in
                approximately 48 hours. This email will include additional
                instructions for submitting a request for reimbursement.
              </li>
            </ol>
            <p className="text-base text-warmbrown mb-2">
              For additional information about Letters of Medical Necessity,
              check out the{' '}
              <Link
                href="https://docs.truemed.com/docs/faq"
                target="_blank"
                className="underline font-medium hover:text-secch"
              >
                Truemed FAQ
              </Link>
              .
            </p>
            <p className="text-base text-warmbrown mb-2">
              For other questions about reimbursement eligibility or claims,
              please contact your plan administrator. This is usually
              coordinated through your employer or health insurance carrier.
            </p>
          </div>

          <BaseModal.Footer>
            <Button
              type="button"
              onClick={() => setShowModal(false)}
              color="secondary"
              className="w-1/2"
            >
              Close
            </Button>

            {ctaButton}
          </BaseModal.Footer>
        </>
      </BaseModal.Modal>
    </>
  )
}

const MODAL_TITLE = (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-1 flex-shrink-1 h-5 w-auto max-w-full"
      viewBox="0 0 499 87"
      fill="none"
    >
      <g clipPath="url(#clip0_3136_5260)">
        <mask
          id="mask0_3136_5260"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="499"
          height="87"
        >
          <path d="M0 0.300591H498.265V86.0151H0V0.300591Z" fill="white"></path>
        </mask>
        <g mask="url(#mask0_3136_5260)">
          <path
            d="M491.483 73.6948H492.711C493.273 73.7556 493.778 73.3491 493.839 72.787C493.844 72.7424 493.846 72.6971 493.845 72.6521C493.845 71.9989 493.387 71.6132 492.711 71.6132H491.483V73.6948ZM490.781 76.5022V70.9482H492.76C493.679 70.8768 494.483 71.5644 494.554 72.484C494.559 72.5448 494.561 72.606 494.559 72.6672C494.577 73.3941 494.116 74.0465 493.426 74.2728L494.687 76.5173H493.879L492.692 74.3862H491.48V76.5173L490.781 76.5022Z"
            fill="currentColor"
          ></path>
          <path
            d="M492.647 68.8972C489.934 68.8972 487.736 71.096 487.736 73.8086C487.736 76.5211 489.934 78.7199 492.647 78.7199C495.36 78.7199 497.558 76.5211 497.558 73.8086C497.558 71.096 495.36 68.8972 492.647 68.8972ZM492.647 79.4302C489.542 79.4302 487.025 76.9133 487.025 73.8086C487.025 70.7034 489.542 68.1869 492.647 68.1869C495.752 68.1869 498.269 70.7034 498.269 73.8086C498.264 76.9114 495.75 79.426 492.647 79.4302Z"
            fill="currentColor"
          ></path>
          <path
            d="M122.032 78.6667V15.518H98.4309V6.58689H154.881V15.518H131.28V78.6667H122.032Z"
            fill="currentColor"
          ></path>
          <path
            d="M146.37 78.6667V26.153H155.089V33.7089C158.278 28.926 163.382 26.153 171.142 26.153H174.542V35.0765H170.292C159.661 35.0765 155.407 41.2422 155.407 49.7464V78.6667H146.37Z"
            fill="currentColor"
          ></path>
          <path
            d="M178.906 55.7005V26.1458H187.943V55.2774C187.943 65.2701 193.576 71.2246 202.299 71.2246C211.022 71.2246 216.655 65.2701 216.655 55.2774V26.1458H225.692V55.7005C225.692 70.4799 216.123 79.7288 202.303 79.7288C188.483 79.7288 178.913 70.4799 178.913 55.7005"
            fill="currentColor"
          ></path>
          <path
            d="M238.768 47.729H272.15C271.088 39.4175 264.707 33.3728 255.459 33.3728C246.954 33.3728 240.468 39.0057 238.768 47.729ZM229.406 52.4062C229.406 37.0978 240.781 25.0839 255.556 25.0839C270.668 25.0839 281.394 36.3536 281.394 51.6619C281.427 52.9415 281.355 54.2215 281.179 55.489H238.548C239.716 65.2701 246.735 71.4358 256.195 71.4358C263.532 71.4358 269.165 67.7145 272.248 61.9909L279.691 66.1466C274.696 74.6546 266.09 79.7473 256.195 79.7473C240.993 79.7473 229.406 67.7334 229.406 52.425"
            fill="currentColor"
          ></path>
          <path
            d="M286.068 78.6667V26.153H294.787V31.6764C298.297 27.5206 303.824 25.0838 310.522 25.0838C318.388 25.0838 324.769 28.484 328.169 34.7592C332.106 28.8055 339.125 25.0838 347.308 25.0838C360.067 25.0838 368.994 34.0153 368.994 48.262V78.6667H359.957V49.0021C359.957 39.3305 354.642 33.588 346.137 33.588C337.739 33.588 332.106 39.6328 332.106 49.0021V78.6667H323.069V49.0021C323.069 39.3305 317.749 33.588 309.245 33.588C300.847 33.588 295.105 39.6328 295.105 49.0021V78.6667H286.068Z"
            fill="currentColor"
          ></path>
          <path
            d="M382.07 47.7479H415.452C414.39 39.4364 408.013 33.3916 398.761 33.3916C390.256 33.3916 383.77 39.0246 382.07 47.7479ZM372.715 52.4062C372.715 37.0978 384.091 25.0839 398.866 25.0839C413.978 25.0839 424.704 36.3536 424.704 51.6619C424.737 52.9415 424.665 54.2215 424.489 55.489H381.858C383.025 65.2701 390.045 71.4358 399.505 71.4358C406.842 71.4358 412.475 67.7149 415.561 61.9909L423 66.1466C418.006 74.6546 409.4 79.7473 399.505 79.7473C384.302 79.7473 372.715 67.7334 372.715 52.425"
            fill="currentColor"
          ></path>
          <path
            d="M474.139 52.4062C474.139 41.4501 466.379 33.5882 455.747 33.5882C445.117 33.5882 437.357 41.4539 437.357 52.4062C437.357 63.3586 445.117 71.2247 455.747 71.2247C466.379 71.2247 474.135 63.3586 474.135 52.4062M428.32 52.4062C428.32 37.0979 439.695 25.084 455.109 25.084C462.979 25.084 469.783 28.3784 474.033 33.7998V6.59838H483.07V78.6669H474.354V70.6916C470.198 76.3283 463.19 79.7285 455.109 79.7285C439.695 79.7285 428.32 67.715 428.32 52.4062Z"
            fill="currentColor"
          ></path>
          <path
            d="M42.5173 16.0621C38.2357 16.0621 34.7649 19.5329 34.7649 23.8145C34.7649 28.0961 38.2357 31.5669 42.5173 31.5669C46.7989 31.5669 50.2697 28.0961 50.2697 23.8145C50.2655 19.5344 46.797 16.0663 42.5173 16.0621Z"
            fill="currentColor"
          ></path>
          <path
            d="M40.3866 45.4133L18.0059 68.7801C12.6487 63.6148 9.05777 56.8904 7.74493 49.5653C7.49445 48.1913 7.32293 46.8036 7.23112 45.4095L40.3866 45.4133ZM7.48802 38.2352C9.63164 22.9843 21.301 10.82 36.4499 8.04544C38.5886 7.65404 40.7587 7.45683 42.9329 7.45608C60.7173 7.49235 75.7601 20.6193 78.204 38.2352H7.48802ZM61.9928 73.317C50.2841 80.7124 35.3592 80.6856 23.6766 73.2494L42.8007 53.2828L61.9928 73.317ZM78.4836 45.4133C78.0801 51.8846 75.9184 58.1235 72.2311 63.4569C70.8857 65.3999 69.3534 67.2073 67.656 68.8518L45.2073 45.4284L78.4836 45.4133ZM67.2479 7.89433C47.7748 -5.56432 21.0781 -0.688087 7.61988 18.7851C-5.83876 38.2586 -0.962535 64.9552 18.5106 78.4135C37.974 91.865 64.6551 87.0016 78.1209 67.5484C91.5675 48.0677 86.703 21.3779 67.2479 7.89433Z"
            fill="currentColor"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3136_5260">
          <rect
            width="498.265"
            height="85.7146"
            fill="white"
            transform="translate(0 0.300537)"
          ></rect>
        </clipPath>
      </defs>
    </svg>
    <span className="whitespace-nowrap text-2xl bradford">- HSA/FSA</span>
  </>
)

export default HSALearnMore

export const getImageAspectRatio = (image: { filename: string }) => {
  if (!image) return 'auto'

  const imageDimensions = image.filename.split('/')[5].split('x')
  const imageAspectRatio =
    parseInt(imageDimensions[0], 10) / parseInt(imageDimensions[1], 10)

  if (typeof imageAspectRatio === 'number') {
    return imageAspectRatio
  } else {
    return 'auto'
  }
}

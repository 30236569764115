'use client'
import React, { useEffect, useMemo } from 'react'
import animateScrollTo from 'animated-scroll-to'
import classNames from 'classnames'

type Blok = {
  title?: string
  Title?: string
}

export function slugify(text: string) {
  return text.toLowerCase().replace(/ /g, '-')
}

export default function PurchaseNav({
  activeSection,
  navIsBeforeScroll,
  blok,
}: {
  activeSection?: string | null
  navIsBeforeScroll: boolean
  blok: any
}) {
  const activeSectionLower =
    (!navIsBeforeScroll && activeSection?.toLowerCase()) || ''

  const filteredBloks = useMemo(() => {
    return (
      blok?.content_bloks?.filter((blok: Blok) => blok?.title || blok?.Title) ||
      []
    )
  }, [blok])

  return (
    <div>
      {filteredBloks.length > 1 && (
        <header className="text-[0.8rem] w-full flex gap-2 justify-between border-b border-dark-brown-40">
          {filteredBloks.map((blok: Blok, i: number) => {
            if (!blok?.title && !blok?.Title) return null
            const blokTitle = (blok?.title || blok?.Title || '').toLowerCase()
            return (
              <button
                onClick={() => {
                  const slug = slugify(blok?.title || blok?.Title || '')
                  const element = document.getElementById(slug)

                  /*
                    one-off exception for your-team
                  */

                  let offsetScroll = 65

                  // if (slug === 'your-team') {
                  //   offsetScroll = 0
                  // }

                  if (element) {
                    const offsetTop =
                      element.getBoundingClientRect().top + window.scrollY

                    window.scrollTo({
                      top: offsetTop - offsetScroll,
                    })
                  }
                }}
                key={i}
                className="pb-3 pt-4 relative shrink text-center leading-[110%] group"
              >
                {blok?.title || blok?.Title}
                <div
                  data-active={activeSectionLower === blokTitle}
                  className={classNames(
                    'absolute -bottom-[2px] bg-[black] h-[3px] right-0 left-0 z-30 scale-y-0 duration-500 transition-all sm:group-hover:scale-y-100 sm:group-hover:opacity-100 sm:group-hover:rounded',
                    activeSectionLower === blokTitle
                      ? 'opacity-100 rounded transform-none'
                      : 'opacity-0'
                  )}
                />
              </button>
            )
          })}
        </header>
      )}
    </div>
  )
}

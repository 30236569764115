import { Cart, LineItem } from '@framework/types/cart'
import { Product } from '@commerce/types/product'
import {
  BL_TEST_KITS_SKUS,
  CONTROLLED_PRODUCTS_NAMES,
  LF_CORE_MEMBERSHIP_SKU,
  LF_MEMBERSHIP_SKU,
  NX_SKUS,
  PHARMA_SKUS,
} from '@lib/productCatalog'

import { ExtendedProduct } from '../../types/storyblok'

function isLineItem(item: LineItem | Product): item is LineItem {
  return (item as LineItem).variant !== undefined
}

export const isMembershipInCart = (cartData: Cart): boolean => {
  return !!cartData?.lineItems?.find(
    (item) => item.variant.sku === LF_MEMBERSHIP_SKU
  )
}
export const isControlled = (item: LineItem) =>
  CONTROLLED_PRODUCTS_NAMES.includes(item.variant?.name ?? '')

export const isRxProduct = (item: LineItem | Product) => {
  if (isLineItem(item)) {
    return PHARMA_SKUS.includes(item.variant?.sku ?? '')
  }

  return PHARMA_SKUS.includes(item.sku?.toUpperCase() ?? '')
}

export const isNxProduct = (item: LineItem | Product) => {
  if (isLineItem(item)) {
    return NX_SKUS.includes(item.variant?.sku ?? '')
  }

  return NX_SKUS.includes(item.sku?.toUpperCase() ?? '')
}
export const isMembershipProduct = (item: LineItem | Product) => {
  if (isLineItem(item)) {
    return [LF_MEMBERSHIP_SKU, LF_CORE_MEMBERSHIP_SKU].includes(
      item.variant?.sku
    )
  }

  return (
    item.sku?.toUpperCase() === LF_MEMBERSHIP_SKU ||
    item.sku?.toUpperCase() === LF_CORE_MEMBERSHIP_SKU
  )
}

export const isOneTimeProduct = (item: LineItem) =>
  !!item.options?.find((option) => option.value === 'One-time')

export const isRecurringProduct = (item: LineItem) => !isOneTimeProduct(item)

export const isDxProduct = (item: LineItem) =>
  BL_TEST_KITS_SKUS.includes(item.variant?.sku ?? '')

export type CartlessProduct = {
  slug: string
  option: any
  sku: string
}

import React from 'react'
import { renderRichText, storyblokEditable } from '@storyblok/react/rsc'

import { StoryblokContent } from '@lib/storyblok'

interface Props {
  blok: StoryblokContent
}

export default function RichText({ blok }: Props) {
  const { text } = blok

  if (text === null) {
    return <></>
  } else {
    return (
      <div
        className="rich-text richtext container max-w-5xl mx-auto mt-12 lg:mt-24 px-4 lg:px-6"
        {...storyblokEditable(blok)}
        dangerouslySetInnerHTML={{ __html: renderRichText(text) }}
      ></div>
    )
  }
}

import { useCallback } from 'react'
import debounce from 'lodash.debounce'
import type {
  MutationHookContext,
  HookFetcherContext,
} from '@commerce/utils/types'
import { ValidationError } from '@commerce/utils/errors'
import useUpdateItem, { UseUpdateItem } from '@commerce/cart/use-update-item'
import type { LineItem, UpdateItemHook } from '@commerce/types/cart'
import { handler as removeItemHandler } from './use-remove-item'
import useCart from './use-cart'
import useToast from '@components/ui/Toast'

export type UpdateItemActionInput<T = any> = T extends LineItem
  ? Partial<UpdateItemHook['actionInput']>
  : UpdateItemHook['actionInput']

export default useUpdateItem as UseUpdateItem<typeof handler>

export const handler = {
  fetchOptions: {
    url: '/api/cart',
    method: 'PUT',
  },
  async fetcher({
    input: { itemId, item },
    options,
    fetch,
  }: HookFetcherContext<UpdateItemHook>) {
    if (Number.isInteger(item.quantity)) {
      // Also allow the update hook to remove an item if the quantity is lower than 1
      if (item.quantity! < 1) {
        return removeItemHandler.fetcher({
          options: removeItemHandler.fetchOptions,
          input: { itemId },
          fetch,
        })
      }
    } else if (item.quantity) {
      throw new ValidationError({
        message: 'The item quantity has to be a valid integer',
      })
    }

    return await fetch({
      ...options,
      body: { itemId, item },
    })
  },
  useHook:
    ({ fetch }: MutationHookContext<UpdateItemHook>) =>
    <T extends LineItem | undefined = undefined>(
      ctx: {
        item?: T
        wait?: number
      } = {}
    ) => {
      const { item } = ctx
      const { mutate, data: cartD } = useCart()
      const toast = useToast()

      return useCallback(
        debounce(async (input: UpdateItemActionInput<T>) => {
          const itemId = input.id ?? item?.id
          const productId = input.productId ?? item?.productId
          const variantId = input.productId ?? item?.variantId

          if (!itemId || !productId || !variantId) {
            throw new ValidationError({
              message: 'Invalid input used for this operation',
            })
          }
          if (
            cartD &&
            cartD.lineItems.find(
              (li) =>
                li.productId == productId &&
                (productId == process.env.NEXT_PUBLIC_DIAGNOSTIC_PRODUCT_ID ||
                  productId == '165') // TODO: change hardcoded Lifefore Membership ID
            )
          ) {
            toast.error(
              'Error',
              `Multiple ${
                productId == '165' ? 'Memberships' : 'Diagnostics'
              } cannot be in the cart at the same time`
            )
          } else {
            const data = await fetch({
              input: {
                itemId,
                item: { productId, variantId, quantity: input.quantity },
              },
            })
            await mutate(data, false)
            return data
          }
        }, ctx.wait ?? 500),
        [fetch, mutate, cartD]
      )
    },
}

const ArrowLeft = ({ ...props }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="40"
        height="40"
        rx="20"
        transform="matrix(-1 0 0 1 40 0)"
        fill="#FDD17C"
        className="group-hover:fill-dark-brown transition-colors duration-200 ease-in-out"
      />
      <path
        d="M26 20.75C26.4142 20.75 26.75 20.4142 26.75 20C26.75 19.5858 26.4142 19.25 26 19.25V20.75ZM13.4697 19.4697C13.1768 19.7626 13.1768 20.2374 13.4697 20.5303L18.2426 25.3033C18.5355 25.5962 19.0104 25.5962 19.3033 25.3033C19.5962 25.0104 19.5962 24.5355 19.3033 24.2426L15.0607 20L19.3033 15.7574C19.5962 15.4645 19.5962 14.9896 19.3033 14.6967C19.0104 14.4038 18.5355 14.4038 18.2426 14.6967L13.4697 19.4697ZM26 19.25H14V20.75H26V19.25Z"
        fill="#26180F"
        className="group-hover:fill-yellow transition-colors duration-200 ease-in-out"
      />
    </svg>
  )
}

export default ArrowLeft

import { useMemo } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { renderRichText } from '@storyblok/react'

type Tag = {
  uuid: string
  name: string
}

type Product = {
  subtitle?: string
  image?: string
  name?: string
  slug?: string
  uuid?: string
  content?: {
    name?: string
    category?: string
    // TODO address types
    main_image?: any
    short_description?: any

    // list of IDs that resolved
    // from the original list of ids as strings
    tags?: Tag[] | string[]
  }
}

export default function ProductCard({
  isInProductGrid,
  modifiers,
  product,
}: {
  isInProductGrid: boolean
  modifiers: string
  product: Product
}) {
  return (
    <Link
      href={`/product/${product?.slug}`}
      className={`group ${modifiers}`}
      key={product.uuid}
    >
      <div className="aspect-[1/1] mb-3 w-full relative bg-light-beige rounded-[4px] overflow-hidden">
        {product?.content?.main_image?.filename && (
          <Image
            src={product.content?.main_image?.filename}
            fill={true}
            sizes="(min-width: 768px) 25vw, 250px"
            alt=""
            className="object-cover object-center"
          />
        )}
        {product?.content?.tags && Array.isArray(product.content.tags) && (
          <div className="absolute top-2 left-2 flex flex-wrap gap-2">
            {product.content.tags.map((tag) =>
              typeof tag === 'object' && tag.name ? (
                <span
                  key={tag.uuid}
                  className="block text-xs bg-light-sage whitespace-nowrap px-3 py-1 rounded-[40px] text-dark-brown leading-[1.125rem]"
                >
                  {tag.name.charAt(0).toUpperCase() + tag.name.slice(1)}
                </span>
              ) : null
            )}
          </div>
        )}

        {isInProductGrid && (
          <div className="absolute bottom-4 inset-x-4 transition-opacity opacity-0 group-hover:opacity-100 pointer-events-none">
            <span className="button button--full shadow-lg shadow-dark-brown/5 group-hover:pointer-events-auto">
              Learn More
            </span>
          </div>
        )}
      </div>
      <div className="text-[19px] leading-none h4 mb-2 text-dark-brown">
        {product?.name}
      </div>
      <div
        className="leading-none richtext richtext--p-small"
        dangerouslySetInnerHTML={{
          __html: renderRichText(product?.content?.short_description),
        }}
      />
    </Link>
  )
}

import React from 'react'
import classNames from 'classnames'

type PurchaseOptionProps = {
  name?: string
  title?: string
  price?: string
  crossThroughPrice?: string
  save_label?: string
  richtext?: JSX.Element
  selected?: boolean
  value?: string
  setValue?: (value: string) => void
}

export function removeTrailingZeros(price?: string): string {
  return price?.replace(/\.00$/, '') || ''
}

export default function PurchaseOption({
  name,
  title,
  price,
  crossThroughPrice,
  save_label,
  richtext,
  selected,
  value,
  setValue,
}: PurchaseOptionProps) {
  return (
    <div
      onClick={() => setValue && setValue(value || '')}
      className={classNames(
        'flex flex-col border my-2 rounded-[4px] cursor-pointer p-2 h-fit transition-all',
        selected ? 'border-dark-brown' : 'border-dark-brown-40 '
      )}
    >
      <div className="flex items-center justify-between text-[1.125rem]">
        <div className="flex label-input-group">
          <label
            htmlFor={value}
            className="flex gap-2 items-center cursor-pointer"
          >
            <input
              type="radio"
              name={name}
              id={value}
              checked={selected}
              onChange={() => setValue && setValue(value || '')}
              className="hidden"
            />
            <div className="flex items-center justify-center rounded-full w-6 h-6 border border-dark-brown shrink-0">
              <div
                className={classNames(
                  'label-check rounded-full w-4 h-4 bg-dark-brown transition-opacity shrink-0',
                  selected ? 'opacity-100' : 'opacity-0'
                )}
              ></div>
            </div>
            <span className="h5 text-[1.2rem] bradford tracking-[-0.02em]">
              {title}
            </span>
            {save_label && (
              <span className="text-[0.75rem] capitalize bg-light-teal px-2 ml-0 inline-block py-[0.3rem] leading-none rounded-[40px]">
                {save_label}
              </span>
            )}
          </label>
        </div>
        <div className="price">
          {crossThroughPrice && (
            <span className="opacity-40 mr-1">
              <s>
                {removeTrailingZeros(crossThroughPrice) || crossThroughPrice}
              </s>
            </span>
          )}
          <span>{removeTrailingZeros(price) || price}</span>
        </div>
      </div>

      {richtext && <div className="text-dark-brown-65">{richtext}</div>}
    </div>
  )
}

import { useEffect } from 'react'
import clsx from 'clsx'
import toast, { Toast as HotToast } from 'react-hot-toast'

export default function useToast() {
  function success(title: string, body?: string, duration: number = 2000) {
    toast.custom(
      (t) => <Toast title={title} body={body} toastRef={t} variant="success" />,
      {
        position: 'top-right',
        duration,
      }
    )
  }

  function info(title: string, body?: string, duration: number = 2000) {
    toast.custom(
      (t) => <Toast title={title} body={body} toastRef={t} variant="info" />,
      {
        position: 'top-right',
        duration,
      }
    )
  }

  function error(title: string, body?: string, duration: number = 3000) {
    toast.custom(
      (t) => <Toast title={title} body={body} toastRef={t} variant="error" />,
      {
        position: 'top-right',
        duration,
      }
    )
  }

  return { success, info, error }
}

const Toast = ({
  title,
  body,
  toastRef,
  variant = 'success',
}: {
  title: string
  body?: string
  toastRef: HotToast
  variant: 'success' | 'error' | 'info'
}) => {
  useEffect(() => {
    return () => {
      setTimeout(() => {
        toast.remove(toastRef.id)
      }, 3000)
    }
  })
  return (
    <div
      className={clsx(
        'relative block w-80 bg-offwhite border border-brown/10 shadow-xl rounded l mt-4 mr-4',
        {
          ['animate-enter']: toastRef.visible,
          ['animate-leave']: !toastRef.visible,
        }
      )}
    >
      <div className="flex items-start gap-4 p-4">
        {variant === 'success' && (
          <div className="p-0.5 bg-sage/40 text-brown rounded-full">
            <ToastSuccessIcon />
          </div>
        )}
        {variant === 'error' && (
          <div className="p-0.5 bg-uxred/40 text-brown rounded-full">
            <ToastErrorIcon />
          </div>
        )}
        {variant === 'info' && (
          <div className="p-0.5 bg-beige text-brown rounded-full">
            <ToastInfoIcon />
          </div>
        )}
        <div className="flex-grow text-sm text-warmbrown">
          <p>
            <span className="font-medium">{title}</span> {body}
          </p>
        </div>
      </div>
      <div className="absolute -top-3 -right-3">
        <button
          type="button"
          onClick={() => {
            toast.remove(toastRef.id)
          }}
          className="bg-offwhite text-brown relative flex items-center border border-brown/40 p-1 text-center cursor-pointer rounded-full font-medium text-sm hover:bg-brown hover:text-offwhite focus:outline-none focus:ring-1 focus:ring-brown focus:rounded focus:text-brown focus:bg-beige focus:border-transparent transition-all"
        >
          <CrossIcon />
        </button>
      </div>
    </div>
  )
}

const CrossIcon = () => {
  return (
    <svg
      className="h-4 w-4"
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path>
    </svg>
  )
}

const ToastSuccessIcon = () => {
  return (
    <svg
      className="h-4 w-4"
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
    </svg>
  )
}

const ToastErrorIcon = () => {
  return (
    <svg
      className="h-4 w-4"
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 11,4L 13,4L 13,15L 11,15L 11,4 Z M 13,18L 13,20L 11,20L 11,18L 13,18 Z" />
    </svg>
  )
}
const ToastInfoIcon = () => {
  return (
    <svg
      className="h-4 w-4"
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 9H11V7H13V9M14 15V17H10V15H11V13H10V11H13V15H14Z"></path>
    </svg>
  )
}

import { useEffect } from 'react'

import { getCookie, setCookie } from '../helpers/cookieUtils'

const useReportImpersonation = () => {
  const impersonatorId = getCookie('impersonating')
  const impersonatingRefresh = getCookie('impersonatingRefresh')

  useEffect(() => {
    if (typeof window !== 'undefined' && impersonatorId && impersonatorId !== impersonatingRefresh) {
      const expires = new Date(Date.now() + 1000 * 60 * 30) // 30 minutes from now
      setCookie('impersonatingRefresh', impersonatorId, { path: '/', expires })
      // Perform a hard reload and bypassing server cache.
      // functionally the same as window.location.reload(true) but typescript doesn't like the true argument
      window.location.href = window.location.href;
    }
  }, [impersonatorId, impersonatingRefresh])

  return {}
}

export default useReportImpersonation

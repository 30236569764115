import {
  FC,
  forwardRef,
  Fragment,
  HTMLAttributes,
  LegacyRef,
  useCallback,
} from 'react'
import clsx from 'clsx'

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  className?: string
  variant?:
    | 'hero'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'pull-quote'
    | 'h5'
    | 'h6'
    | 'editorial'
    | 'body-xl'
    | 'body-lg'
    | 'body'
    | 'body-sm'
    | 'body-tiny'
    | 'product-title'
    | 'button-text'
    | 'link'
    | 'body1'
    | 'body2'
  color?:
    | 'navy'
    | 'rust'
    | 'ocean'
    | 'burntsienna'
    | 'honeybee'
    | 'white'
    | 'gray'
    | 'gray-900'
    | 'white-400'
    | 'black-900'
    | 'red'
    | 'teal'
    | 'teal-700'
    | 'green-300'
    | 'green-400'
    | 'black-500'
    | 'black-400'
    | 'warmbrown'
  component?: 'hero' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'
  align?: 'center' | 'left' | 'right'
}

const Typography: FC<TypographyProps> = forwardRef(function Typography(
  props: TypographyProps,
  ref:
    | LegacyRef<HTMLHeadingElement & HTMLParagraphElement & HTMLSpanElement>
    | undefined
) {
  const {
    className,
    variant,
    color,
    align,
    component: Component,
    children,
    style: tagStyles,
    onClick,
  } = props
  const classes = clsx(className, {
    ['text-h1-m md:text-h1 font-aktiv-grotesk-ex']: variant === 'hero',
    ['text-h1-m md:text-h1 font-black font-aktiv-grotesk-ex']: variant === 'h1',
    ['text-h2-m md:text-h2 font-extrabold font-aktiv-grotesk-ex']:
      variant === 'h2',
    ['text-h3-m md:text-h3 font-extrabold font-aktiv-grotesk-ex']:
      variant === 'h3',
    ['text-h4-m md:text-h4 font-extrabold font-aktiv-grotesk-ex']:
      variant === 'h4',
    ['text-pull-quote-m md:text-pull-quote font-extrabold font-aktiv-grotesk-ex']:
      variant === 'pull-quote',
    ['font-extrabold text-h5-m md:text-h5 font-aktiv-grotesk-ex']:
      variant === 'h5',
    ['font-extrabold text-body-sm md:text-body-sm font-aktiv-grotesk-ex']:
      variant === 'h6',
    ['text-body-xl']: variant === 'body-xl',
    ['text-body-lg']: variant === 'body-lg',
    ['text-body']: variant === 'body',
    ['text-body-sm']: variant === 'body-sm',
    ['text-body-tiny']: variant === 'body-tiny',
    ['text-product-title']: variant === 'product-title',
    ['text-button-text']: variant === 'button-text',
    ['text-link']: variant === 'link',
    ['text-lg md:text-xl']: variant === 'editorial',
    ['text-base']: variant === 'body1',
    ['text-tiny']: variant === 'body2',
    ['text-lf-black-900']: color === 'navy' || color === 'black-900',
    ['text-lf-black-500']: color === 'black-500',
    ['text-rust']: color === 'rust',
    ['text-ocean']: color === 'ocean',
    ['text-red']: color === 'red',
    ['text-teal-900']: color === 'teal',
    ['text-teal-700']: color === 'teal-700',
    ['text-green-300']: color === 'green-300',
    ['text-green-400']: color === 'green-400',
    ['text-honeybee']: color === 'honeybee',
    ['text-burntsienna']: color === 'burntsienna',
    ['text-gray-500']: color === 'gray',
    ['text-gray-900']: color === 'gray-900',
    ['text-rich-white-50']: color === 'white',
    ['text-rich-white-400']: color === 'white-400',
    ['text-center']: align === 'center',
    ['text-left']: !align || align === 'left',
    ['text-right']: align === 'right',
    ['text-lf-black-400']: color == 'black-400',
    ['text-warmbrown']: color === 'warmbrown',
  })
  const buildComponent = useCallback(() => {
    let C
    let CProps = {
      ref,
      className: classes,
      style: tagStyles,
      onClick,
    }
    switch (Component) {
      case 'hero':
        C = <h1 {...CProps}>{children}</h1>
        break
      case 'h1':
        C = <h1 {...CProps}>{children}</h1>
        break
      case 'h2':
        C = <h2 {...CProps}>{children}</h2>
        break
      case 'h3':
        C = <h3 {...CProps}>{children}</h3>
        break
      case 'h4':
        C = <h4 {...CProps}>{children}</h4>
        break
      case 'h5':
        C = <h5 {...CProps}>{children}</h5>
        break
      case 'h6':
        C = <h6 {...CProps}>{children}</h6>
        break
      case 'p':
        C = <p {...CProps}>{children}</p>
        break
      case 'span':
        C = <span {...CProps}>{children}</span>
        break
      default:
        break
    }
    return C
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, classes, tagStyles, children])
  return <Fragment>{buildComponent()}</Fragment>
})

Typography.defaultProps = {
  variant: 'body1',
  color: 'navy',
  component: 'p',
}

export default Typography

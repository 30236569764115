'use client'

import React from 'react'
import Image from 'next/image'
import { renderRichText } from '@storyblok/react'
import { motion } from 'framer-motion'

import { StoryblokContent } from '@lib/storyblok'

import styles from './LeadInTextWithImage.module.css'

interface Props {
  blok: StoryblokContent
}

export default function LeadInTextWithImage({ blok }: Props) {
  const { heading, description, image } = blok

  return (
    <div className="bg-off-white section px-4 lg:px-6">
      {heading && (
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5, ease: [0.4, 0, 1, 1] }}
          className="max-w-[555px] mx-auto"
        >
          <div
            className="richtext text-center"
            dangerouslySetInnerHTML={{ __html: renderRichText(heading) }}
          />
        </motion.div>
      )}
      <div className="flex flex-col-reverse mt-10 lg:mt-16 gap-8 lg:gap-0 lg:grid lg:grid-cols-12 lg:items-center">
        {description && (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5, ease: [0.4, 0, 1, 1] }}
            className="lg:col-start-2 lg:col-span-7 lg:pt-6 lg:border-t lg:border-dark-brown-65"
          >
            <div
              className={`richtext text-center lg:max-w-[344px] lg:text-left ${styles['custom-richtext']}`}
              dangerouslySetInnerHTML={{ __html: renderRichText(description) }}
            />
          </motion.div>
        )}
        <motion.div
          initial={{ opacity: 0, y: '20px' }}
          whileInView={{ opacity: 1, y: '0' }}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5, ease: [0.4, 0, 1, 1] }}
          className="max-w-[252px] mx-auto lg:w-full lg:col-span-3 lg:ml-0"
        >
          <Image
            src={image.filename}
            fill={false}
            width={600}
            height={600}
            alt={image.alt}
            className={`object-contain overflow-hidden`}
          />
        </motion.div>
      </div>
    </div>
  )
}

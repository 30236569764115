import  { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import useSWR from 'swr'

import fetcher from '@framework/fetcher'
import useCart from '@commerce/cart/use-cart'
import { LineItem } from '@commerce/types/cart'
import type { Product } from '@commerce/types/product'
import { useMembershipData } from '@lib/context/MembershipContext'
import useAddToCart from '@lib/hooks/useAddToCart'
import useUser from '@lib/hooks/useUser'
import {
  EnrichedProduct, LF_CORE_MEMBERSHIP_SKU,
  LF_DIAGNOSTIC_SKU,
  LF_MEMBERSHIP_SKU
} from '@lib/productCatalog'
import { StoryblokContent } from '@lib/storyblok'

import { getProductBySlug } from '@components/product/helpers'
import { useUI } from '@components/ui'

import { SubscriptionsResponse } from '@/types/subscription'

export default function useAddToCartSlug({ add_to_cart_product_slug, story }: { add_to_cart_product_slug: string, story: StoryblokContent }) {


  const [product, setProduct] = useState<EnrichedProduct | undefined>()
  const {user, isLoading: isUserLoading} = useUser()
  const {setModalView, openModal, setModalPayload} = useUI()
  const { data: cart, isLoading: isCartLoading } = useCart()
  const { data: subscriptionData, error: subscriptionError } =
    useSWR<SubscriptionsResponse>('/api/account/all-subscriptions', fetcher)
  const isSubscriptionLoading = !subscriptionError && !subscriptionData
  const { canBuyMembershipWithoutDx, membershipData, isLoadingMembershipData } =
    useMembershipData()
  const searchParams = Object.fromEntries(useSearchParams()!.entries())
  const [searchParamsProcessed, setSearchParamsProcessed] = useState(false)

  const {
    addToCart,
    loading: addToCartLoading,
    variant: productVariant,
    // @ts-ignore
  } = useAddToCart(story, product?.bcProduct ?? { variants: [] })

  useEffect(() => {
    if (searchParamsProcessed) return
    if (!product || isLoadingMembershipData || isUserLoading) return

    if (searchParams['open-core-membership-modal'] && product.bcProduct?.sku?.toLowerCase() === LF_CORE_MEMBERSHIP_SKU.toLowerCase() && !membershipData?.isMember) {
      openCoreMembershipModal()
    }

    setSearchParamsProcessed(true)
  }, [searchParams, product, membershipData, isLoadingMembershipData, isUserLoading])

  useEffect(() => {
    if (!add_to_cart_product_slug) return

    getProductBySlug(add_to_cart_product_slug, true).then(
      (product: EnrichedProduct | undefined) => {
        setProduct(product)
      }
    )
  }, [add_to_cart_product_slug])

  const isSelectedProductInCart = cart?.lineItems?.some((item: LineItem) => {
    return item.productId === product?.bcProduct?.id
  })

  const hasProductSubscription = !!subscriptionData?.products?.find(
    (p: EnrichedProduct) => {
      return (
        p.bcProduct.id == product?.bcProduct?.id &&
        p.userSubscribed?.some(
          (s) =>
            s.state === 'active' ||
            s.state === 'queued_for_activation' ||
            s.state === 'future'
        )
      )
    }
  )

  const isCtaDisabled = useMemo(() => {
    if (isCartLoading) return true
    if (isUserLoading) return true
    if (isSubscriptionLoading && user) return true
    if (isLoadingMembershipData && user) return true
    if (product && isSelectedProductInCart) return true
    if (product && hasProductSubscription) return true

    if (
      [LF_MEMBERSHIP_SKU.toLowerCase(), LF_CORE_MEMBERSHIP_SKU.toLowerCase()].includes(product?.bcProduct?.sku?.toLowerCase() ?? '') && membershipData?.isMember
    ) {
      return true
    }

    if (
      product?.bcProduct?.sku?.toLowerCase() === LF_DIAGNOSTIC_SKU.toLowerCase() &&
      (membershipData?.diagnostic_codes?.length ?? 0) > 0
    ) {
      return true
    }

    return false
  }, [
    isCartLoading,
    product,
    isSelectedProductInCart,
    isSubscriptionLoading,
    hasProductSubscription,
    isLoadingMembershipData,
    membershipData,
  ])

  const openCoreMembershipModal = () => {
    if (user) {
      setModalPayload([product])
      setModalView('CONFIRM_START_PLAN_MODAL')
      openModal()
    } else {
      window.location.href = `/api/auth/login?returnTo=${encodeURIComponent(
        '/landers/core-membership?open-core-membership-modal=1'
      )}`
    }
  }

  const addProductToCart = () => {
    if (addToCartLoading) return

    if (product) {
      if (product?.bcProduct?.sku?.toLowerCase() === LF_CORE_MEMBERSHIP_SKU.toLowerCase()) {
        openCoreMembershipModal()
        return
      } else if (
        product?.bcProduct?.sku?.toLowerCase() === LF_MEMBERSHIP_SKU.toLowerCase() &&
        canBuyMembershipWithoutDx
      ) {
        return (window.location.href = '/account/profile')
      }

      addToCart()
    }
  }

  return {
    isAddToCartCtaDisabled: isCtaDisabled,
    addProductToCart,
  }
}

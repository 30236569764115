import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'

import useRemoveItem from '@framework/cart/use-remove-item'
import useUpdateItem from '@framework/cart/use-update-item'
import usePrice, { formatPrice } from '@framework/product/use-price'
import type { LineItem } from '@commerce/types/cart'
import { trackAddToCartFromCart, trackRemoveFromCart } from '@lib/datalayer'
import {
  DEFAULT_SETUP_SUBTOTAL,
  DEFAULT_SETUP_TOTAL,
  membershipLineItem,
} from '@lib/productCatalog'
import { saveLastPromotionToSessionStorage } from '@lib/uiUtils'

import MembershipFeatures from '@components/cart/MembershipFeatures'
import { useUI } from '@components/ui/context'

import Quantity from '../../ui/Quantity'

import s from './MembershipCartItem.module.css'

type ItemOption = {
  name: string
  nameId: number
  value: string
  valueId: number
}

const placeholderImg = '/product-img-placeholder.svg'

const SideCartItem = ({
  item,
  variant = 'default',
  currencyCode,
  refreshCart,
  ...rest
}: {
  variant?: 'default' | 'display'
  item: LineItem
  currencyCode: string
  refreshCart: () => void
}) => {
  const { closeSidebarIfPresent } = useUI()
  const [removing, setRemoving] = useState(false)
  const [quantity, setQuantity] = useState<number>(item.quantity)
  const removeItem = useRemoveItem()
  const updateItem = useUpdateItem({ item })

  const { price: monthlyPrice } = usePrice({
    amount: item.variant.price,
    baseAmount: item.variant.listPrice,
    currencyCode,
  })

  // TODO: Add a type for this
  const options = (item as any).options

  useEffect(() => {
    // Reset the quantity state if the item quantity changes
    if (item.quantity !== Number(quantity)) {
      setQuantity(item.quantity)
    }
    // TODO: currently not including quantity in deps is intended, but we should
    // do this differently as it could break easily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.quantity])

  const formattedDefaultSetupSubtotal = formatPrice({
    amount: DEFAULT_SETUP_SUBTOTAL,
    currencyCode: 'USD',
    locale: 'en-US',
  })

  const formattedDefaultSetupTotal = formatPrice({
    amount: DEFAULT_SETUP_TOTAL,
    currencyCode: 'USD',
    locale: 'en-US',
  })

  const handleChange = async ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(value))
    await updateItem({ quantity: Number(value) })
  }

  const increaseQuantity = async (n = 1) => {
    const val = Number(quantity) + n
    const res = await updateItem({ quantity: val })

    if (item.quantity != val) {
      n == 1 ? trackAddToCartFromCart(item) : trackRemoveFromCart(item)
    }

    if (res) {
      setQuantity(val)
    }
  }

  const handleRemove = async () => {
    setRemoving(true)
    try {
      await removeItem(item)
      trackRemoveFromCart(item)
      saveLastPromotionToSessionStorage(null)
      refreshCart()
    } catch (error) {
      setRemoving(false)
    }
  }

  return (
    <>
      <tr className="sm:border-b border-brown/10 flex-col last:border-0">
        <td className="w-16 h-16 pl-0 pr-2 py-4 sm:w-20 sm:h-20 sm:pr-3 align-top">
          <Link
            href={`/product/${item.path}`}
            onClick={() => closeSidebarIfPresent()}
            className="block w-16 h-16 sm:w-20 sm:h-20 rounded overflow-hidden hover:opacity-80 focus:outline-none"
          >
            <Image
              className="w-full h-full object-cover"
              width={64}
              height={64}
              src={item.variant.image?.url || placeholderImg}
              alt={item.variant.image?.altText || 'Product Image'}
            />
          </Link>
        </td>
        <th className="px-0 py-4 text-left font-normal align-top" scope="row">
          <span>
            <span className="block mb-1">
              <Link
                href={`/product/${item.path}`}
                onClick={() => closeSidebarIfPresent()}
                className="text-base font-medium text-brown hover:text-darkteal"
              >
                {item.name}
              </Link>
            </span>
            <MembershipFeatures
              monthlySubtotal={monthlyPrice}
              monthlyTotal={monthlyPrice}
            />
          </span>
        </th>
        <td className="pl-2 pr-0 sm:pr-2 pb-4 pt-[13px] align-top">
          <button
            onClick={handleRemove}
            type="button"
            className="w-full bg-offwhite text-brown relative flex items-center border border-brown/0 px-2 py-1 cursor-pointer rounded-full font-medium text-sm hover:bg-brown hover:text-offwhite  focus:outline-none focus:ring-1 focus:ring-brown focus:rounded focus:text-brown focus:bg-beige focus:border-transparent transition-all"
          >
            <svg
              className="mx-auto h-5 w-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path>
            </svg>
          </button>
        </td>
        <td className="pl-2 pr-0 py-4 align-top">
          <span className="block text-brown/40 font-medium text-sm text-right leading-6 line-through">
            {formattedDefaultSetupSubtotal}
          </span>
          <span className="block text-brown font-medium text-sm text-right leading-6">
            {formattedDefaultSetupTotal}
          </span>
        </td>
      </tr>

      <tr className="sm:hidden border-b">
        <td colSpan={2}>
          <ul className="text-sm text-warmbrown list-disc pl-6 pb-4 max-w-[380px]">
            <li>Initial 50+ biomarker test & retesting every 3 months</li>
            <li>Access to clinicians and health coach</li>
            <li>Personalized program and health tracking</li>
            <li>20% off supplements</li>
            <li>Access to member-only pharmaceuticals</li>
          </ul>
        </td>
      </tr>
    </>
  )
}

export default SideCartItem

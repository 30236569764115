'use client'

import React, { Suspense, useEffect, useRef, useState } from 'react'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import { useInView } from 'framer-motion'

import { StoryblokContent } from '@lib/storyblok'

import PurchaseModule from '@components-v7/PurchaseModule'

import ProductRecommendations from './ProductRecommendations'
import { Product as BigCommerceProduct } from '@/framework/commerce/types/product'

import './membership.css'

interface Props {
  blok: StoryblokContent
  // TODO Get types from Storyblok
  rels: any
  scrollStates: {
    isBeforeScroll: boolean
    setIsBeforeScroll: React.Dispatch<React.SetStateAction<boolean>>
  }
  product?: BigCommerceProduct
  diagnostic?: BigCommerceProduct
}

type SelectedOption = {
  sku?: string
  productId: string
  variantId?: string
  optionId?: string
  optionValue?: string
}

export default function Product(props: Props) {
  const [isBeforeScroll, setIsBeforeScroll] = useState<boolean>(false)
  const [activeSection, setActiveSection] = useState<string | null>(null)
  const [drawerIsOpen, setIsDrawerOpen] = useState<boolean>(true)
  const [selectedOption, setSelectedOption] = useState<SelectedOption | null>(
    null
  )
  const [isBeforeMobileScroll] = useState<boolean>(true)

  const landingRef = useRef<HTMLDivElement>(null)
  const landingIsInView = useInView(landingRef)

  useEffect(() => {
    if (!landingRef.current) return

    if (landingIsInView) {
      setIsDrawerOpen(false)
      setIsBeforeScroll(true)
    } else {
      setIsBeforeScroll(false)
    }
  }, [landingIsInView, landingRef])

  const { blok, rels, product, diagnostic } = props

  return (
    <div
      className="product bg-off-white lg:grid lg:grid-cols-12 "
      {...storyblokEditable(blok)}
    >
      <div ref={landingRef} className="landing-content lg:min-h-[300px]">
        {blok.content_top &&
          blok.content_top.map((b: StoryblokContent) => (
            <StoryblokComponent blok={b} key={b._uid} />
          ))}
      </div>

      {product && (
        <div>
          <PurchaseModule
            blok={blok}
            hideHeader
            drawerIsOpen={drawerIsOpen}
            activeSection={activeSection}
            isBeforeScroll={true}
            navIsBeforeScroll={isBeforeScroll}
            product={product}
            diagnostic={diagnostic}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
      )}

      <div
        className={`relative mt-14 lg:mt-20 lg:grid lg:gap-6 lg:grid-cols-[subgrid]`}
      >
        {product && (
          <PurchaseModule
            mobile={true}
            blok={blok}
            drawerIsOpen={drawerIsOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            activeSection={activeSection}
            navIsBeforeScroll={isBeforeScroll}
            isBeforeScroll={false}
            product={product}
            diagnostic={diagnostic}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        )}

        <div className="product__left lg:grid-cols-[subgrid] lg:col-span-8 lg:pl-6">
          {blok.content_bloks &&
            blok.content_bloks.map((b: StoryblokContent) => (
              <StoryblokComponent
                setActiveSection={setActiveSection}
                blok={b}
                key={b._uid}
              />
            ))}
        </div>

        {product && (
          <div className="hidden lg:block lg:col-span-4">
            <PurchaseModule
              drawerIsOpen={drawerIsOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              blok={blok}
              activeSection={activeSection}
              isBeforeScroll={isBeforeScroll}
              navIsBeforeScroll={isBeforeScroll}
              product={product}
              diagnostic={diagnostic}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
        )}
      </div>

      {blok?.productRecommendations?.length > 0 && rels?.length > 0 && (
        <ProductRecommendations
          category={blok?.category}
          title="You might also like"
          productRecommendations={blok.productRecommendations}
          rels={rels}
        />
      )}
    </div>
  )
}

import React from 'react'

export interface DropdownProps {
  className?: string
  children?: React.ReactNode
}

const Dropdown: React.FC<DropdownProps> = ({className, children}: DropdownProps) => {
  return (<div className={`z-20 absolute mt-1 right-0 bg-offwhite border border-brown/10 shadow-xl rounded overflow-auto min-w-full max-h-[calc(100vh-128px)] ${className}`}>
    {children}
  </div>)
}

export default Dropdown

import { ReactNode } from 'react'

export type ErrorContainerProps = {
  className?: string
  children: ReactNode
}

export default function ErrorContainer({children, className}: ErrorContainerProps) {
  return <div className={`text-sm text-uxred flex items-center bg-uxred/10 border border-uxred/40 rounded py-2 px-3 ${className}`}>
    {children}
  </div>
}

'use client'

import { createContext, ReactNode,useEffect, useState } from 'react'
import { useSearchParams } from 'next/navigation'

import SiteMaintenance from '@components/common/Maintenance/SiteMaintenance'

export const MaintenanceContext = createContext(false)

type MaintenanceProviderPropTypes = {
  value: boolean
  children: ReactNode
}

export default function MaintenanceProvider({
  value: enabledMaintenance,
  children,
}: MaintenanceProviderPropTypes) {
  const searchParams = useSearchParams()
  const [shouldByPassMaintenance, setShouldByPassMaintenance] = useState(false)

  useEffect(() => {
    const localBypass = localStorage.getItem('bypass-maintenance')
    const bypassMaintenance = searchParams?.get('bypass-maintenance')

    if (bypassMaintenance === 'true') {
      localStorage.setItem('bypass-maintenance', 'true')
      setShouldByPassMaintenance(true)
    } else if (bypassMaintenance === 'false') {
      localStorage.removeItem('bypass-maintenance')
      setShouldByPassMaintenance(false)
    } else if (localBypass === 'true') {
      setShouldByPassMaintenance(true)
    }
  }, [searchParams])

  const shouldShowMaintenance = enabledMaintenance && !shouldByPassMaintenance

  return (
    <MaintenanceContext.Provider value={shouldByPassMaintenance}>
      {shouldShowMaintenance ? <SiteMaintenance /> : children}
    </MaintenanceContext.Provider>
  )
}

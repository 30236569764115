import { useEffect, useState } from 'react'
import { differenceInHours } from 'date-fns'

const ANNOUNCEMENT_BAR_DISMISSED_AT_KEY = 'announcement_bar_last_dismissed_at'

function serializeLocalStorageValue(date: Date): string {
  return date.getTime().toString()
}

function getParsedLocalStorageValue(): Date | null {
  const milliseconds = localStorage.getItem(ANNOUNCEMENT_BAR_DISMISSED_AT_KEY)

  if (milliseconds) {
    if (isNaN(Number(milliseconds))) {
      return null
    }
    return new Date(Number(milliseconds))
  }
  return null
}

const useAnnouncementBanner = () => {
  const [show, setShow] = useState(false)
  const [data, setData] = useState({
    header: null,
    secondary_header: null,
    link: '',
    visible: false,
  })

  const deffer = () => {
    localStorage.setItem(
      ANNOUNCEMENT_BAR_DISMISSED_AT_KEY,
      serializeLocalStorageValue(new Date())
    )

    setShow(false)
  }

  useEffect(() => {
    fetch('/api/storyblok?slug=global/announcement-bar')
      .then((res) => res.json())
      .then((data) => {
        const content = data.storyData?.story?.content

        setData({
          header: content.header,
          secondary_header: content.secondary_header,
          link: content.link,
          visible: content.visible,
        })

        let shouldShow = true

        if (content.from) {
          const from = new Date(content.from + 'Z')

          if (from.getTime() > Date.now()) {
            shouldShow = false
          }
        }

        if (content.till) {
          const till = new Date(content.till + 'Z')

          if (till.getTime() < Date.now()) {
            shouldShow = false
          }
        }

        const lastDismissedAt = getParsedLocalStorageValue()

        if (
          lastDismissedAt &&
          differenceInHours(new Date(), lastDismissedAt) < 72
        ) {
          shouldShow = false
        }

        setShow(content.visible && shouldShow)
      })
  }, [])
  return { show, data, deffer }
}

export default useAnnouncementBanner

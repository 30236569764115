import Link from 'next/link'
import classNames from 'clsx'

import { useMembershipData } from '@lib/context/MembershipContext'
import { getProductUrl } from '@lib/helpers/landers'
import {
  EnrichedProduct,
  LF_CORE_MEMBERSHIP_SLUG,
  LF_MEMBERSHIP_SLUG,
} from '@lib/productCatalog'

import { Button } from '@components/design'
import { AddToCartButton } from '@components/landers/AddToCartButton'

interface Props {
  data: Array<{
    text?: string
    url?: string
    product_object: EnrichedProduct
    add_to_basket: boolean
    button_color: 'primary' | 'black'
  }>
  centered?: boolean
  source?: string
  onAction?: (product: EnrichedProduct) => void
}

export default function CallToAction({
  data,
  centered = true,
  source,
  onAction,
}: Props) {
  const { canBuyMembershipWithoutDx, membershipData } = useMembershipData()

  if (!data.length) return null
  const {
    text,
    url,
    product_object,
    add_to_basket,
    button_color = 'primary',
  } = data[0]

  if (!url && !product_object) return null

  if (add_to_basket && product_object) {
    return <AddToCartButton product_object={product_object} cta_text={text} />
  }

  const handleClick = (e: any) => {
    if (
      product_object?.slug?.toLowerCase() === LF_MEMBERSHIP_SLUG &&
      canBuyMembershipWithoutDx
    ) {
      e.preventDefault()
      return (window.location.href = '/account/profile')
    }

    if (
      (product_object && product_object.slug === LF_CORE_MEMBERSHIP_SLUG) ||
      source === 'lifescore'
    ) {
      e.preventDefault()
      return onAction && onAction(product_object) // Call the passed callback function
    } else {
      return (window.location.href = url || getProductUrl(product_object))
    }
  }

  const ctaDisabled =
    (product_object?.slug?.toLowerCase() === LF_MEMBERSHIP_SLUG &&
      membershipData?.isMember) ??
    false

  return (
    <Link href={url || getProductUrl(product_object)}>
      <Button
        className={classNames({ 'mx-auto': centered })}
        onClick={handleClick}
        disabled={ctaDisabled}
      >
        {text || 'Get Started'}
      </Button>
    </Link>
  )
}

import { useEffect, useRef } from 'react'
import { renderRichText } from '@storyblok/react'
import { useInView } from 'framer-motion'

import { slugify } from '../PurchaseModule/PurchaseNav'

// TODO: GENERATE TYPES FROM STORYBLOK
type Image = {
  filename?: string
  alt?: string
}

type Block = {
  image?: Image
  title?: string
  // TODO: address richtext type
  text?: any
}

type OpenTextImageModuleProps = {
  blok: {
    content?: Block[]
    title?: string
    image?: Image
    text?: any
    top_centered?: boolean
  }
  setActiveSection?: (section: string) => void
}

export default function OpenTextImageModule(props: OpenTextImageModuleProps) {
  const { blok, setActiveSection } = props

  const ref = useRef<HTMLDivElement>(null)
  const inView = useInView(ref)

  useEffect(() => {
    if (inView && blok?.title) setActiveSection?.(blok.title)
  }, [inView, blok?.title])

  return (
    <section
      id={slugify(blok?.title || '')}
      ref={ref}
      className="mt-20 p-4 sm:p-6 pb-8 col-span-full bg-light-beige rounded-[4px]"
    >
      <header
        className={`grid gap-4 w-full  mb-8 mt-4 sm:mt-0 sm:mb-12 ${
          blok?.top_centered === true ? 'grid-cols-1' : 'grid-cols-2'
        }`}
      >
        <div className="eyebrow text-dark-brown">{blok?.title}</div>
        {blok?.image && (
          <div className="p-4">
            <img
              className="max-h-[100px] mx-auto w-auto"
              src={blok?.image?.filename}
              alt={blok?.image?.alt}
            />
          </div>
        )}
        {blok?.text && (
          <div
            className={`richtext ${
              blok?.top_centered === true
                ? 'text-center max-w-[680px] mx-auto'
                : ''
            }`}
            dangerouslySetInnerHTML={{
              __html: renderRichText(blok?.text),
            }}
          />
        )}
      </header>

      {blok?.content?.map((block: Block, index: number) => (
        <div
          key={index}
          className={`sm:grid sm:grid-cols-2 text-center sm:text-left gap-4 py-6 sm:py-8 items-center border-t ${
            index === (blok?.content?.length ?? 0) - 1 ? 'border-b' : ''
          } border-dark-brown-40 mt-[-1px]`}
        >
          <div className="sm:flex text-center sm:text-left sm:flex-wrap sm:flex-nowrap items-center pr-2">
            <div className="max-w-[70px] sm:max-w-[50px] mx-auto mb-2 sm:mb-0 sm:ml-0 sm:mr-4">
              <img src={block?.image?.filename} alt={block?.image?.alt} />
            </div>
            <div className="richtext mb-3 sm:mb-0">
              <h4>{block?.title}</h4>
            </div>
          </div>
          <div className="mt-0 text-[1rem] mb-2 sm:mb-0 pr-2">
            {block?.text && (
              <div
                dangerouslySetInnerHTML={{
                  __html: renderRichText(block?.text),
                }}
              />
            )}
          </div>
        </div>
      ))}
    </section>
  )
}

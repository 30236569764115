import mixpanel from 'mixpanel-browser'

import { EnrichedProduct } from '@lib/productCatalog'

import { isRxProduct } from '../../helpers/cart'

const trackReportDetailRecommendedProductClick = (product: EnrichedProduct) => {
  if (isRxProduct(product?.bcProduct)) {
    return
  }

  try {
    mixpanel?.track('Report Details - Recommended Product Click', {
      'Product Name': [product?.bcProduct?.name ?? product?.name].filter(
        Boolean
      ),
      'Product SKU': [product?.bcProduct?.sku].filter(Boolean),
      'Product Slug': [product?.bcProduct?.slug ?? product?.name].filter(
        Boolean
      ),
      'Product Path': [product?.bcProduct?.path ?? product?.path].filter(
        Boolean
      ),
    })
  } catch (e) {}
}

const trackReportDetailPurchasePlanClick = () => {
  try {
    mixpanel?.track('Report Details - Purchase Plan Click', {})
  } catch (e) {}
}

const trackReportDetailCustomizeYourPlanClick = () => {
  try {
    mixpanel?.track('Report Details - Customize Your Plan Click', {})
  } catch (e) {}
}

const trackReportDetailPrevReportClick = () => {
  try {
    mixpanel?.track('Report Details - Previous Report Click', {})
  } catch (e) {}
}

const trackReportDetailNextReportClick = () => {
  try {
    mixpanel?.track('Report Details - Next Report Click', {})
  } catch (e) {}
}

const trackReportDetailDiagnosticReportTabClick = () => {
  try {
    mixpanel?.track('Report Details - Diagnostic Report Tab Click', {})
  } catch (e) {}
}

const trackReportDetailTelehealthNoteTabClick = () => {
  try {
    mixpanel?.track('Report Details - Telehealth Tab Click', {})
  } catch (e) {}
}

const trackReportDetailsNoteSeeMoreClick = () => {
  try {
    mixpanel?.track('Report Details - Note See More Click', {})
  } catch (e) {}
}

const trackReportDetailsPlanModalCheckoutClick = (
  products: EnrichedProduct[]
) => {
  try {
    const productNames = []
    const productSkus = []
    const productSlugs = []
    const productPaths = []

    for (let i = 0; i < products.length; i++) {
      if (isRxProduct(products[i]?.bcProduct)) {
        continue
      }

      productNames.push(products[i]?.bcProduct?.name ?? products[i]?.name ?? '')
      productSkus.push(products[i]?.bcProduct?.sku ?? '')
      productSlugs.push(products[i]?.bcProduct?.slug ?? products[i]?.name ?? '')
      productPaths.push(products[i]?.bcProduct?.path ?? products[i]?.path ?? '')
    }

    mixpanel?.track('Report Details - Plan Modal Checkout Click', {
      'Product Names': productNames,
      'Product SKUs': productSkus,
      'Product Slugs': productSlugs,
      'Product Paths': productPaths,
    })
  } catch (e) {}
}

const trackReportDetailsConfirmPlanCouponClick = (coupon: string) => {
  try {
    mixpanel?.track('Report Details - Confirm Plan Coupon Click', { coupon })
  } catch (e) {}
}

const trackReportDetailsConfirmPlanCheckoutClick = (products: any[]) => {
  try {
    const productSlugs = []

    for (let i = 0; i < products.length; i++) {
      if (isRxProduct(products[i]?.bcProduct)) {
        continue
      }
      productSlugs.push(products[i]?.slug ?? '')
    }

    mixpanel?.track('Report Details - Confirm Plan Checkout Click', {
      'Product Slugs': productSlugs,
    })
  } catch (e) {}
}

export {
  trackReportDetailCustomizeYourPlanClick,
  trackReportDetailDiagnosticReportTabClick,
  trackReportDetailNextReportClick,
  trackReportDetailPrevReportClick,
  trackReportDetailPurchasePlanClick,
  trackReportDetailRecommendedProductClick,
  trackReportDetailsConfirmPlanCheckoutClick,
  trackReportDetailsConfirmPlanCouponClick,
  trackReportDetailsNoteSeeMoreClick,
  trackReportDetailsPlanModalCheckoutClick,
  trackReportDetailTelehealthNoteTabClick,
}

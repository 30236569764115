import Image from 'next/image'
import { renderRichText } from '@storyblok/react/rsc'

type blokType = {
  image: any
  heading: any
  description: {
    content: any
    type: string
  }
  _uid: string
}

export default function Column({ blok }: { blok: blokType }) {
  const { image, heading, description }: blokType = blok
  return (
    <div
      key={blok._uid}
      className={`p-8 lg:px-6 lg:py-0 border-dark-brown-40 border-b last:border-b-0 lg:border-b-0 flex flex-col items-center justify-start gap-4 lg:gap-6 text-center lg:border-r lg:last:border-r-0`}
    >
      {image && image.filename && (
        <div className="aspect-1 w-10 lg:w-12 relative">
          <Image
            src={image.filename}
            fill={true}
            alt={image.alt}
            loading="lazy"
            className="object-contain object-center"
          />
        </div>
      )}
      {heading && <h5 className="h1 text-dark-brown">{heading}</h5>}
      <div
        className="richtext column-richtext"
        dangerouslySetInnerHTML={{
          __html: renderRichText(description),
        }}
      />
    </div>
  )
}

import Link from 'next/link'

interface AnnouncementBannerData {
  header: string | null
  secondary_header: string | null
  link: string
  visible: boolean
}

interface AnnouncementBannerProps {
  show: boolean
  data: AnnouncementBannerData
  deffer: () => void
}
const AnnouncementBanner = ({
  show,
  data,
  deffer,
}: AnnouncementBannerProps) => {
  return show ? (
    <div className="bg-sky text-brown h-11">
      <div className="relative w-full mx-auto px-4 lg:px-8 lg:max-w-[1440px] flex gap-2 items-center justify-center">
        {data.link && data.link.length ? (
          <Link
            href={data.link}
            className="font-medium text-xs mr-7 sm:mx-7 sm:text-sm sm:text-center inline py-3 hover:underline transition-all"
          >
            <span>{data.header}</span>
            <ArrowIcon />
          </Link>
        ) : (
          <div className="font-medium text-xs mr-7 sm:mx-7 sm:text-sm sm:text-center inline py-3">
            <span>{data.header}</span>
          </div>
        )}
        <div className="absolute right-4 lg:right-8">
          <button
            onClick={() => deffer()}
            type="button"
            className="bg-transparent relative flex items-center border border-brown/0 p-1 text-center cursor-pointer rounded-full font-medium text-sm hover:bg-brown hover:text-offwhite focus:outline-none focus:ring-1 focus:ring-brown focus:rounded focus:text-brown focus:bg-beige focus:border-transparent transition-all"
          >
            <CrossIcon />
          </button>
        </div>
      </div>
    </div>
  ) : null
}

export default AnnouncementBanner

const ArrowIcon = () => (
  <svg
    className="inline-flex w-5 h-5 flex-shrink-0"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path d="M14 16.94V12.94H5.08L5.05 10.93H14V6.94L19 11.94Z"></path>
  </svg>
)

const CrossIcon = () => (
  <svg
    className="h-4 w-4"
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path>
  </svg>
)

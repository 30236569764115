'use client'
import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import { storyblokEditable } from '@storyblok/react/rsc'

import { StoryblokContent } from '@lib/storyblok'

interface Props {
  blok: StoryblokContent
}

export default function Press({ blok }: Props) {
  const { backgroundImage, press_items } = blok

  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [rotatePaused, setRotatePaused] = useState<boolean>(false)

  const handleElementClick = (index: number) => {
    setCurrentIndex(index)
    setRotatePaused(true)
  }

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null

    if (!rotatePaused) {
      intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % press_items.length)
      }, 5000)
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [rotatePaused, press_items.length])

  return (
    <div
      className="press section bg-off-white px-4 lg:px-6 flex flex-col lg:flex-row lg:items-center gap-20"
      {...storyblokEditable(blok)}
    >
      <div className="px-4 lg:px-6 w-full h-full rounded overflow-hidden relative">
        <Image
          className=" object-cover"
          src={backgroundImage.filename}
          fill={true}
          alt={backgroundImage.alt}
        />
        <div className="absolute inset-0 bg-dark-brown-40"></div>
        <span className="eyebrow relative font-medium text-center text-off-white block mt-14 mb-10 lg:mt-20 lg:mb-14">
          Press
        </span>
        <div className="grid grid-cols-1 grid-rows-1 relative max-w-[875px] mx-auto">
          {press_items.map(
            (
              press_item: {
                quote: string
                logo: { filename: string; alt: string }
              },
              index: number
            ) => (
              <span
                key={index}
                className={`text-off-white h3 text-center transition-opacity duration-300 ease-in-out col-start-1 col-span-1 row-start-1 row-span-1 flex flex-col items-center justify-center ${
                  currentIndex === index ? 'opacity-1' : 'opacity-0'
                }`}
              >
                &ldquo;{press_item.quote}&rdquo;
              </span>
            )
          )}
        </div>
        <div className="mt-10 lg:mt-14 pt-10 mb-14 lg:mb-20 border-t border-off-white border-opacity-70 flex flex-row items-center justify-center gap-4 lg:gap-12 relative">
          {press_items.map(
            (
              press_item: {
                quote: string
                logo: { filename: string; alt: string }
              },
              index: number
            ) => (
              <div
                key={index}
                className={`block transition-opacity duration-300 ease-in-out lg:cursor-pointer ${
                  currentIndex === index ? 'opacity-100' : 'opacity-30'
                }`}
                onClick={() => handleElementClick(index)}
              >
                <Image
                  src={press_item.logo.filename}
                  fill={false}
                  alt={press_item.logo.alt}
                  className="object-center w-full h-10 max-w-[64px] lg:max-w-[115px] object-contain"
                  width={230}
                  height={80}
                />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}

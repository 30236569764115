import { renderRichText } from '@storyblok/react'
import Cookies from 'js-cookie'
import mixpanel from 'mixpanel-browser'
import { ExtendedProduct } from 'types/storyblok'

import type { LineItem } from '@commerce/types/cart'
import { Cart } from '@commerce/types/cart'
import { Product } from '@commerce/types/product'

import { getMonthlyOption } from '@components/product/ProductCard/AddToCartToggle'

import { AddressInfo } from '../../checkout/checkoutReducer'
import { MEMBERSHIP_GRANTING_COUPONS } from '../../coupons'
import { isNxProduct, isRxProduct } from '../../helpers/cart'
import { stripHTML } from '../../product-schema/product-schema'
import {
  LF_CORE_MEMBERSHIP_SKU,
  LF_MEMBERSHIP_SKU,
  membershipLineItem,
} from '../../productCatalog'

declare const window: Window &
  typeof globalThis & {
    dataLayer: any
    klaviyo: any
    edgetag: any
    Northbeam: any
    jumbleberry: any
    _atr: any
  }

const ecommerceParams = (cart: Cart) => {
  return {
    currency: 'USD',
    items: cart?.lineItems
      ?.filter((item) => !isRxProduct(item))
      .map((item) => ({
        item_id: item.variant?.sku,
        item_name: item.name,
      })),
  }
}

interface ECommerceItem {
  item_id: String
  item_name: String
  affiliation: String
  currency: String
  index: number
  item_brand: String
  item_category: String
  item_category2: String
  item_list_id?: String
  item_list_name?: String
  item_variant: String
  price: number
  quantity: number
}

interface KlaviyoItem {
  ProductName?: String
  SKU: String
  Categories?: String
  Price: number
}

interface Invoice {
  currency: String
  discount: String
  id: String
  lineItems: InvoiceLineItem[]
  number: string
  tax: number
  total: number
}

interface InvoiceLineItem {
  discount: number
}

function productToECItem(
  product: ExtendedProduct,
  index: number,
  quantity?: number,
  pageName?: String
) {
  const item_variant = getMonthlyOption(product.bcProduct?.options?.[0])
    ? 'Recurring'
    : 'One-time'

  const item: ECommerceItem = {
    item_id: product.bcProduct?.sku ?? product.content?.sku ?? '',
    item_name: product.bcProduct?.name,
    affiliation: 'My Life Force Online Store',
    currency: product.bcProduct?.price?.currencyCode ?? 'USD',
    index,
    item_brand: 'My Life Force',
    item_category:
      product.content?.category ??
      product.bcProduct?.categories?.[0] ??
      undefined,
    item_category2: product.content?.need_description ?? '',
    item_variant,
    price: product.bcProduct?.price?.value,
    quantity: quantity ?? 1,
  }

  if (pageName) {
    item.item_list_id = pageName
    item.item_list_name = pageName.toLowerCase().replaceAll(' ', '_')
  }

  return item
}

function lineItemToECItem(lineItem: LineItem, index: number) {
  const product = {
    bcProduct: {
      sku: lineItem.variant?.sku,
      name: lineItem.name,
      price: {
        value: lineItem.variant?.price,
      },
    },
    content: {
      category: lineItem.categories[0] ?? 'unavailable',
    },
  } as ExtendedProduct

  if (lineItem.options) {
    product.bcProduct.options = [
      {
        id: '',
        displayName: '',
        values: [
          {
            entityId: '123',
            label: lineItem.options?.[0].value,
          },
        ],
      },
    ]
  }

  return productToECItem(product, index, lineItem.quantity)
}

function bcProductToECItem(bcProduct: Product, index: number) {
  const product = {
    bcProduct,
    content: {
      category: bcProduct.categories[0] ?? 'unavailable',
    },
  } as ExtendedProduct
  return productToECItem(product, index, 1)
}

const addMembership = (lineItems: LineItem[], coupons: string[]) => {
  let hasMGC = false

  for (let i = 0; i < coupons.length; i++) {
    for (let j = 0; j < MEMBERSHIP_GRANTING_COUPONS.length; j++) {
      if (
        MEMBERSHIP_GRANTING_COUPONS[j].coupon.toLowerCase() ===
        coupons[i].toLowerCase()
      ) {
        hasMGC = true
        break
      }
    }
  }

  let items = lineItems

  if (hasMGC) {
    items = [...lineItems, membershipLineItem]
  }

  return items
}

// This needs to be corrected for membership
const trackBeginCheckout = (cart: Cart) => {
  try {
    const lineItems = cart.lineItems.filter((item) => !isRxProduct(item))

    window.dataLayer?.push({ ecommerce: null })

    window.dataLayer?.push({
      event: 'begin_checkout',
      hitTimeStamp: Date.now(),
      ecommerce: { items: lineItems.map(lineItemToECItem) },
    })

    window.klaviyo?.track('Started Checkout', {
      $event_id: `${cart.id}_${Date.now()}`,
      $value: cart.totalPrice,
      CheckoutURL: `${window.location.origin}/checkout`,
      ItemNames: lineItems.map((lineItem) => lineItem.name),
      Items: lineItems.map((lineItem) => {
        return {
          SKU: lineItem.sku ?? lineItem.variant?.sku,
          ProductName: lineItem.name,
          Quantity: lineItem.quantity,
          ItemPrice: lineItem.price ?? lineItem.variant?.price,
          ImageURL: lineItem.variant?.image?.url,
        }
      }),
    })

    window.edgetag('tag', 'InitiateCheckout', {
      contents: lineItems.map((lineItem) => {
        return {
          id: lineItem.sku ?? lineItem.variant?.sku,
          quantity: lineItem.quantity,
          item_price: lineItem.price ?? lineItem.variant?.price,
          image: lineItem.variant?.image?.url,
        }
      }),
      currency: 'USD',
      value: cart?.totalPrice,
      checkoutUrl: `${window.location.origin}/checkout`,
    })

    window._atr?.push(
      'Checkout Start',
      {
        ecommerce: {
          currency: 'USD',
          items: lineItems.map((lineItem) => {
            return {
              item_id: lineItem.sku ?? lineItem.variant?.sku,
              item_name: lineItem.name,
              quantity: lineItem.quantity,
              price: lineItem.price ?? lineItem.variant?.price,
            }
          }),
        },
      },
      ['ef']
    )
  } catch (e) {}
}

// This needs to be corrected for membership
const trackAddShippingInfo = (
  cart: Cart | null | undefined,
  addressInfo: AddressInfo
) => {
  try {
    const lineItems = cart?.lineItems.filter((item) => !isRxProduct(item)) || []

    window.dataLayer?.push({ ecommerce: null })

    window.dataLayer?.push({
      event: 'add_shipping_info',
      hitTimeStamp: Date.now(),
      currency: cart?.currency,
      value: cart?.totalPrice,
      ecommerce: {
        items: lineItems?.map(lineItemToECItem),
      },
    })

    window.edgetag('tag', 'AddShippingInfo', {
      contents: lineItems?.map((lineItem) => {
        return {
          id: lineItem.sku ?? lineItem.variant?.sku,
          quantity: lineItem.quantity,
          item_price: lineItem.price ?? lineItem.variant?.price,
          image: lineItem.variant?.image?.url,
        }
      }),
      currency: 'USD',
      value: cart?.totalPrice,
    })

    window.edgetag('data', {
      firstName: addressInfo.firstName,
      lastName: addressInfo.lastName,
      phone: addressInfo.phone,
      address: addressInfo.streetAddress + '\n' + addressInfo.streetAddress2,
      state: addressInfo.state,
      zip: addressInfo.zip,
      country: 'USA',
    })
  } catch (e) {}
}

const trackAddPaymentInfo = (
  lineItems: LineItem[],
  coupons: string[],
  invoice: Invoice
) => {
  try {
    const items = addMembership(lineItems, coupons).filter(
      (item) => !isRxProduct(item)
    )

    window.dataLayer?.push({ ecommerce: null })

    window.dataLayer?.push({
      event: 'add_payment_info',
      hitTimeStamp: Date.now(),
      currency: invoice.currency,
      value: invoice.total,
      coupon: coupons[0],
      payment_type: 'Credit Card',
      ecommerce: {
        items: items.map(lineItemToECItem),
      },
    })

    window.edgetag('tag', 'AddPaymentInfo', {
      contents: items.map((lineItem) => {
        return {
          id: lineItem.sku ?? lineItem.variant?.sku,
          quantity: lineItem.quantity,
          item_price: lineItem.price ?? lineItem.variant?.price,
          image: lineItem.variant?.image?.url,
        }
      }),
      currency: 'USD',
      value: invoice.total,
      checkoutUrl: `${window.location.origin}/checkout`,
    })
  } catch (e) {}
}

const trackPageView = async (cart: Cart | null) => {
  try {
    const lineItems = cart?.lineItems.filter((item) => !isRxProduct(item)) || []
    window.dataLayer?.push({ ecommerce: null })

    window.dataLayer?.push({
      event: 'page_view',
      hitTimeStamp: Date.now(),
      page_URL: window.location.href,
    })

    mixpanel?.track('Page View', {
      Currency: 'USD',
      'Product Names': lineItems?.map((item) => item.name) ?? [],
      'Product Prices': lineItems?.map((item) => item.variant?.price) ?? [],
      'Product Quantities': lineItems?.map((item) => item.quantity) ?? [],
      'Product SKUs': lineItems?.map((item) => item.variant?.sku) ?? [],
    })

    window.edgetag('tag', 'PageView')
  } catch (e) {}
}

const trackAddToCartFromCart = (lineItem: LineItem) => {
  try {
    if (isRxProduct(lineItem)) {
      return
    }

    window.dataLayer?.push({
      event: 'add_to_cart',
      ecommerce: {
        items: [lineItemToECItem(lineItem, 0)],
      },
    })
  } catch (e) {}
}

// This needs corrected for membership
const trackAddToCart = (
  product: ExtendedProduct,
  cart: Cart | null | undefined,
  index = 0,
  pageName = ''
) => {
  try {
    if (isRxProduct(product.bcProduct)) {
      return
    }

    const lineItems = cart?.lineItems.filter((item) => !isRxProduct(item)) || []

    window.dataLayer?.push({ ecommerce: null })

    window.dataLayer?.push({
      event: 'add_to_cart',
      ecommerce: {
        items: [productToECItem(product, index, 1, pageName)],
      },
    })

    mixpanel?.track('Add to Cart', {
      Name: product.bcProduct.name,
      SKU: [product?.bcProduct?.sku ?? product?.content?.sku].filter(Boolean),
      Price: product.bcProduct.price.value,
      Quantity: 1,
      Discount: 0,
    })

    window.klaviyo?.track('Added to Cart', {
      AddedItemProductName: product.bcProduct?.name,
      AddedItemSKU: product.bcProduct?.sku ?? product.content?.sku ?? '',
      AddedItemCategories:
        product.bcProduct?.categories ?? [product.content?.category] ??
        undefined,
      AddedItemImageURL: product.content?.main_image?.filename,
      AddedItemPrice: product.bcProduct?.price?.value,
      AddedItemQuantity: 1,
      $value: cart?.totalPrice ?? product.bcProduct?.price?.value,
      CheckoutURL: `${window.location.origin}/checkout`,
      ItemNames: lineItems?.map((lineItem) => lineItem.name) ?? [
        product.bcProduct?.name,
      ],
      Items: [
        ...(lineItems?.map((lineItem) => {
          return {
            SKU: lineItem.sku ?? lineItem.variant?.sku,
            ProductName: lineItem.name,
            Quantity: lineItem.quantity,
            ItemPrice: lineItem.price ?? lineItem.variant?.price,
            ImageURL: lineItem.variant?.image?.url,
          }
        }) ?? []),
        {
          SKU: product.bcProduct?.sku ?? product.content?.sku ?? '',
          ProductName: product.bcProduct?.name,
          Quantity: 1,
          ItemPrice: product.bcProduct?.price?.value,
          ImageURL: product.content?.main_image?.filename,
        },
      ],
    })

    if (isNxProduct(product.bcProduct)) {
      window.edgetag('tag', 'ATC Supplement', {
        contents: lineItems?.map((lineItem) => {
          return {
            id: lineItem.sku ?? lineItem.variant?.sku,
            quantity: lineItem.quantity,
            item_price: lineItem.price ?? lineItem.variant?.price,
            image: lineItem.variant?.image?.url,
          }
        }) ?? [
          {
            id: product.bcProduct.sku ?? product.content.sku ?? '',
            quantity: 1,
            item_price: product.bcProduct.price.value,
            image: product.content?.main_image?.filename,
          },
        ],
        currency: 'USD',
        value: cart?.totalPrice ?? product.bcProduct?.price?.value,
        checkoutUrl: `${window.location.origin}/checkout`,
      })
    }
  } catch (e) {}
}

const trackRemoveFromCart = (lineItem: LineItem) => {
  try {
    if (isRxProduct(lineItem)) {
      return
    }

    window.dataLayer?.push({ ecommerce: null })

    window.dataLayer?.push({
      event: 'remove_from_cart',
      hitTimeStamp: Date.now(),
      ecommerce: {
        items: [lineItemToECItem(lineItem, 0)],
      },
    })

    mixpanel?.track('Remove from Cart', {
      Name: lineItem.name,
      SKU: [lineItem.variant.sku],
      Price: lineItem.variant.price,
      Quantity: lineItem.quantity,
      Discount: 0,
    })
  } catch (e) {}
}

const trackViewCart = (cart: Cart, total: string) => {
  try {
    window.dataLayer?.push({ ecommerce: null })

    window.dataLayer?.push({
      event: 'view_cart',
      ecommerce: ecommerceParams(cart),
    })

    const lineItems =
      cart?.lineItems?.filter((item) => !isRxProduct(item)) || []

    mixpanel?.track('View Cart', {
      Currency: 'USD',
      'Product Names': lineItems?.map((item) => item.name) ?? [],
      'Product Prices': lineItems?.map((item) => item.variant?.price) ?? [],
      'Product Quantities': lineItems?.map((item) => item.quantity) ?? [],
      'Product SKUs': lineItems?.map((item) => item.variant?.sku) ?? [],
    })
  } catch (e) {}
}

const trackViewItem = (product: ExtendedProduct) => {
  try {
    window.dataLayer?.push({ ecommerce: null })

    window.dataLayer?.push({
      event: 'view_item',
      ecommerce: {
        items: [productToECItem(product, 0, 1, product.name)],
      },
    })

    mixpanel?.track('View Item', {
      Name: product.name,
      SKU: [product.bcProduct.sku ?? product.content.sku],
      Price: product.bcProduct.price.value,
    })

    window.klaviyo?.track('Viewed Product', {
      ProductName: product.bcProduct?.name,
      SKU: product.bcProduct?.sku ?? product.content?.sku ?? '',
      Categories:
        product.bcProduct?.categories ?? [product.content?.category] ??
        undefined,
      Price: product.bcProduct?.price?.value,
      ImageURL: product.content?.main_image?.filename,
    })

    let description = ''
    if (product.content?.description) {
      if (typeof product.content.description === 'string') {
        description = product.content.description
      } else {
        description = stripHTML(renderRichText(product.content.description))
      }
    }

    window.edgetag('tag', 'ViewContent', {
      contents: [
        {
          id: product.bcProduct.sku ?? product.content.sku ?? '',
          quantity: 1,
          item_price: product.bcProduct.price.value,
          description,
          category:
            product.bcProduct?.categories.join(',') ??
            product.content?.category ??
            undefined,
          image: product.content?.main_image?.filename,
        },
      ],
      currency: 'USD',
      value: product.bcProduct?.price?.value,
      checkoutUrl: `${window.location.origin}/checkout`,
    })
  } catch (e) {}
}

const trackCompletePurchase = (
  lineItems: LineItem[],
  coupons: string[],
  invoice: Invoice,
  customerId: string,
  newsletterConsent: boolean
) => {
  try {
    const items = addMembership(lineItems, coupons).filter(
      (item) => !isRxProduct(item)
    )

    window.dataLayer?.push({ ecommerce: null })

    window.dataLayer?.push({
      event: 'purchase',
      ecommerce: {
        coupon: coupons[0],
        currency: invoice.currency,
        shipping: 0,
        tax: invoice.tax,
        transaction_id: `LF${invoice.number}`,
        value: invoice.total,
        items: items.map(lineItemToECItem),
        userId: customerId,
      },
    })

    mixpanel?.track('Complete Purchase', {
      'Coupon Code': coupons[0],
      'Delivery Fee': 0,
      Discount: invoice.discount,
      'Product Discounts':
        invoice.lineItems.map((item: any) => item.discount) ?? [],
      'Product Names': items?.map((item) => item.name) ?? [],
      'Product Prices': items?.map((item) => item.variant.price) ?? [],
      'Product Quantities': items?.map((item) => item.quantity) ?? [],
      'Product SKUs': items?.map((item) => item.variant?.sku) ?? [],
      Tax: invoice.tax,
      'Total Charge': invoice.total,
      'Transaction Id': `LF${invoice.number}`,
      'Newsletter Consent': newsletterConsent,
    })

    if (window.jumbleberry) {
      window.jumbleberry('track', 'Purchase', {
        transaction_id: `LF${invoice.number}`,
        order_value: invoice.total,
      })
    }

    window._atr?.push(
      'Purchase',
      {
        ecommerce: {
          coupon: coupons.join(','),
          currency: 'USD',
          shipping: 0,
          tax: invoice.tax,
          transaction_id: `LF${invoice.number}`,
          value: invoice.total,
          items: items.map((lineItem) => {
            return {
              item_id: lineItem.sku ?? lineItem.variant?.sku,
              item_name: lineItem.name,
              quantity: lineItem.quantity,
              price: lineItem.price ?? lineItem.variant?.price,
            }
          }),
        },
      },
      ['ef']
    )

    mixpanel?.people.track_charge(invoice.total)
    mixpanel?.people.increment('Lifetime Revenue', invoice.total)

    if (process.env.VERCEL_ENV === 'production') {
      const hasAnyMembership = items.some(
        (lineItem) =>
          lineItem.sku === LF_MEMBERSHIP_SKU ||
          lineItem.variant?.sku === LF_MEMBERSHIP_SKU ||
          lineItem.sku === LF_CORE_MEMBERSHIP_SKU ||
          lineItem.variant?.sku === LF_CORE_MEMBERSHIP_SKU
      )

      if (hasAnyMembership) {
        window.edgetag('tag', 'Purchase', {
          contents: items.map((lineItem) => {
            return {
              id: lineItem.sku ?? lineItem.variant?.sku,
              quantity: lineItem.quantity,
              item_price: lineItem.price ?? lineItem.variant?.price,
              image: lineItem.variant?.image?.url,
            }
          }),
          currency: 'USD',
          value: invoice.total,
          checkoutUrl: `${window.location.origin}/checkout`,
          orderId: `LF${invoice.number}`,
        })
      }

      const membership = items.find(
        (lineItem) =>
          lineItem.sku === LF_MEMBERSHIP_SKU ||
          lineItem.variant?.sku === LF_MEMBERSHIP_SKU
      )

      if (membership) {
        window.edgetag('tag', 'Subscribe', {
          sourceId: 'Checkout',
          currency: 'USD',
          value: membership.price ?? membership.variant?.price,
        })
      }

      window.Northbeam?.firePurchaseEvent({
        id: `LF${invoice.number}`,
        totalPrice: invoice.total,
        shippingPrice: 0,
        taxPrice: invoice.tax,
        coupons: coupons.join(','),
        currency: 'USD',
        customerId,
        lineItems: items.map((lineItem) => ({
          productId: lineItem.id,
          variantId: lineItem.variantId,
          productName: lineItem.name,
          variantName: lineItem.variant?.name,
          price: lineItem.price ?? lineItem.variant?.price ?? 0,
          quantity: lineItem.quantity,
        })),
      })

      trackClickref(invoice, items, coupons)
    }
  } catch (e) {
    console.error('trackCompletePurchase', e)
  }
}

function trackClickref(
  invoice: Invoice,
  lineItems: LineItem[],
  coupons: string[]
) {
  try {
    const clickref = Cookies.get('clickref')

    if (!clickref) {
      return
    }

    const products = lineItems
      .filter((item) => !isRxProduct(item))
      .map(
        (lineItem) =>
          `[category:${lineItem.categories[0] ?? 'unknown'}/sku:${
            lineItem.variant?.sku ?? lineItem.sku
          }/value:${lineItem.variant?.price ?? lineItem.price}/quantity:${
            lineItem.quantity
          }]`
      )

    const voucher = coupons.length ? `voucher:${coupons[0]}/` : ''
    let parameters = `campaign:1100l6034/clickref:${clickref}/conversionref:LF${
      invoice.number
    }/country:US/currency:USD/${voucher}${products.join('/')}`

    fetch(`https://prf.hn/conversion/${parameters}`)
  } catch (e) {}
}

const trackSignUp = (email?: string) => {
  try {
    if (email) {
      mixpanel.people.set({ $email: email })
      window.klaviyo.identify({ $email: email })
      window.edgetag('data', { email: email })
    }
  } catch (e) {}
}

const trackLeadSubmission = (payload?: any) => {
  try {
    window.edgetag('tag', 'Lead', payload || {})
  } catch (e) {}
}

const trackViewCLP = (
  categorizedProducts: { [id: string]: ExtendedProduct[] },
  pageName: string
) => {
  try {
    const items: ECommerceItem[] = []
    Object.keys(categorizedProducts).forEach((category) => {
      categorizedProducts[category].forEach((product, index) => {
        items.push(productToECItem(product, index, 1, pageName))
      })
    })

    window.dataLayer?.push({ ecommerce: null })

    window.dataLayer?.push({
      event: 'view_item_list',
      hitTimeStamp: Date.now(),
      ecommerce: { items },
    })
  } catch (e) {}
}

const trackSelectItem = (product: ExtendedProduct, pageName?: String) => {
  try {
    window.dataLayer?.push({ ecommerce: null })

    window.dataLayer?.push({
      event: 'select_item',
      hitTimeStamp: Date.now(),
      ecommerce: {
        items: [productToECItem(product, 0, 1, pageName)],
      },
    })
  } catch (e) {}
}

const trackMembershipCancellation = () => {
  try {
    mixpanel?.track('Membership Cancellation')
  } catch (e) {}
}

const trackGender = (gender: string) => {
  try {
    mixpanel?.people.set('Gender', gender)
  } catch (e) {}
}

// Reports Hub
const trackViewReport = (orderId: String) => {
  try {
    mixpanel?.track('View Report', { 'Order Id': orderId })
  } catch (e) {}
}

const trackViewReportPurchaseYourPlan = (orderId: String) => {
  try {
    mixpanel?.track('View Report - Purchase Your Plan', {
      'Order Id': orderId,
    })
  } catch (e) {}
}

const trackViewReportBookTelehealth = (orderId: String) => {
  try {
    mixpanel?.track('View Report - Book Telehealth', {
      'Order Id': orderId,
    })
  } catch (e) {}
}

const trackEncouragBecomingMemberModalAction = (action: 'Later' | 'CTA') => {
  try {
    mixpanel?.track('Encourage Becoming Member', { action })
  } catch (e) {}
}

const trackNewsletterSubscription = (email: string) => {
  try {
    window.dataLayer?.push({ ecommerce: null })

    window.dataLayer?.push({
      event: 'subscribe_newsletter',
      email,
    })
  } catch (e) {}
}

const trackEncouragHealthQuestionnaireModalAction = (
  action: 'Later' | 'CTA'
) => {
  try {
    mixpanel?.track('Encourage Health Questionnaire', { action })
  } catch (e) {}
}

const trackNextTelehealthAppointmentInDays = (
  nextTelehealthAppointmentInDays: number
) => {
  try {
    mixpanel?.track('Next Telehealth Appointment in Days', {
      next_telehealth_appt_by_days: nextTelehealthAppointmentInDays,
    })
  } catch (e) {}
}

const trackLifescoreModalAction = (action: 'Later' | 'CTA') => {
  try {
    mixpanel?.track('Lifescore Modal Action', { action })
  } catch (e) {}
}

const trackPlanModalAction = () => {
  try {
    mixpanel?.track('Plan Modal Action')
  } catch (e) {}
}

const trackModalCompletePurchase = (
  products: Product[],
  coupons: string[],
  invoice: Invoice,
  customerId: string
) => {
  try {
    const items = products
      .filter((item) => !isRxProduct(item))
      .map((product) => ({ ...product, quantity: 1 }))
    window.dataLayer?.push({ ecommerce: null })

    window.dataLayer?.push({
      event: 'purchase',
      ecommerce: {
        coupon: coupons[0],
        currency: invoice.currency,
        shipping: 0,
        tax: invoice.tax,
        transaction_id: `LF${invoice.number}`,
        value: invoice.total,
        items: items.map(bcProductToECItem),
        userId: customerId,
      },
    })

    mixpanel?.track('Complete Plan Purchase', {
      'Coupon Code': coupons[0],
      'Delivery Fee': 0,
      Discount: invoice.discount,
      'Product Discounts':
        invoice.lineItems.map((item: any) => item.discount) ?? [],
      'Product Names': items?.map((item) => item.name) ?? [],
      'Product Prices': items?.map((item) => item.price) ?? [],
      'Product Quantities': items?.map(() => 1) ?? [],
      'Product SKUs': items?.map((item) => item.sku) ?? [],
      Tax: invoice.tax,
      'Total Charge': invoice.total,
      'Transaction Id': `LF${invoice.number}`,
    })

    if (window.jumbleberry) {
      window.jumbleberry('track', 'Purchase', {
        transaction_id: `LF${invoice.number}`,
        order_value: invoice.total,
      })
    }

    window._atr?.push(
      'Purchase',
      {
        ecommerce: {
          coupon: coupons.join(','),
          currency: 'USD',
          shipping: 0,
          tax: invoice.tax,
          transaction_id: `LF${invoice.number}`,
          value: invoice.total,
          items: items.map((lineItem) => {
            return {
              item_id: lineItem.sku,
              item_name: lineItem.name,
              quantity: lineItem.quantity,
              price: lineItem.price,
            }
          }),
        },
      },
      ['ef']
    )

    mixpanel?.people.track_charge(invoice.total)
    mixpanel?.people.increment({
      'Lifetime Revenue': invoice.total,
    })

    if (process.env.VERCEL_ENV === 'production') {
      const hasAnyMembership = items.some(
        (lineItem) =>
          lineItem.sku === LF_MEMBERSHIP_SKU ||
          lineItem.sku === LF_CORE_MEMBERSHIP_SKU
      )
      if (hasAnyMembership) {
        window.edgetag('tag', 'Purchase', {
          contents: items.map((lineItem) => {
            return {
              id: lineItem.sku,
              quantity: lineItem.quantity,
              item_price: lineItem.price,
              image: lineItem.images[0].url,
            }
          }),
          currency: 'USD',
          value: invoice.total,
          checkoutUrl: `${window.location.origin}/checkout`,
          orderId: `LF${invoice.number}`,
        })
      }

      const membership = items.find(
        (lineItem) => lineItem.sku === LF_MEMBERSHIP_SKU
      )

      if (membership) {
        window.edgetag('tag', 'Subscribe', {
          sourceId: 'Checkout',
          currency: 'USD',
          value: membership.price,
        })
      }

      window.Northbeam?.firePurchaseEvent({
        id: `LF${invoice.number}`,
        totalPrice: invoice.total,
        shippingPrice: 0,
        taxPrice: invoice.tax,
        coupons: coupons.join(','),
        currency: 'USD',
        customerId,
        lineItems: items.map((lineItem) => ({
          productId: lineItem.id,
          variantId: lineItem.variants[0].id ?? '',
          productName: lineItem.name,
          variantName: lineItem.variants[0].options[0].displayName ?? '',
          price: lineItem.price ?? 0,
          quantity: lineItem.quantity,
        })),
      })
    }
  } catch (e) {
    console.error('trackCompletePurchase', e)
  }
}

const trackOhConversion = (lineItems: LineItem[], invoice: any) => {
  if (process.env.VERCEL_ENV !== 'production') {
    return
  }

  const dheaQuantity = lineItems
    .filter((l) => l.variant.sku.toUpperCase() === 'DHEA-00030')
    .reduce((acc, l) => acc + l.quantity, 0)

  if (dheaQuantity === 0) {
    return
  }

  const hasSubscription = lineItems
    .filter((l) => l.variant.sku.toUpperCase() === 'DHEA-00030')
    .some((l) => l.options?.some((o) => o.value.toLowerCase() === 'monthly'))
  const totalBasketPrice = invoice.total
  const orderNumber = invoice.number

  /* oh_conversion.js variant */
  try {
    if (getAppConvCookie('oh_click') !== '') {
      var default_partner_id = '210'
      var partner_id =
        getAppConvCookie('overriden_partner_id') !== ''
          ? getAppConvCookie('overriden_partner_id')
          : default_partner_id
      var product_version =
        getAppConvCookie('product_version') !== ''
          ? getAppConvCookie('product_version')
          : ''

      var total_quantity = dheaQuantity
      var total_basket =
        totalBasketPrice /*TODO: Include the total price of the basket */
      var eid =
        orderNumber /*TODO: if we know the order id, include it here, if not, leave it as 0*/
      var conv_code = hasSubscription ? 'subscription' : 'payment' // if you know it's subscription, modify this by 'subscription'
      var buy_from = 'payment_page' /* TODO: if upsell, change this to upsell */
      var currency = 'USD'
      var product_name = 'DHEA'

      if (eid === '0' || eid === 'null' || eid === null) {
        eid =
          'z' +
          cyrb53(Date.now().toString(), Math.floor(Math.random() * 999999) + 1)
      }

      var request = new XMLHttpRequest()
      request.onreadystatechange = function () {
        if (request.readyState === 4) {
          if (request.status === 200) {
            console.log(request.responseText)
          } else {
            console.log(
              'An error occurred during your request: ' +
                request.status +
                ' ' +
                request.statusText
            )
          }
        }
      }

      let funnelParam =
        product_version === '' ? '' : `&funnel=${product_version}`

      request.open(
        'Get',
        'https://track.openhermes.com/i/' +
          partner_id +
          '/d/ftd?clid=' +
          getAppConvCookie('oh_click') +
          '&payout=' +
          total_basket +
          '&quantity=' +
          total_quantity +
          '&buy_from=' +
          buy_from +
          '&currency=' +
          currency +
          '&code=' +
          product_name +
          '&conv_code=' +
          conv_code +
          '&original_price=' +
          total_basket +
          '&order_eid=' +
          eid +
          funnelParam +
          '&extra=' +
          eid +
          '_' +
          product_name
      )
      request.send()
    }
  } catch (err: any) {
    console.log('Error in oh_conversion: ' + err.message)
    console.log(err.stack)
  }

  function getAppConvCookie(cname: string) {
    try {
      let name = cname + '='
      let decodedCookie = decodeURIComponent(document.cookie)
      let ca = decodedCookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    } catch (err: any) {
      console.log('Error in getAppConvCookie(' + cname + '): ' + err.message)
    }
  }

  function isPositiveInteger(value: any) {
    return /^\d+$/.test(value)
  }
  /*
      cyrb53 (c) 2018 bryc (github.com/bryc)
      A fast and simple hash function with decent collision resistance.
      Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.
      Public domain. Attribution appreciated.
  */
  function cyrb53(str: string, seed = 0) {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i)
      h1 = Math.imul(h1 ^ ch, 2654435761)
      h2 = Math.imul(h2 ^ ch, 1597334677)
    }
    h1 =
      Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
      Math.imul(h2 ^ (h2 >>> 13), 3266489909)
    h2 =
      Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
      Math.imul(h1 ^ (h1 >>> 13), 3266489909)
    return 4294967296 * (2097151 & h2) + (h1 >>> 0)
  }
}

const trackPlanMetrics = (products: Product[], invoice: Invoice) => {
  try {
    // we need to register this property so we can
    // track a membership purchase down the line
    mixpanel?.register({
      purchasedPlanThroughProfilePage: true,
    })

    mixpanel?.track('Plan items purchased', { quantity: products.length })
    mixpanel?.track('Plan revenue', { ammount: invoice.total })

    const boughtMembership = products.some(
      (product) => product.sku?.toUpperCase() === LF_MEMBERSHIP_SKU
    )

    if (boughtMembership) {
      trackMembershipPurchase()
    }
  } catch (error) {
    console.error('trackPlanMetrics', error)
  }
}

const trackMembershipPurchase = () => {
  try {
    const purchasedPlanThroughProfilePage = mixpanel?.get_property(
      'purchasedPlanThroughProfilePage'
    )
    if (!purchasedPlanThroughProfilePage) return

    mixpanel?.track('Becomes Member', { timestamp: Date.now() })
  } catch (error) {
    console.error('trackMembershipPurchase', error)
  }
}

const trackCheckoutError = (error: any) => {
  try {
    const errorMessage = error?.message ?? error
    mixpanel?.track('Checkout Error', { reason: errorMessage })
  } catch (e) {
    console.error('Error tracking Checkout Error', error)
  }
}

export {
  trackAddPaymentInfo,
  trackAddShippingInfo,
  trackAddToCart,
  trackAddToCartFromCart,
  trackBeginCheckout,
  trackCheckoutError,
  trackCompletePurchase,
  trackEncouragBecomingMemberModalAction,
  trackEncouragHealthQuestionnaireModalAction,
  trackGender,
  trackLeadSubmission,
  trackLifescoreModalAction,
  trackMembershipCancellation,
  trackMembershipPurchase,
  trackModalCompletePurchase,
  trackNewsletterSubscription,
  trackNextTelehealthAppointmentInDays,
  trackOhConversion,
  trackPageView,
  trackPlanMetrics,
  trackPlanModalAction,
  trackRemoveFromCart,
  trackSelectItem,
  trackSignUp,
  trackViewCart,
  trackViewCLP,
  trackViewItem,
  trackViewReport,
  trackViewReportBookTelehealth,
  trackViewReportPurchaseYourPlan,
}

'use client'

import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import {
  renderRichText,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react/rsc'

import { StoryblokContent } from '@lib/storyblok'

export const textSlides = [
  { text: 'better sleep.' },
  { text: 'slower aging.' },
  { text: 'greater strength.' },
  { text: 'sharper focus.' },
]

interface Props {
  blok: StoryblokContent
}

export default function TextSlideshow({ blok }: Props) {
  const { icon, text, textSlides, bg_color, speed } = blok

  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % textSlides.length)
    }, speed)

    return () => clearInterval(intervalId)
  }, [textSlides.length, speed])

  return (
    <div
      className={`section px-4 lg:px-6 ${
        bg_color === 'light-beige' ? 'bg-light-beige' : 'bg-off-white'
      }`}
      {...storyblokEditable(blok)}
    >
      <div className="border-b border-dark-brown-40 pb-14 lg:pb-20 flex flex-col items-center">
        {icon && icon.filename && (
          <div className="relative mb-8 w-16 h-16 block">
            <Image
              src={icon.filename}
              fill={true}
              alt={icon.alt}
              className="object-cover object-center"
            />
          </div>
        )}

        <div className="text-center max-w-[540px] lg:max-w-[930px] mx-auto">
          <div className="h1 tracking-[-0.8px]">
            <div
              className="text-dark-brown richtext"
              dangerouslySetInnerHTML={{ __html: renderRichText(text) }}
            ></div>
            {textSlides && (
              <span className="text-sage text-center inline-block grid relative w-full h1 tracking-[-0.8px]">
                {textSlides.map((nestedBlok: any) => (
                  <StoryblokComponent
                    blok={nestedBlok}
                    isActive={textSlides[currentIndex]._uid == nestedBlok._uid}
                    key={nestedBlok._uid}
                  />
                ))}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

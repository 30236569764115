'use client'

import React, { createContext, useContext, useMemo } from 'react'
import { MembershipDetailsData } from 'pages/api/membership/details'
import useSWR from 'swr'

import useUser from '@lib/hooks/useUser'

import { fetcherWithError } from '../fetchWithError'

interface MembershipDataContextProps {
  membershipData: MembershipDetailsData | undefined
  isLoadingMembershipData: boolean
  refreshMembershipData: () => void
  canBuyMembershipWithoutDx: boolean
}

const MembershipDataContext = createContext<MembershipDataContextProps>({
  membershipData: undefined,
  isLoadingMembershipData: false,
  refreshMembershipData: () => {},
  canBuyMembershipWithoutDx: false,
})

export const useMembershipData = (): MembershipDataContextProps => {
  return useContext(MembershipDataContext)
}

type MembershipDetailsDataResponse = {
  data: MembershipDetailsData
}

export const MembershipDataProvider: React.FC = ({ children }) => {
  const { user } = useUser()
  const {
    data,
    error,
    isLoading: isLoadingMembershipData,
    mutate: refetch,
  } = useSWR<MembershipDetailsDataResponse>(
    user ? '/api/membership/details' : null,
    fetcherWithError
  )
  let membershipData = data?.data

  const refreshMembershipData = () => {
    refetch()
  }

  const canBuyMembershipWithoutDx = useMemo(() => {
    if (membershipData) {
      return (
        membershipData?.diagnostic_codes.length > 0 ||
        membershipData?.diagnostics!.length > 0 ||
        membershipData?.membership_state.dx_purchased
      )
    }

    return false
  }, [membershipData])

  const value: MembershipDataContextProps = {
    membershipData,
    isLoadingMembershipData,
    refreshMembershipData,
    canBuyMembershipWithoutDx,
  }

  return (
    <MembershipDataContext.Provider value={value}>
      {children}
    </MembershipDataContext.Provider>
  )
}

'use client'

import { useContext } from 'react'
import { apiPlugin, storyblokInit } from '@storyblok/react/rsc'

import CtaSection from '@components/common/DynamicComponent/CtaSection'
import VimeoVideo from '@components/common/DynamicComponent/VimeoVideo'

import AnimationWithText from '@/components-v7/AnimationWithText'
import BiomarkersDrawers from '@/components-v7/BiomarkersDrawers'
import BlogPost from '@/components-v7/BlogPost'
import Chart from '@/components-v7/Chart'
import Collection from '@/components-v7/Collection'
import Column from '@/components-v7/Column'
import DiscountedBundle from '@/components-v7/DiscountedBundle'
import Footer from '@/components-v7/Footer/Footer'
import FullMedia from '@/components-v7/FullMedia'
import HeroVideo from '@/components-v7/HeroVideo'
import Home from '@/components-v7/Home'
import ImageGroupWithText from '@/components-v7/ImageGroupWithText'
import ImagePurchase from '@/components-v7/ImagePurchase'
import ImageSlideshow from '@/components-v7/ImageSlideshow'
import LeadInTextWithImage from '@/components-v7/LeadInTextWithImage'
import Link from '@/components-v7/Link'
import MediaBannerWithQuote from '@/components-v7/MediaBannerWithQuote'
import Menu from '@/components-v7/Menu'
import MultiColumn from '@/components-v7/MultiColumn'
import Press from '@/components-v7/Press'
import PressBar from '@/components-v7/PressBar'
import Product from '@/components-v7/Product'
import DynamicTextImageModule from '@/components-v7/Product/DynamicTextImageModule'
import ImagePairModule from '@/components-v7/Product/ImagePairModule'
import OpenTextImageModule from '@/components-v7/Product/OpenTextImageModule'
import ProductGrid from '@/components-v7/ProductGrid'
import RichText from '@/components-v7/RichText'
import SingleImageWithText from '@/components-v7/SingleImageWithText'
import StackedImagesWithText from '@/components-v7/StackedImagesWithText'
import StaticImageColumns from '@/components-v7/StaticImageColumns'
import StaticImagesText from '@/components-v7/StaticImagesText'
import StaticPage from '@/components-v7/StaticPage'
import StickyColumns from '@/components-v7/StickyColumns/StickyColumns'
import TestimonialCard from '@/components-v7/TestimonialCard'
import Testimonials from '@/components-v7/Testimonials'
import TextBanner from '@/components-v7/TextBanner'
import TextSlide from '@/components-v7/TextSlide'
import TextSlideshow from '@/components-v7/TextSlideshow'
import TextWithImage from '@/components-v7/TextWithImage'
import { LinksContext, storyBlokLinksType } from '@/lib/context/LinksContext'

export const components = {
  'animation-with-text': AnimationWithText,
  'biomarkers-drawers': BiomarkersDrawers,
  'dynamic-text-image-container': DynamicTextImageModule,
  'full-bleed-media': FullMedia,
  'image-group-with-text': ImageGroupWithText,
  'image-purchase': ImagePurchase,
  'image-slideshow': ImageSlideshow,
  'lead-in-text-with-image': LeadInTextWithImage,
  'media-banner-with-quote': MediaBannerWithQuote,
  'multi-column': MultiColumn,
  'open-text-image-container': OpenTextImageModule,
  'product-v7': Product,
  'rich-text': RichText,
  'single-image-with-text': SingleImageWithText,
  'stacked-images-with-text': StackedImagesWithText,
  'Static Page': StaticPage,
  'static-image-columns': StaticImageColumns,
  'static-images-text': StaticImagesText,
  'static-images': ImagePairModule,
  'static-page': StaticPage,
  'sticky-columns': StickyColumns,
  'testimonials-v7': Testimonials,
  'text-banner': TextBanner,
  'text-slideshow': TextSlideshow,
  'text-with-image': TextWithImage,
  'discounted-bundle': DiscountedBundle,
  blogpost: BlogPost,
  chart: Chart,
  collection: Collection,
  column: Column,
  footer: Footer,
  hero: HeroVideo,
  home: Home,
  link: Link,
  menu: Menu,
  press: Press,
  productGrid: ProductGrid,
  testimonialBlok: TestimonialCard,
  textSlide: TextSlide,
  'vimeo-video': VimeoVideo,
  'cta-section': CtaSection,
  Landing: StaticPage,
  'press-bar': PressBar,
}

type blokType = {
  cta_link?: {
    id: string
    linktype: string
    story?: {
      url: string
      id: string
    }
    target: string
    url?: string
  }
  cta_text: string
  heading: string
  image?: {
    filename: string
  }
  subheading: string
  text: string
  url?: {
    id: string
    linktype: string
    story?: {
      url: string
      id: string
    }
    target: string
    url?: string
  }
}

export const RichTextResolver = (component: string, blok: blokType) => {
  const storyBlokLinks: storyBlokLinksType = useContext(LinksContext)

  switch (component) {
    case 'cta': {
      var href: string | undefined = ''

      if (!blok.url) return ''

      switch (blok.url.linktype) {
        case 'story': {
          href = blok.url.story
            ? `/${blok.url.story.url}`
            : storyBlokLinks[blok.url.id as string]?.real_path || ''
          break
        }
        case 'url': {
          href = blok.url.url
          break
        }
        default: {
          href = `https://www.google.com/`
        }
      }
      return `<p><a class="button button--primary" href="${href}" data-linktype="${
        blok.url.linktype
      }" ${blok.url.target == '_blank' ? 'target="_blank"' : ''}>
      ${blok.text}</a></p>`
      break
    }
    case 'blog-image-cta': {
      if (!blok.cta_link) return ''
      var href: string | undefined = ''
      switch (blok.cta_link.linktype) {
        case 'story': {
          href = `/${blok.cta_link.story?.url || '/'}`
          break
        }
        case 'url': {
          href = blok.cta_link.url
          break
        }
        default: {
          href = `https://www.google.com/`
        }
      }
      return `<div class="my-10 relative w-full max-w-[566px] mx-auto">
      <div class="relative pb-[130%] lg:pb-[78%] rounded overflow-hidden">
        <img class="cta-block absolute inset-0 h-full w-full object-cover object-center" src="${blok
          .image?.filename}" />
        <div class="absolute left-4 right-4 bottom-6 lg:left-6 lg:right-6 lg:bottom-8 flex flex-col gap-2 text-off-white">
          <h4 class="!text-off-white">${blok.heading}</h4>
          <p>${blok.subheading}</p>
          <p class="mt-2"><a class="button button--primary" href="${href}" data-linktype="${
            blok.cta_link.linktype
          }" ${blok.cta_link.target == '_blank' ? 'target="_blank"' : ''}>${
            blok.cta_text
          }</a></p>
        </div>
      </div>
      </div>`
    }
    case 'youtube-video': {
      if (!blok.url) return ''
      return `<div class="aspect-w-16 aspect-h-9">
                <iframe
                  width="560"
                  height="315"
                  src="${blok.url}"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  class="border-0"
                ></iframe>
              </div>`
    }
    case 'call-to-action': {
      return `<a href="${blok.url}" style="text-decoration:none">
                <button class="mx-auto bg-yellow border-brown/0 text-brown
                 focus:text-brown focus:bg-beige focus:border-transparent
                  focus:ring-brown hover:bg-brown hover:text-offwhite group justify-center
                  relative flex items-center border p-2 text-center rounded-full font-medium
                  text-sm transition-all focus:ring-1 focus:outline-none focus:rounded cursor-pointer">
                  <span class='whitespace-nowrap mx-2'>${blok.text}</span>
                  </button>
               </a>`
    }
    default:
      return 'Resolver not defined'
  }
}

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
  use: [apiPlugin],
  components,
  richText: {
    resolver: RichTextResolver,
  },
  apiOptions: {
    maxRetries: 10,
  },
})

// @ts-ignore
export default function StoryblokProvider({ children }) {
  return children
}

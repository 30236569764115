import Link from 'next/link'

import ProductCard from '@components-v7/ProductCard'

type Tag = {
  uuid: string
  name: string
  content?: {
    component: 'tag'
  }
}

type Product = {
  subtitle?: string
  image?: string
  name?: string
  slug?: string
  uuid?: string
  content?: {
    component: 'product-v7'
    name?: string
    category?: string
    // TODO address types
    main_image?: any
    short_description?: any
    tags?: string[] | Tag[]
  }
}

type ProductRecommendationsProps = {
  title?: string
  productRecommendations?: string[]
  rels?: Product[]
  category?: string
}

export default function ProductRecommendations(
  props: ProductRecommendationsProps
) {
  const { title, category, productRecommendations, rels } = props

  const products = productRecommendations?.map((uuid) => {
    const product = rels?.find((rel) => rel.uuid === uuid) as
      | Product
      | undefined
    if (product && product.content?.tags) {
      product.content.tags = product.content.tags.map((tag) => {
        if (typeof tag === 'string') {
          return rels?.find((rel) => rel.uuid === tag) as Tag
        }
        return tag
      })
    }
    return product
  })

  return (
    <div className="pl-4 md:px-6 py-10 text-center w-full">
      <h4 className="h4 text-dark-brown">{title}</h4>

      <div className="md:grid md:grid-flow-col justify-start md:auto-cols-fr md:gap-4 mt-10 mb-6 md:my-10 w-full flex overflow-x-auto -webkit-overflow-scrolling-touch scroll-snap-type-x-mandatory ">
        {products?.map((product: Product | undefined) => (
          <ProductCard
            key={product?.uuid}
            isInProductGrid={false}
            modifiers={
              'cursor-pointer text-left hover:opacity-80 transition-opacity duration-300 scroll-snap-align-center scroll-snap-stop-normal flex-shrink-0 pr-4 md:pr-0 pb-4 w-[250px] md:w-auto'
            }
            product={product as Product}
          />
        ))}
      </div>

      {category && (
        <div className="inline-block">
          <Link
            href={`/collections/${category}`}
            className="button button--full"
          >
            Shop All{' '}
            <span className="title inline-block capitalize ml-1">
              {category}
            </span>
          </Link>
        </div>
      )}
    </div>
  )
}

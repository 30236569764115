import React from 'react'

import { LoadingModal } from '@components/ui'


const SiteMaintenance: React.FC = () => {
  return (
    <div className="pointer-events-none">
      <LoadingModal
        title="Health is on the way."
        description="We can’t wait to reintroduce ourselves. Please check back later today."
        maintenance
      />
    </div>
  )
}

export default SiteMaintenance

import React, { useEffect, useRef } from 'react'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import { renderRichText, storyblokEditable } from '@storyblok/react/rsc'
import { useInView } from 'framer-motion'

import { StoryblokContent } from '@lib/storyblok'

import { getImageAspectRatio } from '../helper'
import { slugify } from '../PurchaseModule/PurchaseNav'

import './static-image-columns.css'

interface Props {
  blok: StoryblokContent
  setActiveSection?: (section: string) => void
}

export default function StaticImageColumns(props: Props) {
  const { blok, setActiveSection } = props

  const {
    heading,
    image1,
    image2,
    image3,
    image4,
    step1,
    step2,
    step3,
    step4,
    Title,
  } = blok
  const pathname = usePathname() ?? ''

  const ref = React.useRef<HTMLDivElement>(null)
  const inView = useInView(ref)

  useEffect(() => {
    if (inView) {
      setActiveSection?.(Title || '')
    }
  }, [inView, setActiveSection, Title])

  return (
    <div
      ref={ref}
      {...storyblokEditable(blok)}
      id={slugify(Title || '')}
      className={`static-image-columns section bg-off-white px-4 lg:px-0`}
    >
      <div
        className="richtext heading"
        dangerouslySetInnerHTML={{ __html: renderRichText(heading) }}
      />

      <div className="grid lg:gap-x-6 gap-y-10 lg:gap-y-14 lg:grid-cols-8 mt-10 lg:mt-12">
        <div className="image-text lg:col-span-4">
          <figure
            className="relative"
            style={{ aspectRatio: `${getImageAspectRatio(image1)}` }}
          >
            <Image
              src={image1.filename}
              fill={true}
              alt={image1.alt}
              className="object-cover object-center rounded-[4px]"
            />
          </figure>
          <div
            className="richtext mt-6"
            dangerouslySetInnerHTML={{ __html: renderRichText(step1) }}
          />
        </div>

        <div className="image-text lg:col-span-4 lg:mt-20">
          <figure
            className="relative"
            style={{ aspectRatio: `${getImageAspectRatio(image2)}` }}
          >
            <Image
              src={image2.filename}
              fill={true}
              alt={image1.alt}
              className="object-cover object-center rounded-[4px]"
            />
          </figure>
          <div
            className="richtext mt-6"
            dangerouslySetInnerHTML={{ __html: renderRichText(step2) }}
          />
        </div>

        <div className="image-text lg:col-span-3 lg:-mt-[50%]">
          <figure
            className="relative"
            style={{ aspectRatio: `${getImageAspectRatio(image3)}` }}
          >
            <Image
              src={image3.filename}
              fill={true}
              alt={image1.alt}
              className="object-cover object-center rounded-[4px]"
            />
          </figure>
          <div
            className="richtext mt-6"
            dangerouslySetInnerHTML={{ __html: renderRichText(step3) }}
          />
        </div>

        <div className="image-text lg:col-span-5">
          <figure className="relative" style={{ aspectRatio: `5/4` }}>
            <Image
              src={image4.filename}
              fill={true}
              alt={image1.alt}
              className="object-cover object-center rounded-[4px]"
            />
          </figure>
          <div
            className="richtext mt-6"
            dangerouslySetInnerHTML={{ __html: renderRichText(step4) }}
          />
        </div>
      </div>
    </div>
  )
}

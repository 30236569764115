import Cookies from 'js-cookie'
import type { NextApiRequest, NextApiResponse } from 'next'

export const getCookie = (name: string, req?: NextApiRequest) => {
  if (typeof window !== 'undefined') {
    // Client-side
    return Cookies.get(name)
  } else if (req) {
    // Server-side
    const cookie = req.headers?.cookie?.split(';')?.find(c => c.trim().startsWith(`${name}=`))
    return cookie ? decodeURIComponent(cookie.split('=')[1]) : undefined
  }
  return null
}

export const setCookie = (name: string, value: string, options?: Cookies.CookieAttributes, res?: NextApiResponse) => {
  if (typeof window !== 'undefined') {
    // Client-side
    Cookies.set(name, value, options)
  } else if (res) {
    // Server-side
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
    if (options) {
      if (options.expires) {
        if (options.expires instanceof Date) {
          cookieString += `; Expires=${options.expires.toUTCString()}`;
        } else {
          cookieString += `; Expires=${options.expires}`;
        }
      }
      if (options.path) {
        cookieString += `; Path=${options.path}`;
      }
      if (options.domain) {
        cookieString += `; Domain=${options.domain}`;
      }
      if (options.secure) {
        cookieString += '; Secure';
      }
      if (options.httpOnly) {
        cookieString += '; HttpOnly';
      }
      if (options.sameSite) {
        cookieString += `; SameSite=${options.sameSite}`;
      }
    }
    res.setHeader('Set-Cookie', cookieString)
  }
}

export const removeCookie = (name: string, options?: Cookies.CookieAttributes, res?: NextApiResponse) => {
  if (typeof window !== 'undefined') {
    // Client-side
    Cookies.remove(name, options)
  } else if (res) {
    // Server-side
    let cookieString = `${encodeURIComponent(name)}=; Expires=${new Date(0).toUTCString()}`;
    if (options && options.path) {
      cookieString += `; Path=${options.path}`;
    }
    res.setHeader('Set-Cookie', cookieString)
  }
}

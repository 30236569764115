'use client'
import { PropsWithChildren } from 'react'

import ReactPortal from '../ReactPortal/ReactPortal'

import styles from './LoadingModal.module.css'

type LoadingModalProps = {
  title?: string
  description?: string
  maintenance?: boolean
}

const LoadingModal = ({
  title = 'Loading Lifeforce...',
  description = 'Thank you for being patient as your Lifeforce experience loads.',
  maintenance = false,
}: LoadingModalProps) => {
  return (
    <div
      className="fixed top-0 right-0 bottom-0 left-0 bg-charcoal bg-opacity-80 cursor-pointer flex z-40 overflow-auto"
      style={{ backdropFilter: `${maintenance ? 'blur(50px)' : 'blur(5px)'}` }}
    >
      <div className="relative block w-full m-auto p-4 max-w-[360px]">
        <div className="cursor-default relative block bg-offwhite border border-brown/10 rounded mb-8 last:mb-0">
          <div className="px-4 py-12">
            <div className="h-16 w-16 mx-auto flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`transition-all text-warmbrown w-full h-full ${styles['custom-rot-animation-loading']}`}
                viewBox="0 0 99 94"
                fill="none"
              >
                <path
                  d="M76.3685 45.874C76.3685 67.4731 60.8765 84.9895 41.7761 84.9895C22.6758 84.9895 2 67.4731 2 45.874C2 24.2749 22.6673 6.75853 41.7677 6.75853C60.868 6.75853 76.36 24.2665 76.36 45.874H76.3685Z"
                  stroke="currentColor"
                  strokeWidth="3.21383"
                  strokeMiterlimit="10"
                ></path>
                <path
                  d="M78.8538 76.7434C65.3777 93.6246 48.7591 96.8094 33.8262 84.8833C18.8932 72.9572 5.41703 45.7509 18.8932 28.8697C32.3693 11.9886 61.2866 12.6747 76.2196 24.5923C91.1526 36.5099 92.33 59.8623 78.8538 76.7434Z"
                  stroke="currentColor"
                  strokeWidth="3.21383"
                  strokeMiterlimit="10"
                ></path>
                <path
                  d="M86.1862 9.91056C102.458 24.1151 98.6544 53.7355 86.0846 68.1264C73.5148 82.5173 50.137 82.6697 33.8657 68.4652C17.5944 54.2606 14.5875 31.0776 27.1573 16.6783C39.7271 2.27889 69.9065 -4.30247 86.1778 9.91056H86.1862Z"
                  stroke="currentColor"
                  strokeWidth="3.21383"
                  strokeMiterlimit="10"
                ></path>
              </svg>
            </div>
            <form className="text-center">
              <label className="block mt-6 mb-2 flex-grow text-2xl text-brown bradford">
                {title}
              </label>
              <p className="text-base text-warmbrown">{description}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingModal

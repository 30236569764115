'use client'

import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { usePathname, useSearchParams } from 'next/navigation'
import { AnimatePresence, motion } from 'framer-motion'
import ClickOutside from 'lib/click-outside/click-outside'

import useCart from '@framework/cart/use-cart'
import { LineItem } from '@commerce/types/cart'
import { useAccount } from '@lib/context/AccountContext'

import AnnouncementBanner from '@components/common/AnnouncementBanner'
import { useUI } from '@components/ui'

import useAnnouncementBanner from '../../lib/hooks/useAnnouncementBanner'
import UserDropdown from '@/components/common/Navbar/UserDropdown'

import styles from './Header.module.css'

export default function Header({}) {
  const [desktopDropdownIsOpen, setDesktopDropdownIsOpen] =
    useState<boolean>(false)

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState<boolean>(false)
  const [solutionsDropdownIsActive, setSolutionsDropdownIsActive] =
    useState<boolean>(false)

  const {
    show: showAnnouncementBanner,
    data: bannerData,
    deffer,
  } = useAnnouncementBanner()

  function toggleSolutionsDesktopDropdown(force?: boolean) {
    let newOpenState = force !== undefined ? force : !desktopDropdownIsOpen
    setDesktopDropdownIsOpen(newOpenState)
  }

  const pathname = usePathname() || ''
  const searchParams = useSearchParams() || ''

  useEffect(() => {
    setMobileMenuIsOpen(false)
    setDesktopDropdownIsOpen(false)
  }, [pathname, searchParams])

  const { getUserData } = useAccount()
  const userData = getUserData()
  const user = userData?.userObj

  const [showUserDropdown, setShowUserDropdown] = useState(false)

  const { data: cart } = useCart()
  const countItem = (count: number, item: LineItem) => count + item.quantity
  const itemsCount = cart?.lineItems.reduce(countItem, 0) ?? 0

  const CartLinkButton = ({ count }: { count: number }) => {
    const { toggleSidebar } = useUI()

    return (
      <button
        onClick={() => toggleSidebar()}
        className={`cartLink ${styles.cartLink}`}
      >
        {itemsCount}
      </button>
    )
  }

  const closeAllDropdowns = () => {
    setShowUserDropdown(false)
    setDesktopDropdownIsOpen(false)
    setSolutionsDropdownIsActive(false)
  }

  const shouldDisplayBanner =
    ['/account', '/landers', '/checkout'].every(
      (url) => !pathname.includes(url)
    ) && showAnnouncementBanner

  return (
    <>
      {shouldDisplayBanner && (
        <nav className="sticky top-0 z-30">
          <AnnouncementBanner
            show={showAnnouncementBanner}
            data={bannerData}
            deffer={deffer}
          />
        </nav>
      )}

      <header
        className={`header ${styles.header} ${
          shouldDisplayBanner ? '!top-11' : ''
        }`}
        id="header"
      >
        <Link className={`logo ${styles.logo}`} href="/">
          <svg
            fill="currentColor"
            height="22"
            viewBox="0 0 155 22"
            width="155"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 20.7678V1.2515C0 0.865919 0.315138 0.550781 0.700719 0.550781H3.32934C3.71492 0.550781 4.03006 0.865919 4.03006 1.2515V17.179C4.03006 17.5682 4.3452 17.8797 4.73078 17.8797H15.1303C15.5196 17.8797 15.8311 18.1948 15.8311 18.5804V20.7567C15.8311 21.146 15.5159 21.4574 15.1303 21.4574H0.700719C0.311431 21.4574 0 21.1423 0 20.7567V20.7678Z" />
            <path d="M17.6365 20.7678V1.2515C17.6365 0.865919 17.9516 0.550781 18.3372 0.550781H20.9658C21.3551 0.550781 21.6665 0.865919 21.6665 1.2515V20.7678C21.6665 21.1534 21.3514 21.4685 20.9658 21.4685H18.3372C17.9516 21.4685 17.6365 21.1534 17.6365 20.7678Z" />
            <path d="M25.3743 20.7678V1.2515C25.3743 0.865919 25.6894 0.550781 26.075 0.550781H39.9114C40.297 0.550781 40.6121 0.865919 40.6121 1.2515V3.42781C40.6121 3.81339 40.297 4.12853 39.9114 4.12853H30.1088C29.7232 4.12853 29.408 4.44367 29.408 4.82925V8.79999C29.408 9.18557 29.7232 9.50071 30.1088 9.50071H38.4136C38.8029 9.50071 39.1143 9.81584 39.1143 10.2014V12.3777C39.1143 12.767 38.7992 13.0785 38.4136 13.0785H30.1088C29.7232 13.0785 29.408 13.3936 29.408 13.7792V20.7419C29.408 21.1312 29.0929 21.4426 28.7073 21.4426H26.0787C25.6894 21.4426 25.378 21.1275 25.378 20.7419V20.7567L25.3743 20.7678Z" />
            <path d="M42.5879 20.7678V1.2515C42.5879 0.865919 42.903 0.550781 43.2886 0.550781H57.7219C58.1075 0.550781 58.4227 0.865919 58.4227 1.2515V3.42781C58.4227 3.81339 58.1075 4.12853 57.7219 4.12853H47.3224C46.9368 4.12853 46.6217 4.44367 46.6217 4.82925V8.23274C46.6217 8.62203 46.9368 8.93346 47.3224 8.93346H55.9238C56.3094 8.93346 56.6245 9.2486 56.6245 9.63418V11.8105C56.6245 12.1998 56.3094 12.5112 55.9238 12.5112H47.3224C46.9368 12.5112 46.6217 12.8263 46.6217 13.2119V17.153C46.6217 17.5423 46.9368 17.8537 47.3224 17.8537H58.0185C58.4078 17.8537 58.7193 18.1689 58.7193 18.5544V20.7307C58.7193 21.12 58.4041 21.4315 58.0185 21.4315H43.2886C42.8993 21.4315 42.5879 21.1163 42.5879 20.7307V20.7493V20.7678Z" />
            <path d="M61.1476 20.7678V1.2515C61.1476 0.865919 61.4627 0.550781 61.8483 0.550781H75.6847C76.074 0.550781 76.3854 0.865919 76.3854 1.2515V3.42781C76.3854 3.81339 76.0703 4.12853 75.6847 4.12853H65.8821C65.4965 4.12853 65.1813 4.44367 65.1813 4.82925V8.79999C65.1813 9.18557 65.4965 9.50071 65.8821 9.50071H74.1869C74.5762 9.50071 74.8876 9.81584 74.8876 10.2014V12.3777C74.8876 12.767 74.5725 13.0785 74.1869 13.0785H65.8821C65.4965 13.0785 65.1813 13.3936 65.1813 13.7792V20.7419C65.1813 21.1312 64.8662 21.4426 64.4806 21.4426H61.852C61.4664 21.4426 61.1513 21.1275 61.1513 20.7419V20.7567L61.1476 20.7678Z" />
            <path d="M99.5952 20.7678V1.2515C99.5952 0.865919 99.9104 0.550781 100.296 0.550781H109.305C112.89 0.550781 115.73 2.94212 115.73 6.23068C115.73 8.18454 115.1 9.63788 113.821 10.6241C113.395 10.9503 113.454 11.6103 113.951 11.8253C114.974 12.2702 115.437 13.1823 115.437 14.4725V20.7641C115.437 21.1497 115.122 21.4648 114.737 21.4648H112.108C111.719 21.4648 111.407 21.1497 111.407 20.7641V16.1891C111.407 14.7283 110.221 13.5419 108.76 13.5419H104.341C103.955 13.5419 103.64 13.857 103.64 14.2426V20.7567C103.64 21.146 103.325 21.4574 102.939 21.4574H100.311C99.9215 21.4574 99.61 21.1423 99.61 20.7567L99.5952 20.7678ZM103.629 9.31904C103.629 9.70462 103.944 10.0198 104.33 10.0198H108.108C110.499 10.0198 111.693 8.94458 111.693 7.00185C111.693 5.06282 110.499 4.13223 108.108 4.13223H104.33C103.944 4.13223 103.629 4.44737 103.629 4.83295V9.31162V9.31904Z" />
            <path d="M117.732 9.57809C118.136 4.36904 122.477 0.246295 127.709 0.250002C132.836 0.25371 135.746 3.04546 136.369 6.60467C136.444 7.03474 136.102 7.42032 135.669 7.42032H132.981C132.669 7.42032 132.391 7.209 132.31 6.9124C131.731 4.95112 130.178 3.83516 127.831 3.83516H127.531C124.094 3.83516 121.702 6.52682 121.702 11.0055C121.702 15.4842 124.094 18.1758 127.531 18.1758H127.831C130.178 18.1758 131.739 17.1043 132.31 15.0986C132.399 14.7983 132.669 14.5906 132.981 14.5906H135.669C136.099 14.5906 136.44 14.9762 136.369 15.3989C135.758 19.0879 132.84 21.7647 127.679 21.7647C120.783 21.7647 117.161 16.7781 117.724 9.57068H117.728L117.732 9.57809Z" />
            <path d="M138.869 20.7678V1.2515C138.869 0.865919 139.184 0.550781 139.569 0.550781H154.003C154.388 0.550781 154.704 0.865919 154.704 1.2515V3.42781C154.704 3.81339 154.388 4.12853 154.003 4.12853H143.603C143.218 4.12853 142.903 4.44367 142.903 4.82925V8.23274C142.903 8.62203 143.218 8.93346 143.603 8.93346H152.212C152.598 8.93346 152.913 9.2486 152.913 9.63418V11.8105C152.913 12.1998 152.598 12.5112 152.212 12.5112H143.603C143.218 12.5112 142.903 12.8263 142.903 13.2119V17.153C142.903 17.5423 143.218 17.8537 143.603 17.8537H154.299C154.685 17.8537 155 18.1689 155 18.5544V20.7307C155 21.12 154.685 21.4315 154.299 21.4315H139.569C139.18 21.4315 138.869 21.1163 138.869 20.7307V20.7493V20.7678Z" />
            <path d="M77.1459 10.9019C77.1459 4.23575 81.2946 0.235352 87.2192 0.235352C93.1438 0.235352 97.2925 4.23575 97.2925 10.9019C97.2925 17.568 93.1438 21.5684 87.2192 21.5684C81.2946 21.5684 77.1459 17.568 77.1459 10.9019ZM87.0709 18.0129H87.3675C91.0713 18.0129 93.2921 15.3472 93.2921 10.9019C93.2921 6.45655 91.0713 3.79085 87.3675 3.79085H87.0709C83.3671 3.79085 81.1463 6.45655 81.1463 10.9019C81.1463 15.3472 83.3671 18.0129 87.0709 18.0129Z" />
          </svg>
        </Link>

        <nav className={styles['navigation--main']}>
          <Link
            className={`${styles['parent-link']} ${
              pathname.includes('health-goals') ? styles.current : ''
            }`}
            href="/health-goals"
          >
            Health Goals
          </Link>

          <div
            className={`${styles['parent-link-wrapper']} ${
              desktopDropdownIsOpen ? styles.open : ''
            }
            ${
              pathname.includes('collections') || pathname.includes('product')
                ? styles.current
                : ''
            }
            `}
          >
            <button
              className={styles['parent-link']}
              onClick={(event) => toggleSolutionsDesktopDropdown()}
            >
              Solutions
            </button>

            <div className={styles.headerDesktopDropdown}>
              <div
                className={styles.headerDesktopDropdownCurtain}
                onClick={(event) => {
                  toggleSolutionsDesktopDropdown(false)
                }}
              ></div>

              <div className={styles.headerDesktopDropdownInner}>
                <div className="bg-off-white border-b border-dark-brown-40 sticky top-0 z-20 mb-16 min-h-[calc(var(--header-height)+1px)]" />

                <h2 className="eyebrow mt-16 mb-10">Health Tracking</h2>

                <nav className="h2 flex flex-col items-start gap-y-6 leading-[1.05] mt-10 mb-20">
                  <Link
                    href="/product/membership?variant=one-time-diagnostic#biomarkers"
                    className="block"
                  >
                    Diagnostic
                  </Link>

                  <Link
                    href="/product/membership#your-team"
                    className="flex gap-x-2"
                  >
                    <span>
                      Health Coaching
                      <br />+ Clinical Support
                    </span>
                    <span className="tag tag--gold self-end mb-6">
                      Members Only
                    </span>
                  </Link>
                </nav>

                <h2 className="eyebrow mt-16 mb-10">Products</h2>

                <nav className="h2 flex flex-col items-start gap-y-6">
                  <Link href="/collections/all" className="block">
                    Shop All
                  </Link>

                  <Link href="/collections/supplements" className="block">
                    Supplements
                  </Link>

                  <Link
                    href="/collections/pharmaceuticals"
                    className="flex gap-x-2"
                  >
                    <span>Pharmaceuticals</span>
                    <span className="tag tag--gold self-end mb-6">
                      Members Only
                    </span>
                  </Link>
                </nav>
              </div>
            </div>
          </div>

          <Link
            className={`${styles['parent-link']} ${
              pathname.includes('about') ? styles.current : ''
            }`}
            href="/about"
          >
            About
          </Link>
          <Link
            className={`${styles['parent-link']} ${
              pathname.includes('journal') ? styles.current : ''
            }`}
            href="/journal"
          >
            Journal
          </Link>
        </nav>

        <nav
          className={`${styles['navigation--secondary--mobile']} flex justify-self-end lg:hidden`}
        >
          <CartLinkButton count={itemsCount} />
          <div
            className={`mobile-menu self-center ${
              mobileMenuIsOpen ? 'mobile-menu--is-open' : ''
            }`}
          >
            <button
              className={`button ${styles.button} ${styles['menu-button']} list-none self-center ${styles.summary}`}
              onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
            >
              {mobileMenuIsOpen ? 'Close' : 'Menu'}
            </button>

            <AnimatePresence initial={true}>
              {mobileMenuIsOpen && (
                <motion.div
                  className={`mobile-menu__dropdown ${styles['mobile-menu__dropdown']}`}
                  initial="closed"
                  animate="opened"
                  exit="closed"
                  variants={{
                    closed: { opacity: 0 },
                    opened: { opacity: 1 },
                  }}
                  transition={{ duration: 0.5, ease: [0.4, 0, 0.2, 1] }}
                >
                  {user ? (
                    <p className="mt-10 mb-4">
                      <Link className="button button--full" href="/account">
                        Account
                      </Link>
                    </p>
                  ) : (
                    <>
                      <p className="mt-10 mb-4">
                        <Link
                          className="button button--full"
                          href="/product/membership"
                        >
                          Become a Member
                        </Link>
                      </p>
                      <p className="mt-4 mb-0">
                        <Link
                          className="button button--tertiary button--full"
                          href="/account/profile"
                        >
                          Login
                        </Link>
                      </p>
                    </>
                  )}

                  <nav className={`mt-14 ${styles['mobile-menu__nav']}`}>
                    <ul>
                      <li>
                        <Link className="h1" href="/health-goals">
                          Health Goals
                        </Link>
                      </li>
                      <li
                        className={`${
                          solutionsDropdownIsActive ? styles.open : ''
                        } flex flex-col pl-6`}
                      >
                        <button
                          className={`${styles.summary} h1 inline-flex -ml-6`}
                          onClick={() =>
                            setSolutionsDropdownIsActive(
                              !solutionsDropdownIsActive
                            )
                          }
                        >
                          Solutions
                        </button>
                        <AnimatePresence initial={true}>
                          {solutionsDropdownIsActive && (
                            <motion.div
                              className="overflow-hidden"
                              initial="collapsed"
                              animate="open"
                              exit="collapsed"
                              variants={{
                                open: { height: 'auto' },
                                collapsed: { height: 0 },
                              }}
                              transition={{
                                duration: 0.3,
                              }}
                            >
                              <h5 className="eyebrow mt-10 mb-6">
                                Health Tracking
                              </h5>

                              <ul className="flex flex-col gap-y-3">
                                <li>
                                  <Link
                                    className="h3 my-3"
                                    href="/product/membership?variant=one-time-diagnostic#biomarkers"
                                  >
                                    Diagnostic
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="h3 inline-flex gap-x-2 items-start"
                                    href="/product/membership#meet-your-team"
                                  >
                                    <span>
                                      Health Coaching +
                                      <br />
                                      Clinical Support
                                    </span>
                                    <span className="tag tag--gold">
                                      Members Only
                                    </span>
                                  </Link>
                                </li>
                              </ul>

                              <h5 className="eyebrow mt-7 mb-6">Products</h5>

                              <ul className="flex flex-col gap-y-3 mb-4">
                                <li>
                                  <Link
                                    className="h3 my-3"
                                    href="/collections/all"
                                  >
                                    Shop All
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="h3 my-3"
                                    href="/collections/supplements"
                                  >
                                    Supplements
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="h3 flex gap-x-2 items-start"
                                    href="/collections/pharmaceuticals"
                                  >
                                    <span>Pharmaceuticals</span>
                                    <span className="tag tag--gold">
                                      Members Only
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </li>
                      <li>
                        <Link className="h1" href="/about">
                          About
                        </Link>
                      </li>
                      <li>
                        <Link className="h1" href="/journal">
                          Journal
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </nav>

        <nav className={styles['navigation--secondary--desktop']}>
          {user ? (
            <div className="relative">
              <ClickOutside
                active={true}
                onClick={() => setShowUserDropdown(false)}
              >
                <div>
                  <button
                    className="button bg-transparent text-current hover:text-white hover:bg-dark-brown transition-colors duration-500"
                    onClick={() => setShowUserDropdown(!showUserDropdown)}
                    color="tertiary"
                  >
                    <span className="mx-2 whitespace-nowrap">Account</span>
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
                    </svg>
                  </button>
                  {showUserDropdown && (
                    <UserDropdown
                      onClose={() => closeAllDropdowns()}
                      userAuthed={!!user}
                    />
                  )}
                </div>
              </ClickOutside>
            </div>
          ) : (
            <Link className="self-center" href="/account/profile">
              Login
            </Link>
          )}
          <CartLinkButton count={itemsCount} />
          <Link className="button" href="/product/membership">
            Become a Member
          </Link>
        </nav>

        <hr className={`hairline ${styles.hairline}`} />
      </header>
    </>
  )
}

'use client'

import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import { storyblokEditable } from '@storyblok/react'

import useUser from '@lib/hooks/useUser'
import { EnrichedProduct, LF_CORE_MEMBERSHIP_SLUG } from '@lib/productCatalog'
import { StoryblokContent } from '@lib/storyblok'

import ConfirmStartPlan from '@components/common/Modals/ConfirmStartPlan'
import { Typography } from '@components/design'
import { CallToAction } from '@components/landers/CallToAction'

interface ProductHeroProps {
  blok: StoryblokContent
}

export default function CtaSection({ blok }: ProductHeroProps) {
  const {
    title,
    sub_title,
    call_to_action,
    product_object,
    image,
    extra_padding,
    cta_sub_title,
    source,
  } = blok
  const [product, setProduct] = useState<EnrichedProduct | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [isLogged, setIsLogged] = useState(false)
  const { user } = useUser()

  useEffect(() => {
    if (user) {
      setIsLogged(true)
    }
  }, [user])
  const handleShowModal = (product: EnrichedProduct) => {
    if (source === 'lifescore') {
      if (isLogged) {
        window.location.href = '/account/profile'
      } else {
        window.location.href = 'get-started'
      }
    }
    if (product && product.slug === LF_CORE_MEMBERSHIP_SLUG) {
      if (isLogged) {
        setProduct(product)
        setShowModal(true)
      } else {
        window.location.href = `/api/auth/login?returnTo=${encodeURIComponent(
          '/landers/core-membership?openModal=true'
        )}`
      }
    }
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className={`bg-lf-black-900 relative ${
        extra_padding ? 'py-16 md:py-32' : 'py-12'
      }`}
    >
      {image?.filename && (
        <Image
          src={image.filename}
          alt={image.alt}
          fill
          quality={100}
          className="object-cover opacity-25"
        />
      )}
      <div className="container relative flex flex-col items-center max-w-2xl px-6 mx-auto text-center">
        <Typography
          variant="h2"
          component="h2"
          className="text-center uppercase"
          color="white"
        >
          {title}
        </Typography>
        {sub_title && <p className="mt-5 text-rich-white-400">{sub_title}</p>}
        {call_to_action && (
          <div className="flex flex-col items-center w-full mt-10 md:w-auto">
            <CallToAction
              data={call_to_action}
              onAction={handleShowModal}
              source={source}
            />
            {cta_sub_title && (
              <p className="mt-2 text-sm uppercase text-rich-white-200">
                {cta_sub_title}
              </p>
            )}
          </div>
        )}
        {product && showModal && (
          <ConfirmStartPlan
            onClosed={async () => setShowModal(false)}
            onConfirm={async () => setShowModal(false)}
            products={[product]}
            offerPromoCode={null}
          />
        )}
      </div>
    </div>
  )
}

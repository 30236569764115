import React from 'react'

export interface DropdownDividerProps {
  className?: string
}

const DropdownDivider: React.FC<DropdownDividerProps> = ({className}: DropdownDividerProps) => {
  return (<hr className={`border-brown/10 ${className}`} />)
}

export default DropdownDivider

import { useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import { renderRichText } from '@storyblok/react'
import classNames from 'classnames'
import { AnimatePresence, motion, useInView } from 'framer-motion'

import { getImageAspectRatio } from '../helper'
import { slugify } from '../PurchaseModule/PurchaseNav'

type Block = {
  title?: string
  // TODO: address richtext type
  text?: any
  image?: any
}

type DynamicTextImageModule = {
  blok?: {
    title?: string
    color?: 'light-sage' | 'light-beige'
    content?: Block[]
    add_margins?: boolean
    isFirstDrawerOpen?: boolean
  }
  setActiveSection?: (section: string) => void
}

type NestedBlockProps = {
  block: Block
  index: number
  isFirstDrawerOpen?: boolean
  totalBlocks: number
}

function NestedBlock({
  block,
  index,
  isFirstDrawerOpen,
  totalBlocks,
}: NestedBlockProps) {
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(
    index === 0 && (isFirstDrawerOpen || false)
  )

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen)
  }

  return (
    <div
      key={index}
      className={`border-t pb-2 ${
        index === totalBlocks - 1 ? 'border-b' : ''
      } border-dark-brown-40 mt-[-1px]`}
    >
      <button
        aria-label={block.title}
        onClick={toggleDrawer}
        className={classNames('w-full relative text-left py-4 pt-5 ')}
      >
        <div
          className={classNames(
            'richtext sm:mb-0 transition-opacity duration-300 ease-in-out',
            isDrawerOpen ? 'opacity-100' : 'opacity-50  hover:opacity-75'
          )}
        >
          <h4 className="leading-none mb-0 pr-10 max-w-[700px]">
            {block?.title}
          </h4>
        </div>
        <svg
          className="absolute right-0 top-[calc(50%+0.25rem)] transform -translate-y-1/2"
          width="30"
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="20"
            cy="20.5002"
            r="19.5"
            stroke="#26180F"
            strokeOpacity="0.4"
          />
          <line x1="11" y1="20.5" x2="29" y2="20.5" stroke="#26180F" />
          <line
            x1="20"
            y1="11.5"
            x2="20"
            y2="29.5"
            stroke="#26180F"
            className={`transition-opacity duration-200 ease-in-out ${
              isDrawerOpen ? 'opacity-0' : 'opacity-100'
            }`}
          />
        </svg>
      </button>
      <AnimatePresence initial={true}>
        {isDrawerOpen && (
          <motion.div
            className="overflow-hidden"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: 'auto' },
              collapsed: { height: 0 },
            }}
            transition={{
              duration: 0.3,
            }}
          >
            <div className="mt-0 text-[1rem] mb-2 sm:mb-0 pr-4 pb-6 max-w-[780px]">
              {block?.text && (
                <div
                  className="richtext"
                  dangerouslySetInnerHTML={{
                    __html: renderRichText(block?.text),
                  }}
                />
              )}
              {block?.image && block?.image.filename && (
                <figure
                  className="relative block mt-4 lg:mt-6"
                  style={{ aspectRatio: `${getImageAspectRatio(block.image)}` }}
                >
                  <Image
                    src={block.image.filename}
                    fill={true}
                    alt={block.image.alt}
                    className="object-cover object-center rounded overflow-hidden"
                  />
                </figure>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default function DynamicTextImageModule(props: DynamicTextImageModule) {
  const { blok, setActiveSection } = props

  const ref = useRef<HTMLDivElement>(null)
  const inView = useInView(ref)

  useEffect(() => {
    if (inView && blok?.title) setActiveSection?.(blok.title)
  }, [inView, blok?.title, setActiveSection])

  return (
    <section
      className="section w-full col-span-full"
      id={slugify(blok?.title || '')}
      ref={ref}
    >
      <div
        className={classNames(
          'p-4 pb-8 sm:p-6 sm:pb-10 col-span-full rounded-[4px]',
          blok?.color === 'light-sage' ? 'bg-light-sage' : 'bg-light-beige',
          blok?.add_margins === true ? 'mx-4 lg:mx-6' : ''
        )}
      >
        <header className="grid grid-cols-2 gap-4 w-full pb-4">
          {blok?.title && (
            <div className="eyebrow text-dark-brown mb-4 sm:mt-0 sm:mb-8">
              {blok?.title}
            </div>
          )}
        </header>

        {blok?.content?.map((block: Block, index: number) => (
          <NestedBlock
            key={index}
            block={block}
            index={index}
            isFirstDrawerOpen={index === 0 ? blok?.isFirstDrawerOpen : false}
            totalBlocks={blok?.content?.length || 0}
          />
        ))}
      </div>
    </section>
  )
}

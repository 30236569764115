export const fetcherWithError = <TResponse extends object>(
  url: string,
  opts?: RequestInit
) =>
  fetch(url, opts)
    .then((res) => {
      return Promise.all([res, res.json()])
    })
    .then(([resp, body]) => {
      if (resp.status >= 400) {
        throw new Error(body.message)
      }
      return body as TResponse
    })

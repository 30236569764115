import { UserProfile } from '@auth0/nextjs-auth0/client'
import useSWR from 'swr'

// @ts-ignore
const fetcher = (...args) => fetch(...args).then((res) => res.json())

const useUser = (forceRefetch = false) => {
  const { data, mutate, error, isValidating } = useSWR(
    `/api/auth/me${forceRefetch ? '?refetch' : ''}`,
    fetcher
  )

  const user: UserProfile | null = ((u) => {
    if (!u) {
      return null
    }
    const nu: UserProfile = { ...u }
    nu.name =
      nu?.given_name && nu?.family_name
        ? `${nu.given_name} ${nu.family_name}`
        : null
    return nu
  })(data)
  return {
    // Gave this user type as any because specifying 'UserProfile | null' type will require a shotgun therapy. May be we can do this in a separate PR
    user: user as any,
    error,
    isLoading: (!data && !error) || isValidating,
    refetch: async () => await mutate(),
  }
}

export default useUser

'use client'

import React, { useEffect, useRef, useState } from 'react'
import Image, { getImageProps } from 'next/image'
import { storyblokEditable } from '@storyblok/react/rsc'

import { StoryblokContent } from '@lib/storyblok'

import './ImageSlideshow.css'

interface Props {
  blok: StoryblokContent
}

export default function ImageSlideshow({ blok }: Props) {
  const { slides } = blok
  const carouselRef = useRef<HTMLDivElement>(null)
  const [activeSlide, setActiveSlide] = useState(0)

  const handleThumbnailClick = (index: number) => {
    if (carouselRef.current) {
      const scrollPosition = index * carouselRef.current.clientWidth
      carouselRef.current.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (carouselRef.current) {
        const scrollLeft = carouselRef.current.scrollLeft
        const clientWidth = carouselRef.current.clientWidth
        const newActiveSlide = Math.round(scrollLeft / clientWidth)
        if (newActiveSlide !== activeSlide) {
          setActiveSlide(newActiveSlide)
        }
      }
    }

    const carousel = carouselRef.current

    if (carousel) {
      carousel.addEventListener('scroll', handleScroll)
      return () => {
        carousel.removeEventListener('scroll', handleScroll)
      }
    }
  }, [activeSlide])

  return (
    <div
      className={`hero-slideshow bg-off-white relative z-0 ${
        slides[activeSlide].darkMode ? 'dark-mode' : ''
      }`}
      {...storyblokEditable}
    >
      {slides && (
        <div
          ref={carouselRef}
          className="relative aspect-[75/94] lg:aspect-[unset] lg:w-full lg:h-[100svh] flex flex-row flex-nowrap overflow-x-auto snap-x snap-mandatory no-scrollbar"
        >
          {slides.map((slide: any, index: number) => {
            const common = {
              alt: slide.image.alt || 'Image Alt Text',
              sizes: '100vw',
            }
            const {
              props: { srcSet: desktop },
            } = getImageProps({
              ...common,
              width: 1440,
              height: 875,
              quality: 100,
              src: slide.image.filename,
            })
            const {
              props: { srcSet: mobile, ...rest },
            } = getImageProps({
              ...common,
              width: 750,
              height: 1334,
              quality: 70,
              src:
                slide.mobileImage && slide.mobileImage.filename
                  ? slide.mobileImage.filename
                  : slide.image.filename,
            })

            return (
              <div
                key={index}
                className="block w-full flex-shrink-0 snap-center"
              >
                <figure className="relative w-full h-full">
                  <picture>
                    <source media="(min-width: 1000px)" srcSet={desktop} />
                    <source media="(min-width: 500px)" srcSet={mobile} />
                    <img
                      className="absolute inset-0 w-full h-full object-cover object-center"
                      {...rest}
                      alt={slide.image.alt || 'Image Alt Text'}
                    />
                  </picture>
                </figure>
              </div>
            )
          })}
        </div>
      )}

      <div className="absolute bottom-4 left-4 lg:bottom-8 lg:left-6 flex flex-col gap-2 lg:gap-4">
        {slides &&
          slides.map((slide: any, index: number) => (
            <button
              key={index}
              className="relative block cursor-pointer"
              onClick={() => handleThumbnailClick(index)}
            >
              <Image
                src={
                  slide.thumbnail && slide.thumbnail.filename
                    ? slide.thumbnail.filename
                    : slide.mobileImage?.filename || slide.image.filename
                }
                fill={false}
                width={80}
                height={80}
                alt={slide.mobileImage?.alt || 'Image Alt Text'}
                className={`w-10 h-10 lg:w-16 lg:h-16 object-cover object-bottom rounded border transition-colors duration-200 ease-in-out overflow-hidden ${
                  index === activeSlide
                    ? 'border-dark-brown'
                    : 'border-transparent'
                }`}
              />
            </button>
          ))}
      </div>
    </div>
  )
}

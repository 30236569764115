const Logo = ({ className = '', ...props }) => (
  <svg
    width="452"
    height="63"
    viewBox="0 0 452 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M0 60.2405V3.09215C0 1.96308 0.919502 1.04028 2.04454 1.04028H9.71427C10.8393 1.04028 11.7588 1.96308 11.7588 3.09215V49.7315C11.7588 50.8714 12.6783 51.7833 13.8034 51.7833H44.1469C45.2828 51.7833 46.1915 52.7061 46.1915 53.8352V60.208C46.1915 61.3479 45.272 62.2598 44.1469 62.2598H2.04454C0.908684 62.2598 0 61.337 0 60.208V60.2405Z"
      fill="#F4F4EA"
    />
    <path
      d="M51.7822 60.2405V3.09215C51.7822 1.96308 52.7017 1.04028 53.8268 1.04028H61.4965C62.6324 1.04028 63.541 1.96308 63.541 3.09215V60.2405C63.541 61.3696 62.6215 62.2924 61.4965 62.2924H53.8268C52.7017 62.2924 51.7822 61.3696 51.7822 60.2405Z"
      fill="#F4F4EA"
    />
    <path
      d="M73.5297 60.2405V3.09215C73.5297 1.96308 74.4492 1.04028 75.5743 1.04028H115.946C117.071 1.04028 117.99 1.96308 117.99 3.09215V9.46489C117.99 10.594 117.071 11.5168 115.946 11.5168H87.3439C86.2189 11.5168 85.2994 12.4396 85.2994 13.5686V25.1959C85.2994 26.325 86.2189 27.2478 87.3439 27.2478H111.575C112.711 27.2478 113.62 28.1706 113.62 29.2996V35.6724C113.62 36.8123 112.701 37.7242 111.575 37.7242H87.3439C86.2189 37.7242 85.2994 38.647 85.2994 39.7761V60.1645C85.2994 61.3045 84.3799 62.2164 83.2548 62.2164H75.5851C74.4492 62.2164 73.5405 61.2936 73.5405 60.1645V60.208L73.5297 60.2405Z"
      fill="#F4F4EA"
    />
    <path
      d="M124.276 60.2405V3.09215C124.276 1.96308 125.196 1.04028 126.321 1.04028H168.434C169.559 1.04028 170.478 1.96308 170.478 3.09215V9.46489C170.478 10.594 169.559 11.5168 168.434 11.5168H138.09C136.965 11.5168 136.046 12.4396 136.046 13.5686V23.5349C136.046 24.6748 136.965 25.5867 138.09 25.5867H163.187C164.312 25.5867 165.232 26.5095 165.232 27.6386V34.0113C165.232 35.1512 164.312 36.0632 163.187 36.0632H138.09C136.965 36.0632 136.046 36.986 136.046 38.1151V49.6555C136.046 50.7954 136.965 51.7073 138.09 51.7073H169.299C170.435 51.7073 171.344 52.6301 171.344 53.7592V60.1319C171.344 61.2719 170.424 62.1838 169.299 62.1838H126.321C125.185 62.1838 124.276 61.261 124.276 60.1319V60.1862V60.2405Z"
      fill="#F4F4EA"
    />
    <path
      d="M178.129 60.2405V3.09215C178.129 1.96308 179.048 1.04028 180.173 1.04028H220.545C221.68 1.04028 222.589 1.96308 222.589 3.09215V9.46489C222.589 10.594 221.67 11.5168 220.545 11.5168H191.943C190.818 11.5168 189.898 12.4396 189.898 13.5686V25.1959C189.898 26.325 190.818 27.2478 191.943 27.2478H216.174C217.31 27.2478 218.219 28.1706 218.219 29.2996V35.6724C218.219 36.8123 217.299 37.7242 216.174 37.7242H191.943C190.818 37.7242 189.898 38.647 189.898 39.7761V60.1645C189.898 61.3045 188.979 62.2164 187.854 62.2164H180.184C179.059 62.2164 178.139 61.2936 178.139 60.1645V60.208L178.129 60.2405Z"
      fill="#F4F4EA"
    />
    <path
      d="M291.013 60.2405V3.09215C291.013 1.96308 291.933 1.04028 293.058 1.04028H319.345C329.805 1.04028 338.092 8.04269 338.092 17.6724C338.092 23.3937 336.253 27.6494 332.521 30.5373C331.277 31.4926 331.45 33.4251 332.899 34.0547C335.885 35.3575 337.237 38.0282 337.237 41.8063V60.2296C337.237 61.3587 336.318 62.2815 335.193 62.2815H327.523C326.387 62.2815 325.478 61.3587 325.478 60.2296V46.8328C325.478 42.5553 322.017 39.0813 317.754 39.0813H304.86C303.735 39.0813 302.815 40.0041 302.815 41.1331V60.2079C302.815 61.3479 301.896 62.2598 300.771 62.2598H293.101C291.965 62.2598 291.056 61.337 291.056 60.2079L291.013 60.2405ZM302.783 26.7158C302.783 27.8449 303.702 28.7677 304.827 28.7677H315.851C322.828 28.7677 326.311 25.6193 326.311 19.9305C326.311 14.2526 322.828 11.5276 315.851 11.5276H304.827C303.702 11.5276 302.783 12.4504 302.783 13.5795V26.6941V26.7158Z"
      fill="#F4F4EA"
    />
    <path
      d="M344.018 27.3148C345.197 12.0615 357.865 -0.0108491 373.129 7.31648e-06C388.09 0.0108638 396.581 8.18577 398.399 18.608C398.615 19.8673 397.62 20.9964 396.354 20.9964H388.511C387.603 20.9964 386.791 20.3776 386.553 19.5091C384.866 13.766 380.333 10.4982 373.486 10.4982H372.609C362.581 10.4982 355.604 18.38 355.604 31.4946C355.604 44.6092 362.581 52.491 372.609 52.491H373.486C380.333 52.491 384.887 49.3534 386.553 43.4801C386.813 42.6007 387.603 41.9928 388.511 41.9928H396.354C397.609 41.9928 398.604 43.1218 398.399 44.3595C396.614 55.1616 388.1 63 373.042 63C352.921 63 342.352 48.3981 343.997 27.2931H344.007L344.018 27.3148Z"
      fill="#F4F4EA"
    />
    <path
      d="M404.932 60.2405V3.09215C404.932 1.96308 405.852 1.04028 406.977 1.04028H449.09C450.215 1.04028 451.135 1.96308 451.135 3.09215V9.46489C451.135 10.594 450.215 11.5168 449.09 11.5168H418.747C417.621 11.5168 416.702 12.4396 416.702 13.5686V23.5349C416.702 24.6748 417.621 25.5867 418.747 25.5867H443.865C444.99 25.5867 445.91 26.5095 445.91 27.6386V34.0113C445.91 35.1512 444.99 36.0632 443.865 36.0632H418.747C417.621 36.0632 416.702 36.986 416.702 38.1151V49.6555C416.702 50.7954 417.621 51.7073 418.747 51.7073H449.956C451.081 51.7073 452 52.6301 452 53.7592V60.1319C452 61.2719 451.081 62.1838 449.956 62.1838H406.977C405.841 62.1838 404.932 61.261 404.932 60.1319V60.1862V60.2405Z"
      fill="#F4F4EA"
    />
    <path
      d="M224.732 31.234C224.732 11.7141 236.837 0 254.124 0C271.41 0 283.515 11.7141 283.515 31.234C283.515 50.7539 271.41 62.468 254.124 62.468C236.837 62.468 224.732 50.7539 224.732 31.234ZM253.691 52.0567H254.556C265.363 52.0567 271.843 44.2509 271.843 31.234C271.843 18.2171 265.363 10.4113 254.556 10.4113H253.691C242.884 10.4113 236.404 18.2171 236.404 31.234C236.404 44.2509 242.884 52.0567 253.691 52.0567Z"
      fill="#F4F4EA"
    />
  </svg>
)

export default Logo

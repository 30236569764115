export default function TextSlide({
  blok,
  isActive,
}: {
  blok: any
  isActive: boolean
}) {
  const { text } = blok

  return (
    <span
      className={`col-start-1 row-start-1 transition-opacity duration-300 ease ${
        isActive ? 'opacity-1' : 'opacity-0'
      }`}
    >
      {text}
    </span>
  )
}

import Image from 'next/image'
import classNames from 'clsx'

import { AuthorContent } from '@lib/blog'

interface Props {
  className?: string
  author: AuthorContent
}

const AUTHOR_IMAGE_SIZE = 80

const BlogPostAuthorCard = ({ className, author }: Props) => {
  return (
    <div className={classNames('flex items-start mt-6 lg:mt-8', className)}>
      <div className="flex-initial mr-4">
        {author?.photo?.filename ? (
          <Image
            className="rounded overflow-hidden"
            alt={`photo of ${author.name}`}
            src={author.photo.filename}
            height={AUTHOR_IMAGE_SIZE}
            width={AUTHOR_IMAGE_SIZE}
          />
        ) : (
          <div
            className="bg-dark-brown-40 rounded"
            style={{
              height: `${AUTHOR_IMAGE_SIZE}px`,
              width: `${AUTHOR_IMAGE_SIZE}px`,
            }}
          >
            <span></span>
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <span className="eyebrow text-dark-brown-65 block mb-2 lg:mb-3">
          Written By
        </span>
        <span className="font-medium body">{author?.name}</span>
      </div>
    </div>
  )
}

export default BlogPostAuthorCard

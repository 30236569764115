import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'

import { StoryblokContent } from '@lib/storyblok'

export default function Menu({ blok }: { blok: StoryblokContent }) {
  const { title, links } = blok

  return (
    <nav className="menuu" {...storyblokEditable(blok)}>
      <p className="menu__title opacity-60 block mt-10 mb-8 md:mt-0 md:mb-6 eyebrow">
        {title}
      </p>
      <ul>
        {links.map((nestedBlok: StoryblokContent) => (
          <li key={nestedBlok._uid}>
            <StoryblokComponent blok={nestedBlok} />
          </li>
        ))}
      </ul>
    </nav>
  )
}

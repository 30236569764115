'use client'

import Image from 'next/image'
import Link from 'next/link'
import { renderRichText, storyblokEditable } from '@storyblok/react/rsc'
import { motion } from 'framer-motion'

import { StoryblokContent } from '@lib/storyblok'

import Button from '@components-v7/design/Button'

import styles from './TextWithImage.module.css'

interface Props {
  blok: StoryblokContent
}

export default function TextWithImage({ blok }: Props) {
  const { body, indentText, image1, image2, image3 } = blok

  const container = {
    hidden: {
      opacity: 0,
      y: '20px',
    },
    show: {
      opacity: 1,
      y: '0',
      transition: {
        duration: 0.6,
        staggerChildren: 0.075,
      },
    },
  }

  const item = {
    hidden: { opacity: 0, y: '20px' },
    show: { opacity: 1, y: '0' },
  }

  return (
    <div
      className="text-with-image section bg-off-white px-4 lg:px-6 flex flex-col lg:grid lg:grid-cols-2 lg:items-center gap-10 lg:gap-14 container mx-auto"
      {...storyblokEditable(blok)}
    >
      <div
        className={`w-full richtext ${indentText ? styles.indentedText : ''}`}
        dangerouslySetInnerHTML={{ __html: renderRichText(body) }}
      />
      <motion.div
        variants={container}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.5 }}
        className="grid grid-cols-8 lg:grid-cols-12 grid-rows-2 gap-x-4 w-full"
      >
        <motion.div
          variants={item}
          key={0}
          className="relative w-full aspect-[4/3] col-start-2 col-span-6 lg:col-start-3 lg:col-span-8 row-start-1 row-span-1 rounded overflow-hidden"
        >
          <Image
            src={image1.filename}
            fill={true}
            sizes="(min-width: 1024px) 33vw, 75vw"
            alt={image1.alt}
            className="object-cover object-center"
          />
        </motion.div>
        <motion.div
          variants={item}
          key={1}
          className="relative w-full aspect-[1/1] row-start-2 row-span-1 col-start-1 col-span-4 mt-[-3rem] lg:mt-[-2rem] rounded overflow-hidden"
        >
          <Image
            alt={image2.alt}
            className="object-cover object-center"
            fill={true}
            sizes="(min-width: 1024px) 16vw, 50vw"
            src={image2.filename}
          />
        </motion.div>
        <motion.div
          variants={item}
          key={2}
          className="relative w-full aspect-[3/4] row-start-2 row-span-1 col-start-5 col-span-4 lg:col-start-8 rounded overflow-hidden lg:mt-10 lg:ml-4"
        >
          <Image
            alt={image3.alt}
            className="object-cover object-center"
            fill={true}
            sizes="(min-width: 1024px) 16vw, 50vw"
            src={image3.filename}
          />
        </motion.div>
      </motion.div>
    </div>
  )
}

import { Claims } from '@auth0/nextjs-auth0'
import type { NextApiRequest } from 'next'

import { getCookie } from '@lib/helpers/cookieUtils'

export function getFirstName(user: any): string {
  if (user && user.given_name) {
    return user.given_name
  } else if (user && user.name) {
    return user.name.split(' ')[0]
  } else {
    return ''
  }
}

export const isReportImpersonationEnabled = (user?: Claims) => {
  return user?.['https://mylifeforce.com/claims/app_metadata']?.['reportImpersonation']
}

export const impersonatingUserId = (user?: Claims, req?: NextApiRequest) => {
  if (isReportImpersonationEnabled(user)) {
    const cookieUserId = getCookie('impersonating', req);
    if (cookieUserId) {
      return cookieUserId;
    }
  }

  const appMetadata = user?.['https://mylifeforce.com/claims/app_metadata']

  if (appMetadata?.['timedImpersonation'] && appMetadata?.['timedImpersonationExpires']) {
    const expirationTime = parseInt(appMetadata['timedImpersonationExpires'], 10)
    if (expirationTime > Date.now()) {
      return appMetadata['timedImpersonation']
    }
  }

  return appMetadata?.['impersonating']
}

export const getUserId = (user?: Claims, req?: NextApiRequest) => {
  return impersonatingUserId(user, req) ?? user?.sub
}

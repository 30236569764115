'use client'

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { AccountResult } from '@pages/api/account'
import { renderRichText } from '@storyblok/react'
import classNames from 'classnames'
import { motion, useInView } from 'framer-motion'
import useSWR from 'swr'

import { useAddItem } from '@framework/cart'
import commerceFetcher from '@framework/fetcher'
import useSWRCart from '@commerce/cart/use-swr-cart'
import { formatPrice } from '@commerce/product/use-price'
import { CartItemBody, LineItem } from '@commerce/types/cart'
import { Product } from '@commerce/types/product'
import { useMembershipData } from '@lib/context/MembershipContext'
import { trackAddToCart } from '@lib/datalayer'
import { isMembershipInCart } from '@lib/helpers/cart'
import useUser from '@lib/hooks/useUser'
import {
  DEFAULT_SETUP_TOTAL,
  EnrichedProduct,
  LF_CLASSIC_MEMBERSHIP_MONTHLY_PRICE,
  LF_MEMBERSHIP_SKU,
  PHARMA_SKUS,
} from '@lib/productCatalog'
import { StoryblokContent } from '@lib/storyblok'

import Button from '@components/design/Button'
import useToast from '@components/ui/Toast'

import { useUI } from '../../components/ui'
import { fetcherWithError } from '../../lib/fetchWithError'
import { ExtendedProduct } from '../../types/storyblok'
import PurchaseNav from './PurchaseNav'
import PurchaseOption from './PurchaseOption'
import { SubscriptionsResponse } from '@/types/subscription'

import styles from './PurchaseModule.module.css'

type ProductOptionWithPrice = {
  sku?: string
  productId: string
  variantId?: string
  label: string
  optionId: string
  optionValue: string
  price: string
}

type SelectedOption = {
  sku?: string
  productId: string
  variantId?: string
  optionId?: string
  optionValue?: string
}

export default function PurchaseModule({
  mobile = false,
  modifiers = '',
  activeSection,
  isBeforeScroll,
  navIsBeforeScroll,
  setIsDrawerOpen,
  drawerIsOpen,
  hideHeader,
  mainMobile,
  blok,
  product,
  diagnostic,
  selectedOption,
  setSelectedOption,
}: {
  mobile?: boolean
  activeSection?: string | null
  isBeforeScroll: boolean
  navIsBeforeScroll: boolean
  modifiers?: string
  setIsDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>
  drawerIsOpen?: boolean
  hideHeader?: boolean
  classNames?: string
  mainMobile?: boolean
  // TODO fix type
  blok: StoryblokContent
  product: Product
  diagnostic?: Product
  selectedOption: SelectedOption | null
  setSelectedOption: React.Dispatch<React.SetStateAction<SelectedOption | null>>
}) {
  const { user } = useUser()
  const toast = useToast()
  const [ctaLoading, setCtaLoading] = useState(false)
  const [productOptionsWithPrices, setProductOptionsWithPrices] = useState<
    ProductOptionWithPrice[]
  >([])

  const searchParams = Object.fromEntries(useSearchParams()!.entries())
  const previousVariantRef = useRef<string | undefined>()

  const addItemToCart = useAddItem()
  const { data: cart, isLoading: isCartLoading, refreshCart } = useSWRCart()
  const { canBuyMembershipWithoutDx, membershipData } = useMembershipData()
  const { data: accountData } = useSWR<AccountResult>(
    '/api/account',
    fetcherWithError
  )
  const { data: subscriptionData, error: subscriptionError } =
    useSWR<SubscriptionsResponse>(
      '/api/account/all-subscriptions',
      fetcherWithError
    )

  const isSubscriptionLoading = !subscriptionError && !subscriptionData

  const [showBottomBar, setShowBottomBar] = useState(false)
  const ref = React.useRef<HTMLDivElement>(null)
  const inView = useInView(ref)

  const { toggleSidebar } = useUI()

  useEffect(() => {
    if (inView) {
      setShowBottomBar(true)
    } else {
      setShowBottomBar(false)
    }
  }, [inView])

  const allProductOptions = useMemo(() => {
    return product?.options
      .map((option) => {
        if (option.displayName.includes('Frequency')) {
          // @ts-ignore
          return option.values.map((value: ProductOption) => {
            return {
              sku: product.sku,
              productId: product.id,
              variantId:
                product.variants?.length > 0
                  ? product.variants[0].id
                  : undefined,
              label: value.label,
              optionId: option.id,
              optionValue: value.entityId,
            }
          })
        }

        return []
      })
      .filter((v) => v.length > 0)
      .reduce((acc, currentArray) => [...acc, ...currentArray], [])
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [product?.options])

  const membershipSelectOption = useMemo(() => {
    return {
      sku: product.sku,
      productId: product.id,
      variantId: String(product.variants[0].id),
      optionId: productOptionsWithPrices.find((p) => p.productId === product.id)
        ?.optionId,
      optionValue: productOptionsWithPrices.find(
        (p) => p.productId === product.id
      )?.optionValue,
    }
  }, [product, productOptionsWithPrices])

  const diagnosticSelectOption = useMemo(() => {
    return {
      sku: diagnostic?.sku ?? '',
      productId: diagnostic?.id ?? '',
      variantId: String(diagnostic?.variants[0].id),
    }
  }, [diagnostic])

  useEffect(() => {
    if (allProductOptions.length === 0) return
    if (selectedOption) return

    if (
      diagnosticSelectOption?.sku &&
      searchParams.variant === 'one-time-diagnostic'
    ) {
      setSelectedOption(diagnosticSelectOption)
    } else {
      setSelectedOption({
        sku: allProductOptions[0].sku,
        productId: allProductOptions[0].productId,
        variantId: String(allProductOptions[0].variantId),
        optionId: allProductOptions[0].optionId,
        optionValue: allProductOptions[0].optionValue,
      })
    }
  }, [allProductOptions, selectedOption, diagnosticSelectOption, searchParams])

  useEffect(() => {
    if (
      diagnosticSelectOption?.sku &&
      searchParams.variant &&
      searchParams.variant === 'one-time-diagnostic' &&
      previousVariantRef.current !== searchParams.variant
    ) {
      setSelectedOption(diagnosticSelectOption)
      previousVariantRef.current = searchParams.variant
    }
  }, [selectedOption, diagnosticSelectOption, searchParams])

  useEffect(() => {
    if (allProductOptions.length === 0) return
    if (productOptionsWithPrices.length === allProductOptions.length) return

    allProductOptions?.forEach(async (option) => {
      if (
        productOptionsWithPrices.some(
          (p) => p.optionValue === option.optionValue
        )
      )
        return
      const data = await commerceFetcher({
        url: '/api/product/pricing',
        method: 'POST',
        body: {
          products: [
            {
              productId: Number(product.id),
              options: [
                {
                  option_id: option.optionId,
                  option_value: option.optionValue,
                },
              ],
            },
          ],
        },
      })

      setProductOptionsWithPrices((prevItems) => {
        if (prevItems.some((p) => p.optionValue === option.optionValue)) {
          return prevItems
        }

        return [
          ...prevItems,
          {
            sku: product.sku,
            productId: product.id,
            label: option.label,
            optionId: option.optionId,
            optionValue: option.optionValue,
            variantId:
              product.variants?.length > 0
                ? String(product.variants[0].id)
                : undefined,
            price: formatPrice({
              amount: data.productPrices[product.id],
              currencyCode: 'USD',
              locale: 'en-US',
            }),
          },
        ]
      })
    })
  }, [allProductOptions, productOptionsWithPrices])

  const isSelectedOptionRx = useMemo(() => {
    if (!selectedOption) return false

    return PHARMA_SKUS.includes(selectedOption!.sku!.toUpperCase())
  }, [selectedOption])

  const isSelectedOptionAllowedRx = useMemo(() => {
    if (!selectedOption) return false

    return (
      accountData?.allowedRx?.includes(
        selectedOption!.sku!.toLowerCase() as string
      ) &&
      (accountData?.isMember || isMembershipInCart(cart!))
    )
  }, [selectedOption, accountData, cart])

  const hasProductSubscription = !!subscriptionData?.products?.find(
    (p: EnrichedProduct) => {
      return (
        p.bcProduct.id == selectedOption?.productId &&
        p.userSubscribed?.some(
          (s) =>
            s.state === 'active' ||
            s.state === 'queued_for_activation' ||
            s.state === 'future'
        )
      )
    }
  )

  const { name, long_description, short_description, tags, category } = blok

  const getOptionCrossThroughPrice = (option: ProductOptionWithPrice) => {
    if (option.label === 'Monthly') {
      const oneTime = productOptionsWithPrices?.find(
        (p) => p.label === 'One-time'
      )
      if (oneTime) {
        return oneTime.price
      }
    }

    return undefined
  }

  const isSelectedProductInCart = cart?.lineItems?.some((item: LineItem) => {
    if (item.productId === selectedOption?.productId) {
      if ((item.options?.length ?? 0) > 0) {
        if (
          item.options![0].nameId === selectedOption?.optionId &&
          item.options![0].valueId === selectedOption?.optionValue
        ) {
          return true
        }
      } else {
        return true
      }
    }

    return false
  })

  const determineIsOptionInCart = (option: SelectedOption) => {
    return cart?.lineItems?.some((item: LineItem) => {
      if (item.productId === option?.productId) {
        if ((item.options?.length ?? 0) > 0) {
          if (
            item.options![0].nameId === option?.optionId &&
            item.options![0].valueId === option?.optionValue
          ) {
            return true
          }
        } else {
          return true
        }
      }

      return false
    })
  }

  const isOneTimePurchase = useMemo(() => {
    // find the option that has the label 'One-time'
    const oneTimeOption = productOptionsWithPrices.find(
      (option) => option.label === 'One-time'
    )

    if (!oneTimeOption) return false

    return selectedOption?.optionValue === oneTimeOption.optionValue
  }, [selectedOption, productOptionsWithPrices])

  const shouldOpenMiniCart = useMemo(() => {
    if (isSelectedProductInCart) return true

    if (category === 'membership' || category === 'diagnostic') {
      if (
        determineIsOptionInCart(membershipSelectOption) ||
        determineIsOptionInCart(diagnosticSelectOption)
      ) {
        return true
      }
    }

    return false
  }, [
    category,
    membershipSelectOption,
    diagnosticSelectOption,
    cart?.lineItems,
    isSelectedProductInCart,
  ])

  const isCtaDisabled = useMemo(() => {
    if (isCartLoading) return true

    if (category === 'membership' || category === 'diagnostic') {
      if (membershipData?.isMember) return true

      if (
        selectedOption?.productId === diagnostic?.id &&
        (membershipData?.diagnostic_codes?.length ?? 0) > 0
      ) {
        return true
      }
    } else {
      if (isSelectedOptionRx && !isSelectedOptionAllowedRx) return true

      return hasProductSubscription && !isOneTimePurchase
    }

    return false
  }, [
    isCartLoading,
    isSelectedProductInCart,
    isSubscriptionLoading,
    category,
    selectedOption,
    diagnostic,
    membershipData,
    subscriptionData,
    isSelectedOptionRx,
    isSelectedOptionAllowedRx,
    hasProductSubscription,
    membershipSelectOption,
    diagnosticSelectOption,
    isOneTimePurchase,
  ])

  const rxAllowedStates = useMemo(() => {
    let states: string | undefined
    product?.customFields?.edges.forEach((edge) => {
      if (edge?.node?.name === 'allowedStates') {
        states = edge.node.value
      }
    })
    return states
  }, [product])

  const onCtaClick = async () => {
    if (shouldOpenMiniCart) {
      toggleSidebar()
      return
    }

    if (isSelectedOptionRx) {
      if (
        rxAllowedStates &&
        accountData?.address &&
        !rxAllowedStates.includes(accountData?.address.region as string)
      ) {
        return toast.error(
          `We're sorry, but this product is not available in the state of ${accountData?.address.region}.`
        )
      }
    }

    try {
      setCtaLoading(true)

      if (
        selectedOption?.sku?.toLowerCase() ===
          LF_MEMBERSHIP_SKU.toLowerCase() &&
        canBuyMembershipWithoutDx
      ) {
        return (window.location.href = '/account/profile')
      }

      const addToCartBody: CartItemBody = {
        productId: selectedOption!.productId,
        variantId: String(selectedOption!.variantId),
        quantity: 1,
        optionSelections: selectedOption!.optionId
          ? [
              {
                option_id: Number(selectedOption!.optionId!),
                option_value: selectedOption!.optionValue!,
              },
            ]
          : undefined,
      }
      await addItemToCart(addToCartBody)

      refreshCart()

      trackAddToCart(
        { content: blok, bcProduct: product } as ExtendedProduct,
        cart
      )

      toggleSidebar()
    } catch (err) {
      console.error(err)
      toast.error(
        'An error has occurred. Please contact support@mylifeforce.com if this issue continues'
      )
    } finally {
      setCtaLoading(false)
    }
  }

  const addToCartLabel = useMemo(() => {
    const defaultLabel = 'Add to Cart'

    if (!selectedOption) return defaultLabel

    if (PurchaseOptionCopy[selectedOption!.sku!]?.ctaTitle) {
      return PurchaseOptionCopy[selectedOption!.sku!]!.ctaTitle
    }

    if (isCtaDisabled && hasProductSubscription && !isOneTimePurchase) {
      return 'Already Subscribed'
    }

    if (isSelectedOptionRx) {
      return 'Add to Program'
    }

    return defaultLabel
  }, [selectedOption, hasProductSubscription, isCtaDisabled, isOneTimePurchase])

  const purchaseOptions = (
    <>
      {(category === 'diagnostic' || category === 'membership') && (
        <>
          <PurchaseOption
            {...PurchaseOptionCopy['LFPPP0001']}
            crossThroughPrice={formatPrice({
              amount: diagnostic?.price.value ?? 0,
              currencyCode: diagnostic?.price.currencyCode ?? 'USD',
              locale: 'en-US',
            })}
            price={formatPrice({
              amount: DEFAULT_SETUP_TOTAL,
              currencyCode: 'USD',
              locale: 'en-US',
            })}
            selected={selectedOption?.productId === product.id}
            setValue={() => setSelectedOption(membershipSelectOption)}
          />
          <PurchaseOption
            {...PurchaseOptionCopy['FB67VIR01']}
            price={formatPrice({
              amount: diagnostic?.price.value ?? 0,
              currencyCode: diagnostic?.price.currencyCode ?? 'USD',
              locale: 'en-US',
            })}
            selected={selectedOption?.productId === diagnostic?.id}
            setValue={() => setSelectedOption(diagnosticSelectOption)}
          />
        </>
      )}

      {category !== 'diagnostic' &&
        category !== 'membership' &&
        productOptionsWithPrices
          ?.sort((a, b) => a.label.localeCompare(b.label))
          .map((option, i) => {
            return (
              <PurchaseOption
                {...PurchaseOptionCopy[option.label]}
                price={option.price}
                crossThroughPrice={getOptionCrossThroughPrice(option)}
                key={i}
                selected={
                  selectedOption?.productId === option.productId &&
                  selectedOption?.optionValue === option.optionValue &&
                  selectedOption?.optionId === option.optionId
                }
                setValue={() =>
                  setSelectedOption({
                    sku: option.sku,
                    productId: option.productId,
                    variantId: option.variantId,
                    optionId: option.optionId,
                    optionValue: option.optionValue,
                  })
                }
              />
            )
          })}
    </>
  )

  const desktopView = (
    <>
      <div
        className={`purchaseModule ${
          hideHeader ? 'lg:hidden' : 'sticky'
        } left-0 block ${
          mainMobile && 'top-0'
        } transition-top transition-top duration-500 ease-in-out`}
        style={{
          top: 'calc(var(--header-top--offset) + 1.5rem)',
        }}
      >
        <div
          className={`bg-off-white text-dark-brown box-border lg:border border-dark-brown-40  rounded-[4px] flex flex-col justify-between  lg:mr-6 max-lg:py-0 lg:-mt-[calc(100vh_-_5rem)] transform transition-all max-lg:z-10 ${styles['max-height-container']} overflow-auto ${modifiers}`}
        >
          <div
            className={`p-5 pt-0 pb-0 top-0 left-0 bg-gradient-to-b w-full from-[70%] from-off-white to-transparent`}
          >
            {!hideHeader && (
              <PurchaseNav
                navIsBeforeScroll={navIsBeforeScroll}
                activeSection={activeSection}
                blok={blok}
              />
            )}

            <div className="flex lg:grow justify-center align-center  py-6 pb-0 lg:pt-20 lg:pb-14">
              <div
                className={classNames(
                  'text-center ',
                  category && category !== 'diagnostic'
                    ? 'flex lg:block flex-wrap w-full justify-between items-between'
                    : ''
                )}
              >
                {category && category !== 'diagnostic' && (
                  <span className="text-[0.75rem] capitalize order-2 lg:order-1 bg-light-sage px-3 mb-3 inline-block py-1 rounded-[40px]">
                    {category}
                  </span>
                )}
                <h1
                  className={classNames(
                    'h4 h-fit self-center',
                    category && category !== 'diagnostic'
                      ? 'order-1 lg:order-2'
                      : ''
                  )}
                >
                  {category === 'diagnostic' ? 'Choose your solution' : name}
                </h1>
              </div>
            </div>
          </div>

          <motion.div
            className="pt-6 sm:pt-4"
            animate={{
              opacity: !isBeforeScroll && !drawerIsOpen ? 0 : 1,
              maxHeight: !isBeforeScroll && !drawerIsOpen ? 0 : 'unset',
              overflow: !isBeforeScroll && !drawerIsOpen ? 'hidden' : 'visible',
            }}
          >
            {long_description && renderRichText(long_description) != '' && (
              <div className={`px-5 mb-8`}>
                <div
                  className={`${styles.text} text-dark-brown-65`}
                  dangerouslySetInnerHTML={{
                    __html: renderRichText(long_description),
                  }}
                />

                {rxAllowedStates && (
                  <span className={`utility text-dark-brown-65`}>
                    *Eligible States: {rxAllowedStates.split(',').join(', ')}
                  </span>
                )}
              </div>
            )}

            <div className="px-5">{purchaseOptions}</div>
          </motion.div>

          <div className="buy p-5 bg-gradient-to-t from-[80%] from-off-white to-transparent bottom-0 sticky left-0 z-4 border-brown lg:border-0 pt-5">
            <div className="flex ">
              <motion.div
                initial={{
                  opacity: 0,
                  width: 0,
                }}
                animate={{
                  opacity: !isBeforeScroll ? 1 : 0,
                  width: !isBeforeScroll ? 'auto' : 0,
                }}
              >
                <button
                  // disabled={isCtaDisabled}
                  onClick={() => {
                    setIsDrawerOpen && setIsDrawerOpen(!drawerIsOpen)
                  }}
                  className="button button--tertiary bg-red mr-3"
                >
                  <svg
                    width="30"
                    height="12"
                    viewBox="0 0 30 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.6421 1.14302L15 10.7852L5.35787 1.14302"
                      stroke="#26180F"
                      style={{
                        transformOrigin: 'center',
                      }}
                      className={`${drawerIsOpen ? 'rotate-180' : 'rotate-0'} `}
                    />
                  </svg>
                </button>
              </motion.div>

              <Button
                loading={ctaLoading}
                className="w-full"
                color="primary"
                disabled={isCtaDisabled}
                onClick={onCtaClick}
              >
                {addToCartLabel}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const mobileView = (
    <>
      <div
        ref={ref}
        className="ref lg:hidden show-bottom-bar absolute h-[calc(100%-100vh)] w-full top-[100vh] z-10 pointer-events-none"
      />
      <div
        className={`${
          hideHeader ? '' : 'sticky'
        } lg:hidden w-full z-20 bg-off-white pt-[1rem] lg:pt-0 left-0 block ${
          mainMobile && 'bottom-0'
        } transition-top  transition-top duration-[500ms] [transition-timing-function:cubic-bezier(0.4,0,0.2,1)] translate-y-[-1rem]`}
        style={{ top: 'var(--header-top--offset)' }}
      >
        <div
          className={`bg-off-white  text-dark-brown box-border lg:border border-brown/40 rounded-[4px] flex flex-col justify-between  lg:mr-6 max-lg:py-0 lg:-mt-[calc(100vh_-_2rem)] transform  transition-all max-lg:z-10 lg:max-h-[calc(100vh-6rem)] overflow-auto ${modifiers}`}
        >
          <div
            className={`p-5 w-full pt-0 pb-0 top-0 left-0 bg-gradient-to-b w-full from-[70%] from-off-white to-transparent`}
          >
            {!hideHeader && (
              <PurchaseNav
                navIsBeforeScroll={navIsBeforeScroll}
                activeSection={activeSection}
                blok={blok}
              />
            )}
          </div>
        </div>
      </div>

      <motion.button
        onClick={() => setIsDrawerOpen && setIsDrawerOpen(false)}
        initial={{
          opacity: 0,
          pointerEvents: 'none',
        }}
        animate={{
          opacity: drawerIsOpen ? 1 : 0,
          pointerEvents: drawerIsOpen ? 'auto' : 'none',
        }}
        className="h-screen lg:hidden w-full cursor-pointer bg-black/40 top-0 left-0 fixed z-20"
      ></motion.button>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: showBottomBar ? 1 : 0,
        }}
        className="fixed bottom-0 w-full left-0 z-20 bg-off-white lg:hidden "
      >
        <div className="flex lg:grow justify-center align-center py-4">
          <div
            className={classNames(
              'text-center px-5 ',
              category && category !== 'diagnostic'
                ? 'flex lg:block flex-wrap w-full justify-between items-between'
                : ''
            )}
          >
            {category && category !== 'diagnostic' && (
              <span className="text-[0.75rem] capitalize order-2 lg:order-1 bg-light-sage px-3 mb-3 inline-block py-1 rounded-[40px]">
                {category}
              </span>
            )}
            <h1
              className={classNames(
                'h4 h-fit self-center',
                category && category !== 'diagnostic'
                  ? 'order-1 lg:order-2'
                  : ''
              )}
            >
              {category === 'diagnostic' ? 'Choose your solution' : name}
            </h1>
          </div>
        </div>

        <motion.div
          animate={{
            opacity: !drawerIsOpen ? 0 : 1,
            height: !drawerIsOpen ? 0 : 'auto',
            overflow: !drawerIsOpen ? 'hidden' : 'visible',
          }}
        >
          {long_description && (
            <div className={`px-5 mb-8 ${styles}`}>
              <div
                className={`richtext ${styles.text}`}
                dangerouslySetInnerHTML={{
                  __html: renderRichText(long_description),
                }}
              />
            </div>
          )}

          <div className="px-5 mb-4">{purchaseOptions}</div>
        </motion.div>

        <div className="buy p-5 pt-0 bg-gradient-to-t from-[80%] from-off-white to-transparent bottom-0 sticky left-0 z-4 border-brown lg:border-0">
          <div className="flex ">
            <motion.div>
              <button
                onClick={() => {
                  setIsDrawerOpen && setIsDrawerOpen(!drawerIsOpen)
                }}
                className="button button--tertiary bg-red mr-3"
              >
                <svg
                  width="30"
                  height="12"
                  viewBox="0 0 30 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    style={{
                      transformOrigin: 'center',
                    }}
                    className={drawerIsOpen ? 'rotate-0' : 'rotate-180'}
                    d="M24.6421 1.14302L15 10.7852L5.35787 1.14302"
                    stroke="#26180F"
                  />
                </svg>
              </button>
            </motion.div>

            <Button
              loading={ctaLoading}
              className="w-full"
              color="primary"
              disabled={isCtaDisabled}
              onClick={onCtaClick}
            >
              {addToCartLabel}
            </Button>
          </div>
        </div>
      </motion.div>
    </>
  )

  return (
    <>
      {!mobile && desktopView}
      {mobile && mobileView}
    </>
  )
}

const PurchaseOptionCopy: any = {
  'One-time': {
    title: 'One-Time Purchase',
    richtext: <></>,
  },
  Monthly: {
    title: 'Monthly',
    richtext: (
      <ul className="list-disc pl-6 pb-2 text-[0.875rem]">
        <li>Ships every 30 days</li>
        <li>Cancel, modify or skip anytime</li>
      </ul>
    ),
    save_label: 'Save $20',
  },
  '25 Day': {
    title: '25 Days',
    richtext: (
      <ul className="list-disc pl-6 pb-2 text-[0.875rem]">
        <li>Ships every 25 days</li>
        <li>Cancel, modify or skip anytime</li>
      </ul>
    ),
  },
  LFPPP0001: {
    title: 'Lifeforce Membership',
    ctaTitle: 'Buy Lifeforce Membership',
    richtext: (
      <>
        <ul className="list-disc pl-0 pt-1 text-[0.875rem]">
          <p className="mb-2 text-[0.875rem]">
            Ongoing monthly fee ${LF_CLASSIC_MEMBERSHIP_MONTHLY_PRICE} (cancel
            anytime)
          </p>
          <ul className="list-disc pl-6 pb-2 text-[0.875rem]">
            <li>
              At-home phlebotomist blood draw every 3 months covering 50+
              biomarkers
            </li>
            <li>1:1 clinical consultation every 3 months</li>
            <li>Unlimited health coaching</li>
            <li>20% off supplements</li>
            <li>Access to members-only pharmaceuticals</li>
            <li>Personalized program and health tracking dashboard</li>
          </ul>
        </ul>
      </>
    ),
  },
  FB67VIR01: {
    title: 'One-Time Diagnostic',
    ctaTitle: 'Buy One-Time Diagnostic',
    richtext: (
      <>
        <ul className="list-disc pl-0 pt-1 text-[0.875rem]">
          <ul className="list-disc pl-6 pb-2 text-[0.875rem]">
            <li>At-home phlebotomist blood draw covering 50+ biomarkers</li>
            <li>Telehealth consultation with clinician</li>
            <li>Personalized health program and recommendations</li>
          </ul>
        </ul>
      </>
    ),
  },
}
